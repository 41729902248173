#mortgage-page {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: spacing(2);

	max-width: 1400px;
	margin: 0 auto;

	@include if-size("desktop") {
		grid-template-columns: 1fr 1fr;
		grid-gap: spacing(3);
	}

	@include if-size("desktop-xl") {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: spacing(3);
	}

	#mortgage-banner {
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 1 / -1;
		}
	}

	#property-value-chart-and-feedback {
		&.span-full-grid {
			grid-column: 1 / -1;

			@include if-size("desktop") {
				grid-column: 1 / -1;
			}
		}

		&.two-columns {
			@include if-size("desktop") {
				grid-column: span 2;
			}
		}
	}

	#roi-calculator-and-feedback {
		grid-column: span 1;

		@include if-size("desktop") {
			grid-column: span 2;
		}

		@include if-size("desktop-xl") {
			grid-column: span 1;
		}
	}

	.mortgage-rates-mortgage-details-cards {
		display: flex;
		flex-direction: column-reverse;
		gap: spacing(2);
		flex-wrap: wrap;
		@include if-size("desktop") {
			gap: spacing(3);
			grid-column: span 1;
		}

		@include if-size("desktop-xl") {
			grid-column: span 2;
		}

		@include if-size("monitor") {
			flex-direction: row;
		}

		#current-mortgage-rates-card {
			//flex: 1;
		}

		#mortgage-details {
			flex: 1;
		}

		.mortgage-details-col {
			flex: 1;
		}
	}

	#mortgage-feedback,
	.feedback-feature-rating-given {
		grid-column: 1 / -1;
	}

	#property-value-chart-and-feedback,
	#roi-calculator-and-feedback {
		#mortgage-feedback,
		#roi-calculator-feedback {
			position: relative;
			padding: spacing(2);
			border: 1px solid #d4dee3;
			box-shadow:
				-2px 13px 28px 0px #ababab1a,
				-7px 51px 51px 0px #ababab17,
				-16px 115px 70px 0px #ababab0d,
				-28px 204px 82px 0px #ababab03,
				-44px 319px 90px 0px #ababab00;

			margin-top: spacing(2);
			@include if-size("desktop") {
				margin-top: spacing(3);
			}
		}

		.feedback-feature-rating-given {
			border: 1px solid #d4dee3;
			box-shadow:
				-2px 13px 28px 0px #ababab1a,
				-7px 51px 51px 0px #ababab17,
				-16px 115px 70px 0px #ababab0d,
				-28px 204px 82px 0px #ababab03,
				-44px 319px 90px 0px #ababab00;
		}
	}

	#equity-debt-cards {
		order: 3;
		position: relative;
		background-color: $white;
		box-shadow:
			-2px 13px 28px 0px #ababab1a,
			-7px 51px 51px 0px #ababab17,
			-16px 115px 70px 0px #ababab0d,
			-28px 204px 82px 0px #ababab03,
			-44px 319px 90px 0px #ababab00;

		border: solid 1px $slate;
		border-radius: 16px;
		padding: spacing(2);
		display: flex;
		flex-direction: column;
		gap: 16px;
		overflow: hidden;
		@include if-size("desktop") {
			padding: spacing(3);
		}
		.line-seperator {
			width: 100%;
			height: 1px;
			border: 1px solid $sand;
		}
		.graph-img-back-wrapper {
			position: absolute;
			top: -30px;
			left: -50%;
			transform: translate(-50%, 0);
			opacity: 0.2;
		}
	}
}
