#bill-reduction-otp-request-page {
	#bill-reduction-otp-request-page-header {
		background: $sky-light;

		#bill-reduction-otp-request-page-header-content {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: spacing(3) spacing(2);
			gap: spacing(3);

			@include if-size("desktop") {
				flex-direction: row;
				padding: spacing(3);
				max-width: 1136px;
				margin: 0 auto;
			}
		}

		#bill-reduction-otp-request-page-header-icon {
			svg {
				height: 132px;
				width: 150px;
			}

			@include if-size("tablet") {
				svg {
					height: 241px;
					width: 272px;
				}
			}
		}

		#bill-reduction-otp-request-page-header-text {
			flex: 1;
		}
	}

	#bill-reduction-otp-request-page-body {
		padding: spacing(4) spacing(2);
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		gap: spacing(4);

		@include if-size("desktop") {
			gap: 0;
			flex-direction: row;
			flex-wrap: wrap;
			padding: spacing(6) spacing(3);
			max-width: 1136px;
			margin: 0 auto;
		}

		#bill-reduction-otp-request-page-body-info-container {
			display: flex;
			flex-direction: column;
			gap: spacing(2);

			.bill-reduction-otp-request-page-body-info-card {
				width: max(326px, 100%);
				border-radius: 8px;
				padding: spacing(1.5) spacing(2);
				border: 1px solid $slate;
				background: $denim-lightest;
				display: flex;
				flex-direction: column;
				gap: spacing(1);

				@include if-size("desktop") {
					width: 326px;
				}

				.bill-reduction-otp-request-page-body-info-card-row {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.label {
						font-weight: 200;
					}
				}
			}
		}

		#bill-reduction-otp-request-page-body-input-container {
			@include if-size("desktop") {
				max-width: 656px;
			}

			.bill-reduction-otp-request-page-body-input {
				.textarea-label {
					font-size: 12px;
					font-weight: 200;
					margin-left: 0;
				}
			}

			#bill-reduction-otp-request-page-body-input-cta {
				display: flex;
				align-items: center;
				margin: 0 auto;

				svg {
					margin-top: 3px;
					height: 20px;
					width: 20px;
				}
			}
		}
	}
}
