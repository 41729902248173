#testimonials-page {
	#testimonials-fixed-scroll-div {
		position: fixed;
		bottom: 16px;
		right: 26px;
		display: none;
		@include if-size("tablet") {
			bottom: 24px;
			right: 32px;
		}
		&.testimonials-fixed-scroll-div-show {
			display: unset;
		}
	}
	#testimonials-fixed-scroll-btn {
		svg {
			width: 12px;
			height: 12px;
			margin-left: 4px;
			position: relative;
			top: 1.5px;

			@include if-size("tablet") {
				height: 14px;
				top: 2px;
			}
		}
	}
	#testimonials-page-hero-section {
		padding: spacing(6) 0 spacing(8) 0;

		#testimonials-page-hero-img {
			grid-column: 1 / -1;
			margin: 0 auto spacing(2);
			max-width: 50%;
			width: 250px;
			height: auto;

			@include if-size("desktop") {
				width: 100%;
				max-width: 300px;
				grid-column: 2 / 5;
				margin: 0;
			}
		}

		#testimonials-page-title-container {
			grid-column: 1 / -1;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include if-size("desktop") {
				grid-column: 5 / 12;
				padding-left: 16px;
			}
		}

		#testimonials-page-title {
			text-align: center;

			@include if-size("desktop") {
				text-align: left;
			}
		}

		#testimonials-page-body {
			grid-column: 1 / -1;
			margin: spacing(1) 0 0;

			@include if-size("desktop") {
				margin: spacing(1) 0 spacing(4);
			}
		}
	}
}

#testimonials-page-endless-section {
	background-color: $sky-light;
	padding: spacing(8) 0;

	.testimonials-page-endless-section-wrapper-one,
	.testimonials-page-endless-section-wrapper-two,
	.testimonials-page-endless-section-wrapper-three {
		grid-column: span 4;
	}

	.testimonials-endless-card {
		background-color: $white;
		box-shadow: 0px 4px 16px 0px #7f939d2e;
		border-radius: 8px;
		margin-bottom: 16px;
		padding: 16px;
		break-inside: avoid-column;
		page-break-inside: avoid;
		display: inline-block;
		width: 100%;

		.testimonials-endless-card-review {
			padding-top: 12px;
			padding-bottom: spacing(1);

			font-family: "sofia-pro";
			font-size: 14px;
			font-weight: 433;
			line-height: 18px;
			letter-spacing: -0.015em;
			text-align: left;

			@include if-size("desktop") {
				padding-top: 16px;
				padding-bottom: spacing(1);
				font-size: 16px;
				font-weight: 433;
				line-height: 22px;
				letter-spacing: -0.01em;
			}
		}

		.testimonials-endless-card-review-from-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.testimonials-endless-card-name {
				padding-top: 4px;
			}

			.google-review-icon {
				width: 24px;
				height: 24px;
			}
		}
	}

	.testimonials-endless-card-location {
		padding-left: 16px;
	}
}

.testimonials-fade-in {
	animation: fadeInAnimation 1s linear;
	animation-iteration-count: 1;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
