.table-pagination {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 12px;

	.table-pagination-buttons {
		display: flex;
		flex-direction: row;
		gap: 12px;
		justify-content: center;
		align-items: center;

		.table-pagination-skip-buttons {
			display: flex;
			flex-direction: row;
			gap: 6px;
			justify-content: center;
			align-items: center;
		}

		.table-pagination-page-buttons {
			display: flex;
			flex-direction: row;
			gap: 4px;
			justify-content: center;
			align-items: center;
		}

		.table-pagination-button {
			border: solid 1px $slate;
			border-radius: 32px;
			height: 32px;
			width: 32px;
			background-color: white;
			@include if-size("desktop") {
				height: 42px;
				width: 42px;
			}

			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				cursor: pointer;
			}

			svg {
				width: 12px;
				height: 12px;
			}

			p {
				margin-bottom: 4px;
			}

			&:hover {
				background-color: $azure;
				border-color: $azure;

				p {
					color: $white;
				}

				svg path {
					fill: $white;
				}
			}

			&-active {
				background-color: $azure;
				border-color: $azure;

				p {
					color: $white;
				}

				&:hover {
					background-color: $royal;
					border-color: $royal;
				}
			}

			&-disabled {
				svg {
					path {
						fill: $slate;
					}
				}

				&:hover {
					background-color: $denim-lightest;
					border-color: $denim-lightest;
				}
			}
		}
	}
}
