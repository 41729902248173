#bill-reduction-modal-container {
	@include if-size("desktop") {
		> .property-grid {
			display: flex; // This is a hack to center the smaller than usual modal (desktop fixed width 944px)
		}
	}
}

#bill-reduction-modal {
	padding: 32px;
	@include if-size("desktop") {
		width: 944px;
	}
}

#bill-reduction-form {
	#bill-reduction-heading {
		font-size: 20px;
		line-height: 28px;
	}
}

.bill-reduction-form-content {
	background: $denim_lightest;
	border-radius: 16px;
	padding: spacing(2);
	margin-top: spacing(2);

	.bill-reduction-split-row {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: spacing(2);

		@include if-size("desktop") {
			flex-direction: row;
			gap: spacing(3);
		}

		> * {
			flex: 1;
			width: 100%;
		}
	}

	.bill-reduction-split-row-2 {
		@extend .bill-reduction-split-row;
		gap: spacing(1);

		@include if-size("desktop") {
			gap: spacing(2);
		}
	}

	.bill-reduction-form-right-comment {
		align-self: end;
		padding-bottom: 6px;
	}

	select.with-label {
		margin-top: 0;
	}
}

.bill-reduction-other-info {
	background-color: $sunflower-lightest;
	padding: 12px 16px;
	margin: -8px 0 16px;
	border-radius: 8px;

	p {
		color: $rust;
	}
}

.bill-reduction-highly-recommended {
	position: absolute;
	top: calc(50% - 3px);
	right: 8px;
	padding: 6px;
	border-radius: 6px;
	background-color: $sky-light;

	p {
		color: $royal;
	}
}

#bill-reduction-success-animation-container {
	margin: 0 auto;
	width: fit-content;
}

#bill-reduction-service-content {
	background: $white;
	border-radius: 16px;

	@include if-size("desktop") {
		padding: spacing(2);
	}
	.denied-icon {
		width: 20px;
		height: 20px;
		fill: $denim_5;
		margin-left: 5px;
		g path {
			fill: $denim_5;
		}
	}
}
