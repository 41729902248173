$header-height: 90px;

#site-header {
	width: 100%;
	height: $header-height;
	background-color: $cloud;
	position: absolute;
	top: 0;
	z-index: 3000;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&-absolute {
		@extend #site-header;
		position: fixed;
		top: -90px;
		transform: translateY(-90px);
		transition: transform 0.5s linear;
	}

	&-sticky {
		@extend #site-header;
		position: fixed;
		top: -90px;
		transform: translateY(90px);
		transition: transform 0.3s;
	}

	&.site-header-skyblue {
		background-color: $sky-light;
	}

	&.site-header-dark {
		background-color: $denim-black;
		.nav-link {
			.dark-theme {
				color: white;
				&:hover {
					color: $powder;
				}
			}
			.fill-white path {
				fill: white;
			}
			.fill-white:hover {
				path {
					fill: $powder;
				}
			}
		}
		.white-outline {
			border-color: white;
			color: white;
		}
	}

	#site-logo {
		grid-column: span 2;
		cursor: pointer;
		max-width: 130px;

		@include if-size("tablet") {
			grid-column: span 3;
			max-width: 150px;
		}

		@include if-size("desktop-lg") {
			grid-column: span 2;
		}
		.dark-theme-logo {
			svg .logo_svg__cls-1 {
				fill: white;
			}
		}
	}

	.header-desktop-link-container {
		display: none;
		gap: 18px;

		@include if-size("desktop-lg") {
			margin-top: 4px;
			display: flex;
			align-items: center;
		}
	}

	.nav-link {
		color: $denim;
		cursor: pointer;
		white-space: nowrap;
		p {
			color: $denim;
			font-size: 16px;
		}

		&:hover {
			p {
				color: $royal;
			}
		}

		&-vertical {
			padding: 12px 30px 12px 40px;
			@extend .nav-link;
			width: 270px;

			&:last-of-type {
				margin-bottom: 0px;
			}

			&:hover {
				background-color: $denim_lightest;
				p {
					font-weight: 700;
				}
				svg {
					display: block;
				}
			}

			&-nav-container {
				display: flex;
				align-items: center;
				gap: 2px;
			}

			p {
				color: $royal;
			}

			svg {
				display: none;
				width: 16px;
				height: 16px;
				margin-top: 3px;
				fill: $royal;
			}
		}
		// &-vertical:not(:last-child) {
		// 	border-bottom: 1px solid $denim;
		// }
	}

	.nav-link-active > flex > p {
		font-weight: bold;
		color: $royal;
	}

	.nav-link-section-container {
		position: relative;

		.nav-link-section-popover {
			background-color: $white;
			padding: 16px 0;
			width: auto;
			position: absolute;
			top: 120%;
			// margin-top: 35px;
			left: 0;
			border-radius: 12px;
			box-shadow:
				-44px 319px 90px 0px rgba(171, 171, 171, 0),
				-28px 204px 82px 0px rgba(171, 171, 171, 0.01),
				-16px 115px 70px 0px rgba(171, 171, 171, 0.05),
				-7px 51px 51px 0px rgba(171, 171, 171, 0.09),
				-2px 13px 28px 0px rgba(171, 171, 171, 0.1);

			.nav-link:first-of-type {
				margin-left: 0;
			}
		}
	}

	.header-btn-group {
		display: none;

		@include if-size("tablet") {
			margin-top: 4px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			grid-column: -6 / -2;
		}

		&-partner {
			@extend .header-btn-group;
			@include if-size("tablet") {
				grid-column: span 3;
			}

			@include if-size("desktop") {
				grid-column: -6 / -2;
			}

			@media (min-width: 1060px) and (max-width: 1100px) {
				#header-log-in-btn,
				#header-tablet-start-appeal-btn {
					padding-left: 24px;
					padding-right: 24px;
				}
			}
		}
		@include if-size("desktop-lg") {
			grid-column: -6/ -1;
		}
	}

	#header-tablet-start-appeal-btn {
		white-space: nowrap;
		width: fit-content;
		height: fit-content;
		align-self: center;
		display: none;
		@include if-size("tablet") {
			display: block;
			margin-left: spacing(2);
		}
	}

	#header-log-in-btn {
		display: none;
		height: fit-content;
		align-self: center;
		width: fit-content;
		margin-left: auto;

		@include if-size("desktop-lg") {
			display: block;
		}
	}

	.header-hamburger-container {
		grid-column: -2 / -1;
		align-self: center;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 4px;

		@include if-size("desktop-lg") {
			display: none;
		}

		#hamburger-open {
			margin-top: spacing(1);
		}

		&.dark-theme {
			#hamburger-open path {
				fill: white;
			}
		}
	}

	.header-hamburger {
		cursor: pointer;
		fill: $denim;
	}

	#header-hamburger-menu {
		position: fixed;
		right: 0;
		top: 90px;
		//height: 100svh;
		width: 100%;
		background-color: $sky-light;
		z-index: 100000;

		@include if-size("tablet") {
			height: auto;
			position: absolute;
		}

		.grid > * {
			grid-column: 1 / -1;
		}

		.hamburger-btn-container {
			//position: fixed;
			width: 100%;
			bottom: 0;
			//margin: spacing(4) 0 0;
			padding-bottom: spacing(2);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			flex-wrap: wrap;
			//background-color: $denim_lightest;

			@include if-size("tablet") {
				position: static;
				padding: spacing(2) 0 spacing(4);
			}

			#hamburger-login-btn {
				width: 100%;
				margin: 0;
			}

			.hamburger-btn {
				margin: 15px auto 0;
				max-width: 300px;
				width: 100%;
			}
		}

		.hamburger-link-container {
			padding-bottom: 12px;
			margin-top: 12px;
			border-bottom: 1px solid $sky;
			cursor: pointer;
			color: $denim;

			&#hamburger-language-picker-container {
				border-bottom: none;
			}

			.hamburger-link-expanded-container {
				margin-top: spacing(1);
			}

			&-inner {
				cursor: pointer;
				width: 98%;

				svg {
					width: 16px;
					height: 16px;
					margin-left: 4px;
					position: relative;
					top: 2px;
					fill: none;
				}

				&:last-of-type {
					padding-bottom: 0;
				}

				a > .hamburger-link {
					font-size: 16px;
					padding: 8px;

					&:hover,
					&:active {
						font-weight: 622;
						background-color: $denim_lightest;
						color: $royal;
						svg {
							fill: $royal;
						}
					}
				}
			}

			.hamburger-link {
				user-select: none;
				color: $denim;
				font-size: 18px;

				&:hover,
				&:active {
					color: $royal;
					font-weight: 622;
				}
			}

			.hamburger-sublink {
				padding-left: 24px;
			}

			.hamburger-link-container-mulitple {
				display: flex;
				justify-content: space-between;
				margin-bottom: 8px;
			}

			.nav-link-vertical {
				padding: 8px;
			}

			#nav-link-group-arrow-down,
			#nav-link-group-arrow-up {
				width: 16px;
				height: 16px;
			}
		}

		#header-hambuger-menu-top {
			overflow-y: scroll;
			height: calc(100dvh - 90px);
		}
	}

	.expand-icon {
		height: 20px;
		width: 12px;
		margin-left: 7px;
		margin-top: 1px;
		fill: $denim;

		&-dt {
			fill: $denim;
			height: 12px;
			width: 12px;
			margin-top: 5px;
			margin-left: 6px;
		}
	}
}

#minimal-site-header {
	width: 100%;
	background-color: $denim-lightest;
	z-index: 3000;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0;
}

#minimal-site-logo {
	max-width: 175px;
	margin: 0 auto;
}

.exemptions-header {
	background: $sky-light !important;

	&#site-header .header-hamburger-container {
		@include if-size("tablet") {
			display: none;
		}
	}

	&#site-header #header-hamburger-menu .hamburger-btn-container {
		margin: 0 auto;
	}
}

#header-desktop-language-picker {
	display: none;

	@include if-size("desktop-lg") {
		display: block;
	}
}

#hamburger-language-picker-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: none;

	.hamburger-language-picker-copy-container {
		display: flex;
		align-items: center;

		p {
			display: none;

			@include if-size("tablet") {
				display: block;
				margin-left: spacing(1);
			}
		}
	}

	.language-picker-switch {
		background-color: $denim_lightest;
	}
}

#nav-link-group-arrow-up,
#nav-link-group-arrow-down {
	margin-top: 3px;
	margin-left: 2px;
	width: 12px;
	height: 12px;
}

.site-header-top-mobile-hamburger-opened {
	transition: color 1s;
	#site-header,
	#site-header #header-hamburger-menu {
		background-color: $white !important;
	}

	#site-header {
		position: fixed;
	}
	&.site-header-logged-in #site-header-sticky .header-btn-group {
		display: none;
	}

	#site-logo,
	#hamburger-close {
		z-index: 1000000;
	}
}

.site-header-logged-in {
	#site-header {
		.header-desktop-link-container {
			display: none;
			@include if-size("desktop-nav-bar-signed-in") {
				margin-top: 4px;
				display: flex;
				align-items: center;
			}
		}

		.header-hamburger-container {
			grid-column: -2 / -1;
			align-self: center;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-top: 4px;

			@include if-size("desktop-nav-bar-signed-in") {
				display: none;
			}
		}

		.header-btn-group {
			display: none;

			@include if-size("tablet") {
				margin-top: 4px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				grid-column: -6 / -2;
			}

			@include if-size("desktop") {
				grid-column: -8/-2;
			}

			@include if-size("desktop-nav-bar-signed-in") {
				grid-column: -6/ -1;
			}
		}

		#header-tablet-start-appeal-btn {
			white-space: nowrap;
			width: fit-content;
			height: fit-content;
			align-self: center;
			display: none;
			@include if-size("tablet") {
				display: block;
				margin-left: spacing(2);
			}
		}

		#header-log-in-btn {
			display: none;
			height: fit-content;
			align-self: center;
			width: fit-content;
			margin-left: auto;

			@include if-size("desktop-nav-bar-signed-in") {
				display: block;
			}
		}

		.header-hamburger-container {
			grid-column: -2 / -1;
			align-self: center;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-top: 4px;

			@include if-size("desktop-nav-bar-signed-in") {
				display: none;
			}
		}

		#header-log-in-btn {
			display: none;
			height: fit-content;
			align-self: center;
			width: fit-content;
			margin-left: auto;

			@include if-size("desktop") {
				display: block;
			}
		}
	}
}
