#roi-calculator {
	height: min-content;
	padding: spacing(2);
	border: 1px solid #d4dee3;
	position: relative;
	background-color: $white;
	box-shadow:
		-2px 13px 28px 0px #ababab1a,
		-7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d,
		-28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;
	border-radius: 16px;
	overflow: hidden;

	@include if-size("desktop") {
		padding: spacing(3);
	}

	.grey-graph {
		z-index: 0;
		position: absolute;
		mask-image: linear-gradient(to top, transparent 0%, black 10%);

		top: -12px;
		left: -480px;
		min-width: 100%;
		min-height: 136px;
	}

	.green-graph,
	.red-graph {
		z-index: 0;
		position: absolute;
		mask-image: linear-gradient(to top, transparent 0%, black 10%);

		top: 12px;
		right: -24px;
		min-width: 100%;
		min-height: 136px;

		@include if-size("tablet") {
			top: -24px;
			right: -4px;
			height: auto;
		}

		@include if-size("desktop") {
			top: -64px;
			right: -24px;
		}

		@include if-size("monitor") {
			height: 150px;
			top: 12px;
			right: -24px;
		}
	}

	* {
		position: relative;
		z-index: 2;
	}

	.cash-return {
		display: flex;
		flex-wrap: wrap;
		align-items: end;
		column-gap: spacing(1);
	}

	.data-change {
		display: flex;
		justify-content: space-around;
		align-items: center;
		text-align: center;
		gap: 24px;
		margin: 16px 0;
		.vertical-divider {
			width: 1px;
			border-right: 1px solid $sand;
			height: 60px;
		}
	}

	.btns-container {
		margin-top: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 12px;
		flex-direction: column;
	}

	.quick-link-link {
		font-size: 12px;
		font-weight: 622;
		line-height: 18px;
		letter-spacing: -0.02em;

		@include if-size("desktop") {
			font-size: 14px;
			font-weight: 622;
			line-height: 20px;
			letter-spacing: -0.015em;
		}
	}

	.gradient-border {
		position: relative;
		padding: 12px; /* Adjust padding to your needs */
		background: white; /* Background color for the content area */
		border-radius: 8px; /* Border radius */
		z-index: 1;
		overflow: hidden; /* Ensure the pseudo-elements follow the border radius */

		&:before {
			content: "";
			position: absolute;
			top: -5px;
			left: -5px;
			right: -5px;
			bottom: -5px;
			background: linear-gradient(180deg, #57c975 0%, $azure 100%);
			z-index: -1;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: white; /* Match this to the .box background */
			border-radius: 7px; /* Match border radius of .box */
			margin: 1px; /* This creates the border effect */
			z-index: -1;
		}
	}

	.kelly-azure-gradient-border {
		&:before {
			background: linear-gradient(180deg, #57c975 0%, $azure 100%);
		}
	}

	.rust-gradient-border {
		&:before {
			background: linear-gradient(180deg, #de906f 0%, #de906f 100%);
		}
	}
}

#roi-calculation-modal {
	.sky-light-section {
		background-color: $sky-light;
		border-radius: 16px;
		padding: 12px 16px;
	}

	.sunflower-lightest-section {
		background-color: $sunflower-lightest;
		border-radius: 16px;
		padding: 12px 16px;
	}

	.section-title {
		display: flex;
		align-items: center;
		gap: 4px;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	.sunflower-light-section {
		background-color: $sunflower-light;
		border-radius: 16px;
		padding: 16px;
		flex-wrap: nowrap;
		align-items: center;
		width: min-content;

		&.desktop {
			display: none;
			@include if-size("tablet") {
				display: flex;
			}
		}

		&.mobile {
			display: flex;
			@include if-size("tablet") {
				display: none;
			}
		}

		.equation-text {
			font-size: 8px;
			@media screen and (min-width: 320px) {
				font-size: 10px;
			}
			@media screen and (min-width: 360px) {
				font-size: 12px;
			}
			@media screen and (min-width: 400px) {
				font-size: 14px;
			}
			@include if-size("tablet") {
				font-size: 12px;
			}
			@media screen and (min-width: 780px) {
				font-size: 14px;
			}
			@include if-size("monitor") {
				font-size: 16px;
			}
		}

		.flex-gap-dynamic {
			gap: 0px;
			@media screen and (min-width: 450px) {
				gap: 4px;
			}
			@include if-size("tablet") {
				gap: 2px;
			}
			@include if-size("desktop") {
				gap: 8px;
			}
		}
	}

	.division-line {
		width: 100%;
		background-color: $denim_5;
		height: 1px;
		border-radius: 2px;
	}
}

.view-documents-icon {
	width: 18px;
	height: 18px;

	@include if-size("desktop") {
		width: 20px;
		height: 20px;
	}

	path {
		fill: $denim;
	}
}
