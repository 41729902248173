#mortgage-banner {
	position: relative;
	border: solid 1px $powder;
	background-color: $sky;
	box-shadow:
		-2px 13px 28px 0px #ababab1a,
		-7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d,
		-28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;
	border-radius: 16px;
	padding: spacing(2);

	&:hover {
		cursor: pointer;
	}

	&-svg {
		&-house {
			flex: 0;
			min-width: 64px;
			min-height: 65px;
		}

		&-clipboard {
			flex: 0;
			min-width: 78px;
			min-height: 76px;
		}
	}

	&-title-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: spacing(2);

		@include if-size("desktop") {
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
		}
	}

	&-mobile-layout {
		display: flex;
		flex-direction: column;
		gap: spacing(1);
		align-items: flex-start;

		@include if-size("desktop") {
			display: none;
		}
	}

	&-desktop-layout {
		display: none;

		@include if-size("desktop") {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: spacing(3);
		}
	}

	.cta-buttons {
		margin: 0 auto;
		margin-top: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 6px;

		@media screen and (min-width: 420px) {
			width: 100%;
			margin: 0;
			flex-direction: row;
			justify-content: center;
		}
	}
}
