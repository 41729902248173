.toggle-button {
	isolation: isolate;
	display: inline-flex;
	width: 140px;
	background-color: $denim_lightest;
	border-radius: 24px;
	cursor: pointer;
	position: relative;
	overflow: hidden; /* Ensures the toggle button doesn't overlap the container */

	border: 4px solid #f6f6f6;
	margin-top: spacing(1);
	margin-bottom: spacing(1);
}

.toggle-handle {
	position: absolute;
	height: 100%;
	width: 50%; /* Ensure the button covers half of the wrapper */
	border-radius: 24px;
	background-color: $sky;
	border: 1px solid $azure;
	z-index: 1; /* Ensure the handle is above the text */
	mix-blend-mode: darken;
}

.text-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	position: relative;
	z-index: 0; /* Ensure text is below the handle */
}

.toggle-text {
	flex: 1; /* Each text container takes up half the space */
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: $denim_5;
	font-size: 12px;
	pointer-events: none;
	white-space: nowrap;

	padding: 5px 8px;
	@include if-size("desktop") {
		font-size: 14px;
		letter-spacing: 0.05em;
		padding: spacing(1) spacing(2);
	}

	&.active {
		color: $azure;
	}
}
