.about-page-default-section-padding {
	padding: 64px 0;
	@include if-size("desktop") {
		padding: 96px 0;
	}
}
#about-page {
	width: 100%;
	background-color: $white;

	#landing-big-number-counter-section {
		height: auto;
		@extend .about-page-default-section-padding;
	}

	#landing-signup-steps-steps {
		align-items: flex-start;
	}
}

#about-reviews-section {
	padding-bottom: spacing(12);
}

.about-our-mission {
	grid-column: 1/-1;

	h4 {
		font-weight: 727;
		color: $denim_extra_light;
	}
}

#about-customers {
	@extend .about-page-default-section-padding;
	#about-customers-section {
		@extend #landing-signup-steps-section;
		grid-column: 1/-1;

		h3 {
			text-align: center;
			@include if-size("desktop") {
				text-align: left;
			}
		}
	}
}

#about-product {
	background-color: $denim_lightest;
	@extend .about-page-default-section-padding;

	h3 {
		grid-column: 1/-1;
	}

	.about-product-img {
		grid-column: 1/-1;

		@include if-size("desktop") {
			grid-column: 1/7;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
	.about-product-content {
		margin: auto;
		grid-column: 1/-1;
		padding-top: 24px;

		@include if-size("desktop") {
			grid-column: 7/-1;
			padding-top: 0;
			padding-left: 16px;
		}
	}
}

#about-technology {
	@extend .about-page-default-section-padding;

	h3 {
		grid-column: 1/-1;
	}

	.about-technology-text {
		margin: auto;
		grid-column: 1/-1;
		order: 2;
		@include if-size("desktop") {
			grid-column: 1/6;
			order: 1;
		}
	}

	.about-technology-img {
		grid-column: 1/-1;
		padding: 0 0 24px;
		order: 1;

		@include if-size("desktop") {
			grid-column: 6/-1;
			padding: 16px 32px;
			order: 2;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
}

#about-results {
	@extend .about-page-default-section-padding;
	background-color: $denim_lightest;

	#about-results-section {
		grid-column: 1/-1;
		display: flex;
		flex-direction: column;
		gap: spacing(4);

		h3 {
			text-align: center;
			@include if-size("desktop") {
				text-align: left;
			}
		}
	}

	#landing-stat-section-stats {
		max-width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: spacing(2);

		@include if-size("desktop") {
			flex-direction: row;
		}

		.landing-stat-section-stat {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			@include if-size("desktop") {
				flex: 1 0;
				width: 368px;
			}

			&-value {
				font-family: "sofia-pro";
				font-weight: 800;
				font-size: 62px;
				line-height: 68.2px;
				letter-spacing: -0.01em;
				text-align: center;

				@include if-size("desktop") {
					font-size: 62px;
					font-weight: 838;
					line-height: 68.2px;
				}

				display: flex;
				flex-direction: row;
				align-items: baseline;
				justify-content: center;

				.review-star {
					width: 40px;
					height: 40px;
				}
			}

			&-description {
				color: $denim_5;
				font-family: "sofia-pro";
				font-weight: 433;
				font-size: 20px;
				font-weight: 433;
				line-height: 28px;
				letter-spacing: -0.01em;
				text-align: center;

				@include if-size("desktop") {
					font-size: 24px;
					font-weight: 433;
					line-height: 30px;
				}
			}
		}
	}
}
