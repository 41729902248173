.clipboard-copy-container {
	background-color: $white;
	position: relative;
	width: 100%;

	.clipboard-copy-input {
		position: relative;
		cursor: pointer;
		display: block;
		box-sizing: border-box;
		box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding-right: 75px;
		width: 100%;
	}

	#clipboard-copy-text {
		position: absolute;
		top: 50%;
		right: spacing(2);
		transform: translateY(-50%);
		color: $azure;
	}
}
