.px-auto {
	padding-left: auto;
	padding-right: auto;
}

.pl-auto {
	padding-left: auto;
}

.pr-auto {
	padding-right: auto;
}

@for $i from 1 through 8 {
	.pb-#{$i} {
		padding-bottom: spacing($i);
	}

	.pb-#{$i - 1}_5 {
		padding-bottom: spacing($i - 0.5);
	}
}

@for $i from 1 through 8 {
	.pt-#{$i} {
		padding-top: spacing($i);
	}

	.pt-#{$i - 1}_5 {
		padding-top: spacing($i - 0.5);
	}
}

@for $i from 1 through 8 {
	.pr-#{$i} {
		padding-right: spacing($i);
	}

	.pr-#{$i - 1}_5 {
		padding-right: spacing($i - 0.5);
	}
}

@for $i from 1 through 8 {
	.pl-#{$i} {
		padding-left: spacing($i);
	}

	.pl-#{$i - 1}_5 {
		padding-left: spacing($i - 0.5);
	}
}

@for $i from 1 through 8 {
	.px-#{$i} {
		padding-left: spacing($i);
		padding-right: spacing($i);
	}

	.px-#{$i - 1}_5 {
		padding-left: spacing($i - 0.5);
		padding-right: spacing($i - 0.5);
	}
}

@for $i from 1 through 8 {
	.py-#{$i} {
		padding-top: spacing($i);
		padding-bottom: spacing($i);
	}

	.py-#{$i - 1}_5 {
		padding-top: spacing($i - 0.5);
		padding-bottom: spacing($i - 0.5);
	}
}
