#accessibility-statement {
	margin: auto;
	max-width: 800px;
	padding: spacing(8) 50px spacing(9);

	h4 {
		padding-bottom: spacing(3);
	}

	p.bold {
		padding-bottom: spacing(2);
	}

	p.sm {
		padding-bottom: spacing(1);
	}
}
