#resources-page {
	#resources-page-hero-section {
		padding: spacing(6) 0 spacing(12) 0;

		#resources-page-hero-img {
			grid-column: 1 / -1;
			margin: 0 auto spacing(2);
			max-width: 50%;
			width: 250px;
			height: auto;

			@include if-size("desktop") {
				width: 100%;
				max-width: 300px;
				grid-column: 2 / 5;
				margin: 0;
			}
		}

		#resources-page-title-container {
			grid-column: 1 / -1;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include if-size("desktop") {
				grid-column: 6 / 12;
			}

			#resources-page-title-container-links {
				display: flex;
				div {
					@include text("16px");
					position: relative;
					padding-right: spacing(3);

					@include if-size("desktop") {
						@include text("20px");
					}

					svg {
						position: absolute;
						right: 0px;
						top: auto;
						width: 18px;
						@include if-size("desktop") {
							top: 4px;
						}
					}
				}

				div:last-child {
					padding-left: spacing(4);
				}
			}
		}

		#resources-page-title {
			text-align: center;

			@include if-size("desktop") {
				text-align: left;
				br {
					display: none;
				}
			}
		}

		#resources-page-body {
			grid-column: 1 / -1;
			margin: spacing(1) 0 spacing(4);

			@include if-size("desktop") {
				grid-column: 6 / -1;
				height: fit-content;
				br {
					display: none;
				}
			}
		}
	}

	#resources-page-all {
		padding-left: 20px;
		padding-right: 20px;
	}

	.resources-common-grid-divider {
		grid-column: 1/-1;
		padding-bottom: spacing(12);
	}

	.resources-more-filter {
		grid-column: 1/-1;
		flex-wrap: wrap;
		row-gap: 12px;
		grid-row-gap: 12px;
		color: $denim;

		@include if-size("desktop") {
			display: flex;
			padding-bottom: 0;
		}
		h4 {
			padding-right: spacing(2);
		}

		.resources-more-filter-btn {
			color: $denim;
			border-radius: 50px;
			border: 1px solid $denim;
			padding: 2px 12px 4px 12px;
			@include text("16px");
			margin-left: spacing(2);
			background-color: transparent;
			cursor: pointer;
			margin-top: 12px;

			@include if-size("desktop") {
				margin-top: 0;
				@include text("20px");
			}

			&:hover {
				color: $azure;
				border-color: $azure;
			}

			&.resources-more-filter-btn-active {
				color: $azure;
				border-color: $azure;
			}

			&.resources-more-filter-btn-clear {
				border-width: 0;
				position: relative;
				padding-right: spacing(2);
				margin-left: 6px;

				&:hover {
					color: $denim;
				}
				svg {
					position: absolute;
					top: 11px;
					right: -6px;
					width: 16px;
				}
			}
			&.resources-more-filter-btn-hide {
				display: none;
			}
		}
	}

	.resources-more-cards-section {
		padding-top: spacing(4);

		#resources-more-cards-section-grid {
			row-gap: spacing(2);
			.resource-card:nth-child(1),
			.resource-card:nth-child(2),
			.resource-card:nth-child(3) {
				display: block;
			}

			.resources-more-cards-section-load-more-btn {
				display: block;
				margin: spacing(3) auto spacing(4) auto;
				grid-column: 1/-1;

				@include if-size("tablet") {
					display: none;
				}

				&-hide {
					display: none;
				}
			}
		}

		.resource-card {
			grid-column: span 4;
			display: none;
			@include if-size("tablet") {
				display: block;
			}
		}
		.resources-more-cards-section-no-filter-prompt {
			grid-column: 1/-1;
			text-align: center;
		}
	}
}

/* Shared component */
#resource-cards-slider {
	position: relative;
	padding-top: spacing(4);
	.resources-more-cards {
		grid-column: 1/-1;
		gap: 1;
		position: relative;

		#card-slider-nav-prev {
			left: -38px;
		}
		#card-slider-nav-next {
			right: -38px;
		}
	}

	.resources-more-cards-button {
		grid-column: 1/-1;
		padding-top: spacing(4);
		margin: 0 auto;
		@include if-size("desktop") {
			padding-top: spacing(4);
		}
	}

	.casestudies-commercial-spinner {
		text-align: center;
		grid-column: 1/-1;
	}
}

#resources-property-taxes {
	.resources-property-taxes-state-title {
		padding-bottom: spacing(1);
	}
	.resources-property-taxes-state-selector-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: spacing(1) 0 spacing(2);

		@include if-size("tablet") {
			flex-direction: row;
			align-items: center;
		}
		h5 {
			padding: 10px spacing(2) 0 0;
		}
		select {
			width: 150px;
			margin-right: spacing(1);
			margin-top: spacing(1);
			@include if-size("tablet") {
				width: 200px;
				font-size: 22px;
			}
		}
	}
}
