#landing-page {
	width: 100%;
}

.landing-section {
	width: 100%;
	min-height: calc(100vh - $header-height);
	height: auto;
}

#landing-pricing-section {
	background-color: $sky-light;
	margin-top: spacing(8);
	padding: spacing(8) 0;
}

.landing-page-centered-cta-container {
	padding-top: spacing(6);

	.button-v2 {
		margin: spacing(4) auto spacing(12) auto;
	}
	.landing-page-centered-cta-content {
		grid-column: 1 / -1;
	}
}

#landing-page-b {
	@extend #landing-page;

	#landing-title-hero-section {
		@include if-size("desktop") {
			margin-top: -20px;
			min-height: 500px;
		}
	}

	#landing-featured-title {
		@include if-size("desktop") {
			margin-top: -24px;
		}
	}

	.landing-vp2-col-title {
		min-height: auto;
	}

	#landing-pricing-section {
		margin-top: 0;
		padding: spacing(8) 0;
		@include if-size("desktop") {
			padding: spacing(12) 0;
		}
	}

	#landing-faq {
		padding-top: spacing(8);
	}

	#pricing-faq-section {
		padding-bottom: spacing(12);
	}
}

.landing-page-centered-button {
	display: flex;
	margin-left: auto;
	margin-right: auto;
}

button.button-learn-more {
	box-shadow: 0px 4px 16px 0px #7f939d2e;
	padding: 4px spacing(3);
	border-radius: 50px;

	@include if-size("desktop") {
		padding: spacing(1) spacing(3);
	}

	display: flex;
	justify-content: center;
	align-items: flex-end;
	gap: 4px;
	background-color: $white;
	border: none;

	&:hover {
		cursor: pointer;
	}

	.learn-more-text {
		display: none;
		font-family: "sofia-pro";
		font-size: 14px;
		font-weight: 433;
		line-height: 20px;
		letter-spacing: -0.015em;
		text-align: left;
		color: $royal;
	}
}

button.button-v2.primary.landing-signup-today {
	font-weight: 400;
	white-space: nowrap;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: -0.01em;
	padding: 15px 40px;

	@include if-size("desktop") {
		font-weight: 600;
		padding: spacing(3) spacing(7);
		font-size: 24px;
		line-height: 32px;
		letter-spacing: -0.05em;
	}
}

.landing-section-title {
	color: $denim;
	font-family: "sofia-pro";
	font-weight: 727;
	font-size: 40px;
	line-height: 48px;
	text-align: center;
	letter-spacing: -0.01em;
	margin: 0 spacing(3) spacing(4) spacing(3);

	@include if-size("desktop") {
		font-size: 56px;
		line-height: 64px;
		letter-spacing: -0.02em;
	}
}

#landing-hero-section {
	background-color: $white;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 655px;

	@include if-size("desktop") {
		height: 584px;
	}

	#landing-hero-illustration {
		z-index: 0;
		height: 559px;
		@include if-size("tablet") {
			height: 566px;
		}
		@include if-size("desktop") {
			height: 557px;
		}

		position: absolute;
		top: 0;
		width: 100%;
		overflow-x: hidden;
		margin-bottom: 30px;

		#hero-illustration-sky {
			position: absolute;
			height: 100%;
			width: 100%;
			bottom: 75px;
		}

		#hero-illustration-sky-blob-1 {
			position: absolute;

			display: none;

			@include if-size("desktop") {
				display: block;
				left: 0;
				bottom: 75px;
			}
		}

		#hero-illustration-sky-blob-2 {
			position: absolute;

			right: -212px;
			bottom: 67px;
			width: 961px;
			height: 458px;

			@include if-size("tablet") {
				right: -248px;
				bottom: 73px;
				width: 961px;
				height: 458px;
			}

			@include if-size("desktop") {
				right: -16px;
				bottom: 75px;
			}
		}

		#hero-illustration-cloud-1 {
			position: absolute;

			top: 261px;
			left: -68px;
			height: 24px;
			width: 128px;

			@include if-size("tablet") {
				height: 24px;
				width: 128px;
				top: 246px;
				left: 16px;
			}

			@include if-size("desktop") {
				height: 24px;
				width: 128px;
				bottom: 300px;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		#hero-illustration-cloud-2 {
			position: absolute;

			top: 174px;
			left: 86px;
			width: 200px;
			height: 31px;

			@include if-size("tablet") {
				width: 200px;
				height: 31px;
				top: 167px;
				left: 86px;
			}

			@include if-size("desktop") {
				bottom: 350px;
				left: 158px;
				width: 200px;
				height: 31px;
			}
		}

		#hero-illustration-cloud-3 {
			position: absolute;

			top: 297px;
			right: 19px;
			height: 31px;
			width: 204px;

			@include if-size("tablet") {
				top: 290px;
				right: 19px;
				height: 31px;
				width: 204px;
			}

			@include if-size("desktop") {
				bottom: 250px;
				right: 130px;
				height: 31px;
				width: 204px;
			}
		}

		#hero-illustration-cloud-4 {
			position: absolute;

			top: 94px;
			right: 76px;
			width: 134px;
			height: 29px;

			@include if-size("tablet") {
				top: 87px;
				right: 76px;
				width: 134px;
				height: 29px;
			}

			@include if-size("desktop") {
				bottom: 450px;
				right: 270px;
				width: 134px;
				height: 29px;
			}
		}

		#hero-illustration-cloud-5 {
			position: absolute;

			top: 36px;
			left: -30px;
			height: 34px;
			width: 153px;

			@include if-size("tablet") {
				top: 29px;
				left: -30px;
				height: 34px;
				width: 153px;
			}

			@include if-size("desktop") {
				bottom: 500px;
				left: 0;
				height: 34px;
				width: 153px;
			}
		}

		#hero-illustration-grass-top {
			position: absolute;
			z-index: 1;

			bottom: 85px;
			height: 51px;
			left: 50%;
			transform: translateX(-50%);

			@include if-size("desktop") {
				height: 51px;
				bottom: 104px;
			}
		}

		#hero-illustration-grass-center {
			position: absolute;

			bottom: 81px;
			left: 0;
			height: 4px;
			width: 100%;

			@include if-size("tablet") {
				height: 11px;
				bottom: 76px;
			}

			@include if-size("desktop") {
				height: 29px;
				bottom: 76px;
				left: 0;
			}
		}

		#hero-illustration-grass-bottom {
			position: absolute;

			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 86px;

			@include if-size("desktop") {
				height: 80px;
				bottom: 0;
			}
		}

		#hero-illustration-tree-1 {
			position: absolute;
			z-index: 10;
			width: 22px;
			height: 119px;
			left: 7px;
			bottom: 101px;

			@include if-size("tablet") {
				width: 26px;
				height: 144px;
				left: 8px;
				bottom: 101px;
			}

			@include if-size("desktop") {
				left: 16px;
				bottom: 87px;

				height: 224px;
				width: 41px;
			}
		}

		#hero-illustration-tree-2 {
			position: absolute;
			z-index: 10;
			height: 71px;
			width: 42px;
			right: 16px;
			bottom: 102px;

			@include if-size("tablet") {
				right: 6px;
				bottom: 114px;
				height: 96px;
				width: 57px;
			}

			@include if-size("desktop") {
				right: 16px;
				bottom: 87px;

				height: 176px;
				width: 116px;
			}
		}

		#hero-illustration-house-with-bushes {
			position: absolute;
			z-index: 9;
			height: 130px;
			width: 201px;
			left: 23px;
			bottom: 91px;

			@include if-size("tablet") {
				width: 240px;
				height: 155px;
				left: 47px;
				bottom: 94px;
			}

			@include if-size("desktop") {
				height: 235px;
				width: 364px;
				left: 80px;
				bottom: 84px;
			}
		}

		#hero-illustration-georgia-skyline {
			position: absolute;
			width: 560px;
			right: 30%;
			transform: translateX(30%);
			bottom: 90px;
			height: 150px;
			opacity: 80%;
			@include if-size("tablet") {
				bottom: 106px;
				height: 177px;
			}

			@include if-size("desktop") {
				transform: translateX(-90%);
				opacity: 100%;
				left: 90%;
				bottom: 126px;
				height: 177px;
				width: 560px;
			}

			@include if-size("desktop-xl") {
				transform: translateX(-95%);
				left: 95%;
				bottom: 132px;
				width: 560px;
			}

			@include if-size("monitor-lg") {
				transform: translateX(-75%);
				left: 75%;
				bottom: 120px;
			}
		}

		#hero-illustration-couple {
			position: absolute;
			z-index: 10;
			height: 87px;
			width: 103px;
			left: -28px;
			bottom: 74px;

			@include if-size("tablet") {
				left: -28px;
				bottom: 64px;
				width: 122px;
				height: 103px;
			}

			@include if-size("desktop") {
				left: 10px;
				bottom: 65px;
				height: 130px;
				width: 176px;
			}
		}

		#hero-illustration-family {
			position: absolute;
			z-index: 10;
			height: 68px;
			width: 89px;
			right: 30%;
			bottom: 80px;

			@include if-size("tablet") {
				right: 16%;
				bottom: 95px;
				width: 105px;
				height: 81px;
			}

			@include if-size("desktop") {
				right: 19%;
				bottom: 87px;
				height: 101px;
				width: 131px;
			}
		}

		#hero-illustration-person-with-dog {
			position: absolute;
			z-index: 11;
			height: 92px;
			width: 109px;
			right: 8%;
			bottom: 48px;

			@include if-size("tablet") {
				right: 1%;
				bottom: 65px;
				width: 128px;
				height: 108px;
			}

			@include if-size("desktop") {
				right: 8%;
				bottom: 64px;
				height: 136px;
				width: 160px;
			}
		}

		#hero-illustration-dracula {
			position: absolute;
			z-index: 12;
			height: auto;
			width: 95px;
			bottom: 50px;
			left: -20px;
			@include if-size("tablet") {
				width: 117px;
				bottom: 50px;
				left: -25px;
				right: unset;
			}
			@media screen and (min-width: 1200px) {
				width: 128px;
				bottom: 120px;
				right: 550px;
				left: unset;
			}
		}
		#hero-illustration-grave {
			position: absolute;
			z-index: 15;
			height: auto;
			width: 120px;
			bottom: 50px;
			right: 80px;
			@include if-size("tablet") {
				width: 165px;
				bottom: 70px;
				right: 200px;
			}
			@include if-size("desktop") {
				width: 203px;
				bottom: 80px;
				right: 350px;
			}
		}
		#hero-illustration-zombie {
			position: absolute;
			z-index: 11;
			height: auto;
			width: 70px;
			bottom: 100px;
			right: 50px;
			@include if-size("tablet") {
				width: 112px;
				bottom: 100px;
				right: 50px;
			}
			@include if-size("desktop") {
				width: 152px;
				bottom: 50px;
				right: 150px;
			}
		}
		#hero-illustration-pumpkins {
			position: absolute;
			z-index: 99;
			display: none;
			height: auto;
			@include if-size("desktop") {
				display: block;
				left: 236px;
				top: 415px;
				width: 95px;
			}
		}
		#hero-illustration-pumpkin {
			position: absolute;
			z-index: 99;
			display: block;
			height: auto;
			width: 35px;
			bottom: 93px;
			left: 120px;
			@include if-size("tablet") {
				width: 40px;
				bottom: 93px;
				left: 170px;
			}
			@include if-size("desktop") {
				display: none;
			}
		}
	}

	#landing-hero-title {
		font-family: "Moret";
		font-weight: 600;
		letter-spacing: 0.01em;
		text-align: center;

		font-size: 48px;
		line-height: 52px;
		margin: 0 spacing(3) 0 spacing(3);
		@include if-size("desktop") {
			font-size: 64px;
			line-height: 74px;
		}
	}
}

#landing-hero-section,
#i-want-hero-section {
	#landing-hero-address-input {
		position: relative;
		max-width: 100%;
		padding: 0 spacing(2);

		padding-top: 16px;
		@include if-size("desktop") {
			padding-top: 32px;
		}
		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		gap: 12px;
		@include if-size("tablet") {
			gap: spacing(3);
		}

		#landing-hero-address-search-with-results {
			position: relative;
			width: 100%;
			max-width: 420px;
			@include if-size("tablet") {
				max-width: 500px;
			}
			@include if-size("desktop") {
				max-width: 560px;
			}

			#landing-hero-address-search {
				background-color: $white;
				display: flex;
				flex-direction: column;
				border: 2px solid $denim_5;
				gap: spacing(1);
				border-radius: 8px;
				padding: 12px;

				@include if-size("desktop") {
					gap: 0;
					padding: 4px 12px;
					flex-direction: row;
					align-items: center;
					border-radius: 36px;
				}

				.search-separator {
					height: 1px;
					background-color: $slate;
					width: 100%;

					@include if-size("desktop") {
						display: none;
					}
				}

				.search-input-container {
					width: 100%;
					input.search {
						border: none;
						border-radius: 36px;

						&::placeholder {
							color: $denim-medium;
						}
					}

					svg.search-icon-left {
						left: 4px;

						path {
							fill: $kelly;
						}
					}
				}

				&.focused {
					button.button-v2 {
						display: none;
					}

					&:not(.results) {
						.search-separator {
							display: none;
						}
					}
				}

				&.results {
					padding-bottom: 0;
					border-bottom: none;
					border-radius: 8px 8px 0 0;
					@include if-size("desktop") {
						border-radius: 36px 36px 0 0;
					}
				}
			}

			.search-results-separator-section {
				background-color: $white;
				border: 2px solid $denim_5;
				border-top: none;
				border-bottom: none;

				.search-results-separator-line {
					display: none;

					@include if-size("desktop") {
						display: block;
						height: 2px;
						margin: 0 8px;
						background-color: $slate;
						border-radius: 2px;
					}
				}
			}

			#landing-title-autocomplete-results-wrapper {
				top: calc(100%);

				border-radius: 0 0 8px 8px;
				border: 2px solid $denim_5;
				border-top: none;

				max-width: 100%;
				@include if-size("desktop") {
					border-radius: 0 0 36px 36px;
				}

				.dont-see-address {
					font-family: "sofia-pro";
					font-size: 14px;
					font-weight: 433;
					line-height: 18px;
					letter-spacing: -0.015em;
					text-align: left;
					@include if-size("desktop") {
						font-family: "sofia-pro";
						font-size: 16px;
						font-weight: 433;
						line-height: 22px;
						letter-spacing: -0.01em;
					}

					height: 54px;

					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: spacing(1);

					&:hover {
						background-color: $royal;
					}
				}

				.landing-title-autocomplete-item {
					border-radius: 4px;
					padding: 4px 8px 4px 8px;

					margin: 8px 8px;
					@include if-size("desktop") {
						margin: 14px 8px;
					}
				}

				.landing-title-autocomplete-item-active {
					background-color: $sky-light;

					&:active {
						background-color: $sky;
					}
				}
			}
		}
	}
}

.cloud-gradient-sections {
	background: -webkit-linear-gradient($white, $cloud);

	padding-top: spacing(3);
	padding-bottom: spacing(8);

	@include if-size("desktop") {
		padding-top: spacing(4);
		padding-bottom: spacing(12);
	}
}

#landing-marquee-section {
	.marquee-featured-in {
		color: $denim-medium;
		text-align: center;
	}

	.marquee-featured-logos-img {
		width: 118px;
		height: 50px;
		padding: 0 spacing(2);
		margin: spacing(1) 6px spacing(4) 6px;
		@include if-size("desktop") {
			width: 184px;
			height: 68px;
			padding: 0 spacing(4);
			margin: spacing(1) 12px spacing(4) 12px;
		}
		border: 1px solid rgba(212, 222, 227, 1);
		box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);
		background-color: $white;
		border-radius: 8px;

		display: flex;
		justify-content: center;
		align-items: center;
	}
}

#landing-stat-section {
	display: flex;
	flex-direction: column;
	gap: spacing(4);

	margin-top: spacing(6);
	@include if-size("desktop") {
		margin-top: spacing(10);
	}

	.landing-section-title {
		margin-bottom: 0;
	}
}

#landing-stat-section-stats {
	max-width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
	}

	.landing-stat-section-stat {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include if-size("desktop") {
			flex: 1 0;
			width: 368px;
		}

		&-value {
			font-family: "sofia-pro";
			font-weight: 800;
			font-size: 62px;
			line-height: 68.2px;
			letter-spacing: -0.01em;
			text-align: center;

			@include if-size("desktop") {
				font-size: 62px;
				font-weight: 838;
				line-height: 68.2px;
			}

			display: flex;
			flex-direction: row;
			align-items: baseline;
			justify-content: center;

			.review-star {
				width: 40px;
				height: 40px;
			}
		}

		&-description {
			color: $denim_5;
			font-family: "sofia-pro";
			font-weight: 433;
			font-size: 20px;
			font-weight: 433;
			line-height: 28px;
			letter-spacing: -0.01em;
			text-align: center;

			@include if-size("desktop") {
				font-size: 24px;
				font-weight: 433;
				line-height: 30px;
			}
		}
	}
}

#landing-big-number-counter-section {
	background: -webkit-linear-gradient(#0d435f, $denim_dark);
	height: 224px;
	@include if-size("desktop") {
		height: 269px;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	#big-number-counter {
		display: flex;
		flex-direction: row;
		justify-content: center;

		.big-number-counter-char {
			color: $white;
			font-family: "sofia-pro";
			font-size: 34.27px;
			line-height: 45.53px;
			font-weight: 727;
			letter-spacing: 0.01em;

			@media only screen and (min-width: 460px) {
				font-size: 56px;
				font-weight: 727;
				line-height: 74.4px;
			}
			@include if-size("desktop") {
				font-family: "sofia-pro";
				font-size: 104px;
				font-weight: 727;
				line-height: 124.8px;
			}

			&.dollar-sign {
			}

			&.comma {
			}

			&.digit {
				display: flex;
				justify-content: center;
				align-items: center;

				width: 20px;
				@media only screen and (min-width: 460px) {
					width: 32px;
				}
				@include if-size("desktop") {
					width: 58px;
				}
			}
		}
	}

	#landing-big-number-counter-description {
		color: $sky;
		font-family: "sofia-pro";
		font-size: 20px;
		font-weight: 727;
		line-height: 28px;
		letter-spacing: -0.01em;
		text-align: center;

		@include if-size("desktop") {
			font-family: "sofia-pro";
			font-size: 24px;
			font-weight: 727;
			line-height: 32px;
			letter-spacing: -0.005em;
			text-align: center;
		}
	}
}

.sky-light-gradient-sections {
	background: -webkit-linear-gradient($sky-light, $white);

	padding-top: spacing(12);
	padding-bottom: spacing(8);

	@include if-size("desktop") {
		padding-top: spacing(18);
		padding-bottom: spacing(12);
	}
}

#more-ways-to-save-section,
#landing-more-ways-to-save-section {
	#more-ways-to-save-carousel {
		@include if-size("desktop") {
			display: none;
		}

		margin: 0 spacing(2);

		.carousel-btn {
			background-color: $powder;
			width: 32px;
			height: 32px;

			svg path {
				fill: $white;
			}

			&:hover {
				background-color: $denim_5;
			}
		}
	}

	#more-ways-to-save-carousel-pagination {
		@include if-size("desktop") {
			display: none;
		}
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: spacing(1);

		.pagination-dot {
			background-color: transparent;
			height: 12px;
			width: 12px;
			border-radius: 50%;
			border: 1px solid $denim-medium;
			&.active {
				background-color: $denim-medium;
			}
		}
	}

	.more-ways-to-save-card {
		position: relative;
		width: 100%;
		height: 100%;

		@include if-size("desktop") {
			width: 312px;
			height: min-content;
		}

		background-color: $white;
		padding: spacing(2);
		border-radius: 8px;

		box-shadow: 0px 4px 24px 0px #7f939d2e;

		display: flex;
		flex-direction: column;
		gap: 4px;

		border: solid 2px $white;
		&:hover {
			cursor: pointer;
			@include if-size("desktop") {
				border: solid 2px $azure;
			}
		}

		header {
			margin-bottom: 4px;

			color: $denim;
			font-family: "sofia-pro";
			font-size: 18px;
			font-weight: 727;
			line-height: 26px;
			letter-spacing: -0.02em;
			text-align: left;

			@include if-size("desktop") {
				font-size: 20px;
				font-weight: 727;
				line-height: 28px;
				letter-spacing: -0.01em;
			}
		}

		p.more-ways-to-save-description {
			color: $denim-medium;
			font-family: "sofia-pro";
			font-size: 12px;
			font-weight: 433;
			line-height: 18px;
			letter-spacing: -0.02em;
			text-align: left;

			@include if-size("desktop") {
				font-size: 14px;
				line-height: 20px;
				letter-spacing: -0.015em;
			}

			display: -webkit-box;
			// -webkit-line-clamp: 8;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		button.button-learn-more {
			position: absolute;
			bottom: 16px;
			right: 16px;
			background-color: $sky;

			svg path {
				fill: $royal;
			}

			@include if-size("desktop") {
				background-color: $white;
				bottom: unset;
				right: unset;
				position: relative;
				margin-top: spacing(3);
				margin-left: auto;

				svg path {
					fill: $azure;
				}
			}

			.learn-more-text {
				display: block;
				@include if-size("desktop") {
					display: none;
				}
			}
		}

		&:hover {
			@include if-size("desktop") {
				button.button-learn-more {
					background-color: $sky;
					.learn-more-text {
						display: block;
					}

					svg path {
						fill: $royal;
					}
				}
			}
		}
	}

	#more-ways-to-save-house-composition {
		position: relative;
		margin: 0 auto;
		margin-top: spacing(2);
		@include if-size("desktop") {
			margin-top: spacing(8);
		}

		max-width: 100%;
		width: 393px;
		height: 371px;
		@include if-size("desktop") {
			width: 592px;
			height: 558px;
		}

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		.more-ways-to-save-house {
			z-index: 2;

			width: 290px;
			height: 171px;

			@include if-size("desktop") {
				width: 437px;
				height: 258px;
			}
		}

		.more-ways-to-save-row {
			z-index: 4;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			&:first-of-type {
				margin-top: 19px;
				@include if-size("desktop") {
					margin-top: 28px;
				}
			}

			&:last-of-type {
				margin-bottom: 19px;
				@include if-size("desktop") {
					margin-bottom: 28px;
				}
			}

			.more-ways-to-save-icon {
				width: 48px;
				height: 48px;

				transition: all 0.3s ease-in-out;

				&.mobile-active {
					transform: scale(1.6);
				}
				@include if-size("desktop") {
					width: 72px;
					height: 72px;

					&.mobile-active {
						transform: scale(1);
					}

					&.desktop-active {
						transform: scale(1.3);
					}
				}

				&:first-of-type {
					margin-left: 60px;
					@include if-size("desktop") {
						margin-left: spacing(20);
					}
					@include if-size("desktop-lg") {
						margin-left: spacing(12);
					}
				}

				&:last-of-type {
					margin-right: 60px;
					@include if-size("desktop") {
						margin-right: spacing(20);
					}
					@include if-size("desktop-lg") {
						margin-right: spacing(12);
					}
				}
			}
		}

		.stacked-circles {
			z-index: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			width: 100%;
			height: 100%;
		}
	}

	#more-ways-to-save-comp-with-cards {
		margin: 0 auto;
		position: relative;
		max-width: 1136px;

		.place-tl {
			z-index: 3;
			position: absolute;
			top: 0;
			left: 0;

			@include if-size("desktop") {
				margin-left: spacing(2);
			}
		}

		.place-tr {
			z-index: 3;
			position: absolute;
			top: 0;
			right: 0;

			@include if-size("desktop") {
				margin-right: spacing(2);
			}
		}

		.place-bl {
			z-index: 3;
			position: absolute;
			bottom: 0;
			left: 0;

			@include if-size("desktop") {
				margin-left: spacing(2);
			}
		}

		.place-br {
			z-index: 3;
			position: absolute;
			bottom: 0;
			right: 0;

			@include if-size("desktop") {
				margin-right: spacing(2);
			}
		}

		.more-ways-to-save-card {
			display: none;
			@include if-size("desktop") {
				display: block;
			}
		}
	}
}

#landing-testimonials-section {
	position: relative;
	margin-bottom: spacing(10);
	margin-top: spacing(12);
	@include if-size("desktop") {
		margin-bottom: spacing(12);
		margin-top: spacing(18);
	}

	#testimonial-stats {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 4px;
		.rating {
			color: $denim;
			font-family: "sofia-pro";
			font-size: 20px;
			font-weight: 622;
			line-height: 28px;
			text-align: left;

			@include if-size("desktop") {
				font-size: 20px;
				line-height: 28px;
			}
		}

		.review-count {
			color: $royal;
			font-family: "sofia-pro";
			font-size: 16px;
			font-weight: 433;
			line-height: 22px;
			letter-spacing: -0.01em;
			text-align: left;

			@include if-size("desktop") {
				font-size: 16px;
				line-height: 22px;
			}
		}
	}

	#testimonials-page-endless-section {
		padding-top: 0;
		background-color: transparent;
		max-height: 712px;
		position: relative;
		overflow: hidden;
		-webkit-mask-image: linear-gradient(rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0));
	}

	button.button-v2 {
		position: absolute;
		bottom: 12px;
		left: 50%;
		transform: translateX(-50%);

		z-index: 3;
		margin: 0 auto;
		background-color: $white;

		box-shadow: 0px 4px 16px 0px #7f939d2e;
		font-size: 18px;
		line-height: 26px;
		letter-spacing: -0.02em;

		white-space: nowrap;

		padding: 12px 36px 12px 36px;
		@include if-size("desktop") {
			padding: 12px 40px 12px 40px;
		}

		&:hover {
			color: $denim;
		}
	}
}

#landing-signup-steps-section,
#generalized-signup-steps-section {
	#landing-signup-steps-steps {
		display: flex;
		flex-direction: column;
		gap: spacing(4);
		margin: 0 spacing(2);
		margin-bottom: spacing(4);

		@include if-size("desktop") {
			gap: spacing(2);
			flex-direction: row;
			justify-content: center;
		}

		.landing-signup-step {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			width: auto;
			@include if-size("desktop") {
				width: 368px;
			}

			header {
				color: $denim;
				font-family: "sofia-pro";
				font-size: 20px;
				font-weight: 727;
				line-height: 28px;
				letter-spacing: -0.01em;
				text-align: center;

				@include if-size("desktop") {
					font-family: "sofia-pro";
					font-size: 24px;
					font-weight: 727;
					line-height: 32px;
					letter-spacing: -0.005em;
					text-align: center;
				}

				margin-bottom: spacing(1);
			}

			p {
				color: $denim-medium;
				font-family: "sofia-pro";
				font-size: 18px;
				font-weight: 433;
				line-height: 26px;
				letter-spacing: -0.02em;
				text-align: center;

				@include if-size("desktop") {
					font-size: 20px;
					font-weight: 433;
					line-height: 28px;
					letter-spacing: -0.01em;
					text-align: center;
				}
			}

			.img-circle {
				background-color: $white;
				box-shadow: 0px 4px 24px 0px #7f939d2e;
				border: 1px solid #ffffff;
				border-radius: 100px;

				width: 96px;
				height: 96px;

				@include if-size("desktop") {
					width: 128px;
					height: 128px;
				}

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				img {
					width: 48px;
					height: 48px;

					@include if-size("desktop") {
						width: 64px;
						height: 64px;
					}
				}

				margin-bottom: spacing(3);
			}
		}
	}
}

#landing-view-pricing-section {
	background-color: $sky-light;

	display: flex;
	flex-direction: column;
	gap: spacing(2);
	padding: spacing(12) 0;

	@include if-size("desktop") {
		padding: spacing(18) 0;
		flex-direction: row;
		justify-content: center;
	}

	#view-pricing-card {
		box-shadow:
			-2px 13px 28px 0px #ababab1a,
			-7px 51px 51px 0px #ababab17,
			-16px 115px 70px 0px #ababab0d,
			-28px 204px 82px 0px #ababab03,
			-44px 319px 90px 0px #ababab00;

		background: -webkit-linear-gradient($azure, $royal);
		margin: 0 spacing(2);
		padding: spacing(3) spacing(2);
		border-radius: 8px;

		@include if-size("desktop") {
			border-radius: 16px;
			margin-right: 0;
			width: 100%;
			max-width: 752px;
		}

		#view-pricing-card-title {
			margin-bottom: 4px;
			color: $white;
			font-family: "sofia-pro";
			font-size: 32px;
			font-weight: 727;
			line-height: 40px;
			letter-spacing: -0.01em;
			text-align: center;

			@include if-size("desktop") {
				font-size: 48px;
				line-height: 58px;
				letter-spacing: -0.03em;
			}
		}

		#pricing-value-props {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			row-gap: 4px;
			column-gap: spacing(2);

			.pricing-value-prop {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 4px;

				@include if-size("desktop") {
					gap: 6px;
				}

				p {
					color: $sky-light;
					font-family: "sofia-pro";
					font-size: 14px;
					font-weight: 622;
					line-height: 18px;
					letter-spacing: -0.015em;
					text-align: center;

					@include if-size("desktop") {
						font-size: 16px;
						font-weight: 622;
						line-height: 22px;
						letter-spacing: -0.01em;
					}
				}

				svg {
					path {
						fill: $white;
					}
				}
			}
		}

		#pricing-zipcode-search-container {
			margin: 0 auto;
			margin-top: spacing(3);

			@include if-size("desktop") {
				width: 425px;
			}

			#pricing-zipcode-search {
				background-color: $white;
				display: flex;
				flex-direction: column;
				border: 2px solid $denim_5;
				gap: spacing(1);
				border-radius: 8px;
				padding: 12px;

				@include if-size("desktop") {
					gap: 0;
					padding: 4px 12px;
					flex-direction: row;
					align-items: center;
					border-radius: 36px;
				}

				.search-separator {
					height: 1px;
					background-color: $slate;

					@include if-size("desktop") {
						display: none;
					}
				}

				.search-input-container {
					input.search {
						padding-right: 0;
						border: none;
						border-radius: 36px;

						&::placeholder {
							color: $denim-medium;
						}
					}

					svg.search-icon-left {
						left: 4px;

						path {
							fill: $kelly;
						}
					}
				}

				button.button-v2 {
					background-color: $kelly;
					border-color: $kelly;
					letter-spacing: -0.02em;
					white-space: nowrap;
					width: 100%;
					padding: 12px 24px;

					@include if-size("desktop") {
						width: min-content;
						padding: 12px 40px;
						font-size: 18px;
						line-height: 25px;
					}

					&:hover {
						border-color: $kelly-dark;
						background-color: $kelly-dark;
					}
				}
			}
		}
	}

	#investor-signup-card {
		box-shadow:
			-2px 13px 28px 0px #ababab1a,
			-7px 51px 51px 0px #ababab17,
			-16px 115px 70px 0px #ababab0d,
			-28px 204px 82px 0px #ababab03,
			-44px 319px 90px 0px #ababab00;

		background-color: $white;
		margin: 0 spacing(2);
		padding: spacing(3) spacing(2);
		border-radius: 8px;

		display: flex;
		flex-direction: column;

		@include if-size("desktop") {
			border-radius: 16px;
			max-width: 368px;
			margin-left: 0;
		}

		&:hover {
			cursor: pointer;
		}

		#investor-signup-card-title {
			margin-bottom: 4px;
			color: $denim;
			font-family: "sofia-pro";
			font-size: 20px;
			font-weight: 727;
			line-height: 28px;
			letter-spacing: -0.01em;
			text-align: left;

			@include if-size("desktop") {
				font-size: 24px;
				font-weight: 727;
				line-height: 32px;
				letter-spacing: -0.005em;
			}
		}

		#investor-signup-card-description {
			color: $denim-medium;
			font-family: "sofia-pro";
			font-size: 14px;
			font-weight: 433;
			line-height: 18px;
			letter-spacing: -0.015em;
			text-align: left;

			@include if-size("desktop") {
				font-size: 16px;
				font-weight: 433;
				line-height: 22px;
				letter-spacing: -0.01em;
			}
		}

		.quick-link {
			margin-top: spacing(2);
			margin-left: auto;
			padding: spacing(1) spacing(3);
			@include if-size("desktop") {
				margin-top: auto;
				padding: 12px spacing(3);
			}

			.quick-link-link {
				font-family: "sofia-pro";
				font-size: 16px;
				font-weight: 433;
				line-height: 22px;
				letter-spacing: -0.01em;
				text-align: left;

				@include if-size("desktop") {
					font-size: 16px;
					font-weight: 433;
					line-height: 22px;
					letter-spacing: -0.01em;
					text-align: left;
				}
			}

			.quick-link-arrow {
				width: 12px;
				height: 12px;
			}
		}
	}
}

#landing-experts-section {
	background-color: $white;
	padding: spacing(12) 0;
	@include if-size("desktop") {
		padding: spacing(18) 0;
	}

	#about-team-section-slider-container {
		padding: 0 spacing(2);
		@include if-size("desktop") {
			max-width: 1136px;
			margin: 0 auto;
		}

		#about-team-section-slider-nav-next {
			margin-left: spacing(1);
		}

		#about-team-section-slider-nav-prev {
			margin-right: spacing(1);
		}

		#about-team-section-slider-nav-next,
		#about-team-section-slider-nav-prev {
			button {
				border-radius: 50%;
				cursor: pointer;
				border: none;
				display: grid;
				place-content: center;

				background-color: $powder;
				width: 32px;
				height: 32px;

				svg {
					height: unset;
					width: unset;
					opacity: 1;
					path {
						fill: $white;
					}
				}

				transition: all 0.3s ease-in-out;
				transform: scale(1);

				&:hover {
					transform: scale(1.1);
					background-color: $denim_5;
				}
			}
		}

		.swiper {
			padding: 32px 64px;
		}
	}

	.about-team-card.ptc {
		box-shadow: 0px 4px 8px 0px #7f939d2e;

		.about-team-img-container {
			width: 96px;
			height: 96px;
		}

		h4 {
			color: $denim;
			font-family: "sofia-pro";
			font-size: 20px;
			font-weight: 727;
			line-height: 28px;
			letter-spacing: -0.01em;
			text-align: left;

			margin-bottom: spacing(1);

			@include if-size("desktop") {
				font-size: 24px;
				line-height: 32px;
				letter-spacing: -0.005em;
			}
		}

		.card-body.sm {
			color: $denim-medium;
			font-family: "sofia-pro";
			font-size: 14px;
			font-weight: 433;
			line-height: 18px;
			letter-spacing: -0.015em;
			text-align: left;

			@include if-size("desktop") {
				font-size: 16px;
				line-height: 22px;
				letter-spacing: -0.01em;
			}
		}

		button.button-learn-more {
			height: 44px;
			margin-left: auto;
			margin-top: 12px;
			@include if-size("desktop") {
				margin-top: spacing(2);
			}
			padding: 12px spacing(4);
		}

		border: solid 2px $white;
		&:hover {
			border: solid 2px $azure;
			cursor: pointer;

			button.button-learn-more {
				background-color: $sky;

				.learn-more-text {
					display: block;
				}

				svg path {
					fill: $royal;
				}
			}
		}

		.ptc-close {
			display: none;
		}
	}

	#about-team-read-more-modal {
		background-color: #053249bf;

		button.button-learn-more {
			display: none;
		}

		.about-team-card.ptc {
			&:hover {
				border: solid 2px $white;
			}
			.ptc-learn-more {
				display: none;
			}

			.ptc-close {
				margin-left: auto;
				box-shadow: 0px 4px 16px 0px #7f939d2e;

				border-radius: 50px;

				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $white;
				border: none;

				padding: 8px spacing(3);
				margin-top: 12px;
				@include if-size("desktop") {
					margin-top: spacing(2);
					padding: 12px spacing(4);
				}

				color: $azure;

				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 6px;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	#about-team-section-slider-pagination {
		margin-top: spacing(1);
	}
}

#landing-faq-section {
	background-color: $white;
	padding-bottom: spacing(8);
	@include if-size("desktop") {
		padding-bottom: spacing(12);
	}
}
.pricing-faq-card {
	box-shadow: 0px 4px 24px 0px #7f939d2e;

	&:hover {
		cursor: pointer;
	}

	.pricing-faq-card-title-section {
		align-items: center;
		gap: spacing(2);

		p.lg {
			font-family: "sofia-pro";
			font-size: 20px;
			font-weight: 433;
			line-height: 28px;
			letter-spacing: -0.01em;
			text-align: left;

			@include if-size("desktop") {
				font-family: "sofia-pro";
				font-size: 24px;
				line-height: 30px;
				letter-spacing: -0.01em;
			}
		}
	}

	.pricing-faq-card-body {
		p {
			color: $denim-medium;
			font-family: "sofia-pro";
			font-size: 16px;
			font-weight: 433;
			line-height: 22px;
			letter-spacing: -0.01em;
			text-align: left;

			@include if-size("desktop") {
				font-family: "sofia-pro";
				font-size: 18px;
				font-weight: 433;
				line-height: 26px;
				letter-spacing: -0.02em;
			}

			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		li {
			color: $denim-medium;
		}
	}

	&-open {
		box-shadow: 0px 4px 24px 0px #7f939d2e;

		.pricing-faq-card-body {
			p {
				display: block;
				-webkit-line-clamp: unset;
				-webkit-box-orient: usnet;
				overflow: unset;
			}
		}
	}
}

#landing-property-tax-101-section {
	padding-top: 96px;
	@include if-size("desktop") {
		padding-top: 144px;
	}

	.landing-property-tax-101-section-img {
		grid-column: 1/-1;
		text-align: center;

		@include if-size("desktop") {
			grid-column: 2 / 5;
		}

		img {
			max-width: 272px;
			width: 70%;
			@include if-size("tablet") {
				width: 100%;
			}
		}
	}

	.landing-property-tax-101-section-content {
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: 6 / -1;
		}

		&-title {
			padding-top: 32px;
			@include if-size("desktop") {
				padding-top: 44px;
			}
		}
	}

	.landing-property-tax-101-section-content-cta {
		padding-top: 16px;

		a {
			display: block;
		}

		.landing-property-tax-101-section-content-btn {
			svg {
				height: 16px;
				width: 18px;
				position: relative;
				top: 1px;
				padding-left: 3px;
			}
		}
	}
}

#landing-hero-address-input {
	position: relative;
	max-width: 100%;
	padding: 0 spacing(2);

	padding-top: 16px;
	@include if-size("desktop") {
		padding-top: 32px;
	}
	margin-left: auto;
	margin-right: auto;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	gap: 12px;
	@include if-size("tablet") {
		gap: spacing(3);
	}

	#landing-hero-address-search-with-results {
		position: relative;
		width: 100%;
		max-width: 420px;
		@include if-size("tablet") {
			max-width: 500px;
		}
		@include if-size("desktop") {
			max-width: 560px;
		}

		#landing-hero-address-search {
			background-color: $white;
			display: flex;
			flex-direction: column;
			border: 2px solid $denim_5;
			gap: spacing(1);
			border-radius: 8px;
			padding: 12px;

			@include if-size("desktop") {
				gap: 0;
				padding: 4px 12px;
				flex-direction: row;
				align-items: center;
				border-radius: 36px;
			}

			.search-separator {
				height: 1px;
				background-color: $slate;

				@include if-size("desktop") {
					display: none;
				}
			}

			.search-input-container {
				width: 100%;
				input.search {
					border: none;
					border-radius: 36px;

					&::placeholder {
						color: $denim-medium;
					}
				}

				svg.search-icon-left {
					left: 4px;

					path {
						fill: $kelly;
					}
				}
			}

			button.button-v2 {
				white-space: nowrap;
				width: 100%;

				@include if-size("desktop") {
					width: min-content;
				}
			}

			&.focused {
				button.button-v2 {
					display: none;
				}

				&:not(.results) {
					.search-separator {
						display: none;
					}
				}
			}

			&.results {
				padding-bottom: 0;
				border-bottom: none;
				border-radius: 8px 8px 0 0;
				@include if-size("desktop") {
					border-radius: 36px 36px 0 0;
				}
			}
		}

		.search-results-separator-section {
			background-color: $white;
			border: 2px solid $denim_5;
			border-top: none;
			border-bottom: none;

			.search-results-separator-line {
				display: none;

				@include if-size("desktop") {
					display: block;
					height: 2px;
					margin: 0 8px;
					background-color: $slate;
					border-radius: 2px;
				}
			}
		}

		#landing-title-autocomplete-results-wrapper {
			top: calc(100%);

			border-radius: 0 0 8px 8px;
			border: 2px solid $denim_5;
			border-top: none;

			max-width: 100%;
			@include if-size("desktop") {
				border-radius: 0 0 36px 36px;
			}

			.dont-see-address {
				font-family: "sofia-pro";
				font-size: 14px;
				font-weight: 433;
				line-height: 18px;
				letter-spacing: -0.015em;
				text-align: left;
				@include if-size("desktop") {
					font-family: "sofia-pro";
					font-size: 16px;
					font-weight: 433;
					line-height: 22px;
					letter-spacing: -0.01em;
				}

				height: 54px;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: spacing(1);

				&:hover {
					background-color: $royal;
				}
			}

			.landing-title-autocomplete-item {
				border-radius: 4px;
				padding: 4px 8px 4px 8px;

				margin: 8px 8px;
				@include if-size("desktop") {
					margin: 14px 8px;
				}
			}

			.landing-title-autocomplete-item-active {
				background-color: $sky-light;

				&:active {
					background-color: $sky;
				}
			}
		}
	}
}

#landing-hero-value-props {
	z-index: 1;
	border: 1px solid rgba(212, 222, 227, 1);
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 8px;

	width: min-content;
	margin: 0 auto;
	background-color: $white;

	padding: 12px spacing(6);
	@include if-size("desktop") {
		padding: spacing(3) spacing(8);
	}

	display: flex;
	flex-direction: column;
	gap: spacing(1);
	@include if-size("desktop") {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: spacing(3);
	}

	.landing-hero-value-prop {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		gap: 4px;

		svg {
			margin-top: 6px;
		}

		p {
			font-family: "sofia-pro";
			font-size: 18px;
			font-weight: 727;
			line-height: 26px;
			letter-spacing: -0.02em;
			text-align: center;
			@include if-size("desktop") {
				font-family: "sofia-pro";
				font-size: 20px;
				font-weight: 727;
				line-height: 28px;
				letter-spacing: -0.01em;
			}
		}
	}
}

.state-specific-illustration-ny {
	#hero-illustration-tree-1 {
		display: none;
		@include if-size("desktop") {
			display: block;
		}
	}

	#hero-illustration-newyork-skyline {
		position: absolute;
		bottom: 98px;
		opacity: 80%;
		height: 131px;
		width: 459px;
		left: -70px;
		@include if-size("tablet") {
			bottom: 100px;
			height: 213px;
			width: 940px;
			left: -180px;
		}

		@include if-size("desktop") {
			transform: translateX(15%);
			opacity: 100%;
			left: 15%;
			bottom: 126px;
			height: 213px;
			width: 940px;
		}

		@include if-size("desktop-xl") {
			transform: translateX(25%);
			left: 25%;
			bottom: 132px;
		}

		@include if-size("monitor-lg") {
			transform: translateX(-72%);
			left: 72%;
			bottom: 128px;
		}
	}
}
