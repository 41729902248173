.property-profile-question-card {
	background-color: $denim_lightest;
	padding: spacing(2);
	border-radius: 8px;

	&:last-of-type {
		margin-bottom: spacing(8);
	}
	&:not(:last-of-type) {
		margin-bottom: spacing(4);
	}

	.expand-button-reverse .expand-label {
		font-size: 14px;
	}

	.property-profile-question-card-top {
		width: 100%;
		// margin-bottom: spacing(2);

		// .sm {
		//   margin: spacing(1) 0;
		// }
	}

	.property-profile-question-card-title-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: spacing(1);
	}

	.property-profile-question-card-subtitle {
		color: $denim-medium;
	}

	.property-profile-question-card-radio {
		margin-left: spacing(2);

		.radio:first-of-type {
			margin-bottom: spacing(2);
		}

		@include if-size("tablet") {
			display: flex;

			.radio:first-of-type {
				margin-right: spacing(2);
				margin-bottom: 0;
			}
		}
	}
	.property-profile-question-card-bottom {
		margin-top: 16px;

		.file-upload-container {
			margin-top: spacing(2);
		}

		//Removes margin on a QuestionCard that only has a file upload section or is the first child of the parent
		.file-upload-container:nth-child(1) {
			margin-top: spacing(0);
		}
	}

	.appeal-info-question-card-tooltip-text {
		svg {
			height: 12px;
			width: 12px;
			margin-right: 4px;

			@include if-size("desktop") {
				height: 16px;
				width: 16px;
				margin-right: 6px;
			}
		}
	}
}

.property-profile-question-card-last-year-note {
	margin-top: spacing(2);
	padding: spacing(1);
	background-color: $white;
	border-radius: 8px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

	@include if-size("tablet") {
		padding: spacing(1) spacing(2);
	}
}

.property-profile-question-card + button,
.property-profile-question-card + p + button {
	margin: 0 auto;
}

.property-profile-question-card + p {
	text-align: center;
	padding-bottom: spacing(1);

	&.profile-question-callout {
		background-color: $azure;
		padding: spacing(2);
		color: $white;
		border-radius: 16px;
		margin-bottom: spacing(4);
		margin-top: spacing(-4);
	}
}

.top-margin {
	margin-top: spacing(2);
}

.two-column-checkbox {
	.checkbox {
		margin-bottom: spacing(2);
	}

	@include if-size("tablet") {
		columns: 2;
		.checkbox {
			margin-bottom: spacing(1);
		}
	}
}

.two-column-input {
	margin-bottom: spacing(2);

	@include if-size("tablet") {
		display: flex;
		align-items: flex-end;

		> div {
			width: calc(50% - 8px);
			&:nth-child(1) {
				margin-right: spacing(1);
			}
			&:nth-child(2) {
				margin-left: spacing(1);
			}
		}
	}
}

#property-survey-confirmation-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;

	@include if-size("desktop") {
		margin: 0 auto;
	}

	button {
		margin-top: spacing(4);
	}

	#property-survey-confirmation-primary-cta {
		width: 200px;
	}

	#exemptions-card {
		margin-top: 16px;
		background-color: $denim_lightest;
		border-radius: spacing(1);
		padding: spacing(2);
		width: 100%;

		@include if-size("desktop") {
			max-width: 80%;
			margin: 16px auto;
		}

		#exemptions-service-content {
			margin-top: 12px;
		}
	}

	.lg {
		margin-bottom: 4px;
	}

	.confirmation-referral-card {
		overflow: hidden;
		border-radius: 16px;
		display: flex;
		flex-direction: column;

		@include if-size("monitor") {
			flex-direction: row-reverse;
		}

		.content {
			flex: 1;
			background-color: $denim_lightest;
			padding: spacing(3);

			.lg {
				margin-bottom: 4px;
				@include if-size("monitor") {
					margin-bottom: 8px;
				}
			}

			.body-tiny {
				font-size: 8px;
				line-height: 14px;
			}

			#referral-reflink-section {
				padding-top: spacing(3);
			}
		}

		.image-container {
			background-color: $powder;
			position: relative;
			overflow: hidden;

			height: 137px;
			width: auto;
			@include if-size("monitor") {
				flex: 0 0 337px;
				height: auto;
				width: 337px;
			}

			img {
				position: absolute;
				right: -72px;
				top: -8px;
				height: 175px;
				width: auto;

				@include if-size("monitor") {
					right: unset;
					top: unset;
					left: 0;
					bottom: -12px;
					height: 100%;
					width: auto;
				}
			}
		}
	}
}

#how-did-you-hear-about-us-other-options,
#how-did-you-hear-about-us-social-media-options,
#how-did-you-hear-about-us-radio-podcast-options,
#how-did-you-hear-about-us-tv-video-options {
	margin-left: 8px;
	padding-top: 18px;
	padding-bottom: 12px;

	.checkbox {
		margin-bottom: 6px;
	}

	display: flex;
	flex-direction: column;
	@include if-size("desktop") {
		flex-direction: row;
		gap: spacing(8);
	}
}
