form {
	width: 100%;
}

#appeal-confirmation-savings-box {
	grid-column: 1 / -1;
	width: 100%;
	grid-row: 1;

	@include if-size("desktop") {
		grid-column: 3/-3;

		#appeal-savings-box {
			height: fit-content;
			margin-top: 0;
		}
	}
}

#appeal-confirmation-title-section {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3/-3;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 0 spacing(4);
	}
}

.appeal-confirmation-title {
	margin-bottom: spacing(1);
}

.appeal-confirmation-more-properties {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: spacing(4);

	#appeal-another-property {
		margin: spacing(2) 0;
	}

	#appeal-contact-us {
		margin-top: spacing(2);
	}
}

.appeal-confirmation-referral-section {
	background-color: $cloud;
	margin: spacing(6) 0;
	padding: spacing(8) 0;
}

.appeal-confirmation-referral-title {
	grid-column: 1 / -1;
	display: flex;
	align-items: center;
	margin-bottom: spacing(2);

	@include if-size("desktop") {
		grid-column: 4 / -4;
	}

	h3 {
		margin-left: spacing(2);
	}
}

.appeal-confirmation-referral-description {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 4 / -4;
	}
}

.appeal-confirmation-referral-invite-input {
	grid-column: 1 / -1;
	margin-top: spacing(2);

	@include if-size("desktop") {
		grid-column: 4 / -4;
	}
}

#appeal-confirmation-referral-submit {
	grid-column: 1 / -1;
	margin-top: spacing(2);
	@include if-size("desktop") {
		grid-column: 4 / -4;
	}
}

.appeal-confirmation-referral-share {
	grid-column: 1 / -1;
	margin-top: spacing(5);
	@include if-size("desktop") {
		grid-column: 4 / -4;
	}
}

.appeal-confirmation-referral-input-error {
	grid-column: 1 / -1;
	@include if-size("desktop") {
		grid-column: 4 / -4;
	}
}

.appeal-confirmation-section-wrapper {
	background-color: $cloud;
	margin-top: spacing(8);
}

.appeal-confirmation-account-section {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: spacing(8);

	@include if-size("tablet") {
		grid-column: 2 / -2;
	}

	@include if-size("desktop") {
		grid-column: 1 / 7;
		margin-top: 0;
	}

	.button-v2 {
		margin: spacing(2) auto spacing(3);
	}
}
.appeal-confirmation-what-we-do {
	grid-column: 1 / -1;
	margin: spacing(8) 0;

	@include if-size("tablet") {
		grid-column: 2 / -2;
	}
	@include if-size("desktop") {
		grid-column: 8 / -1;
	}
	.appeal-flow-step-counter-title {
		margin: 0 0 16px;
	}

	.vertical-step-counter {
		.step-dot {
			background-color: $cloud;
		}
	}
}

.save-btn {
	width: 150px;
	margin-top: 40px;
}

.email-invalid-message {
	margin-top: 12px;
	color: red;
}

.pref-label {
	text-decoration: underline;
	text-decoration-color: $navy;
	margin: 0 0 16px;
}

.referral-input-container {
	@include flex("flex-start", "center");
	position: relative;
	margin-top: spacing(1);

	.copy-btn {
		@include flex("center", "center");
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		border-radius: 0 4px 4px 0;
		width: 120px;
		background: $teal;
		color: #fff;
		cursor: pointer;

		&:hover {
			background: #33877f;
		}

		&-disabled {
			@extend .copy-btn;
			cursor: not-allowed;
			background: #aaa;
			&:hover {
				background: #aaa;
			}
		}
	}
}

.send-invite-input {
	width: 100%;
}

.send-invite-btn {
	@extend .copy-btn;
	background: $navy !important;
	width: 120px !important;
	padding: 0 !important;
	border-radius: 0 4px 4px 0 !important;
	&:hover {
		background: #59799c !important;
	}
}

.referral-count-box {
	margin-top: 18px;
	width: 100%;
	background: #e1ebf5;
	padding: 12px;
	border-radius: 4px;
	color: $navy;

	p > span {
		font-weight: 600;
	}
}

.pref-container {
	@include flex("flex-start", "flex-start", "column");
	margin-bottom: 24px;

	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + *::before {
		content: "";
		display: inline-block;
		vertical-align: bottom;
		width: 1rem;
		height: 1rem;
		margin-right: 0.3rem;
		border-radius: 50%;
		border-style: solid;
		border-width: 0.1rem;
		border-color: gray $teal;
	}

	input[type="radio"]:checked + * {
		color: $teal;
	}
	input[type="radio"]:checked + *::before {
		background: radial-gradient(
			$teal 0%,
			$teal 40%,
			transparent 50%,
			transparent
		);
		border-color: $teal;
	}

	input[type="radio"]:checked + * {
		color: teal;
	}
	input[type="radio"]:checked + *::before {
		background: radial-gradient(
			$teal 0%,
			$teal 40%,
			transparent 50%,
			transparent
		);
		border-color: $teal;
	}

	/* basic layout */
	fieldset {
		margin: 20px;
		max-width: 400px;
	}
	label > input[type="radio"] + * {
		display: inline-block;
		padding: 0.5rem 1rem;
	}

	> div {
		margin: 0 0 10px 0;
		> label {
			font-size: 18px;
		}
	}
}

.share-container {
	grid-column: 1 / -1;
	@include flex("center", "center");
	width: 100%;
	margin-top: 20px;

	.share-btn {
		margin: 0 10px;

		img {
			width: 30px;
			height: 30px;
		}
	}
}

.login-btn-seperator {
	width: 80%;
	margin: 28px auto 0;
	text-align: center;
	position: relative;

	&:before {
		position: absolute;
		top: 50%;
		left: 0;
		height: 1px;
		width: 100%;
		content: "";
		background: #aaa;
		z-index: -5;
	}

	p {
		z-index: 5;
		color: #777;
		background: #fff;
		font-size: 14px;
		width: 40px;
		margin: 0 auto;
	}
}

.referral-tracking-container {
	margin: 24px auto 0;
	width: 90%;
}

.referral-title {
	text-align: center;
	@include text("30px", 600);
	margin-bottom: 18px;
	width: 100%;
}

.referral-input {
	width: 100%;
}

.referral-input-title {
	@include text("18px", 600);
	text-align: center;
	margin: 18px 0 12px;

	@include respond-to("medium") {
		width: 100%;
	}
}

.referral-input-title:nth-of-type(even) {
	@include text("18px", 600);
	text-align: center;
	margin: 36px 0 12px;
}

.referral-subtitle-large {
	width: 90%;
	margin: spacing(2) auto;

	a {
		text-decoration: underline;
	}
}

.referral-subtitle {
	margin: 0 auto;
	max-width: 38ch;
	line-height: 22px !important;
	text-align: center;
}

.appeal-confirmation-card-container {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3/-3;
	}

	.appeal-confirmation-card {
		#appeal-confirmation-card-subtitle {
			margin-top: spacing(1);

			> span.as-link {
				color: $azure;
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}

	.button-v2 {
		margin: spacing(4) auto 0;
	}

	#appeal-confirmation-add-another-btn {
		margin: spacing(1) auto 0;
	}
}

#appeal-confirmation-code-input-container {
	display: flex;
	align-items: center;
	margin-top: spacing(1);

	.appeal-confirmation-code-input {
		margin-right: spacing(1);
		width: calc(18px + 10ch);

		@include if-size("desktop") {
			width: calc(24px + 12ch);
		}

		> * > input {
			text-align: center;
			letter-spacing: 0.5ch;
		}

		> .label {
			margin-left: spacing(1);
		}

		.input-wrong {
			border-color: $terracotta;
		}

		.input-confirmed {
			border-color: $azure;
		}
	}

	.label-wrong {
		color: $terracotta;
	}

	.label-confirmed {
		color: $azure;
	}
}

#appeal-confirmation-code-input-label {
	margin-top: spacing(2);
	margin-left: spacing(1);
}

#appeal-confirmation-heard-about-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column;
	margin-top: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
		align-items: center;

		p {
			margin-bottom: 0;
		}
	}

	p {
		flex: 2;
		margin-bottom: spacing(1);
	}

	.appeal-confirmation-heard-about-input {
		flex: 1;
	}
}

#appeal-confirmation-code-expired-card {
	border: 2px solid $terracotta;
	border-radius: 8px;
	width: 100%;
	padding: spacing(1);
	margin-top: spacing(2);
}

#appeal-confirmation-animation {
	width: 300px;
	height: 300px;
}

#confirmation-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 spacing(2);
}
