#appeal-address-input-container {
	grid-column: 1 / -1;
	display: flex;
	flex-wrap: wrap;
	margin-top: spacing(4);
	background-color: $cloud;
	padding: spacing(2);
	border-radius: spacing(2);

	@include if-size("tablet") {
		padding: spacing(4);
		//box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

.appeal-parcel-state-input {
	width: calc(50% - spacing(1));
	margin-right: spacing(2);
}

.appeal-parcel-county-input {
	width: calc(50% - spacing(1));
}

.appeal-parcel-parcel-input-container {
	position: relative;
	margin-top: spacing(2);
	width: 100%;
}

.appeal-parcel-parcel-input {
	margin-bottom: 0;
	margin-right: 0;
	margin-left: 0;
}

.appeal-parcel-parcel-input-with-results {
	@extend .appeal-parcel-parcel-input;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	padding-bottom: spacing(1);
	border-bottom: none;

	&:active,
	&:focus,
	&:hover {
		border: 1px solid $input-border-default !important;
		border-bottom: none !important;
	}
}

.appeal-parcel-autocomplete-results-container {
	background-color: $white;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
	border: 1px solid $input-border-default;
	border-top: none;
	overflow: hidden;
}

.appeal-parcel-autocomplete-results-item {
	padding: spacing(2);
	cursor: pointer;

	&:hover {
		background-color: rgba($denim, 0.25);
	}
}

.appeal-parcel-cta-container {
	margin-top: spacing(2);
	grid-column: 1 / -1;

	p.sm {
		margin-top: spacing(2);
		width: 100%;
		text-align: left;
	}

	.error-text {
		text-align: center !important;
		color: $terracotta;
	}

	.button-v2 {
		margin: 0 auto;
	}

	@include if-size("desktop") {
		margin-top: spacing(6);
		grid-column: 3 / -3;
	}
}

.appeal-parcel-autocomplete-container {
	width: 100%;
	margin-bottom: spacing(1);
}

#appeal-parcel-county-info-container {
	padding-left: 4px;
	margin: spacing(2) 0 0;
}

#appeal-parcel-county-apn-template-container {
	margin-bottom: spacing(1);
}

.parcel-number-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;

	@include if-size("tablet") {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	.quick-link {
		margin-left: 8px;
		font-size: 12px;
		@include if-size("tablet") {
			font-size: 14px;
		}

		&:hover {
			svg {
				width: 16px;
				height: 16px;
			}
		}

		svg {
			width: 12px;
			height: 12px;
			margin-left: 4px;
		}
	}
}

.parcel-number-container-address-page {
	@extend .parcel-number-container;
	margin: auto;
	padding-bottom: 16px;

	@include if-size("tablet") {
		margin: 0 0 0 auto;
		padding-bottom: 0;
	}
}

.parcel-number-container-savings-page {
	@extend .parcel-number-container;
	padding-top: 16px;
}
