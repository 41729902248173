.appeal-history {
	display: flex;
	gap: spacing(4);
	width: 100%;
	flex-direction: column;
	position: relative;

	&.preview {
		overflow: clip;
		mask-image: linear-gradient(to top, transparent, black 70%, black);

		@media screen and (max-width: 460px) {
			max-height: 80px;
			padding-bottom: 32px;
		}

		padding-bottom: 0;
		max-height: 60px;

		@include if-size("desktop") {
			max-height: 40px;
		}

		.faq-articles {
			display: none;
		}

		.history-items {
			overflow: hidden;

			.history-item {
				.history-item-description {
					display: none;
				}
			}

			.history-item-label {
				flex: 1;
				&-skeleton {
					height: 18px;
				}
			}
		}

		.opacity-fade {
			display: none;
			.scroll-to-view-more {
				display: none;
			}
		}
	}

	&:not(.preview) {
		// Leaving this commented for now
		// ... because it was quite tricky to implement
		// ... and we may want to highlight the first status in the future
		// .history-item:first-child {
		// 	.history-item-label {
		// 		flex: 1 0 100px;
		// 		max-width: max-content;
		// 		background-color: $denim;
		// 		color: $white;
		// 		padding: spacing(0.5);
		// 		border-radius: 4px;

		// 		.history-item-date {
		// 			opacity: 1;
		// 		}

		// 		&-skeleton {
		// 			height: 56px;
		// 			@include if-size("desktop") {
		// 				height: 38px;
		// 			}
		// 		}
		// 	}

		// 	.history-item-dot {
		// 		margin-top: 8px;
		// 	}
		// }

		.history-item-label {
			&-skeleton {
				height: 48px;
				@include if-size("desktop") {
					height: 18px;
				}
			}
		}
	}

	.history-items {
		flex: 1 1 50%;
		grid-column: unset;
		position: relative;

		@include if-size("desktop") {
			padding-top: spacing(1);
			max-height: 500px;
			overflow-y: auto;

			&::-webkit-scrollbar-track {
				border-radius: 100px;
				background-color: $denim_5;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $denim;
			}
		}

		.history-item {
			display: flex;
			padding-bottom: spacing(2);
			flex-wrap: wrap;
			position: relative;

			&:last-of-type {
				.history-item-line {
					display: none;
				}
			}

			&-skeleton {
				@extend .history-item;
			}

			.history-item-line {
				position: absolute;
				width: 2px;
				top: 14px;
				height: calc(100%);
				left: 5px;
				background-color: $denim;
				z-index: 1;

				&-skeleton {
					@extend .history-item-line;
				}
			}

			.history-item-dot {
				border-color: $denim;
				background-color: $cloud;
				min-width: 12px;
				height: 12px;
				width: 12px;
				border: 2px solid $denim;
				background-color: $cloud;
				margin: 4px spacing(1) 0 0;
				border-radius: 50%;
				z-index: 100;

				&-skeleton {
					@extend .history-item-dot;
				}
			}

			.history-item-label {
				flex: 1;
				font-size: 16px;
				display: flex;
				flex-direction: column;
				gap: spacing(1);
				line-height: 18px;

				@include if-size("desktop") {
					flex-direction: row;
					align-items: baseline;
				}

				.history-item-date {
					font-size: 12px;
					opacity: 0.5;

					line-height: 14px;
				}

				&-skeleton {
					@include shimmer-bg-white();
					background-color: $sand;
					width: 200px;
					border-radius: 4px;
				}
			}

			.history-item-description {
				margin-top: spacing(1);
				margin-left: 20px;
				margin-right: spacing(3);
				flex: 1 1 100%;

				@include if-size("desktop") {
					margin-top: spacing(0.5);
				}

				&-skeleton {
					@include shimmer-bg-white();
					@extend .history-item-description;
					height: 80px;
					background-color: $sand;
					border-radius: 4px;
				}

				font-size: 12px;
				line-height: 1rem;
				li,
				p {
					font-size: 12px;
					line-height: 1rem;
				}

				ol.documents-failed-review-reasons {
					margin-left: 12px;

					li {
						&:not(:last-child) {
							margin-bottom: spacing(1);
						}
					}
				}
			}
		}
	}

	.opacity-fade {
		display: none;
		@include if-size("desktop") {
			display: flex;
			align-items: center;
			justify-content: center;
			background: linear-gradient(to bottom, transparent, $cloud);

			position: absolute;
			top: 450px;
			left: 0px;
			width: 100%;
			height: 60px;
			z-index: 100;
			border-radius: 0px 0px 0px 16px;
		}

		.scroll-to-view-more {
			display: none;
			@include if-size("desktop") {
				display: inline-block;
				flex: 0 0 min-content;
				white-space: nowrap;
				background: rgba($denim, 0.7);
				color: $white;
				padding: spacing(1) spacing(2);
				border-radius: 40px;
			}
		}

		&.hide {
			display: none;
		}
	}

	.faq-articles {
		flex: 1 1 50%;
		display: grid;
		grid-auto-rows: min-content;
		gap: spacing(2);

		.faq-article {
			width: 100%;
			background-color: $sky-light;
			color: $denim;
			padding: spacing(2);
			border-radius: 8px;
			box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
			display: flex;
			align-items: center;
			justify-content: space-between;

			&-skeleton {
				@include shimmer-bg-white();
				@extend .faq-article;
				width: 100%;
				height: 60px;
				background-color: $sand;
			}

			.faq {
				font-size: 16px;
				line-height: 24px;
				margin-right: spacing(4);
			}

			.faq-view {
				white-space: nowrap;
				color: $azure;
				font-size: 14px;

				display: flex;
				justify-content: center;
				align-items: center;

				span {
					margin-bottom: 4px;
				}

				svg {
					margin-left: 4px;
					width: 12px;
					height: 12px;
					fill: $azure;
				}
			}

			&:hover {
				.faq-view {
					color: $denim;
					svg {
						fill: $mint;
					}
				}
			}
		}
	}
}
