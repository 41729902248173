.expand-button {
	width: fit-content;
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		p {
			color: $denim;
		}
		svg {
			fill: $mint;
		}
	}

	p {
		font-family: "sofia-pro";
		font-size: 16px;
		letter-spacing: 0.01em;
		color: $azure;
		margin-right: spacing(1);

		&:hover {
			color: $denim;
		}
	}

	svg {
		margin-top: 4px;
		fill: $azure;
		width: 12px;
		height: 12px;
	}

	&.expand-button-terracotta {
		.expand-label {
			color: $terracotta;
		}

		svg {
			fill: $terracotta;
		}
	}
}

.expand-button-reverse {
	@extend .expand-button;

	p {
		margin-right: 0;
		margin-left: spacing(1);
	}
}
