#account-property-profile-progress-bar {
	max-width: 500px;
	margin: 0 auto spacing(4);

	p.sm,
	.label {
		text-align: center;
	}

	p.sm {
		margin-bottom: spacing(1);
	}

	.label {
		margin-top: spacing(1);
	}

	.progress-bar-container {
		background-color: $white;
	}
}

#account-property-profile-intro {
	@include if-size("desktop") {
		padding: spacing(4);
	}

	h3 {
		margin-bottom: spacing(2);

		@include if-size("desktop") {
			margin-bottom: spacing(4);
		}
	}
}

#account-property-profile-intro-begin {
	margin: spacing(4) auto 0;
}

#account-property-profile-intro-title-and-badge {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-bottom: spacing(4);
	@include if-size("desktop") {
		margin-bottom: spacing(2);
	}
}

.account-property-profile-deadline-badge {
	background-color: $sunflower-lightest;
	color: $gold;
	font-weight: bold;
	letter-spacing: -0.02em;

	display: flex;
	align-items: center;
	gap: 4px;

	font-size: 10px;
	line-height: 12px;
	border: 1px solid $sunflower;
	border-radius: 6px;
	padding: 4px;
	@include if-size("desktop") {
		font-size: 14px;
		line-height: 16px;
		border: 1.5px solid $sunflower;
		border-radius: 8px;
		padding: 4px 8px;
	}

	svg {
		margin-top: 1px;
		vertical-align: middle;
		width: 12px;
		height: 12px;
		@include if-size("desktop") {
			width: 16px;
			height: 16px;
		}
	}
}

#account-property-profile-intro-info-box {
	margin-top: spacing(4);
	background-color: $sky-light;
	border: solid 2px $azure;
	padding: spacing(2);
	border-radius: 16px;

	display: flex;
	flex-direction: column;
	gap: spacing(1);

	ul {
		li {
			margin-left: spacing(3);
		}
	}
}

#account-property-profile-intro-dismiss {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: spacing(3);

	button {
		margin-top: spacing(1);
	}
}

#property-profile-question {
	.property-profile-question-note {
		&-azure {
			background-color: $azure;
		}

		&-rust {
			background-color: $rust;
		}

		&-azure,
		&-rust {
			padding: spacing(2);
			border-radius: 16px;
			color: $white;
			margin-bottom: spacing(4);
			line-height: spacing(3);

			p {
				color: $white;
			}
		}
	}
}

.account-property-profile-question-instructions {
	width: 100%;
	background-color: $cloud;
	padding: spacing(2);
	border-radius: 8px;
	margin-bottom: spacing(4);

	p.lg {
		margin-bottom: spacing(1);
	}
}

.profile-question,
.profile-field {
	margin-bottom: spacing(2);
	align-self: flex-end;

	&-fw {
		width: 100%;
		@extend .profile-question;
	}

	&-hw {
		width: calc(50% - spacing(1));
		@extend .profile-question;

		&-ml {
			@extend .profile-question-hw;
			margin-left: spacing(1);
		}
		&-mr {
			@extend .profile-question-hw;
			margin-right: spacing(1);
		}
	}

	&-dt {
		@include if-size("tablet") {
			&-fw {
				width: 100%;
			}

			&-hw {
				width: calc(50% - spacing(1));

				&-ml {
					width: calc(50% - spacing(1));
					margin-left: spacing(1);
				}
				&-mr {
					width: calc(50% - spacing(1));
					margin-right: spacing(1);
				}
			}
		}
	}
}

.profile-question-skip {
	cursor: pointer;
	margin: spacing(4) auto 0;
	width: fit-content;
	text-align: center;
	color: $azure;

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	svg {
		margin-top: 6px;
		height: 16px;
		width: 16px;
	}
}

#save-property-details {
	margin: spacing(4) auto;
}

#account-referral-page {
	margin: 0;

	@include if-size("tablet") {
		margin: 0 auto;
	}

	@include if-size("desktop") {
		padding: spacing(4) 0 spacing(8);
	}
}

#account-referral-container {
	grid-column: 1/-1;
	border-radius: 16px;

	@include if-size("tablet") {
		background-color: $cloud;
		padding: 0 spacing(4) spacing(4);
		margin: 0 auto;
	}

	@include if-size("desktop") {
		grid-column: 3/-3;
	}

	.referral-input {
		background-color: $white;
	}

	#referral-section-share-section {
		padding-top: 0;

		@include if-size("desktop") {
			padding-top: spacing(4);
		}
	}

	#referral-reflink-copy-text {
		z-index: 100;
	}
}

.profile-purchase-price {
	margin-top: spacing(2);
}

.yard-sign-terms {
	box-shadow: 1px 3px 7px 2px #0532490d;
	border: 1px solid #f7f9fc;

	padding: spacing(2);
	gap: 16px;
	border-radius: 16px;

	ul {
		li {
			margin-left: spacing(2);
		}
	}
}

#property-profile-question {
	.bill-reduction-form-content {
		padding: 0;
		background-color: $denim_lightest;
	}

	select.with-label,
	select.with-label.empty {
		margin-top: 0;
	}
}
