@keyframes GrowIn {
	from {
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
}

#password-requirements-card {
	background: $white;
	border-radius: 16px;
	padding: spacing(2);
	position: relative;

	ul {
		padding-left: spacing(2);
	}

	.password-requirement {
		list-style: none;
		padding-left: spacing(1);

		&::before {
			content: "•";
			position: absolute;
			left: spacing(3);
		}

		&-valid {
			@extend .password-requirement;

			&::before {
				content: url("/checkmark_small.svg");
				width: 16px;
				height: 11px;
				position: absolute;
				left: spacing(2);
				animation: GrowIn 0.3s ease-in-out;
			}
		}

		&-invalid {
			@extend .password-requirement;
			color: $terracotta;
		}
	}
}
