#property-settings-page {
	grid-column: 1/-1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#property-settings-search-container {
	margin-top: spacing(3);
	.label {
		margin-bottom: 4px;
	}

	.search {
		padding: spacing(1) spacing(2);
	}
}

#property-settings-active-properties-heading {
	margin: spacing(2) 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.property-settings-property-card {
	background-color: $cloud;
	border-radius: 16px;
	padding: spacing(2);
	position: relative;
	margin-bottom: spacing(4);
}

.property-settings-property-card-inactive {
	@extend .property-settings-property-card;
	background-color: #ecece7;
}

.property-settings-property-card-address {
	padding-bottom: spacing(2);
	border-bottom: 2px solid $white;
	display: flex;
	flex-direction: column;

	@include if-size("desktop") {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}

.property-settings-property-card-address-settings-container {
	display: flex;
	align-items: center;

	.quick-link {
		margin-right: spacing(4);
		margin-top: 4px;

		@include if-size("desktop") {
			margin-top: 0;
		}
	}
}

.property-settings-property-card-bottom {
	padding-top: spacing(2);
	display: flex;
	flex-direction: column;

	@include if-size("desktop") {
		flex-direction: row;
	}
}

.property-settings-property-card-appeal {
	@include if-size("desktop") {
		flex: 2;
		border-bottom: none;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.property-settings-property-card-insurance {
	flex: 1;
	border-top: 2px solid $white;
	margin-top: spacing(1);
	padding-top: spacing(1);

	@include if-size("desktop") {
		margin-top: 0;
		padding-top: 0;
		padding-left: spacing(3);
		border-top: none;
		border-left: 2px solid $white;
	}
}

.property-settings-property-card-appeal-title,
.property-settings-property-card-insurance-title {
	margin-bottom: calc(spacing(1) + 4px); // To fix weird line-height issue
}

.property-settings-property-card-appeal-data-container,
.property-settings-property-card-insurance-data-container {
	display: flex;
	flex-wrap: wrap;
	gap: spacing(2);
}

.property-settings-property-card-data-point-label-container {
	display: flex;
	align-items: center;
}

.property-settings-property-card-address-settings-dots {
	position: absolute;
	cursor: pointer;
	z-index: 1;
	top: 22px;
	right: 8px;
	padding: spacing(1);

	@include if-size("desktop") {
		top: 18px;
		right: 8px;
	}
}

.property-settings-property-card-popover {
	position: absolute;
	top: 8px;
	right: 8px;
	background-color: $denim;
	z-index: 10;
	border-radius: 8px;
	padding: spacing(1);
	width: fit-content;
}

.property-settings-property-card-popover-inner {
	position: relative;
}

.property-settings-property-card-popover-options-container {
	width: 100%;
	margin-top: 4px;
	padding: 0 spacing(1);
	display: flex;
	flex-direction: column;
}

.property-settings-property-card-popover-option {
	cursor: pointer;
	padding: 4px spacing(1);
	border-radius: 4px;
	margin-bottom: spacing(1);
	width: fit-content;
	margin-right: spacing(4);
	white-space: nowrap;

	p {
		color: $white;
	}

	&:hover {
		background-color: $azure;
	}

	@include if-size("desktop") {
		margin-bottom: spacing(1);
	}

	&-disabled {
		@extend .property-settings-property-card-popover-option;
		cursor: not-allowed;

		p {
			color: rgba($white, 0.5);
		}
	}
}

.property-settings-property-card-popover-close {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10000;

	svg {
		height: 16px;
		width: 16px;
	}

	svg > g {
		fill: $white;
	}
}

.property-settings-property-card-cta-container {
	margin-top: spacing(2);
}

.property-settings-property-card-data-point {
	.appeal-info-question-card-tooltip-small {
		height: fit-content;

		.appeal-info-question-card-tooltip-text {
			margin: 0;
			margin-left: 4px;

			svg {
				min-height: 12px;
				min-width: 12px;
				height: 12px;
				width: 12px;
			}
		}
	}
}

.property-settings-property-card-label {
	display: flex;
}

.property-settings-flow-card {
	background-color: $denim-lightest;
	padding: spacing(2);
	border-radius: 8px;
	margin: spacing(4) auto 0;

	@include if-size("desktop") {
		margin: spacing(4) spacing(8) 0;
	}
}

.property-settings-flow-card-flex {
	@extend .property-settings-flow-card;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.property-settings-flow-card-title {
	margin-bottom: spacing(2);
}

#property-settings-flow-progress-bar {
	max-width: 500px;
	margin: 0 auto;
	text-align: center;

	.progress-bar-container {
		background-color: transparent;
		margin-bottom: 2px;
	}
}

.property-settings-flow-card-cta-container {
	width: fit-content;
	margin: spacing(4) auto 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.property-settings-flow-card-skip {
	width: fit-content;
	margin: spacing(1) auto 0;
	color: $azure;
	cursor: pointer;

	&-disabled {
		@extend .property-settings-flow-card-skip;
		cursor: not-allowed;
	}
}

.property-settings-mark-as-sold-flow-card-radio {
	&:nth-of-type(1) {
		margin-right: spacing(1);
	}
}

.property-settings-mark-as-sold-flow-represent-new-property-banner {
	background-color: $azure;
	margin: spacing(2) 0 0;
	padding: spacing(2);
	border-radius: 16px;

	> p.sm {
		color: $white;
	}

	@include if-size("desktop") {
		margin: spacing(2) spacing(8) 0;
	}
}

.property-settings-flow-text-area {
	width: 100%;
}

.property-settings-cancel-current-reduced-contingency-cta-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
