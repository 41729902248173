.radio {
	display: flex;
	align-items: center;

	input[type="radio"],
	input[type="checkbox"] {
		cursor: pointer;
		width: 20px;
		height: 20px;
		accent-color: $input-border-default;
		margin-right: 8px;
	}

	input[type="radio"]:checked,
	input[type="checkbox"]:checked {
		accent-color: $azure;
	}

	input[type="radio"] + p {
		cursor: pointer;
	}
}
