#pay-invoice-page {
	padding: spacing(4) 0;

	@include if-size("desktop") {
		padding: spacing(6) 0;
	}

	.invoice-print-button {
		grid-column: 1/-1;
		padding-bottom: 8px;

		@include if-size("desktop") {
			grid-column: 11/-1;
		}
	}
}

#pay-invoice-page-header {
	grid-column: 1 / -1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	row-gap: spacing(2);

	margin-bottom: spacing(2);

	@include if-size("desktop") {
		grid-column: 2 / -3;
	}
}

#pay-invoice-container {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 2 / -2;
	}
}

#pay-invoice-form-card {
	grid-column: 1/ -1;
	padding: spacing(3) spacing(2);
	background: $denim-lightest;
	border-radius: 16px;
	position: relative;

	@include if-size("desktop") {
		padding: spacing(4);
		grid-column: 2 / 8;
	}
}

#pay-invoice-pay-by-check-card {
	grid-column: 1 / -1;
	border: 1px solid $cloud;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 16px;
	padding: spacing(3) spacing(2);
	height: fit-content;
	margin-top: spacing(2);

	@include if-size("desktop") {
		margin-top: 0;
		padding: spacing(4);
		grid-column: 8 / -2;
	}
}

#pay-invoice-form {
	display: flex;
	flex-direction: column;
	margin-top: spacing(3);
	row-gap: spacing(3);
}

#pay-invoice-form-field-row {
	display: flex;
	flex-wrap: wrap;
	gap: spacing(2);
}

.pay-invoice-form-field-half {
	flex: 1;

	@include if-size("tablet") {
		width: calc(50% - spacing(2));
	}
}

#pay-invoice-cc-field {
	position: relative;
}

#pay-invoice-cc-logo {
	position: absolute;
	right: 8px;
	top: 7px;

	@include if-size("tablet") {
		top: 10px;
		right: 12px;
	}
}

#pay-invoice-form-btn-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: spacing(1);

	.powered-by-stripe {
		margin: 0 auto;
	}
}

#pay-invoice-questions-container {
	grid-column: 1 / -1;
	display: flex;
	justify-content: center;
	margin-top: spacing(4);

	@include if-size("desktop") {
		margin-top: spacing(6);
	}
}

.pay-invoice-input-invalid {
	border: 1px solid $terracotta;
	box-sizing: border-box;
	position: relative;

	&:focus,
	&:hover {
		@extend .pay-invoice-input-invalid;
	}
}

.pay-invoice-input-invalid-entry-container {
	display: flex;
	align-items: center;

	svg {
		min-width: 12px;
		min-height: 12px;
		margin-top: 2px;
		margin-right: 5px;
	}
}

#pay-invoice-paid-pill {
	background: $mint;
	padding: spacing(1) spacing(4);
	border-radius: 10000px;
	text-transform: uppercase;
	margin: 0 auto;

	p {
		font-weight: 622;
	}

	@include if-size("tablet") {
		margin: 0;
	}
}

#pay-invoice-void-pill {
	@extend #pay-invoice-paid-pill;
	background: $terracotta;

	p {
		color: $white;
	}
}

#pay-invoice-form-btn {
	z-index: 1001;

	margin: 0 auto;
	&-submitting {
		@extend #pay-invoice-form-btn;
		background: $denim;
		border: $denim;

		&:disabled,
		&:hover,
		&:focus {
			background: $denim;
			border: $denim;
			cursor: wait;
		}
	}
}

#pay-invoice-animation-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background: rgba($cloud, 0.7);
}

#pay-invoice-animation {
	transform: scale(0.3) translateY(-30%);

	@include if-size("desktop") {
		transform: scale(0.5) translateY(-20%);
	}
}

#pay-invoice-form-payment-failed {
	background: $terracotta;
	border-radius: 16px;
	padding: spacing(2);
	margin-top: spacing(2.5);

	p {
		color: $white;
	}
}

#pay-invoice-form-payment-failed-heading {
	display: flex;
	align-items: center;

	> svg {
		margin-right: 6px;
		margin-top: 3px;
	}
	> svg > path {
		fill: $white;
	}
}

#pay-invoice-payment-successful-modal {
	position: relative;
	background-color: $white;
	border-radius: 16px;
	padding: spacing(4) spacing(4);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	max-width: 90vw;

	@include if-size("desktop") {
		padding: spacing(4) spacing(8);
	}
}

#pay-invoice-payment-successful-modal-close {
	position: absolute;
	top: 16px;
	right: 16px;
	height: 22px;
	width: 22px;
	cursor: pointer;

	svg {
		fill: $denim;
	}
}

#pay-invoice-payment-successful-animation-container {
	width: 250px;
	height: 250px;
}

#pay-invoice-payment-successful-animation {
	transform: scale(0.5) translateY(-50%) translateX(-50%);
}

#pay-invoice-other-invoices {
	border-radius: 16px;
	padding: spacing(2);
	background-color: $denim-lightest;
	border: solid 2px $denim-extra-light;

	display: flex;
	flex-direction: column;
	gap: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
		align-items: center;
		gap: spacing(3);
	}

	svg {
		width: 27px;
		height: auto;
	}

	.pay-invoice-other-invoices-select {
		flex: 1;
		@include if-size("desktop") {
			min-width: 210px;
		}
	}
}
