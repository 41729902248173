.taxes-table-card {
	@include if-size("monitor") {
		position: relative;
		background-color: $white;
		border-radius: 16px;
		border: solid 1px $slate;
		padding: spacing(3);
		box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
			-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
			-44px 319px 90px 0px #ababab00;
	}
}

table.taxes-table {
	border-collapse: separate;
	border-spacing: 0 12px;
	height: min-content;
	position: relative;

	td,
	th {
		vertical-align: middle;
	}

	tr {
		height: min-content;

		background: $white;

		&:first-child {
			background: transparent;
		}

		&:not(:first-child) {
			height: 52px;
			@include if-size("monitor") {
				height: 60px;
			}

			background-color: $white;
			@include if-size("monitor") {
				background-color: $denim_lightest;
			}

			position: relative;

			// box-shadow fix
			&::after {
				box-sizing: border-box;
				content: "";
				height: 52px;
				@include if-size("monitor") {
					height: 60px;
				}
				position: absolute;
				left: 0;
				right: 0;
				display: block;

				box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
					-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
					-44px 319px 90px 0px #ababab00;
				-webkit-box-shadow: -2px 13px 28px 0px #ababab1a,
					-7px 51px 51px 0px #ababab17, -16px 115px 70px 0px #ababab0d,
					-28px 204px 82px 0px #ababab03, -44px 319px 90px 0px #ababab00;

				@include if-size("monitor") {
					box-shadow: none;
					-webkit-box-shadow: none;
				}

				border: solid 1px $slate;
				pointer-events: none;

				border-radius: 12px;
				@include if-size("monitor") {
					border-radius: 16px;
				}
			}

			td,
			th {
				text-align: center;

				padding: 12px;
				@include if-size("monitor") {
					padding: 16px;
				}
			}

			// Set border-radius on the top-left and bottom-left of the first table data on the table row
			td:first-child,
			th:first-child {
				border-radius: 12px 0 0 12px;
				@include if-size("monitor") {
					border-radius: 16px 0 0 16px;
				}
			}

			// Set border-radius on the top-right and bottom-right of the last table data on the table row
			td:last-child,
			th:last-child {
				border-radius: 0 12px 12px 0;
				@include if-size("monitor") {
					border-radius: 0 16px 16px 0;
				}
			}
		}

		td {
			.year {
				display: flex;
				align-items: center;
				gap: spacing(1);

				&-skeleton {
					@extend .year;
					width: 65px;
				}

				.projected,
				.pending {
					font-weight: bold;
					letter-spacing: -0.02em;

					padding: 6px;
					border-radius: 6px;
					font-size: 10px;
					line-height: 12px;
					@include if-size("monitor") {
						padding: 6px 8px;
						border-radius: 8px;
						font-size: 14px;
						line-height: 16px;
					}
				}

				.projected {
					background-color: $cloud;
					color: $denim-medium;
				}
				.pending {
					background-color: $sky-light;
					color: $royal;
				}

				.logo {
					margin-top: 4px;
					path {
						fill: $denim;
					}
				}
			}

			.increase {
				color: $denim_5;

				&-skeleton {
					@extend .increase;
					@include shimmer();
					border-radius: 4px;
					width: 30px;
					height: 12px;
				}
			}

			.dollar {
				&-skeleton {
					@extend .dollar;
					@include shimmer();
					border-radius: 4px;
					width: 60px;
					@include if-size("monitor") {
						width: 100px;
					}
					height: 12px;
				}
			}

			.bill {
				&-skeleton {
					@extend .bill;
					@include shimmer();
					border-radius: 4px;
					width: 70px;
					height: 12px;
				}
			}
		}
	}
}
