#contact-section {
	text-align: left;
	padding: spacing(4) 0 spacing(6);
	color: $denim;

	@include if-size("tablet") {
		text-align: center;
		padding: spacing(4) 0 spacing(8);
	}

	textarea {
		min-height: 120px;
	}

	.contact-section-header {
		grid-column: 1/-1;
	}

	.contact-section-title {
		padding-bottom: 4px;
	}

	.contact-section-info {
		padding-bottom: 4px;

		.contact-section-info-contact {
			display: block;
			padding-bottom: 4px;

			&:nth-child(2) {
				padding-left: spacing(0);
				@include if-size("desktop") {
					padding-left: spacing(4);
				}
			}
			@include if-size("desktop") {
				display: inline;
			}
			svg {
				margin: auto 8px auto 0;
			}
		}

		&.contact-section-info-last {
			line-height: 22px;
			@include if-size("tablet") {
				padding-bottom: spacing(3);
			}
		}
		svg {
			max-width: 12px;
			margin-bottom: 2px;
			@include if-size("tablet") {
				max-width: 16px;
			}
		}
	}
}

.contact-form-mobile-box {
	margin-top: spacing(2);
	padding: spacing(3);
	-webkit-box-shadow: 1px 3px 7px 2px rgb(0 0 0 / 10%);
	box-shadow: 1px 3px 7px 2px rgb(0 0 0 / 10%);
	border-radius: 16px;
	background-color: $cloud;
}

#commercial-contact-form,
#partner-contact-form {
	display: flex;
	flex-wrap: wrap;
	grid-column: 1/-1;
	padding-top: spacing(2);

	@include if-size("tablet") {
		grid-column: 1/-1;
		padding: spacing(3);
		-webkit-box-shadow: 1px 3px 7px 2px rgb(0 0 0 / 10%);
		box-shadow: 1px 3px 7px 2px rgb(0 0 0 / 10%);
		border-radius: 16px;
		background-color: $denim_lightest;
	}

	@include if-size("desktop") {
		padding: spacing(4);
		grid-column: 3/-3;
	}

	.with-label {
		margin-top: 2px;
	}

	.contact-input-full {
		width: 100%;
		text-align: left;
		margin-bottom: spacing(2);

		label {
			margin-left: 8px;
		}
	}

	.contact-input {
		margin-bottom: spacing(2);

		label {
			text-align: left;
		}

		&.contact-input-commercial-fname {
			margin-right: 8px;
		}

		&.contact-input-commercial-email,
		&.contact-input-commercial-phone,
		&.contact-input-commercial-unitnumber,
		&.contact-input-commercial-propertytype,
		&.contact-input-fname,
		&.contact-input-lname,
		&.contact-input-commercial-unit-number,
		&.contact-input-partner-linkedin,
		&.contact-input-commercial-company {
			width: 100%;
			margin-left: 0px;
			margin-right: 0px;

			@include if-size("tablet") {
				width: calc(50% - 8px);
			}
		}

		&.contact-input-commercial-phone,
		&.contact-input-commercial-propertytype,
		&.contact-input-lname,
		&.contact-input-commercial-unit-number {
			@include if-size("tablet") {
				margin-left: 8px;
			}
		}

		&.contact-input-commercial-email,
		&.contact-input-commercial-unitnumber,
		&.contact-input-fname,
		&.contact-input-commercial-company {
			@include if-size("tablet") {
				margin-right: 8px;
			}
		}

		&.contact-input-partner-email,
		&.contact-input-partner-phone,
		&.contact-input-partner-partnertype,
		&.contact-input-partner-company,
		&.contact-input-partner-fname,
		&.contact-input-partner-lname,
		&.contact-input-partner-hdyhau {
			width: 100%;
			margin-left: 0px;
			margin-right: 0px;

			@include if-size("tablet") {
				width: calc(50% - 8px);
			}
		}

		&.contact-input-partner-phone,
		&.contact-input-partner-company,
		&.contact-input-partner-lname,
		&.contact-input-partner-hdyhau {
			@include if-size("tablet") {
				margin-left: 8px;
			}
		}

		&.contact-input-partner-email,
		&.contact-input-partner-partnertype,
		&.contact-input-partner-fname,
		&.contact-input-partner-linkedin {
			@include if-size("tablet") {
				margin-right: 8px;
			}
		}

		.input-email-suggestion {
			text-align: left;
			font-size: 12px;
		}
	}

	.commercial-contact-form-thanks-for-reaching-out {
		text-align: left;

		.commercial-contact-form-thanks-for-reaching-out-btn {
			margin: spacing(2) auto 0;
		}
	}

	#commercial-contact-btn {
		margin: 0 auto;
	}
}

.commercial-contact-error {
	width: 100%;
	padding-bottom: spacing(2);
}

.contact-email-prompt {
	margin-top: 4px;
}

.address-search-wrapper {
	position: relative;
	width: 100%;
	.address-search-results {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		margin-top: -20px;
		background-color: white;
		z-index: 9;
		border-right: 1px solid #b1b1bb;
		border-left: 1px solid #b1b1bb;
		border-bottom: 1px solid #b1b1bb;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		overflow: hidden;
		.address-item {
			text-align: left !important;
			padding: 2px 16px;
			cursor: pointer;
			&:hover {
				background-color: #f5f5f5;
			}
		}
		.line-separator {
			width: calc(100% - 10px);
			margin: 0 auto;
			height: 1px;
			background-color: #b1b1bb;
		}
	}
}
