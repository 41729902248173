#referral-reflink-section {
	padding: spacing(4) 0 0;
}

#referral-reflink-text {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / 11;
		text-align: left;
	}

	#referral-reflink-title {
		text-align: left;
		margin-bottom: spacing(1);

		@include if-size("desktop") {
			grid-column: 3 / 8;
		}
	}
}

#referral-reflink-form {
	.referral-input {
		position: relative;
		display: block;
		box-sizing: border-box;
		border-radius: 8px;
		padding-right: 75px;
		opacity: unset;
		background-color: $white;
	}
}

.referral-reflink-copy-container {
	position: relative;

	input {
		cursor: pointer;
	}
	#referral-reflink-copy-text {
		position: absolute;
		top: 50%;
		right: spacing(2);
		transform: translateY(-50%);
		color: $azure;
		z-index: 1;
	}
}
