#referral-referred {
	grid-column: 1/-1;
	border: 1px solid $azure;
	border-radius: 8px;
	padding: spacing(2);
	margin-top: spacing(4);

	@include if-size("desktop") {
		grid-column: 3/11;
	}
	.referral-referred-content {
		color: $azure;
	}
}
