#landing-title-section {
	padding: spacing(6) spacing(0) spacing(0);
	background: $sky-light;

	@include if-size("desktop") {
		padding: 12px spacing(0) spacing(0);
	}

	&.landing-title-section-commercial {
		padding: spacing(6) 0;

		@include if-size("tablet") {
			padding: spacing(12) 0;
		}
	}

	#landing-title-hero-section {
		align-items: center;
	}

	#landing-title-hero-img,
	#exemptions-landing-title-hero-img {
		display: flex;
		justify-content: center;
		align-items: center;
		grid-column: 1 / -1;
		height: 100%;
		width: 100%;

		@include if-size("desktop") {
			grid-column: 7 / 13;
		}

		& > svg > svg {
			transform: unset !important;
		}
	}

	//investor page
	.commercial-hero {
		height: 100%;
		width: 100%;
		max-width: 304px;
		max-height: 228px;

		@include if-size("tablet") {
			max-width: 560px;
			max-height: 420px;
		}
	}

	//commercial page
	.commercial-new-hero {
		height: 100%;
		width: 100%;
		max-width: 300px;
		max-height: 282px;
		margin-bottom: 24px;

		@include if-size("tablet") {
			max-width: 368px;
			max-height: 4346px;
		}

		@include if-size("desktop") {
			margin-bottom: 0;
		}
	}

	#landing-title-subheading {
		margin-bottom: spacing(2);
	}

	#landing-title-input-section {
		grid-column: 1 / -1;

		@include if-size("desktop") {
			max-width: none;
			grid-column: 1 / 6;
		}

		#landing-title {
			margin-bottom: spacing(2);
		}

		#landing-title-partner {
			margin-bottom: 16px;
			span {
				font-family: "moret";
			}
		}

		#landing-title-subtitle {
			color: $azure;
			margin-top: spacing(2);
			line-height: 32px;

			p {
				color: $azure;
				line-height: 32px;
				text-indent: -17px;
				padding-left: 17px;
			}
			span {
				display: inline-block;
			}

			&-commercial {
				@extend #landing-title-subtitle;
				margin-top: 0;
				@include if-size("desktop") {
					padding-bottom: spacing(1);
				}
			}

			&-partner {
				@extend #landing-title-subtitle;
				color: $denim;
				padding-bottom: spacing(2);
			}
		}

		#landing-title-cta {
			text-align: center;
			margin: spacing(3) 0 spacing(7) 0;

			@include if-size("desktop") {
				margin: spacing(2) 0;
				text-align: initial;
			}
			a {
				display: inline-block;
			}
		}
	}

	#commercial-landing-left-side-section {
		grid-column: 1 / -1;
		order: 2;

		@include if-size("desktop") {
			max-width: none;
			grid-column: 1 / 8;
			order: 1;
		}

		#landing-title {
			margin-bottom: spacing(2);
		}

		#landing-title-partner {
			margin-bottom: 16px;
			span {
				font-family: "moret";
			}
		}

		#landing-title-subtitle {
			color: $azure;
			margin-top: spacing(2);
			line-height: 32px;

			p {
				color: $azure;
				line-height: 32px;
				text-indent: -17px;
				padding-left: 17px;
			}
			span {
				display: inline-block;
			}

			&-commercial {
				@extend #landing-title-subtitle;
				margin-top: 0;
				@include if-size("desktop") {
					padding-bottom: spacing(1);
				}
			}

			&-partner {
				@extend #landing-title-subtitle;
				color: $denim;
				padding-bottom: spacing(2);
			}
		}

		#landing-title-cta {
			text-align: center;
			margin: spacing(3) 0 spacing(7) 0;

			@include if-size("desktop") {
				margin: spacing(2) 0;
				text-align: initial;
			}
			a {
				display: inline-block;
			}
		}
	}
}

.landing-title-section-commercial {
	&#landing-title-section
		#commercial-landing-left-side-section
		#landing-title-subtitle-commercial {
		color: $denim-medium;
	}

	#landing-title-hero-section {
		#landing-title-hero-img {
			grid-column: 1/-1;
			order: 1;
			@include if-size("desktop") {
				grid-column: 9/13;
				order: 2;
			}
		}
	}
}

.dont-see-address {
	background-color: $azure;
	width: 100%;
	padding: spacing(1) 0;
	cursor: pointer;
	text-align: center;
	color: $cloud;

	&:hover {
		background-color: $denim;
	}
}

#landing-title-label-autocomplete-loading-spinner {
	display: flex;
	align-items: center;
}

.landing-title-autocomplete-results-loader {
	margin-left: 4px;
	width: 16px;
	position: relative;
	top: -4px;
	opacity: 0.7;
}

#landing-title-autocomplete-results-wrapper {
	overflow-x: hidden;
	position: absolute;
	border: 1px solid $input-border-active;
	border-top: none;
	width: 100%;
	border-radius: 0px 0px 6px 6px;
	z-index: 1000;
	background-color: $white;
	top: calc(100% - 3px);
	@include styled-scrollbar;
}

#landing-title-autocomplete-results-container {
	overflow-y: auto;
	overscroll-behavior: contain;
	animation: slide-in linear 5s;

	.landing-title-autocomplete-item {
		padding: spacing(2) spacing(3);

		font-size: 16px;
		cursor: pointer;

		&-active {
			@extend .landing-title-autocomplete-item;
			background-color: $sky-light;
		}

		&:hover {
			background-color: $sky-light;
		}
	}
}

#landing-title-input-wrapper,
#property-page-search-wrapper {
	position: relative;

	@include if-size("tablet") {
		max-width: 100%;
	}

	#landing-title-input {
		width: 100%;

		&-autocomplete-open {
			@extend #landing-title-input;
			border-color: $input-border-active;
			border-bottom: 1px solid transparent;
			border-radius: 6px 6px 0 0;
		}
	}

	#landing-title-input-label {
		margin-bottom: spacing(1);
	}

	#landing-title-autocomplete-results-wrapper {
		overflow-x: hidden;
		position: absolute;
		border: 1px solid $input-border-active;
		border-top: none;
		width: 100%;
		border-radius: 0px 0px 6px 6px;
		z-index: 1000;
		background-color: $white;
		top: calc(100% - 3px);
		@include styled-scrollbar;
	}

	#landing-title-label-autocomplete-loading-spinner {
		display: flex;
		align-items: center;
	}

	.landing-title-autocomplete-results-loader {
		margin-left: 4px;
		width: 16px;
		position: relative;
		top: -4px;
		opacity: 0.7;
	}

	#landing-title-autocomplete-results-container {
		overflow-y: auto;
		overscroll-behavior: contain;
		animation: slide-in linear 5s;

		.landing-title-autocomplete-item {
			padding: spacing(2) spacing(3);

			font-size: 16px;
			cursor: pointer;

			&-active {
				@extend .landing-title-autocomplete-item;
				background-color: $sand;
			}

			&:hover {
				background-color: $sand;
			}
		}
	}
}

#property-page-search-wrapper {
	grid-column: 1/-1;
	margin-bottom: 24px;
	@include if-size("desktop") {
		margin-bottom: 48px;
	}
}

#landing-title-input-label {
	margin-bottom: spacing(1);
}

#landing-title-input {
	width: 100%;

	&-autocomplete-open {
		@extend #landing-title-input;
		border-color: $input-border-active;
		border-bottom: 1px solid transparent;
		border-radius: 6px 6px 0 0;
	}
}

#commercial-hero-contact-us-cta {
	padding-top: 12px;
	display: flex;
	gap: 24px;
	align-items: center;
	flex-direction: column;
	@include if-size("desktop") {
		flex-direction: row;
	}
}
