#landing-hcad {
	background-color: $denim-lightest;
	#landing-hero-section {
		height: 100%;
		@include if-size("desktop") {
			padding-top: 33px;
			height: 617px;
		}
		#landing-hero-illustration {
			height: 566px;
			@include if-size("desktop") {
				height: 100%;
			}
		}
	}

	#about-product {
		background-color: $white;
	}

	#about-results {
		background-color: $white;
	}

	#landing-experts-section {
		background-color: $denim-lightest;
	}

	#landing-marquee-section {
		padding-top: 48px;
		@include if-size("desktop") {
			padding-top: 0;
		}
	}

	.landing-signup-step {
		justify-content: flex-start !important;
	}
}

#landing-hcad-hero-content {
	z-index: 10;
}
.landing-hcad-hero-title {
	grid-column: 1/-1;
	text-align: center;
}
.hcad-card-link {
	width: 100%;
	position: relative;

	@include if-size("desktop") {
		width: calc(33% - 9px);
		gap: 6px;
		&:first-of-type,
		&:nth-child(2) {
			width: calc(50% - 12px);
		}
	}

	&:first-of-type {
		&::before {
			content: "Featured";
			color: $white;
			background-color: $kelly;
			border-radius: 8px;
			padding: 6px;
			position: absolute;
			top: -22px;
			right: 16px;
			font-family: "Sofia-Pro";
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px; /* 142.857% */
			letter-spacing: -0.21px;
			z-index: 1;
		}
	}
}

.hcad-card {
	border-radius: 16px;
	background: $white;
	box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);
	width: 100%;
	height: 100%;
	padding: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;

	@include if-size("desktop") {
		justify-content: space-between;
	}

	.hcad-card-content {
		flex: 1;
	}

	.hcad-card-title-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.hcad-card-img {
		width: 48px;
		height: 48px;
	}

	.hcad-card-right-arrow {
		min-width: 20px;
		min-height: 20px;
	}
}

.hcad-cards {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	padding-top: 24px;
}

.our-mission-minimal-banner {
	grid-column: 1/-1;
	padding: 64px 0;

	@include if-size("desktop") {
		padding: 96px 0px;
	}
}
