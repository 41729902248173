#rotating-services {
	box-shadow: -2px 13px 28px 0px rgba(171, 171, 171, 0.1019607843),
		-7px 51px 51px 0px rgba(171, 171, 171, 0.0901960784),
		-16px 115px 70px 0px rgba(171, 171, 171, 0.0509803922),
		-28px 204px 82px 0px rgba(171, 171, 171, 0.0117647059),
		-44px 319px 90px 0px rgba(171, 171, 171, 0);
	border: solid 1px #d4dee3;
	border-radius: 16px;
	height: 100%;
	width: 100%;
	display: inline-grid;
	position: relative;
	overflow: hidden;

	.swiper {
		width: 100%;
	}

	.swiper,
	.swiper-wrapper {
		height: 100%;
	}

	.rotating-services-body {
		align-self: flex-start;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		-webkit-line-clamp: 3;
	}

	.swiper-slide {
		width: 100% !important;
		height: 100%;
	}
	.swiper-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: 20px;
	}

	.swiper-pagination-bullet {
		background: transparent;
		opacity: 1;
		border: 1px solid $denim-extra-light;
	}

	.swiper-pagination-bullet-active {
		width: 50px;
		border-radius: 5px;
		background-color: $slate;
	}

	#property-referral-card {
		padding: 24px 24px 44px 24px;
		border: 0;
	}

	.rotating-services-wildcard-answered {
		display: flex;
		gap: 8px;
		justify-content: center;
		width: 100%;
	}
	.rotating-services-confetti {
		width: 20px;
		height: 20px;
	}

	.incident-badge {
		color: $rust-dark;
		border-radius: 8px;
		background-color: $rust-lightest;
		padding: 6px 8px;
	}
}

.property-rotating-services-cta-container {
	display: flex;
	justify-content: space-between;
	gap: 12px;
	width: 100%;

	.button-v2,
	a,
	.link {
		margin: auto;
	}

	.rotating-services-wildcard-buttons {
		width: 100%;
		max-width: 200px;
		&:first-of-type {
			margin: 0 0 0 auto;
		}

		&:last-of-type {
			margin: 0 auto 0 0;
		}
	}
}
