.chart-card {
	position: relative;
	background-color: $white;

	border-radius: 16px;
	border: 1px solid $slate;
	box-shadow:
		-2px 13px 28px 0px #ababab1a,
		-7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d,
		-28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	padding: spacing(2);
	position: relative;

	width: 100%;
	height: max-content;

	display: flex;
	flex-direction: column;

	@include if-size("desktop") {
		padding: spacing(3);
	}

	&-top {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 12x;
		margin-bottom: 12px;

		@include if-size("monitor") {
			flex-wrap: nowrap;
		}

		&-badges {
			display: flex;
			gap: 8px;
			height: min-content;
			flex-direction: column;

			width: 100%;
			@include if-size("desktop") {
				max-width: max-content;
				flex-direction: row;
			}
		}

		&-description {
			width: 100%;
			max-width: 100%;

			p.lg {
				margin-bottom: 4px;
			}

			p.body-tiny {
				margin-bottom: 8px;
			}

			p.sm {
				font-size: 12px;
				line-height: 16px;
			}
		}
	}

	.property-value-chart-view-toggle {
		width: 230px;

		@include if-size("desktop") {
			width: 280px;
		}
	}

	&-badge {
		border-radius: 8px;
		background-color: $denim_lightest;
		border: solid 1px $slate;
		padding: 8px 12px;

		width: 100%;
		@include if-size("desktop") {
			width: fit-content;
		}

		&.value-change {
			svg {
				height: 16px;
				width: 16px;
				margin-right: spacing(1);
			}
		}

		p:first-of-type {
			@include if-size("tablet") {
				white-space: nowrap;
			}
		}

		&-skeleton {
			@extend .chart-card-badge;

			p:first-of-type {
				margin-bottom: 4px;
			}

			p {
				@include shimmer-bg-denim-lightest();
				border-radius: 4px;
				line-height: 24px;
				color: transparent;
			}
		}
	}

	&-legend {
		padding-top: 24px;
		display: flex;
		flex-wrap: wrap;
		column-gap: 24px;
		row-gap: 12px;

		&-entry {
			display: flex;
			gap: 8px;
			align-items: center;
		}

		&-key {
			min-width: 16px;
			min-height: 16px;
			max-width: 16px;
			max-height: 16px;
			border-radius: 50%;

			&.denim {
				background-color: rgba($denim, 0.5);
			}

			&.azure {
				background-color: $azure;
			}

			&.slate {
				background-color: $slate;
			}

			&.powder {
				background-color: $powder;
			}

			&.azure-dashed {
				border: 2px dotted $azure;
			}
		}

		&-value {
			font-size: 12px;
			line-height: 16px;
			color: $denim;
		}
	}
}
