#account-information-page {
	padding: spacing(4) 0 spacing(8);

	#account-information-title {
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}
	}

	.account-settings-box {
		grid-column: 1 / -1;
		background-color: $denim-lightest;
		margin-top: spacing(2);
		padding: spacing(2) spacing(1);
		border-radius: 16px;
		box-shadow: $primary-box-shadow;

		@include if-size("tablet") {
			padding: spacing(4);
		}
		@include if-size("desktop") {
			grid-column: 3 / -3;
		}
	}

	.account-settings-name-input {
		width: calc(50% - spacing(1));
	}

	.account-settings-submit-btn {
		margin: spacing(2) auto 0;
	}

	.account-settings-name-row,
	.account-settings-new-password-row {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: spacing(2);
	}

	.account-settings-old-password {
		margin-top: spacing(2);
	}

	.account-settings-new-password-input {
		width: 100%;
		&:last-of-type {
			margin-top: spacing(2);
		}

		@include if-size("tablet") {
			width: calc(50% - spacing(1));
			&:last-of-type {
				margin-top: 0;
			}
		}
	}

	.account-settings-email-phone {
		margin-top: spacing(4);
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}

		.label {
			margin-top: spacing(1);
		}
	}

	.account-settings-mailing-address {
		margin-top: spacing(4);
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}
	}

	.account-settings-mailing-address-line2 {
		margin-top: spacing(1);
	}

	.account-settings-mailing-address-city {
		margin-top: spacing(2);
	}

	.account-settings-mailing-address-state-zip {
		display: flex;
		justify-content: space-between;
		margin-top: spacing(2);

		> div {
			width: calc(50% - spacing(1));
		}
	}

	.password-error {
		display: flex;
		align-items: center;
		margin-top: spacing(1);

		svg {
			height: 16px;
			width: 16px;
			margin-right: 1ch;
		}
		p {
			color: $terracotta;
		}
	}
}

#account-settings-verification-code-heading {
	grid-column: 1 / -1;
	margin-top: spacing(4);

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#account-settings-verification-code-box {
	grid-column: 1 / -1;
	background: $denim-lightest;
	padding: spacing(2);
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: spacing(2);

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#account-settings-verification-code-input {
	background: $sand;
	border: none;
	margin-bottom: spacing(2);
	letter-spacing: 0.1ch;
}
