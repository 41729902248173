#appeal-contact-question-container {
	position: relative;
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

.appeal-contact-question {
	> button {
		margin: spacing(4) auto spacing(8);
	}
}

.appeal-contact-question-card {
	background-color: $denim-lightest;
	//box-shadow: $primary-box-shadow;
	border-radius: 16px;
	padding: spacing(2);

	p.sm {
		margin-top: 4px;
	}

	@include if-size("desktop") {
		padding: spacing(4);
	}

	> .appeal-info-question-card-tooltip {
		margin-top: spacing(2);
	}
}

.appeal-contact-input-flex-container,
.exemptions-input-flex-container {
	display: flex;
	flex-direction: column;
	margin-top: spacing(2);
	gap: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
		justify-content: space-between;
	}

	.appeal-contact-card-input,
	.exemptions-card-input {
		width: 100%;

		@include if-size("desktop") {
			width: calc(50% - spacing(1));
			margin-top: 0;
		}
	}
}

.appeal-contact-radio-input-container {
	flex: 1;
	display: inline-flex;
	flex-direction: column;

	.radio:first-of-type {
		margin-bottom: spacing(2);
	}

	@include if-size("desktop") {
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		margin-left: spacing(2);

		.radio:first-of-type {
			margin-bottom: 0;
			margin-right: spacing(4);
		}
	}
}

.appeal-contact-radio-list-container {
	margin-top: spacing(2);

	@include if-size("desktop") {
		margin-top: 0;
	}

	.label {
		margin-bottom: spacing(1);
	}

	.radio:not(:first-of-type) {
		margin-top: spacing(1);

		@include if-size("desktop") {
			margin-top: spacing(2);
		}
	}
}

.appeal-contact-takeover-card {
	padding: spacing(2);
	margin: spacing(2) 0;
	border-radius: 16px;

	@include if-size("desktop") {
		margin: spacing(4) 0;
	}

	p.sm {
		color: $white;
	}

	&-azure {
		@extend .appeal-contact-takeover-card;
		background-color: $azure;
	}

	&-rust {
		@extend .appeal-contact-takeover-card;
		background-color: $rust;
	}
}

.appeal-contact-verification-warning-card {
	padding: spacing(2);
	margin: spacing(2) 0;
	border-radius: 16px;

	@include if-size("desktop") {
		margin-top: spacing(2);
		margin-bottom: 0;
	}

	p.sm {
		color: $white;
	}

	&-azure {
		@extend .appeal-contact-verification-warning-card;
		background-color: $azure;
	}

	&-rust {
		@extend .appeal-contact-verification-warning-card;
		background-color: $rust;
	}
}

.appeal-contact-takeover-card-terracotta,
.appeal-contact-recent-purchase-card-terracotta {
	@extend .appeal-contact-takeover-card;
	background-color: $terracotta;
}

.appeal-contact-question-card-verify-owner-details-container {
	display: flex;
	gap: 16px;
	flex-direction: column;
	padding-bottom: 16px;

	@include if-size("desktop") {
		flex-direction: row;
	}
}

.appeal-contact-question-card-verify-owner-details {
	padding: spacing(2);
	background-color: $white;
	border-radius: 16px;
	width: 100%;
}

#appeal-contact-input-container {
	grid-column: 1 / -1;
	display: flex;
	flex-wrap: wrap;
	padding: spacing(2);
	background-color: $cloud;
	border-radius: spacing(1);

	.appeal-contact-description {
		margin-bottom: spacing(3);
	}

	@include if-size("tablet") {
		padding: spacing(4);
		border-radius: 16px;
		box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-contact-checkbox-container {
	width: 100%;
	padding: spacing(2);
	background-color: $cloud;
	border-radius: 8px;

	.checkbox > p {
		margin-left: spacing(1);
	}

	@include if-size("tablet") {
		padding: 0;
		background-color: transparent;
	}
}

.contact-input-hw-container {
	margin-top: spacing(2);
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.contact-input-hw {
	width: 100%;
	margin-top: spacing(2);

	@include if-size("tablet") {
		width: calc(50% - spacing(1));
		margin-top: 0;

		&:nth-of-type(odd) {
			margin-right: spacing(1);
		}
		&:nth-of-type(even) {
			margin-left: spacing(1);
		}
	}
}

.contact-input {
	margin-bottom: 26px;
	width: calc(50% - 8px);
}

.contact-input-fname {
	margin-right: spacing(1);
}

.contact-input-lname {
	margin-left: spacing(1);
}

.contact-input-phone {
	width: 100%;
	padding-top: spacing(2);

	@include if-size("tablet") {
		width: calc(50% - 8px);
		margin-left: spacing(1);
		padding-top: 0;
	}
}

#appeal-contact-input-title {
	grid-column: 1 / -1;
	margin-bottom: spacing(1);

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#reauthorize-company-type-input-container {
	width: 100%;

	.label {
		margin-left: spacing(1);
	}
}

.appeal-contact-duplicate-signup-option-container {
	margin-top: spacing(2);
	gap: spacing(2);
	display: flex;
	flex-direction: column;

	@include if-size("tablet") {
		flex-direction: row;
	}
}

.appeal-contact-duplicate-signup-option-box {
	flex: 1;
	background-color: $white;
	padding: spacing(2);
	text-align: center;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 8px;
	cursor: pointer;

	@include if-size("desktop") {
		max-width: 50%;
	}

	&-active {
		@extend .appeal-contact-duplicate-signup-option-box;
		padding: calc(spacing(2) - 2px);
		border: 2px solid $azure;
	}
}

.appeal-contact-duplicate-signup-customer-email {
	overflow-wrap: anywhere;
}

#contact-premium-pricing {
	display: flex;
	flex-direction: column;

	button.button-v2.primary {
		margin-top: spacing(4);
	}

	button.button-v2.primary-outline {
		margin-top: spacing(2);
	}

	> button.button-v2 {
		margin: auto;
	}

	#premium-pricing-comparison-table {
		margin-top: spacing(8);
	}
}
