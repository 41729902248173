.tile-options {
	display: flex;
	flex-wrap: wrap;
	$gap: 14px;
	gap: $gap;

	&.mobile-1 {
		.tile-option {
			flex: 1 1 calc(100%);
		}
	}

	&.mobile-2 {
		.tile-option {
			flex: 1 1 calc(50% - #{$gap});
		}
	}

	&.desktop-2 {
		.tile-option {
			@include if-size("desktop") {
				flex: 1 1 calc(50% - #{$gap});
			}
		}
	}

	&.desktop-3 {
		.tile-option {
			@include if-size("desktop") {
				flex: 1 1 calc(33% - #{$gap});
			}
		}
	}

	.tile-option {
		flex: 1 1 calc(50% - #{$gap});

		background-color: $white;
		box-shadow: 1px 3px 7px 2px #0532490d;

		padding: spacing(2);
		border-radius: 8px;

		display: flex;
		justify-content: center;
		align-items: center;

		border-width: 2px;
		border-color: $white;
		border-style: solid;

		text-align: center;

		@include if-size("desktop") {
			&:hover {
				cursor: pointer;
				border-color: $azure;
			}
		}

		color: $denim;

		&.selected {
			background-color: $sky-light;
			border-color: $azure;
			font-weight: bold;
		}
	}
}
