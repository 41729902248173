#investor-landing-dark {
	p,
	h1,
	h2,
	h3 {
		color: white;
	}
	.content-wrapper {
		margin: 0 auto;
		max-width: 1136px;
		position: relative;
		padding: 0 16px;
		text-align: center;
		//overflow: hidden;
		@include if-size("desktop") {
			padding: 0;
			text-align: left;
		}
	}
	.white-outline {
		border-color: white;
		color: white;
	}
	#header-section {
		background-color: $denim-black;
		@include if-size("desktop") {
			padding: 16px 0 64px;
		}
		.heading-content {
			.partner-banner-dark {
				padding-bottom: 16px;
				#partners-referral-banner {
					background-color: $slate;
					padding: 12px 32px;
					gap: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 8px;
					width: fit-content;
					color: $denim-black;
					p {
						margin: 0;
						padding: 0;
						color: $denim-black;
						width: unset;
						max-width: unset;
					}
				}
			}
			h1 {
				width: 775px;
				max-width: 100%;
			}
			p {
				margin-top: 20px;
				width: 775px;
				max-width: 100%;
			}
		}
		.header-bottom-wrapper {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			position: relative;
			flex-direction: column;
			@include if-size("desktop") {
				flex-direction: row;
			}
			.left-container {
				display: flex;
				flex-direction: column;
				gap: 20px;
				justify-content: center;
				align-items: center;
				@include if-size("desktop") {
					align-items: start;
					justify-content: start;
				}
				.btns-container {
					display: flex;
					gap: 16px;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					@include if-size("desktop") {
						flex-direction: row;
					}
				}
				.trusted-by-container {
					display: flex;
					gap: 5px;
					justify-content: start;
					align-items: center;
					.trusted-by-imgs {
					}

					.trusted-by-content {
						display: flex;
						flex-direction: column;
						justify-content: start;
						.star-icon {
							text-align: left;
						}
						.trusted-by-text {
							.trusted-by-title {
							}
						}
					}
				}
			}
			.right-container {
				position: relative;
				width: 100%;
				height: auto;
				margin-top: 20px;
				@include if-size("desktop") {
					width: 50%;
					margin-top: 0;
				}
				.product-page-screenshot-container {
					position: absolute;
					right: 0;
					top: 0;
					width: 100%;
					max-width: 100vw;
					overflow: hidden;
					@include if-size("tablet") {
						width: 60%;
						left: 50%;
						transform: translateX(-50%);
					}
					@include if-size("desktop") {
						width: 100%;
					}
				}
			}
		}
	}
	#rating-steps-section {
		padding-top: 250px;
		padding-bottom: 64px;
		background-color: $denim_lightest;
		@include if-size("tablet") {
			padding-top: 350px;
		}
		@include if-size("desktop") {
			padding-top: 300px;
		}
		.rating-container {
			margin-top: 48px;
			background-color: $denim-black;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 8px;
			flex-direction: column;
			gap: 20px;
			padding: 20px;
			margin: 10px;
			@include if-size("desktop") {
				flex-direction: row;
				padding: 40px;
				margin: 24px auto;
			}
			.btns-container {
				display: flex;
				gap: 16px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				@include if-size("desktop") {
					flex-direction: row;
				}
			}
			.title-container {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 20px;
				@include if-size("desktop") {
					justify-content: start;
					align-items: start;
					gap: 10px;
				}
				h4 {
					color: white;
					margin-bottom: 16px;
				}
			}

			.rating-boxes {
				display: flex;
				flex-direction: column;
				gap: 16px;
				@include if-size("desktop") {
					gap: 32px;
				}
				.ratings-row {
					display: flex;
					gap: 16px;
					@include if-size("desktop") {
						gap: 32px;
					}
					.rating-box {
						background-color: #00233580;
						height: 112px;
						border-radius: 4px;
						text-align: center;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						width: 50%;
						padding: 10px;
						gap: 4px;
						@include if-size("desktop") {
							width: 200px;
							padding: 0;
						}
						.value {
							p {
								position: relative;
								.superscript {
									position: absolute;
									font-size: 24px;
									top: -10px;
									right: -15px;
									&.front {
										left: -15px;
										right: unset;
									}
								}
							}
						}

						.rating-text {
							p {
								color: $slate;
							}
						}
					}
				}
			}
		}
		.appeal-steps {
			margin-top: 96px;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@include if-size("desktop") {
				flex-direction: row;
			}
			.appeal-step {
				width: 368px;
				padding: 24px;
				.step-img {
					text-align: center;
					margin: 0 auto;
					margin-bottom: 10px;
				}
				.step-title {
					font-weight: 800;
					color: $denim_dark;
					text-align: center;
					@include if-size("desktop") {
						text-align: left;
					}
				}

				.step-text {
					color: $denim-medium;
					text-align: center;
					@include if-size("desktop") {
						text-align: left;
					}
				}
			}
		}
	}

	#advantage-section {
		background-color: $denim-black;
		padding: 16px;
		padding-top: 64px;
		padding-bottom: 250px;
		position: relative;
		@include if-size("desktop") {
			padding: 96px;
			padding-bottom: 200px;
		}
		.advantage-content-wrapper {
			display: flex;
			gap: 48px;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			@include if-size("desktop") {
				flex-direction: row;
				gap: 112px;
			}
			.advantage-content {
				display: flex;
				flex-direction: column;
				gap: 16px;
				justify-content: start;
				align-items: start;
				.h4 {
					text-align: left;
				}
				.subtext {
					color: $slate;
					text-align: left;
				}
				.btns-container {
					margin-top: 16px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 16px;
					align-items: center;
					margin: 16px auto;
					@include if-size("desktop") {
						justify-content: start;
						align-items: start;
						margin: 16px 0;
					}
				}
			}
			.advantage-cards {
				display: flex;
				flex-direction: column;
				gap: 16px;
				.advantage-card {
					flex: 1;
					padding: 16px;
					display: flex;
					gap: 16px;
					justify-content: start;
					align-items: center;
					background: #566c7733;
					box-shadow: 1px 3px 7px 2px #0532490d;
					border-radius: 8px;
					p {
						text-align: left;
					}
					.card-content {
						display: flex;
						justify-content: start;
						flex-direction: column;
						.card-title {
						}
						.card-text {
							color: $slate;
						}
					}
				}
			}
		}
		.featured-in {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			gap: 16px;
			background-color: $denim_lightest;
			padding: 16px 64px;
			border-top-left-radius: 32px;
			border-top-right-radius: 32px;
			.label {
				color: $denim;
			}
			.featured-in-img {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	#pricing-section {
		background-color: $denim_lightest;
		#landing-view-pricing-section {
			padding-top: 96px;
			padding-bottom: 0;
			background-color: $denim_lightest;
			#view-pricing-card {
				background: linear-gradient(202.37deg, #002335 19.45%, #000a0f 90.29%);
				box-shadow: none;
				button {
					background-color: $kelly;
					&:hover {
						background-color: $kelly-dark;
					}
				}
			}
		}
	}

	#contact-section {
		background-color: $denim_lightest;
		padding: 24px 0;
		padding-top: 48px;
		.content-wrapper {
			h3 {
				color: $denim-black;
				text-align: center;
			}

			p {
				color: $denim-black;
				text-align: center;
			}

			#commercial-contact-form {
				@include if-size("desktop") {
					background-color: white;
				}
				box-shadow: none;
			}
		}
	}

	#landing-commercial-contact-modal-underlay-container {
		p {
			color: $denim;
		}
	}
}
