#referral {
	row-gap: spacing(4);
}

#referral-modal-container {
	position: fixed;
	top: 50px;
	left: 0;
	width: 100%;
	height: calc(100% - 50px);
	z-index: 100001;
	background-color: #fff;
	overflow-x: hidden;

	@include if-size("tablet") {
		background-color: rgba($denim, 0.5);
		top: 0;
		height: 100%;
		width: 100%;
		padding: 32px;
	}
}

#referral-modal {
	position: absolute;
	height: auto;
	width: 100%;
	max-width: 1000px;
	background-color: $denim_lightest;
	display: flex;
	flex-direction: column;
	justify-content: center;

	overflow: hidden;

	@include if-size("tablet") {
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		border-radius: spacing(2);
		box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
		width: 90%;
	}

	@include if-size("desktop") {
		top: 35%;
		width: 80%;
		top: 42%;
		flex-direction: row-reverse;
		min-width: 880px;
	}

	.referral-modal-image-container {
		background-color: $powder;
		max-width: none;
		text-align: right;
		position: relative;
		overflow: hidden;
		height: 380px;

		@include if-size("desktop") {
			height: unset;
			flex: 1;
		}

		#referral-modal-image-school {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateY(-50%) translateX(-50%);
			min-width: 100%;
			min-height: 100%;
			max-height: 200%;
			max-width: 200%;
			height: auto;
			width: auto;

			@include if-size("desktop") {
				min-height: 100%;
				min-width: 100%;
			}
		}

		#referral-modal-school-overlay {
			padding: spacing(2);
			background: rgba(255, 249, 249, 0.55);
			backdrop-filter: blur(4.5px);
			border-radius: 16px;
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: calc(100% - 32px);
			width: max-content;
			text-align: left;
			transform: translateX(-50%) translateY(-80%);

			@include if-size("desktop") {
				padding: spacing(4);
				transform: translateX(-50%) translateY(-70%);
			}
		}

		#referral-modal-image-dogs {
			height: auto;
			width: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate3d(0px, -32%, 0.1px);

			@include if-size("tablet") {
				transform: translateY(-28%);
			}

			@include if-size("desktop") {
				transform: translate3d(0px, -48%, 0.1px);
				height: auto;
				min-height: 100%;
				min-width: 100%;
				object-fit: cover;
			}

			@include if-size("monitor") {
				transform: translate3d(0px, -45%, 0.1px);
			}
		}
		#referral-modal-image-dogs {
			height: auto;
			width: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate3d(0px, -32%, 0.1px);

			@include if-size("tablet") {
				transform: translateY(-28%);
			}

			@include if-size("desktop") {
				transform: translate3d(0px, -48%, 0.1px);
				height: auto;
				min-height: 100%;
				min-width: 100%;
				object-fit: cover;
			}

			@include if-size("monitor") {
				transform: translate3d(0px, -45%, 0.1px);
			}
		}
	}

	#referral-modal-close {
		height: 24px;
		width: 24px;
		z-index: 1000;
		fill: $denim;
		padding: 4px;
		cursor: pointer;
		position: absolute;
		right: spacing(2);
		top: spacing(2);

		@include if-size("tablet") {
			right: spacing(4);
			top: spacing(4);
		}

		&:hover {
			fill: $white;
		}
	}

	#referral-modal-body {
		flex: 1;
		padding: 16px;
		max-width: none;

		@include if-size("desktop") {
			padding: 40px 48px;
		}

		h1 {
			white-space: nowrap;
			font-size: 32px;

			@include if-size("desktop") {
				font-size: 49px;
			}
		}

		#referral-modal-school-list {
			padding: 0;
			padding-left: 32px;
		}

		ul {
			padding: 24px 0 0 24px;
		}

		.referral-modal-body-footnote {
			color: $denim_5;
			font-size: 8px;
			font-weight: 433;
			line-height: 14px;
		}
	}

	.referral-modal-image-overlay {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: max-content;
		max-width: calc(100% - 32px);
	}

	.referral-modal-image-referral-message {
		background-color: $sky;
		padding: 3px spacing(2) 8px;
		border-radius: 8px;
		width: 100%;

		@include if-size("desktop") {
			padding: 5px spacing(2) 9px;
		}

		p {
			text-align: center;
			letter-spacing: -0.24px;
			line-height: 18px;
		}

		.referral-inline {
			display: inline;
			position: relative;
			top: 1px;
			font-weight: 800;
		}
	}

	.referral-modal-image-partner-message {
		border-radius: 6px;
		background: rgba(0, 35, 53, 0.32);
		padding: 3px 16px;
		width: 100%;
		margin: 6px 0 8px;

		@include if-size("desktop") {
			margin: 6px 0 16px;
		}

		p {
			color: $white;
			font-weight: 433;
		}

		a {
			color: $white;
			text-decoration: underline;
			font-weight: 700;
		}
	}
}
