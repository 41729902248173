.filter {
	&-dropdown {
		z-index: 10;
		position: relative;

		label {
			white-space: nowrap;
			color: $denim_5;
		}

		.label-container {
			display: flex;
			gap: 5px;
			justify-content: start;
			align-items: center;
			svg {
				cursor: pointer;
			}
			.tooltip2-body,
			.triangle-top {
				background-color: $slate;
			}
			.tooltip2-body-text {
				color: $denim;
			}
		}

		&-label {
			margin-left: spacing(0.5);
			margin-bottom: spacing(0.5);
		}

		&-container {
			width: 100%;
			height: 30px;
		}

		&-menu {
			width: 100%;
			overflow: clip;

			background-color: $white;
			border-width: 1px;
			border-style: solid;

			border-radius: 8px;
			border-color: $denim_5;

			&:hover {
				cursor: pointer;
				border-color: $denim;
			}

			&.selected {
				border-color: $denim;
			}

			&-button {
				padding: 3px 11px;
				border: none;
				background-color: transparent;
				font-family: $ff-secondary;

				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				gap: spacing(2);

				@extend .sm;

				&:hover {
					cursor: pointer;
				}

				// background-image to match select button
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				background-color: $white;
				background-image: url("data:image/svg+xml;utf8,<svg fill='%23b1b1bb' width='8' height='4' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
				background-repeat: no-repeat;
				background-position: calc(100% - 0.5rem) center;

				@include if-size("tablet") {
					background-position: calc(100% - 0.8rem) center;
					background-image: url("data:image/svg+xml;utf8,<svg fill='%23b1b1bb' width='13' height='6' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
				}

				&:active,
				&:focus,
				&:hover {
					outline: none;
					border-color: $denim;
					background-image: url("data:image/svg+xml;utf8,<svg fill='%23053249' width='8' height='4' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
					@include if-size("tablet") {
						background-image: url("data:image/svg+xml;utf8,<svg fill='%23053249' width='13' height='6' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
					}
				}

				&.hide-arrow {
					background-image: unset;
				}

				svg {
					position: absolute;
					right: spacing(1);
					width: 16px;
				}

				&-selections {
					color: $denim;
					margin-right: spacing(3);
					overflow: hidden;

					display: flex;
					flex-wrap: nowrap;
					gap: spacing(1);

					span {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						&:first-child {
							flex: 1;
						}

						flex: 0 1 min-content;

						&.no-selections {
							color: $denim_5;
						}
					}
				}
			}

			.apply-button {
				margin: spacing(2) auto;
				padding: 6px 16px 8px;
			}

			&-options {
				max-height: 200px;
				overflow: auto;
				.checkbox {
					display: flex;
					align-items: center;
					padding: spacing(1);

					label {
						color: $denim;
						white-space: pre-wrap;
					}
				}
			}
		}
	}

	&-select {
		.select-field-input-wrapper:has(select.with-label) {
			margin-top: 0;
			border-radius: 8px;
			height: 30px;
		}

		label {
			white-space: nowrap;
			color: $denim_5;
			margin-left: spacing(0.5);
			margin-bottom: 4px;
		}

		select {
			@extend .sm;

			margin: 0;
			padding: 3px 11px;

			background-color: $white;
			border-width: 1px;
			border-style: solid;
			border-color: $denim_5;

			border-radius: 8px;

			&:hover {
				cursor: pointer;
				border-color: $denim;
			}

			option {
				color: $denim_5;
			}

			&:focus {
				border-color: $denim;
			}
			&:not(:focus):valid {
				border-color: $denim;
			}
		}
	}

	&-search {
		border: solid 1px $denim;
		padding: 3px 11px;
		border-radius: 8px;

		display: flex;
		justify-content: space-between;
		align-items: center;

		&-input {
			@extend .sm;
			padding: 0;
			border: none;
			border-radius: 0;

			&::placeholder {
				color: $denim_5;
			}
		}

		svg {
			width: 13px;
			height: 13px;
		}
	}
}
