#properties-value-prop-card {
	display: none;
	@include if-size("desktop") {
		display: block;
	}

	grid-column: 1;
	grid-row: 1;
	background-color: $slate;
	padding: spacing(2);
	border-radius: 16px;
	height: fit-content;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	position: relative;

	@include if-size("desktop-xl") {
		grid-column: 2;
		margin: spacing(3.5) spacing(2);
	}
}

#properties-value-prop-card-close-container {
	position: absolute;
	top: 12px;
	right: 16px;
	cursor: pointer;
}

#properties-value-prop-card-title {
	padding-right: spacing(3.5);
}

#properties-value-prop-card-card-container {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	margin-top: spacing(3);
}

.properties-value-prop-card-value-prop-card {
	display: flex;
	align-items: center;
	gap: spacing(3);
	background: $white;
	padding: spacing(2);
	border-radius: 8px;
}

#properties-value-prop-card-cta {
	width: 100%;

	> span {
		display: flex;
		align-items: center;
	}

	svg {
		fill: $white;
		height: 14px;
		width: 14px;
		margin-right: spacing(1);
		margin-top: 3px;
	}
}
