.needs-attention-modal-address,
.detailed-appeal-status-view-address {
	text-align: center;
	width: 100%;
	margin-bottom: spacing(2);
}

.needs-attention-modal-title,
.detailed-appeal-status-view-title {
	display: flex;
	align-items: center;
	padding-top: spacing(1);
	padding-bottom: 4px;

	svg {
		height: 32px;
		width: 32px;
		margin-left: spacing(2);
	}
}
.needs-attention-consumer-vs-county-records-cards {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 16px;
	@include if-size("tablet") {
		flex-direction: row;
	}
}
.needs-attention-consumer-vs-county-records-single-card {
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 16px;
	border: 1px solid $cloud;
	padding: spacing(2);
	width: 100%;
	margin-bottom: spacing(1);

	.needs-attention-consumer-vs-county-records-highlight {
		background-color: $blonde;
		display: inline-block;
		padding: 0 spacing(1);
		border-radius: 4px;
		margin: 2px 0 spacing(1);

		&-only-ownername {
			margin: 2px 0 0;
		}
	}
}

.needs-attention-uploading-docs-under-review {
	background-color: $kelly-light;
}

.needs-attention-uploading-docs-failed-review {
	background-color: $rust;

	p.body-tiny {
		color: $white;
	}

	ol.documents-failed-review-reasons {
		margin-left: spacing(2);

		li {
			color: $white;

			&:not(:last-child) {
				margin-bottom: spacing(1);
			}
		}
	}
}

.needs-attention-uploading-docs-failed-review,
.needs-attention-uploading-docs-under-review {
	margin-top: spacing(2);

	border-radius: 8px;
	padding: 16px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: spacing(1);

	@include if-size("desktop") {
		gap: 12px;
		flex-direction: row;
		align-items: flex-start;
	}

	.needs-attention-uploading-docs-review-pill-my > p {
		position: relative;
		top: -2px;
	}
}
.needs-attention-uploading-docs-failed-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: spacing(1);
}

.needs-attention-uploading-docs-review-pill {
	white-space: nowrap;
	background-color: $kelly;
	border-radius: 10px;
	width: fit-content;
	padding: 6px spacing(2);

	&-my {
		@extend .needs-attention-uploading-docs-review-pill;
		margin: spacing(1) 0;
	}
}

.needs-attention-uploading-docs-failed-pill {
	@extend .needs-attention-uploading-docs-review-pill;
	background-color: $rust-dark;

	&-my {
		@extend .needs-attention-uploading-docs-failed-pill;
		margin: spacing(1) 0;
	}

	p.white {
		margin-top: -2px;
	}
}

.needs-attention-reason {
	margin-bottom: spacing(4);

	.expand-button {
		padding-top: 4px;

		p {
			font-size: 20px;
		}
		svg {
			width: 16px;
			height: 16px;
			margin-top: 5px;
		}
	}

	ul {
		padding-left: spacing(3);
	}

	.needs-attention-uploading-docs-failed-review,
	.needs-attention-uploading-docs-under-review {
		margin-bottom: spacing(2);
	}
}

.needs-attention-reason-cta-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: spacing(2);
}

.needs-attention-cancel-cta {
	margin: spacing(2) 0;
}

.needs-attention-multi-reason-heading {
	margin-top: spacing(1);
	margin-bottom: spacing(2);
}

.needs-attention-read-more-content {
	p:not(:last-of-type) {
		margin-bottom: spacing(1);
	}

	a {
		text-decoration: underline;

		&:hover {
			color: $denim;
		}
	}
}

.needs-attention-read-more-highlighted {
	padding: spacing(1);
	border: 2px solid $terracotta;
	background-color: rgba($terracotta, 0.15);
	border-radius: 8px;
	width: fit-content;
	margin: spacing(1) 0;
}
