#about-page {
	.expand-label {
		font-size: 12px;
		@include if-size("tablet") {
			font-size: 14px;
		}
	}

	#about-investors-section {
		background-color: $white;
		margin-bottom: 0;
		padding: 32px 0;

		@include if-size("desktop") {
			padding: 56px 0;
		}
	}
}
#about-team-section {
	padding-top: 64px;
	@include if-size("desktop") {
		padding-top: 96px;
	}

	#about-team-header {
		grid-column: 1 / -1;
		margin: 0 auto;
		text-align: center;
		padding-bottom: spacing(2);
	}

	#about-team-body {
		grid-column: 1 / -1;
		padding-bottom: spacing(6);

		@include if-size("desktop") {
			grid-column: 3 / 11;
			padding-bottom: spacing(6);
		}
	}

	.careers-page-executive-team-section {
		grid-column: 1/-1;

		.careers-page-executive-team {
			padding-bottom: 0;
		}
	}

	#about-team-slider-header {
		grid-column: 1 / -1;
		text-align: center;
		margin-bottom: 4px;
	}

	.about-team-section-body {
		grid-column: 1/-1;
		padding-bottom: spacing(4);
		text-align: center;
	}

	#landing-experts-section {
		padding: 0 0 64px;
		@include if-size("desktop") {
			padding: 0 0 96px;
		}
	}
}

#about-team-section-slider-container {
	grid-column: 1 / -1;
	display: flex;
}

#about-team-section-slider-nav-prev,
#about-team-section-slider-nav-next {
	display: flex;
	cursor: pointer;
	align-items: center;

	svg {
		fill: $denim;
		opacity: 0.2;
		height: 25px;
		width: 25px;

		@include if-size("tablet") {
			height: 30px;
			width: 30px;
		}

		@include if-size("desktop") {
			height: 30px;
			width: 18px;
		}
	}
}

#about-team-section-slider-pagination {
	grid-column: 1 / -1;
	display: flex;
	justify-content: center;
}

.about-team-section-pagination-bullet {
	width: 10px;
	user-select: none;
	height: 10px;
	background: $navy;
	border-radius: 50%;
	margin: 0 4px;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.about-team-card {
	position: relative;
	max-width: 500px;
	min-width: 200px;
	background-color: $white;
	padding: spacing(4) spacing(2) spacing(2);
	width: 100%;
	height: auto;
	box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
	border-radius: 8px;

	&:not(:last-of-type) {
		margin-right: 25px;
	}

	.about-team-img-container {
		position: absolute;
		right: 16px;
		top: -45px;
		height: 75px;
		width: 75px;
		border-radius: 50%;
		overflow: hidden;
		background-color: $sand;
	}

	.about-team-img {
		height: auto;
		width: 100%;
	}

	.card-body {
		display: -webkit-box;
		width: calc(100%);
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.quick-link {
		margin-top: spacing(2);
	}
}

#about-team-read-more-modal {
	z-index: 4000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;

	.about-team-card {
		background-color: #fff;
		width: 500px;
		max-width: 80vw;
	}
}

#about-team-head-office-header {
	grid-column: 1 / -1;
	margin: spacing(6) auto spacing(1);

	@include if-size("tablet") {
		margin: spacing(8) 0 0;
	}
}

.about-head-office-card {
	display: flex;
	align-items: center;
	grid-column: 1 / -1;
	box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background-color: $white;

	margin: spacing(2) auto;
	overflow: hidden;

	flex-direction: column;
	max-width: 300px;
	height: 324px;

	@include if-size("tablet") {
		max-height: 180px;
		flex-direction: row;
		max-width: 100%;
		height: auto;
	}

	@include if-size("desktop") {
		grid-column: span 4;
	}

	.about-head-office-card-left {
		width: 100%;
		overflow: hidden;
		height: 170px;
		@include if-size("tablet") {
			width: auto;
			height: 100%;
			max-width: 40%;
		}
	}

	.about-head-office-card-left-view {
		height: auto;
		width: 100%;
		position: relative;
		top: -48px;
		@include if-size("tablet") {
			top: -10px;
		}

		@include if-size("desktop") {
			top: 0;
			left: -30px;
			width: 200px;
		}
	}

	.about-head-office-card-right {
		flex: 2;
		height: 100%;
		padding: spacing(2);
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.about-head-office-card-body {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3; /* number of lines to show */
			-webkit-box-orient: vertical;
		}

		.about-head-office-card-read-more {
			font-size: 16px;
			letter-spacing: 0.05em;
			color: #1c7ce5;
		}
	}
}

#about-team-cta {
	grid-column: 1 / -1;
	margin: spacing(6) auto 0;

	@include if-size("tablet") {
		margin: spacing(12) auto 0;
	}
}

#about-team-executive {
	@extend .about-page-default-section-padding;
	background-color: $denim-lightest;

	.careers-page-executive-team {
		padding: 0;
	}

	.careers-page-executive-team-bio-container {
		background-color: $white;
	}
}
