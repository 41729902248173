#life-time-savings {
	padding: spacing(2);
	border: 1px solid #d4dee3;
	position: relative;
	background-color: $white;
	box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;
	border-radius: 16px;
	overflow: hidden;

	display: flex;
	gap: spacing(4);
	justify-content: space-between;
	flex-direction: column;

	@include if-size("tablet") {
		padding: spacing(4);
		flex-direction: row;
	}

	.saving-value-wrapper {
		flex: 1;
		max-width: 300px;
	}
	.green-graph {
		z-index: 0;
		position: absolute;
		mask-image: linear-gradient(to top, transparent 0%, black 10%);
		//margin-right: spacing(4);
		top: 12px;
		right: -24px;
		min-width: 100%;
		min-height: 136px;

		@include if-size("tablet") {
			top: -24px;
			right: -4px;
			height: auto;
		}

		@include if-size("desktop") {
			top: -64px;
			right: -24px;
		}

		@include if-size("monitor") {
			height: 150px;
			top: 12px;
			right: -24px;
		}
	}

	* {
		position: relative;
		z-index: 2;
	}

	.total-savings-amount {
		margin-bottom: 4px;
		font-weight: 727;
		font-size: 48px;
		line-height: 56px;
		letter-spacing: -0.01em;
		@include if-size("desktop") {
			font-size: 64px;
			line-height: 74px;
			letter-spacing: -0.03em;
		}
	}

	.total-savings-title {
		margin-bottom: spacing(3);
		@include if-size("desktop") {
			margin-bottom: spacing(4);
		}

		font-weight: 622;
		font-size: 16px;
		line-height: 19px;
		color: $denim;

		@include if-size("desktop") {
			font-size: 20px;
			line-height: 28px;
		}
	}

	.savings-categories {
		margin-bottom: spacing(3);

		> * + * {
			margin-top: 6px;
			@include if-size("desktop") {
				margin-top: spacing(1);
			}
		}
	}

	.savings-category {
		display: flex;
		justify-content: space-between;
		gap: spacing(12);
		@include if-size("tablet") {
			margin-left: spacing(4);
		}
		.category {
			color: $denim-medium;
			font-weight: 622;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: -0.015em;

			@include if-size("desktop") {
				font-size: 16px;
				line-height: 22px;
				letter-spacing: -0.01em;
			}
		}

		.savings-amount {
			color: $kelly-dark;
			background-color: $kelly-light;
			padding: 2px 12px;
			border-radius: 8px;

			font-weight: 622;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: -0.015em;

			@include if-size("desktop") {
				font-size: 16px;
				line-height: 22px;
				letter-spacing: -0.01em;
			}
		}
	}

	.savings-tip-box {
		background-color: $sunflower-lightest;
		padding: 12px 16px;
		border-radius: 8px;
		flex: 1;
		svg {
			height: 20px;
			width: 20px;
		}

		.savings-tip-title {
			display: flex;
			gap: 4px;
			align-items: center;
			margin-bottom: spacing(1);

			p {
				color: $gold;
				font-weight: 622;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: -0.015em;

				@include if-size("desktop") {
					font-size: 16px;
					line-height: 22px;
					letter-spacing: -0.01em;
				}
			}
		}

		.savings-tip-description {
			color: $denim-medium;

			font-size: 14px;
			line-height: 18px;
			letter-spacing: -0.015em;

			@include if-size("desktop") {
				font-size: 16px;
				line-height: 22px;
				letter-spacing: -0.01em;
			}
		}
	}
}
