#account-upload-evidence {
	padding: spacing(4) 0 spacing(8);

	#upload-evidence-heading {
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3/-3;
		}

		h2 {
			margin-bottom: spacing(4);
		}
	}

	#upload-evidence-card {
		@extend #property-documents-upload-card;
		overflow: hidden;
		position: relative;
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3/-3;
		}
		background-color: $cloud;
		padding: spacing(2);
		margin-top: spacing(2);
		border-radius: 16px;

		.property-select {
			margin-bottom: spacing(2);
		}

		.uploading-files-text {
			margin: 0 auto;
			text-align: center;
		}

		> .button-v2 {
			margin: spacing(4) auto spacing(2);
		}

		.property-select-appeal-tax-year-dropdown {
			display: flex;
			justify-content: flex-end;
		}

		.upload-evidence-card-submitting-button {
			position: relative;
			background-color: $denim;
			z-index: 1001;
		}

		#upload-evidence-card-submitting-overlay {
			@extend #property-documents-upload-submitting-overlay;
			height: 100% !important;
		}

		.upload-evidence-card-submitting-animation {
			height: 200px;
			width: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	#upload-evidence-card-success {
		@extend #property-documents-upload-card;
		overflow: hidden;
		position: relative;
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3/-3;
		}

		margin-top: spacing(4);
		padding: spacing(2);
		border-radius: 8px;

		#upload-evidence-card-success-animation-container {
			display: flex;
			justify-content: center;

			.upload-evidence-card-success-animation {
				height: 144px;
				width: auto;
				margin: 0 auto;
			}
		}

		.upload-evidence-card-success-file {
			padding: spacing(1);
			background-color: $white;
			border-radius: 8px;
			box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
			margin-top: spacing(1);
		}

		#upload-evidence-card-success-cta-container {
			margin: spacing(4) 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 16px;
		}
	}

	#view-documents-heading {
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3/-3;
		}
		margin-top: spacing(8);
	}

	.view-documents-loading-animation {
		margin: 0 auto;
		width: 40px;
		height: 40px;
		margin-top: 8px;
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: 3/-3;
		}
	}

	.view-property-evidence-card {
		grid-column: 1 / -1;
		margin-top: spacing(2);
		padding: spacing(4) spacing(2);
		background-color: $denim-lightest;
		border-radius: 8px;
		border: 1px solid $slate;

		@include if-size("desktop") {
			grid-column: 3/-3;
		}

		.view-property-evidence-address {
			display: flex;
			align-items: center;
			cursor: pointer;

			.view-property-evidence-expand {
				fill: red;
				height: 20px;
				width: 20px;
				margin-right: 2ch;
				margin-top: 4px;

				svg {
					transform: rotate(45deg);
					transition: transform 200ms;
				}

				svg > path {
					fill: $azure;
					transition: fill 200ms;
				}

				&-expanded {
					@extend .view-property-evidence-expand;
					svg {
						transform: rotate(0);
					}
					svg > path {
						fill: $denim;
					}
				}
			}
		}
	}
}

.view-property-evidence-expanded {
	padding-top: spacing(2);

	.label {
		padding-bottom: 4px;
		padding-top: 20px;
	}
}

.upload-evidence-card-upload-prompt {
	border-radius: 8px;
	background: $denim_lightest;
	padding: 16px;
	margin-top: 16px;
	margin-bottom: 32px;

	ul {
		padding-left: 24px;
		padding-top: 6px;
	}
}
