#staging-env-login-container {
	height: 100%;
	width: 100%;
	background: $denim;
	display: grid;
	place-items: center;
}

#staging-env-login-card {
	width: fit-content;
	height: fit-content;
	padding: spacing(4);
	background: $white;
	border-radius: 16px;
}

#staging-env-login-form {
	margin-top: spacing(4);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#staging-env-toolkit {
	position: fixed;
	z-index: 1000000000;
	transform: translate(0px, 0px);
	bottom: 24px;
	right: 24px;
}

#staging-env-toolkit-inner {
	cursor: pointer;

	#space-cowboy {
		height: 150px;
	}

	&-open {
		@extend #staging-env-toolkit-inner;
	}
}

#staging-env-toolkit-options-container {
	position: absolute;
	top: 0;
	left: 0;
	background: $white;
	padding: 16px;
	transform: translateX(-100%) translateY(-60%);
	width: max-content;
	cursor: auto;
	border: 1px solid $denim;
	border-radius: 8px;
}

.staging-env-toolkit-option {
	cursor: pointer;
	padding: spacing(1);
	border-bottom: 1px solid $denim;

	&:hover {
		p {
			color: $white;
		}
		background: $denim;
	}
}

#staging-env-toolkit-back {
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-bottom: spacing(1);

	p.sm {
		margin-left: 5px;
	}
}
