textarea {
	font-size: 16px;
	border-radius: 8px;
	border: 1px solid $input-border-default;
	padding: spacing(1);
	font-family: "sofia-pro";
	resize: vertical;
	color: $denim;

	@include if-size("tablet") {
		font-size: 20px;
		padding: spacing(1) spacing(2);
	}

	&:active,
	&:focus,
	&:hover {
		outline: none;
		border-color: $denim;
	}

	&:disabled {
		cursor: not-allowed;
		background: $slate;
		border: 1px solid $denim_5;

		color: $denim_5;
	}
}

textarea.with-label {
	margin-top: 8px;
}

.textarea-label {
	margin-left: spacing(1);
}
