// Large variant -- TODO: NAME CLASSES/IDS BETTER
#appeal-savings-box,
#exemptions-savings-box {
	grid-column: 1 / -1;
	background-color: rgba($powder, 0.25);
	display: flex;
	position: relative;
	align-items: center;
	border-radius: 16px;
	margin-top: spacing(3);
	overflow: hidden;
	//box-shadow: $primary-box-shadow;

	@include if-size("tablet") {
		margin-top: 32px;
	}

	@include if-size("desktop") {
		grid-column: 4 / -4;
	}

	&.appeal-savings-box-vertical {
		display: flex;
		flex-direction: column;
		overflow: visible;

		#appeal-savings-box-left {
			width: 100%;
			height: 108px;
			display: block;
			@include if-size("tablet") {
				top: 0;
				left: 0;
				width: 35%;
				height: auto;
				align-self: stretch;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
			}
		}

		@include if-size("tablet") {
			flex-direction: row;
		}
	}

	#appeal-savings-box-right {
		padding: spacing(2);
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin: auto;

		@include if-size("tablet") {
			text-align: left;
			flex: 1;
			padding: spacing(4) spacing(2);
		}

		@include if-size("desktop") {
			flex: 1;
			padding: spacing(4);
		}
	}

	#savings-next-btn {
		margin-top: spacing(1);
		@include if-size("tablet") {
			margin-top: spacing(2);
		}
	}

	#appeal-savings-address-container {
		padding: 0 0 8px;
		min-height: 18px;
		width: 100%;

		#appeal-savings-address {
			font-weight: 600;
		}
	}

	#savings-amt-container {
		height: 48px;
		margin-top: 14px;
		margin-bottom: 10px;
	}

	#estimated-savings-amt {
		font-size: 55px;
		line-height: 64px;
		text-align: center;
		color: $azure;
		margin-left: spacing(1);

		@include if-size("tablet") {
			text-align: left;
		}

		&.estimated-savings-amt-large {
			font-size: 48px;
			line-height: 56px;
			position: relative;
			top: -4px;
			@include if-size("tablet") {
				font-size: 64px;
				line-height: 74px;
			}
		}
	}

	#savings-loading-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: center;
		border-top: 2px solid $white;
		padding-top: spacing(1);

		@include if-size("tablet") {
			justify-content: unset;
			border: none;
			flex-direction: row;
			padding-top: 0;
		}

		#estimated-savings-title {
			max-width: none;
			margin-right: 4px;

			@include if-size("tablet") {
				max-width: 110px;
			}

			&.estimated-savings-title-average-savings {
				@include if-size("tablet") {
					max-width: 190px;
				}
			}
		}
	}

	#estimated-savings-amt,
	#estimated-savings-title {
		opacity: 1;
		animation: fade-in 400ms;
	}
}

.appeal-savings-box-expand-btn {
	padding-top: 20px;
	width: 100%;

	.appeal-savings-box-expand-label-container {
		display: inline-block;
		cursor: pointer;
	}

	.appeal-savings-box-expand-label {
		font-family: $ff-secondary;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 23px;
		letter-spacing: 0.05em;
		color: #1c7ce5;
		display: inline;

		@include if-size("tablet") {
			font-size: 20px;
		}
	}

	.appeal-savings-box-expand-icon {
		display: inline;
		width: 17px;
		height: 17px;
		margin-right: 5px;
		top: 2px;
		position: relative;

		@include if-size("tablet") {
			margin-right: 12px;
		}

		&-expanded {
			transition: transform 0.3s;
			transform: rotate(45deg);
		}
	}

	.appeal-savings-box-expand-footer {
		text-align: left;
		padding-top: spacing(1);
		p {
			padding-top: 4px;
		}
	}
}

.appeal-savings-box {
	grid-column: 1 / -1;
	background-color: rgba($powder, 0.25);
	border-radius: 16px;
	height: fit-content;
	margin: spacing(2) 0 spacing(4);
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;

	@include if-size("tablet") {
		margin: spacing(2) 0;
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}

	.appeal-savings-box-estimated-savings {
		text-align: left;

		.appeal-savings-box-label {
			display: flex;

			@include if-size("desktop") {
				flex-direction: column;
			}

			&.appeal-savings-box-label-nowrap {
				text-align: center;
				flex-direction: row;
			}
		}
	}

	.appeal-savings-box-left {
		align-self: stretch;
		width: 35%;
		font-size: 18px;
		line-height: 21px;
		color: #313131;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		#appeal-sattelite-img {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	.appeal-savings-box-right {
		padding: 16px;
		font-size: 14px;
		line-height: 16px;
		flex: 1;
		display: flex;
		flex-direction: column;

		@include if-size("tablet") {
			align-items: center;
		}

		.appeal-savings-box-hr {
			width: 33%;
			height: 2px;
			background-color: $white;
			margin: spacing(0.5);
		}

		.appeal-savings-box-address {
			flex-shrink: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.appeal-savings-box-no-img {
	@extend .appeal-savings-box;

	.appeal-savings-box-left {
		display: none;
	}

	.appeal-savings-box-right {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.appeal-savings-box-no-img-lg {
	@extend .appeal-savings-box;

	.appeal-savings-box-left {
		display: none;
	}

	.appeal-savings-box-right {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		h1 {
			font-size: 55px;
			line-height: 64px;
		}
	}
}

.appeal-savings-box-no-img-horizontal {
	@extend .appeal-savings-box;
	padding: 0;

	.appeal-savings-box-estimated-savings {
		display: flex;
		align-items: center;

		.label {
			text-align: center;
			margin-right: 1ch;
		}
	}

	.appeal-savings-box-left {
		display: none;
	}

	.appeal-savings-box-right {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-around;
		padding: spacing(2) spacing(4);

		h1 {
			font-size: 55px;
			line-height: 64px;
			margin-left: spacing(1);
			min-width: 190px;
		}

		.appeal-savings-box-hr {
			width: 2px;
			height: 48px;
			background-color: $white;
			margin: spacing(0.5);
		}
	}
}

.appeal-savings-box-horizontal {
	@extend .appeal-savings-box;
	padding: 0;

	.appeal-savings-box-estimated-savings {
		display: flex;
		align-items: center;
	}

	.appeal-savings-box-left {
		width: 30%;
	}

	.appeal-savings-box-right {
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: spacing(2) spacing(4);

		.appeal-savings-box-form-flow-savings-amt {
			font-size: 32px;

			@include if-size("tablet") {
				font-size: 42px;
				min-width: 156px;
			}
		}

		.label {
			text-align: right;
		}

		h1 {
			font-size: 55px;
			line-height: 55px;
			margin-left: spacing(2);
		}

		.appeal-savings-box-hr {
			min-width: 2px;
			width: 2px;
			height: 48px;
			background-color: $white;
			margin: spacing(0.5) spacing(4);
		}
	}
}

.appeal-savings-box-hide-on-mobile {
	display: none;
	@include if-size("tablet") {
		display: block;
	}
}
#appeal-savings-box-left.appeal-savings-box-left-savings-page {
	overflow: visible;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;

	@include if-size("tablet") {
		border-bottom-left-radius: 16px;
		border-top-right-radius: 0;
	}
}

#appeal-savings-page-savings-box {
	.appeal-savings-page-savings-box-expand-table {
		table {
			background-color: $white;
			margin-top: 20px;
			border-radius: 8px;
			color: $denim;
			line-height: 24px;
			width: 100%;
			text-align: left;

			tr:nth-child(1) {
				border-bottom: 4px solid #e3edf6;
			}
		}
		td:not(.appeal-savings-page-savings-box-expand-divider-td) {
			padding: 8px;
		}

		.appeal-savings-page-savings-box-expand-table-no-border-bottom {
			border-bottom: 0;
		}

		.appeal-savings-page-savings-box-expand-divider-tr-one-tr-up,
		.appeal-savings-page-savings-box-expand-divider-tr {
			border-bottom: 0;
		}
		.appeal-savings-page-savings-box-expand-divider-tr-one-tr-down {
			border-top: 0;
		}

		.appeal-savings-page-savings-box-expand-divider-td {
			border-top: 2px solid #e3edf6;
			border-bottom: 2px solid #e3edf6;
			height: 4px;
			background-color: $denim;
		}

		td:nth-child(1):not(.appeal-savings-page-savings-box-expand-divider-td) {
			border-right: 4px solid #e3edf6;
		}

		.appeal-savings-page-savings-box-expand-divider-row {
			border-bottom: 2px solid #e3edf6;
		}

		.appeal-savings-page-savings-box-expand-last-td-left {
			border-bottom-left-radius: 8px;
			background-color: $denim;
			color: $white;
		}
		.appeal-savings-page-savings-box-expand-last-td-right {
			border-bottom-right-radius: 8px;
			background-color: $denim;
			color: $white;
		}
	}

	.appeal-savings-box-expand-how-was-this-calculated-label-tooltip {
		display: flex;
	}

	.appeal-savings-box-expand-how-was-this-calculated-td-left {
		border-bottom: 4px solid #e3edf6;

		.appeal-savings-box-expand-how-was-this-calculated-td-container {
			display: flex;
			justify-content: space-between;
		}

		p {
			font-size: 10px;
			@include if-size("tablet") {
				font-size: 16px;
			}
		}

		&.appeal-savings-box-expand-how-was-this-calculated-td-first-left {
			border-bottom: 0;
		}
	}

	.appeal-savings-box-expand-how-was-this-calculated-td-right {
		border-bottom: 4px solid #e3edf6;
	}

	.appeal-savings-box-expand-how-was-this-calculated-math-symbol {
		margin-left: 2px;
	}

	.appeal-info-question-card-tooltip-body {
		max-width: 150px;

		@include if-size("tablet") {
			max-width: none;
		}
	}

	.appeal-info-question-card-tooltip.appeal-info-question-card-tooltip-small {
		display: inline-block;
		top: 5px;
		margin-left: 4px;
		@include if-size("desktop") {
			margin-left: spacing(1);
		}
		svg {
			margin-right: 0;
			width: 12px;
			height: 12px;

			@include if-size("tablet") {
				width: 16px;
				height: 16px;
			}
		}
		.appeal-info-question-card-tooltip-text {
			margin: 0;
		}
	}
}
