#account-menu-header {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column-reverse;

	> div {
		flex: 1;
	}
	@include if-size("desktop") {
		grid-column: 2 / -2;
		flex-direction: row;
	}
}

#mobile-account-link-container {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 2 / -2;
	}

	.mobile-account-link {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding-bottom: spacing(4);
		margin-bottom: spacing(4);
		border-bottom: 2px solid $sand;

		&:last-of-type {
			border-bottom: 0;
		}

		svg {
			min-height: 24px;
			height: 24px;
			width: 24px;
		}

		svg:not(.premium-diamond) {
			path {
				fill: $denim;
			}
		}
	}

	.mobile-account-link-hidden {
		@extend .mobile-account-link;

		@include if-size("desktop") {
			display: none;
		}
	}
}

.mobile-account-link-flex-wrap {
	flex-wrap: wrap;
}

.mobile-account-link-sub-navigation {
	flex-basis: 100%;
	padding: spacing(1) 0 0 spacing(4);
	z-index: 100;
	a {
		display: inline-block;
	}
}

.mobile-account-link-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	cursor: pointer;
	align-items: center;

	.mobile-account-icon-container {
		margin-right: spacing(2);
	}

	.mobile-account-icon-container {
		width: 24px;
		height: 24px;
	}
}

#mobile-account-premium-info-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-top: spacing(2);
}

.mobile-account-premium-info-row {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

#premium-pricing-modal-menu-container {
	@include if-size("tablet") {
		display: none;
	}
}
