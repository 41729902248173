#careers-page {
	#careers-page-hero-section {
		padding: spacing(6) 0 spacing(6);

		@include if-size("tablet") {
			padding: spacing(8) 0 spacing(12);
		}

		#careers-page-hero-img {
			grid-column: 1 / -1;
			margin: 0 auto spacing(2);
			height: auto;

			@include if-size("desktop") {
				width: 100%;
				grid-column: 2 / 6;
				margin: 0;
			}
		}
		#careers-page-title-container {
			grid-column: 1 / -1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include if-size("desktop") {
				grid-column: 7 / -1;
			}
		}

		#careers-page-title {
			text-align: center;
			padding-bottom: spacing(2);

			@include if-size("desktop") {
				text-align: left;
				br {
					display: none;
				}
			}
		}
	}

	.careers-page-cta {
		margin: auto;
		@include if-size("desktop") {
			margin: 0;
		}
	}

	.careers-page-why-we-exist {
		background-color: $sky-light;
		padding: spacing(6) 0 spacing(8);

		@include if-size("tablet") {
			padding: spacing(12) 0;
		}
	}

	.careers-page-by-the-numbers-container {
		background-color: $sky-light;
		padding: 64px 0;
		@include if-size("desktop") {
			padding: 96px 0;
		}
	}

	.careers-page-by-the-numbers {
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: 2/-2;
		}
		h2 {
			text-align: center;
		}
		.careers-page-by-the-numbers-body {
			gap: 16px;
			width: 100%;
			flex-wrap: wrap;
			padding-top: spacing(2);
			@include if-size("tablet") {
				display: flex;
			}
		}
		.careers-page-by-the-numbers-card {
			background-color: $white;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 16px;
			border-radius: 16px;
			margin-bottom: 16px;

			@include if-size("tablet") {
				margin-bottom: 0;
				width: calc(50% - 16px);
			}

			svg {
				width: 48px;
				height: 48px;
				margin-right: spacing(2);
			}
		}
	}

	.careers-page-plain-body {
		grid-column: 1/-1;

		@include if-size("desktop") {
			grid-column: 3/-3;
		}

		h2 {
			padding-bottom: spacing(1);
		}
		p.sm:not(:last-of-type) {
			padding-bottom: spacing(1);
		}
		p {
			line-height: 20px;

			@include if-size("tablet") {
				line-height: 26px;
			}
		}
	}

	.careers-page-life-at-ownwell {
		padding: 16px 0 64px;
		@include if-size("desktop") {
			padding: 16px 0 96px;
		}

		.careers-page-plain-body p:last-of-type {
			padding-bottom: spacing(2);
		}
	}

	.careers-page-life-at-ownwell-title {
		padding-top: 64px;
	}

	.careers-page-life-at-ownwell-photos {
		grid-column: 1/-1;
		padding-top: spacing(8);
		display: flex;
		gap: spacing(2);
		flex-wrap: wrap;

		@include if-size("desktop") {
			flex-wrap: nowrap;
		}

		@include if-size("desktop") {
			padding-top: spacing(12);
		}

		.careers-page-life-at-ownwell-photos-img {
			@extend .careers-page-photos-styling;
			width: calc(50% - 16px);
		}

		.careers-page-life-at-ownwell-photos-img:last-child {
			@include if-size("desktop") {
				display: none;
			}
		}

		.grid {
			gap: 16px;
		}
	}

	.careers-page-life-at-ownwell-footnote {
		grid-column: 1/-1;
		text-align: right;
	}

	.careers-page-life-at-ownwell-footnote-inner {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

		p {
			width: 325px;
		}

		svg {
			margin-bottom: 12px;
			flex: 0 0 auto;

			@include if-size("tablet") {
				margin-right: 16px;
			}
		}
	}

	.careers-page-content-centered {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.careers-page-testimonials {
		background-color: $sky-light;
		padding: spacing(8) 0 spacing(8);
		@include if-size("desktop") {
			padding: spacing(12) 0;
		}
	}

	.careers-page-investors {
		background-color: $sky-light;
		position: relative;
		padding-bottom: 64px;
		#about-investors-section {
			background: $sky-light;
			margin-bottom: 0;
			padding-bottom: 16px;
			#about-investors-img-container {
				flex-direction: column-reverse;
				@include if-size("tablet") {
					flex-direction: row;
				}
			}
		}

		.about-investor-img-wrapper {
			opacity: 0.5;
		}

		.careers-page-investors-footnote {
			grid-column: 1/-1;
			bottom: 40px;
			text-align: center;
			width: 100%;

			@include if-size("tablet") {
				padding-left: 60px;
				width: auto;
				bottom: 0;
				text-align: left;

				p {
					max-width: 240px;
				}
			}
		}
	}
}

#careers-page-awards {
	display: flex;
	justify-content: center;
	padding-top: spacing(8);

	@include if-size("tablet") {
		padding-top: spacing(12);
	}

	#careers-page-awards-built-in img {
		width: 128px;
		height: auto;
		margin: 16px 0 12px;
		@include if-size("tablet") {
			margin: 0 16px 0 0;
			width: 176px;
		}
	}

	#careers-page-awards-gptw img {
		width: 93px;
		height: auto;
		margin: 34px 0 16px;

		@include if-size("tablet") {
			margin: 39px 45px 0 29px;
			width: 118px;
		}
	}

	.careers-page-awards-text-container {
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: center;
		@include if-size("tablet") {
			justify-content: flex-start;
		}
		&:not(:last-of-type) {
			padding-bottom: 16px;

			@include if-size("tablet") {
				padding-bottom: 24px;
			}
		}
	}

	.careers-page-awards-text-section {
		vertical-align: middle;
	}

	table {
		td {
			display: block;
			text-align: center;

			@include if-size("tablet") {
				display: table-cell;
			}
		}
	}
}

.careers-page-split-panels-section {
	position: relative;
	background-color: $white;
}

.careers-page-split-panels-upper-half {
	background-color: $cloud;
	position: absolute;
	width: 100%;
	height: 25%;
	z-index: 1;

	@include if-size("desktop") {
		height: 50%;
	}
}

.careers-page-split-panels {
	width: 100%;
	display: flex;
	justify-content: space-between;
	grid-column: 1/-1;
	z-index: 10;
	gap: 16px;
	flex-wrap: wrap;
	@include if-size("desktop") {
		flex-wrap: nowrap;
	}

	.careers-page-split-panels-panel {
		@extend .careers-page-photos-styling;
		width: calc(50% - 16px);
		display: inline-block;

		@include if-size("desktop") {
			width: 100%;
			height: 100%;
		}

		&.careers-page-split-panels-panel-hide-on-desktop {
			@include if-size("desktop") {
				display: none;
			}
		}

		img {
			height: 100%;
		}
	}
	.careers-page-split-panels-panel-blue {
		padding: 16px 32px 24px;
		background-color: $royal;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		border-radius: spacing(2);
		width: 100%;

		&:first-of-type {
			svg {
				width: 79px;
				height: 60px;
			}
		}
		&:nth-of-type(2) {
			svg {
				width: 94px;
				height: 61px;
			}
		}

		svg {
			width: 64px;
			height: 64px;
		}

		p {
			color: $white;
		}
	}
}

.careers-page-ownwell-solution-section {
	padding: spacing(8) 0;

	@include if-size("desktop") {
		padding: spacing(9) 0;
	}

	h2 {
		padding-top: 32px;
	}

	p {
		padding-bottom: spacing(4);
	}

	.careers-page-ownwell-solution-split-paragraph {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 32px;
		@include if-size("desktop") {
			flex-direction: row;
			gap: 112px;
			img {
				max-height: 223px;
			}

			p {
				padding-bottom: 0;
			}
		}
	}
}

.careers-page-ownwell-solution {
	grid-column: 1/-1;
	@include if-size("desktop") {
		grid-column: 2/-2;
	}
}

.careers-page-ownwell-vision-section {
	background-color: $sky-light;
	padding: 64px 0;
	@include if-size("desktop") {
		padding: 96px 0;
	}

	.careers-page-cta {
		display: block;
	}
}

.careers-page-ownwell-vision-content {
	grid-column: -1/1;

	@include if-size("desktop") {
		grid-column: 1/8;
		order: 1;
		margin: auto;
	}

	button {
		margin-top: 32px;
	}

	h2 {
		padding-top: 8px;
	}
}

.careers-page-ownwell-vision-photo {
	grid-column: -1/1;
	max-height: 200px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: 32px;

	@include if-size("tablet") {
		max-height: 400px;
	}
	@include if-size("desktop") {
		grid-column: 9/-1;
		order: 2;
		margin-bottom: none;
	}

	img {
		display: inline;
		margin-top: -40px;
		width: 100%;
		@include if-size("tablet") {
			margin-top: -20px;
		}
	}
}

.careers-page-ownwell-benefits {
	background-color: $sky-light;
	padding: spacing(8) 0;

	@include if-size("tablet") {
		padding: spacing(12) 0;
	}

	.careers-page-ownwell-benefits-list {
		grid-column: 1/-1;
		display: flex;
		flex-wrap: wrap;
		grid-gap: 16px;
		gap: 32px;
		justify-content: center;
		padding: 48px 0 48px;

		@include if-size("desktop") {
			grid-column: 1/-1;
		}
	}

	.careers-page-ownwell-benefits-item {
		width: 45%;
		text-align: center;
		svg {
			height: 64px;
			width: 64px;
		}

		@include if-size("tablet") {
			width: 25%;
		}
	}
}

.careers-page-our-locations {
	padding: spacing(8) 0;

	@include if-size("desktop") {
		padding: spacing(12) 0;
	}

	.careers-page-our-locations-cities-text {
		padding-top: 8px;
		max-width: 70%;
		margin: 0 auto;
	}

	.careers-page-our-locations-map {
		padding: spacing(2) 0 spacing(4);

		@include if-size("desktop") {
			padding-bottom: spacing(6);
		}
	}
}

#careers-page-review-carousel {
	grid-column: 1/-1;
	padding-top: spacing(2);
}

.careers-page-photos-styling {
	border-radius: 8px;
	overflow: hidden;
}

.careers-page-our-values {
	grid-column: 1/-1;

	@include if-size("desktop") {
		grid-column: 2/-2;
	}

	.careers-page-our-values-cards {
		padding-top: spacing(2);
		text-align: left;
		gap: 16px;

		@include if-size("desktop") {
			display: flex;
		}

		.careers-page-our-values-column {
			width: 100%;
			@include if-size("desktop") {
				width: 50%;
			}
		}

		.pricing-faq-card-col
			> .pricing-faq-card
			> .pricing-faq-card-title-section
			div
			> p {
			font-weight: 600;
		}
	}
}

.careers-page-executive-team {
	grid-column: -1/1;
	padding: 64px 0;
	@include if-size("desktop") {
		padding: 96px 0;
	}

	.careers-page-executive-team-people {
		display: flex;
		justify-content: space-between;
		gap: 16px;
		flex: 1 1 0;
		padding: 32px 0 16px;
		flex-direction: column;
		align-items: center;

		@include if-size("tablet") {
			flex-direction: row;
		}
	}

	.careers-page-executive-team-people-individual-container {
		cursor: pointer;
		flex: 1 1 0;
		border-radius: 16px;
		height: 100%;
		width: 100%;
		box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
		position: relative;
		overflow: hidden;
		max-width: 350px;

		&:hover {
			.careers-page-executive-team-people-individual-img img {
				opacity: 1;
				transition: opacity 0.2s ease-in-out;
			}
		}

		.careers-page-executive-team-people-individual-img {
			height: 176px;
			width: 100%;
			background-color: $denim;
			position: relative;
			@include if-size("tablet") {
				height: 100%;
			}
			img {
				display: block;
				opacity: 0.5;
				max-width: 100%;
				width: 100%;
				height: auto;
				position: absolute;
				top: -35%;
				@include if-size("tablet") {
					position: relative;
					top: auto;
				}
			}
		}

		.careers-page-executive-team-people-individual-content {
			position: absolute;
			bottom: 0;
			padding: 12px;

			@include if-size("tablet") {
				display: none;
			}

			@include if-size("desktop") {
				display: block;
			}
		}

		p,
		.label {
			cursor: pointer;
			color: $cloud;
		}
	}

	.careers-page-executive-team-bio-container {
		background-color: $sky-light;
		padding: 32px;
		border-radius: 16px;
		box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
		display: flex;
		flex-direction: column;
		max-width: 350px;
		gap: 24px;

		@include if-size("tablet") {
			gap: 0;
			flex-direction: row;
			max-width: none;
		}

		&-hide-on-desktop {
			@include if-size("tablet") {
				display: none;
			}
		}

		&-hide-on-mobile {
			display: none;
			@include if-size("tablet") {
				display: flex;
			}
		}
	}

	.careeers-page-executive-team-bio-left {
		min-width: 33%;
	}
}

.careers-page-values-slider-container {
	padding: 64px 0;
	@include if-size("desktop") {
		padding: 96px 0;
	}
}

.careers-page-values-slider-top {
	grid-column: -1/1;
}

.careers-page-origin-content-wrapper {
	overflow: hidden;
	max-height: 126px;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5; /* number of lines to show */
	-webkit-box-orient: vertical;
	margin-bottom: 8px;

	&-hide {
		max-height: 126px;
		display: -webkit-box;
	}

	&-show {
		max-height: 100%;
		display: block;
	}
}

.careers-page-origin-read-more {
	p {
		font-size: 12px;
		@include if-size("desktop") {
			font-size: 14px;
		}
	}

	svg {
		width: 12px;
		height: 12px;
		margin-top: 2px;
	}
}

.careers-page-values-slider-component-selection {
	display: flex;
	gap: 8px;
	padding: 32px 0 16px;
	justify-content: center;
	flex-wrap: wrap;
	@include if-size("desktop") {
		flex-wrap: nowrap;
	}
}

.careers-page-values-slider-component-selection-button {
	border-radius: 4px;
	padding: 4px 12px;
	border: 1px solid $slate;
	cursor: pointer;

	&:hover {
		background-color: $azure;
		p {
			color: $white;
		}
	}

	&:active {
		background-color: $denim;
	}

	&-selected {
		background-color: $azure;
		p {
			color: $white;
		}
	}
}

.careers-page-values-slider-card {
	grid-column: 1/-1;
	background-color: $sky-light;
	border-radius: 16px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	display: flex;
	overflow: hidden;
	flex-direction: column;
	@include if-size("desktop") {
		grid-column: 2/-2;
		flex-direction: row;
		height: 300px;
	}

	.careers-page-values-slider-card-image {
		width: 100%;
		max-height: 30vh;
		overflow: hidden;
		@include if-size("desktop") {
			width: 45%;
			max-height: none;
		}

		img {
			height: auto;
			width: 100%;
			display: inline;
			margin-top: -15%;

			@include if-size("desktop") {
				margin-left: -30px;
				margin-top: 0;
				height: 100%;
				width: auto;
			}
		}
	}

	.careers-page-values-slider-card-content {
		margin: auto;
		padding: 32px;
		@include if-size("desktop") {
			width: 55%;
		}
	}
}

.careers-page-benefits {
	padding: 64px 0;
	@include if-size("tablet") {
		padding: 96px 0;
	}
}
