#appeal-info-step {
	#appeal-info-title-section,
	#appeal-info-submit-section,
	#appeal-info-question-card-container {
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}
	}

	.appeal-info-hear-about-card {
		border-radius: 8px;
		background-color: $cloud;
		padding: spacing(2);
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		row-gap: spacing(2);
		align-items: center;

		@include if-size("tablet") {
			grid-template-columns: 2fr 1fr;
		}

		.appeal-info-hear-about-select {
			background-color: #fff;
			width: 100%;
		}
	}

	#appeal-info-question-card-container {
		margin-top: spacing(4);
		.property-profile-question-card {
			margin-bottom: 16px;
		}
	}

	#appeal-info-title-section {
		#appeal-info-title-error {
			display: flex;
			align-items: center;
			border: 1px solid $terracotta;
			border-radius: 8px;
			padding: spacing(1);
			margin-top: spacing(2);

			svg {
				height: 16px;
				width: 16px;
				margin-top: 6px;
				fill: $terracotta;
			}

			p {
				margin-left: spacing(1);
				color: $terracotta;
			}
		}

		h4 {
			margin-bottom: spacing(2);
		}
	}

	#appeal-info-submit-section {
		display: flex;
		flex-direction: column;
		text-align: left;

		button {
			width: fit-content;
			margin: spacing(5) auto 0;
		}

		#appeal-info-submit-skip {
			color: $azure;
			margin: spacing(4) auto;
			cursor: pointer;
		}
	}

	.appeal-info-question-card-description {
		margin-top: spacing(1);
		opacity: 0.75;
	}

	.appeal-info-question-card {
		border-radius: 8px;
		background-color: $cloud;
		display: grid;
		grid-template-columns: 3fr 1fr;
		padding: spacing(2);
		margin-bottom: spacing(2);

		.appeal-info-question-card-question {
			grid-column: 1;
		}

		.appeal-info-question-card-radio-container {
			grid-column: 2;
			display: flex;
			padding: 0 spacing(1);
			flex-direction: column;
			justify-content: center;

			.radio:first-of-type {
				margin-bottom: spacing(2);
			}

			@include if-size("tablet") {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				.radio:first-of-type {
					margin-bottom: 0;
					margin-right: spacing(1);
				}
			}
		}

		.appeal-info-question-card-expanded {
			grid-column: 1/-1;
			border-top: 3px solid #fff;
			width: 100%;
			margin-top: spacing(2);
			padding: spacing(2) 0;

			&-row:not(:first-of-type) {
				margin: spacing(4) 0 0;
			}
		}
	}

	#appeal-info-animation-container {
		position: absolute;
		bottom: 0;
		left: 0;
		height: calc(100vh - 90px);
		width: 100%;
		background-color: $white;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: spacing(8);
		z-index: 10000;

		h4 {
			margin-top: spacing(4);
		}

		#appeal-info-animation {
			max-width: 500px;
			height: fit-content;
		}
	}
}

#appeal-info-submit-error {
	display: flex;
	align-items: center;
	margin-top: spacing(1);

	svg {
		height: 12px;
		width: 12px;
		fill: $terracotta;
		margin-top: 4px;
	}

	p {
		color: $terracotta;
		margin-left: spacing(1);
	}
}

.appeal-info-question-card-tooltip {
	position: relative;
	grid-column: 1 / -1;

	.appeal-info-question-card-tooltip-body {
		position: absolute;
		top: calc(100% + 12px);
		left: -16px;
		z-index: 1000;
		width: 275px;
		height: auto;
		background-color: $azure;
		border-radius: 8px;
		padding: spacing(2);
		box-shadow: 2px 2px 7px 2px rgba(5, 50, 73, 0.4);
		pointer-events: none;

		label {
			margin-bottom: spacing(1);
		}

		label,
		p {
			color: #fff;
		}

		.triangle-top {
			position: absolute;
			left: 18px;
			top: -16px;
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 8px 16px 8px;
			border-color: transparent transparent $azure transparent;
		}
	}

	.appeal-info-question-card-tooltip-text {
		display: flex;
		align-items: center;
		margin-top: spacing(1);
		cursor: help;
		width: fit-content;

		p {
			color: $azure;
			width: fit-content;
			margin-bottom: 2px;
		}

		svg {
			width: 20px;
			margin-right: 1ch;
		}
	}

	&-small {
		@extend .appeal-info-question-card-tooltip;

		.appeal-info-question-card-tooltip-text {
			svg {
				min-width: 16px;
				max-width: 16px;
			}
		}
	}
}

.notice-of-value-banner {
	background-color: $terracotta;
	padding: spacing(1) spacing(1.5);
	margin-top: spacing(1);
	border-radius: 8px;

	p {
		color: $white;
	}
}

.wildcard-question-header-card {
	background-color: $white;
	border-radius: 8px;
	padding: spacing(1);
	margin-bottom: spacing(3);
}
