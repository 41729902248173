#all-active-property-page {
	position: relative;
	padding-top: 10px;
	.table-footer {
		position: sticky;
		bottom: 0;
		z-index: 20;
		display: flex;
		align-items: center;
		justify-content: center;
		@include if-size("desktop") {
			display: flex;
			justify-content: space-between;
		}
		padding: 20px;
		background-color: $denim-lightest;
		margin: 0;
		.results-per-page-wrapper {
			display: none;
			@include if-size("desktop") {
				display: block;
			}
			.results-per-page {
				display: flex;
				gap: 10px;
				justify-content: center;
				align-items: center;
				select {
					width: 75px;
				}
			}
		}
		.table-pagination-wrapper {
			.table-pagination {
				@include if-size("desktop") {
					display: flex;
					flex-direction: row-reverse;
				}
				.table-pagination-button-disabled:hover {
					background-color: $denim_5;
				}
			}
		}
	}
	.view-edit-property {
		.property-settings-property-card-popover {
			background-color: #fbfcfd;
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			top: 0;
			right: calc(100% + 20px);
			.tooltip2-body,
			.triangle-top {
				background-color: $slate;
			}
			.tooltip2-body-text {
				color: $denim;
			}
			.tooltip2-direction-right .tooltip2-transparent-cursor-bridge {
				left: -30px;
			}
			.tooltip2-transparent-cursor-bridge {
				padding-top: 8px;
			}
			.property-settings-property-card-popover-option,
			.property-settings-property-card-popover-option-disabled {
				margin-bottom: 4px;
				padding-bottom: 4px;
			}
		}
		.property-settings-property-card-popover-option p {
			color: $denim;
		}
		.property-settings-property-card-popover-option-disabled p {
			color: gray;
		}
		.property-settings-property-card-popover-option:hover {
			background-color: $slate;
			border: 1px solid $slate;
			border-radius: 4px;
		}
		p {
			text-overflow: unset;
			white-space: unset;
			overflow: unset;
			max-width: unset;
		}
		.property-settings-property-card-popover-close {
			display: none;
		}
	}
}

#all-active-property-mobile-filters-bar {
	z-index: 100;
	position: fixed;
	top: 44px;
	left: 0;
	width: 100%;
	background-color: $white;
	box-shadow: 1px 3px 7px 2px #0532490d;

	@include if-size("desktop") {
		display: none;
	}

	#view-hide-mobile-filters {
		flex: 1;
		display: flex;
		align-items: center;
		gap: spacing(1);

		&:hover {
			color: $azure;
			cursor: pointer;

			path {
				fill: $azure;
			}
		}

		p {
			color: $azure;
		}
	}

	#all-active-property-mobile-tax-years-filter {
		z-index: 100;
	}

	#all-active-property-mobile-states-filter {
		z-index: 100;
		flex: 1;
	}

	.all-active-property-mobile-filter {
		flex: 1;
	}

	#all-active-property-mobile-task-statuses-filter {
		z-index: 99;
		span:not(.no-selections) {
			text-transform: uppercase;
		}
	}
	#all-active-property-mobile-task-types-filter {
		z-index: 98;
	}
}

#all-active-property-mobile-top-section {
	padding: 10px 5px;
	margin-top: 30px;
	@include if-size("desktop") {
		display: none;
	}
}

#all-active-property-search-filter {
	margin-left: spacing(2);
	margin-right: spacing(2);

	@include if-size("desktop") {
		margin-right: 0;
		margin-left: auto;
	}
}

#all-active-property-top-section {
	display: none;
	@include if-size("desktop") {
		display: flex;
	}
	@include if-size("mobile") {
		padding: 10px;
	}

	padding-bottom: spacing(2);
	margin-top: 0;
	border-bottom: solid 1px $denim-extra-light;
	justify-content: space-between;
	#all-properties-count-pill {
		margin-left: spacing(2);
		color: $royal;
		background: $sky-light;
		padding: 6px 8px;
		border-radius: 8px;
	}
	.flex-gap-20 {
		gap: 20px;
	}
}

.arrow-down-icon {
	width: 20px;
	height: 20px;
}

.download-icon {
	width: 12px;
	height: 12px;

	@include if-size("desktop") {
		width: 16px;
		height: 16px;
	}
}

#all-active-property-filters-bar {
	width: 100%;
	display: none;
	@include if-size("desktop") {
		display: flex;
	}

	margin-top: spacing(3);

	flex-direction: row;
	align-items: center;
	row-gap: spacing(2);
	column-gap: spacing(2);
	height: min-content;

	#all-active-property-tax-years-filter {
		width: 100px;
	}

	#all-active-property-states-filter {
		//width: 120px;
		z-index: 100;
	}

	#all-active-property-task-statuses-filter {
		width: 140px;

		span:not(.no-selections) {
			text-transform: uppercase;
		}
	}

	#all-active-property-task-types-filter {
		width: 220px;

		@include if-size("desktop-xl") {
			width: 320px;
		}
	}

	#all-active-property-search-filter {
		width: 220px;

		@include if-size("desktop-xl") {
			width: 320px;
		}
	}
}

#dashboard-table {
	@include if-size("desktop") {
		padding-left: spacing(2);
		padding-right: spacing(2);
	}
}

#dashboard-table-view-container {
	max-width: 100%;
}

#dashboard-table-view {
	overflow-x: auto;
	@include styled-scrollbar;
	overflow: auto;
	.task-status-tags {
		display: flex;
		flex-direction: row;
		gap: 12px;

		.task-status-tag {
			padding: 2px 12px;
			text-transform: uppercase;
			border-radius: 4px;
			white-space: nowrap;

			&-rust {
				@extend .task-status-tag;
				background-color: $rust;
				color: $white;
			}

			&-rust-lightest {
				@extend .task-status-tag;
				background-color: $rust-lightest;
				color: $rust;
			}

			&-sky-light {
				@extend .task-status-tag;
				background-color: $sky-light;
				color: $royal;
			}

			&-mint-light {
				@extend .task-status-tag;
				background-color: $mint-light;
				color: $kelly-dark;
			}
		}
	}

	table {
		border-collapse: collapse;
		overflow: auto;
		th {
			background-color: $denim-lightest;

			label {
				white-space: nowrap;
				text-align: left;
				display: flex;
				justify-content: center;
				gap: 4px;
			}

			> div {
				padding: 12px 8px;
			}

			&:last-child label {
				text-align: center;
			}
		}

		td {
			height: 48px;
			@include if-size("tablet") {
				height: 56px;
			}
			background-color: $white;
			position: relative;

			> div {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				//overflow: hidden;
				padding: 0 8px;

				display: flex;
				align-items: center;
			}
		}

		td,
		th {
			z-index: 1;

			> div {
				border-left: solid 1px $sand;
				border-bottom: solid 1px $sand;
			}

			div p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
			}

			&:first-child > div {
				border-left: none;
			}

			&:last-child > div {
				border-right: none;
			}
			.header-cell {
				display: flex;
				justify-content: start;
				align-items: center;
				gap: 10px;
				cursor: pointer;
				.asc-arrow {
					transform: rotate(180deg);
				}
				.arrow-wrapper {
					&.hover {
						background: $sky;
						padding: 2px;
						margin-left: 4px;
						border-radius: 1000px;
						height: 16px;
						width: 16px;
						display: flex;
						align-items: center;
						justify-content: center;

						svg {
							margin-left: 0px !important;
						}
					}
				}
			}
		}

		td:last-child,
		th:last-child {
			position: sticky;
			right: 0;
			z-index: 2;
			@include if-size("desktop") {
				width: 120px;
			}
			> div {
				//padding: 0;
				box-shadow: 0px 4px 16px 0px #7f939d2e;
				.view-edit-property {
					position: relative;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				display: flex;
				justify-content: center;
			}
		}

		tr:hover {
			td {
				cursor: pointer;
				background-color: #fbfcfd;
			}
			td:last-child {
				background-color: $denim_lightest;
			}
		}

		td:last-child {
			background-color: white;
		}

		td:last-child > div {
			display: flex;
			padding: 0;
			.view-edit-property {
				flex: 1;
				&:before {
					display: none;
					content: "";
					background-color: #fbfcfd;
					width: 20px;
					height: 100%;
					position: absolute;
					right: 100%;
					z-index: 20;
				}
				&.show-before::before {
					display: block;
				}
				&.show-before {
					background-color: #fbfcfd;
				}
			}
		}
	}

	.quick-link-link {
		&.semibold {
			font-weight: 700;
		}

		&.rust-dark {
			color: $rust-dark;

			svg path {
				fill: $rust-dark;
			}
		}

		&.azure {
			color: $azure;

			svg path {
				fill: $azure;
			}
		}

		&.kelly-dark {
			color: $kelly-dark;

			svg path {
				fill: $kelly-dark;
			}
		}
	}

	.quick-link-small {
		.quick-link-arrow {
			height: 6.85px !important;
			width: 8px !important;
			margin-left: 3px;
			margin-top: 4px;
		}
	}
}
