.label,
label {
	display: block;
	color: $denim;
	font-family: $ff-secondary;
	line-height: 11px;
	text-transform: uppercase;
	white-space: normal;
	letter-spacing: 1px;
	font-weight: 700;
	@include _create_typography("12px", normal);
	@include if-size("tablet") {
		@include _create_typography("14px", normal);
	}

	&.xl,
	&.extra-large {
		@include _create_typography("16px", normal);
		letter-spacing: 2px;
		@include if-size("tablet") {
			@include _create_typography("18px", normal);
			letter-spacing: 3px;
		}
	}

	&.lg,
	&.large {
		@include _create_typography("14px", normal);
		@include if-size("tablet") {
			@include _create_typography("16px", normal);
			letter-spacing: 3px;
		}
	}

	&.sm,
	&.small {
		font-weight: 622;
		@include _create_typography("10px", normal);
		@include if-size("tablet") {
			@include _create_typography("12px", normal);
		}
	}

	&.tiny {
		font-weight: 622;
		@include _create_typography("8px", normal);
		@include if-size("tablet") {
			@include _create_typography("10px", normal);
		}
	}

	&.required {
		&::after {
			content: "\00a0*";
		}
	}
}
