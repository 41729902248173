#investors-hero-contact-us-cta {
	margin-top: spacing(2);
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: spacing(4);
	gap: spacing(2);

	@include if-size("desktop") {
		align-items: flex-start;
	}
}

#investors-data-card-section {
	position: relative;
}

#investor-data-card-underlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 55px;
	background-color: $sky-light;
	z-index: 1;
}

#investor-data-cards {
	grid-column: 1/-1;
	display: flex;
	justify-content: center;
	gap: 8px;
	flex-wrap: wrap;
	width: 100%;
	margin: 0 auto;
}

.investor-data-card {
	background-color: $white;
	border: 1px solid $cloud;
	border-radius: 16px;
	padding: spacing(2);
	z-index: 100;
	flex: 1;
	min-width: calc(50% - 16px);
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

	@include if-size("desktop") {
		min-width: calc(25% - 16px);
	}
}

#investors-value-prop-1 {
	padding: spacing(8) 0;
	grid-column: 1 / -1;

	@include if-size("desktop") {
		padding: spacing(12) 0;
		grid-column: 2 / -2;
	}
}

#commercial-value-prop-1 {
	padding: 0 0 spacing(8);
	grid-column: 1 / -1;

	@include if-size("desktop") {
		padding: 0;
		grid-column: 2 / -2;
	}
}

#investors-value-prop-1-cards {
	gap: 16px;
	grid-column: 1 / -1;
	margin: spacing(5) auto 0;
	display: flex;
	justify-content: center;
	flex-direction: column;

	@include if-size("desktop") {
		flex-direction: row;
	}
}

.investors-value-prop-1-card {
	padding: spacing(2);
	border-radius: 8px;
	border: 1px solid $cloud;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	flex: 1;
}

.investors-value-prop-1-card-icon {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: spacing(3);
}

#investors-value-prop-2 {
	background-color: $sky-light;
	margin-bottom: spacing(8);
	@include if-size("desktop") {
		margin-bottom: spacing(12);
	}
}

#commercial-value-prop-2 {
	background-color: $denim-lightest;
	margin-bottom: spacing(8);
	@include if-size("desktop") {
		margin-bottom: spacing(12);
	}

	.landing-featured-section-commercial {
		background-color: $denim-lightest;
	}
}

#investors-value-prop-2-content {
	padding: spacing(8) 0 0;
	display: flex;
	flex-direction: column;
	gap: 64px;
	margin: 0 auto;
	grid-column: 1 / -1;

	@include if-size("desktop") {
		gap: 112px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: spacing(12) 0 0;
	}
}

#investors-value-prop-2-cta {
	button {
		margin: spacing(3) auto 0;

		@include if-size("desktop") {
			margin: spacing(3) 0 0;
		}
	}

	@include if-size("desktop") {
		width: 368px;
	}
}

#investors-value-prop-2-cards {
	flex: 1;

	@include if-size("desktop") {
		max-width: 652px;
	}
}

.investors-value-prop-2-card {
	background-color: $white;
	border-radius: 8px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	padding: spacing(2);
	margin-bottom: spacing(2);
	display: flex;
}

.investors-value-prop-2-card-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: spacing(2);
}

.commercial-denim-lightest-bg {
	background-color: $denim-lightest;
}
