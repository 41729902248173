#taxes-page {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: spacing(2);

	margin: 0 auto;
	width: 100%;
	max-width: 1400px;

	@include if-size("monitor") {
		grid-template-columns: 3fr 2fr;
		grid-gap: spacing(3);
	}

	.col-1 {
		grid-column: span 1;
	}

	.col-2 {
		grid-column: span 1;

		@include if-size("monitor") {
			grid-column: span 2;
		}
	}

	.property-card-maximize-tax-savings-card {
		grid-column: span 1;

		@include if-size("monitor") {
			grid-column: span 2;
		}
	}

	.taxes-total-saved {
		margin-bottom: spacing(2);

		@include if-size("monitor") {
			margin-bottom: spacing(3);
		}

		&.desktop-only {
			display: none;

			@include if-size("monitor") {
				display: flex;
			}
		}

		&.mobile-only {
			display: flex;

			@include if-size("monitor") {
				display: none;
			}
		}
	}

	#exemptions-card {
		padding: spacing(2);

		overflow: hidden;
		border-radius: 16px;
		position: relative;
		background-color: $white;
		border: 1px solid $slate;
		box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
			-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
			-44px 319px 90px 0px #ababab00;

		@include if-size("monitor") {
			padding: spacing(3);
		}

		#exemptions-service-content {
			margin-top: 12px;

			.desktop-card {
				height: auto;
				padding: 0;

				.card-content {
					background-color: $denim_lightest;
					border-radius: spacing(1);
					padding: spacing(2);
				}
			}
		}
	}

	#taxable-values-table {
		width: 100%;

		th:nth-child(2) {
			text-align: right;
			padding-right: 16px;
		}
		td:nth-child(2) {
			text-align: right;
			padding-left: 4px;
		}

		th:nth-child(3) {
			text-align: right;
			padding-right: 16px;
		}
		td:nth-child(3) {
			text-align: right;
			padding-left: 4px;
		}
	}

	#property-tax-bill-table {
		width: 100%;

		th:nth-child(1) {
			text-align: left;
		}

		th:nth-child(2),
		td:nth-child(2) {
			width: 100%;
			text-align: right;
			padding-left: 0px;
			padding-right: 0px;
		}

		th:nth-child(3) {
			text-align: right;
			padding-right: 16px;
		}
		td:nth-child(3) {
			text-align: right;
			padding-left: 5px;
		}
	}

	#dates-and-links {
		display: flex;
		flex-direction: column;
		gap: spacing(2);
		flex-wrap: wrap;

		@include if-size("monitor") {
			flex-direction: row;
			grid-column: span 2;
			gap: spacing(3);
		}

		.county-resources-card {
			flex: 1;
			height: fit-content;
			border-radius: 16px;
			position: relative;
			border: 1px solid $slate;
			box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
				-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
				-44px 319px 90px 0px #ababab00;

			.county-resources-table {
				width: 100%;
				margin-top: 0;
				height: fit-content;
				border: none;

				thead {
					background-color: $denim_lightest;
				}

				tbody {
					background-color: $white;

					tr,
					td {
						border-color: $denim_lightest;
					}
				}
			}
		}
	}

	#property-tax-bill-feedback,
	.feedback-feature-rating-given {
		position: absolute;
		z-index: 500;
		bottom: 0;
		left: 0;
		border-radius: 16px;
		border: 1px solid $slate;
		box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
			-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
			-44px 319px 90px 0px #ababab00;
		@include if-size("monitor") {
			grid-column: span 2;
		}
	}
}
