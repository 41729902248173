#appeal-legal-owner-info-box {
	grid-column: 1 / -1;
	width: 100%;
	display: flex;
	overflow: hidden;
	background-color: $denim-lightest;
	border-radius: 8px;

	@include if-size("tablet") {
		border-radius: 16px;
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

.appeal-legal-title,
.appeal-legal-body {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

.appeal-legal-body {
	padding-top: 6px;
}

.appeal-legal-high-demand-box {
	margin-top: spacing(2);
	grid-column: 1/-1;
	background-color: $terracotta;
	border-radius: 16px;
	padding: 16px;

	@include if-size("desktop") {
		margin-top: spacing(4);
		grid-column: 3 / -3;
	}

	p {
		color: $white;
	}
}

#appeal-legal-owner-info-left {
	flex: 1;
	display: none;

	@include if-size("tablet") {
		display: block;
		background-size: cover;
		background-repeat: no-repeat;
	}

	@include if-size("desktop") {
		display: none;
	}
}

#appeal-legal-owner-info-right {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	padding: spacing(2);

	@include if-size("tablet") {
		justify-content: center;
		padding: spacing(2) spacing(4);

		p:not(:last-of-type) {
			margin-bottom: spacing(2);
		}
	}

	.appeal-legal-owner-info-field {
		display: flex;
		flex-direction: column;
		flex: 100%;
		margin-right: 8px;

		@include if-size("tablet") {
			flex: calc(50% - 8px);
		}

		&:not(:last-of-type) {
			margin-bottom: spacing(1);
		}

		.appeal-legal-info-field-editable-save-btn {
			position: absolute;
			right: 8px;
			top: 8px;
			color: $azure;
			background: none;
			border: none;
			padding: 4px;
			outline: inherit;
			cursor: pointer;

			&:hover {
				color: $denim;
			}
		}
	}

	.appeal-legal-owner-info-field-editable {
		display: flex;
		position: relative;

		input {
			padding-right: 58px;
		}

		div {
			//delete this if I want the save to be outside
			width: 100%;
		}
		svg {
			position: relative;
			top: 5px;
			margin-left: 4px;
			width: 12px;
			height: 13px;
			cursor: pointer;

			@include if-size("tablet") {
				top: 9px;
			}
		}
	}

	#appeal-legal-owner-info-title {
		font-size: 18px;
		line-height: 21px;
		margin-bottom: 23px;
		color: $navy;

		@include respond-to("medium") {
			margin: 12px 0;
		}
	}

	.appeal-legal-owner-info-piece {
		margin-bottom: 14px;
		margin-right: 14px;
		font-size: 24px;
		line-height: 28px;
	}
}

#appeal-legal-disclaimer {
	grid-column: 1 / -1;
	margin-top: spacing(2);

	@include if-size("desktop") {
		max-width: 500px;
		margin: spacing(5) auto 0;
		grid-column: 4 / -4;
	}
}

.appeal-legal-input-row {
	display: flex;
	align-items: center;
	border-bottom: spacing(1);

	&-active {
		@extend .appeal-legal-input-row;
		user-select: none;
		margin-top: -5px;
		margin-bottom: 5px;
	}
}

.appeal-legal-input-edit-icon {
	margin-top: -10px;
	cursor: pointer;
}

.appeal-legal-editable {
	margin-right: 15px;
}

.appeal-legal-pdf-container {
	z-index: 10000;
	// background: #bebebef0;
	background-color: #ffffffcc;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow-y: auto;
	padding: 20px 0;

	#legal-pdf-spinner {
		width: 125px;
		height: 125px;
	}
}

#appeal-legal-open-pdf-text {
	color: $azure;
	cursor: pointer;
	text-decoration: underline;
}

.appeal-legal-pdf {
	height: 100%;
}

#appeal-legal-pdf-header {
	margin-bottom: 20px;
	width: 612px;
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 25px;
}

#download-pdf-container {
	display: flex;
	align-items: center;
	cursor: pointer;
}

#download-pdf-icon {
	fill: $teal;
	margin-left: 5px;
	margin-top: 5px;
}

#download-pdf-text {
	color: $teal;
	font-weight: 600;
	margin-left: 5px;
	margin-top: 5px;
}

#close-pdf-icon {
	fill: $teal;
	cursor: pointer;
}

#appeal-legal-submit-btn {
	margin: spacing(3) auto 0;

	@include if-size("tablet") {
		max-width: 500px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: spacing(3) auto 0;
		justify-self: flex-end;
	}
}

.appeal-legal-pdf-page {
	border: 2px solid #707070;
	&:not(:last-of-type) {
		margin-bottom: 25px;
	}
}
.appeal-legal-agreement-description {
	grid-column: 1 / -1;
	margin-top: spacing(4);

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}

	ol {
		margin-top: spacing(1);
		margin-left: spacing(5);
		list-style-position: outside;

		li {
			margin: 4px 0;
		}
	}
}

.appeal-legal-preview-agreement {
	grid-column: 1 / -1;
	margin-top: 4px;
	display: flex;
	justify-content: flex-end;

	@include if-size("desktop") {
		grid-column: 3 / -3;
		grid-row: 6;
	}

	.quick-link {
		font-size: 14px;

		&:hover {
			.quick-link-arrow {
				width: 16px;
				height: 16px;
			}
		}
	}

	.quick-link-arrow {
		width: 12px;
		height: 12px;
		@include if-size("desktop") {
			margin-top: 4px;
		}
	}
}

.canvas-container {
	position: relative;
	height: auto;
	width: 100%;
}

.sigCanvas {
	border: 1px solid $denim;
	user-select: none;
	border-radius: 10px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	aspect-ratio: 3.33;
}

.appeal-sign {
	grid-column: 1 / -1;
	position: relative;
	user-select: none;
	-webkit-user-drag: none;
	width: 100%;
}

.dotted-line {
	position: absolute;
	bottom: 25px;
	left: 5%;
	width: 90%;
	border: 1px dashed rgba(52, 72, 94, 0.2);
	user-select: none;
	pointer-events: none;
}

.dotted-line-x {
	position: absolute;
	bottom: 29px;
	left: 5%;
	font-size: 32px;
	color: rgba(52, 72, 94, 0.2);
	pointer-events: none;
	user-select: none;
}

.signature-box-specific-font {
	white-space: nowrap;
	font-size: 12px;
	line-height: 18px;

	@include if-size("tablet") {
		font-size: 14px;
		line-height: 21px;
	}
}

#appeal-legal-signature-clear {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: $denim;
	padding-left: 2px;
	padding-bottom: 1px;

	&:hover {
		color: $azure;

		svg {
			background-color: $azure;
		}
	}

	span {
		@extend .signature-box-specific-font;
	}

	svg {
		fill: white;
		border-radius: 50%;
		padding: 3px;
		margin-top: 2px;
		background-color: $denim;
		height: 12px;
		width: 12px;
		margin-left: spacing(1);
	}
}

#appeal-legal-signature-box-form {
	grid-column: 1 / -1;

	.signature-context-box {
		width: 100%;
		margin: spacing(2);
		padding: spacing(2);
		background-color: $white;
		border-radius: 16px;
		box-shadow: 1px 3px 7px 2px #0532490d;
	}
}

.appeal-legal-signature-container {
	background-color: $denim-lightest;
	width: 100%;
	max-width: 516px;
	border-radius: 8px;
	margin: spacing(1) auto 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 spacing(1) spacing(3);
	grid-column: 1 / -1;
	@include if-size("desktop") {
		grid-column: 4 / -4;
		padding: 0 spacing(2) spacing(4);
	}
}

#appeal-legal-signature-type-toggle-container {
	width: 100%;
	max-width: 500px;
	display: flex;
	margin-bottom: spacing(1);
	text-align: center;

	.appeal-legal-signature-type-toggle {
		padding: spacing(1) spacing(2);
		width: calc(50% + 8px);
		background: $white;
		cursor: pointer;
		border-radius: 0 8px 0 0;
		margin: 2px -6px 0px 0px;

		&:first-of-type {
			margin: 2px 0px 0px -6px;
			border-radius: 8px 0 0 0;
		}

		p {
			font-size: 14px;
			font-weight: 600;
			line-height: 16px;
			color: $denim;

			@include if-size("tablet") {
				font-size: 16px;
				line-height: 18px;
			}
		}

		&-active {
			@extend .appeal-legal-signature-type-toggle;
			background: $cloud;
		}
	}

	#appeal-legal-signature-type-toggle-helper-text {
		align-self: center;
		margin-right: auto;
	}
}

.appeal-sign-instructions {
	margin: 4px 0;

	&-error {
		@extend .appeal-sign-instructions;
		color: $terracotta;
	}
}

.appeal-sign-instructions-card {
	background-color: $white;
	padding: spacing(2);
	border-radius: spacing(2);
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	margin: spacing(2) 0;
}

#appeal-type-signature,
#appeal-type-signature-display {
	width: 100%;
	background-color: $white;
	border: 1px solid $denim;
}

#appeal-type-signature {
	height: 50px;

	&-error {
		@extend #appeal-type-signature;
		border: 1px solid $terracotta;
	}
}

#appeal-type-signature-display {
	margin-top: 14px;
	aspect-ratio: 3.333;
	font-family: "turbinado-pro", cursive;
	border-radius: 5px;
	font-size: 38px;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.appeal-legal-signature-placeholder {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	pointer-events: none;
	user-select: none;
}

.appeal-sign-status-container {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: spacing(0.5);
	padding-right: 1px;
	fill: #252525; // Default svg fill
	color: #25252580; // Default p color

	.row {
		display: flex;
		align-items: center;
		margin-left: auto;

		p {
			color: inherit;
			margin-right: 1ch;
			padding-bottom: 2px;
		}

		svg {
			fill: inherit;
			margin-left: spacing(1);
		}

		.signature-spinner {
			animation: spin 4s linear infinite;
		}
	}
}

.appeal-sign-type-container {
	position: relative;

	.appeal-sign-type-placeholder {
		position: absolute;
		pointer-events: none;
		top: 0;
		left: 8px;
		line-height: normal;
		font-size: 16px;
		color: rgba($denim, 0.2);
		transform: translateY(calc(50% + 2px)) translateX(1px);

		@include if-size("tablet") {
			left: 16px;
			font-size: 20px;
			transform: translateY(calc(50% - 3px)) translateX(1px);
		}
	}
}

.signature-box-sms-consent {
	margin: 8px 0 0;
}

.signature-started {
	color: $terracotta !important;
	fill: $terracotta !important;
}

.signature-complete {
	color: $azure !important;
	fill: $azure !important;

	svg {
		animation: none;
	}
}

.appeal-legal-invalid-email-or-phone-error-message {
	grid-column: 1/-1;
	text-align: center;
	padding-top: spacing(1);

	@include if-size("tablet") {
		grid-column: 4/-4;
	}
}

#exemption-only-legal-agreement-form,
#property-only-legal-agreement-form {
	grid-column: 1 / -1;
	margin-top: spacing(2);

	@include if-size("tablet") {
		grid-column: 3/-3;
	}

	button {
		margin: spacing(4) auto 0;

		@include if-size("tablet") {
			margin: spacing(8) auto 0;
		}
	}
}
