#referral-email-wrapper {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / 11;
	}

	#referral-email-label {
		.login-input {
			border-width: 2px;
			border-color: $denim;
		}
	}

	#referral-email-submit {
		grid-column: 1 / 2;
		margin: 0 auto;
		color: $royal;
		padding-top: 10px;
	}
}
