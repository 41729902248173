.chart {
	position: relative;
	background-color: $white;
	border-radius: 8px;

	tspan {
		fill: $denim-medium;
		font-size: 16px;
		line-height: 24px;
	}

	line {
		fill: $sand;
		stroke-linecap: round;
	}

	g &-bar {
		&:hover {
			cursor: pointer;
		}

		&-filled {
			@extend .chart-bar;

			&:hover {
				opacity: 0.5;
			}
		}

		&-outline {
			@extend .chart-bar;

			&:hover {
				@keyframes stroke {
					0% {
						stroke-dashoffset: 100;
					}
					100% {
						stroke-dashoffset: 0;
					}
				}

				animation: stroke 8s linear forwards infinite;
			}
		}
	}

	// necessary for extending bar shape past axis line
	.no-clip-path g {
		clip-path: none !important;
	}

	&-no-data {
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 4px;

		width: 100%;
		height: 100%;
		backdrop-filter: blur(1.5px);

		border-radius: 8px;

		padding: 0 spacing(8);

		p.lg {
			text-align: center;
		}

		p.sm {
			text-align: center;
		}
	}

	&-tooltip {
		background-color: $white;
		padding: spacing(1);
		border-radius: spacing(1);
		box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.35);
		max-width: 200px;

		border: 1px solid $marshmallow;

		label {
			color: rgba($denim, 0.5);
		}
	}

	&.property-value-chart {
		.y-axis-label {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateX(-40%) translateY(-100%) rotate(-90deg);
			font-size: 16px;
		}

		.x-axis-label {
			position: absolute;
			left: 50%;
			bottom: 0;
			font-size: 16px;
		}

		.property-value-chart-tooltip {
			display: flex;
			max-width: max-content;

			gap: spacing(1);
			flex-direction: column;
			align-items: flex-start;
			@include if-size("desktop") {
				gap: spacing(2);
				flex-direction: row;
				align-items: flex-end;
			}

			.value-change {
				svg {
					height: 12px;
					width: 12px;
					margin-right: 4px;
				}
			}
		}

		.recharts-layer.recharts-active-dot {
			circle {
				fill: $denim;
				opacity: 0.3;
				r: 18;
				stroke: none;
			}
		}

		.recharts-wrapper.has-data {
			.recharts-cartesian-grid {
				.recharts-cartesian-grid-horizontal {
					line:nth-last-child(-n + 2) {
						fill: transparent;
						stroke: transparent;
					}
				}
			}
		}

		.recharts-curve.recharts-tooltip-cursor {
			stroke: transparent;
		}
	}
}
