#files-page {
	margin-top: spacing(8);
	align-items: center;

	#files-page-hero-img {
		grid-column: 2 / -2;

		@include if-size("tablet") {
			grid-column: 3 / -3;
		}
		@include if-size("desktop") {
			grid-column: 2 / 5;
		}
	}

	#files-page-heading {
		grid-column: 1 / -1;
		margin-top: spacing(2);

		h1 {
			margin-bottom: spacing(1);
		}

		@include if-size("desktop") {
			grid-column: 6 / -1;
		}
	}
}

#files-page-template-container {
	position: relative;
	grid-column: 1 / -1;
	padding: spacing(8) 0;
	margin-top: spacing(8);

	#files-page-template-underlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $sky-light;
		z-index: -1;
	}

	#files-page-template-grid {
		row-gap: spacing(2);
	}
}

.file-template-card {
	display: flex;
	flex-direction: column;
	gap: spacing(1);
	padding: spacing(2);
	background-color: $white;
	border-radius: 16px;
	grid-column: span 4;
	box-shadow: 1px 3px 7px 2px #0532490d;

	h4 {
		margin: spacing(1) 0;
	}
	.label {
		color: $azure;
	}
	.file-template-card-description {
		opacity: 0.5;
	}

	.file-template-card-download {
		display: flex;
		align-items: center;
		align-self: flex-end;
		gap: spacing(1);

		p {
			color: $azure;
		}

		p.sm {
			margin-top: 2px;
		}
		svg {
			width: 16px;
			height: 16px;
			margin: 2px 4px 0 0;
			margin-bottom: 2px;
		}
	}
}

.template-page-wrapper {
	.template-content {
		margin: 0 auto;
		max-width: 1136px;
		position: relative;
		padding: 64px 16px;
		text-align: center;
		//overflow: hidden;
		@include if-size("desktop") {
			text-align: left;
		}
		display: flex;
		flex-direction: column;
		gap: 24px;
		ul {
			text-align: left;
		}
		li {
			text-align: left;
			margin-left: 32px;
		}
		h2 {
			margin-top: 30px;
		}
		.cta {
			margin: 0 auto;
			margin-top: 24px;
		}
	}
}
