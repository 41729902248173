.resources-more {
	width: 100%;
	background-color: $denim_lightest;
	padding-top: spacing(2);
	padding-bottom: spacing(2);

	@include if-size("tablet") {
		padding: spacing(4) spacing(5);
	}
	@include if-size("desktop") {
		padding: spacing(8) spacing(10);
	}

	.resources-more-header {
		grid-column: 1/-1;
	}

	a.resources-view-all,
	button.resources-view-all {
		margin: spacing(4) auto 0 auto;
		grid-column: 1/-1;
	}
	.resources-more-cards {
		position: relative;
		grid-column: 1/-1;

		.swiper-slide {
			text-align: center;
		}
	}

	.resources-more-cards-button {
		grid-column: 1/-1;
		padding-top: spacing(4);
		margin: 0 auto;
		@include if-size("desktop") {
			padding-top: spacing(4);
		}
	}
}

.card-slider-nav-prev,
.card-slider-nav-next {
	cursor: pointer;
	position: absolute;
	top: 50%;
	z-index: 10;

	&.swiper-button-lock {
		display: block;
	}
	@include if-size("desktop") {
		&.swiper-button-lock {
			display: none;
		}
	}

	svg {
		fill: $denim;
		opacity: 20%;
		height: 25px;
		width: 25px;
		@include if-size("desktop") {
			height: 30px;
			width: 30px;
		}
	}
}

.card-slider-nav-prev {
	left: -38px;
}
.card-slider-nav-next {
	right: -38px;
}

.card-slider-pagination {
	padding-top: spacing(2);
	grid-column: 1 / -1;
	display: flex;
	justify-content: center;
	overflow: hidden;

	.card-pagination-bullet {
		width: 8px;
		min-width: 8px;
		height: 8px;
		user-select: none;
		background: $denim;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;

		@include if-size("tablet") {
			width: 10px;
			height: 10px;
			min-width: 10px;
		}
	}
}

.resource-card {
	cursor: pointer;
	width: 100%;
	text-decoration: none;
	-webkit-box-shadow: 1px 3px 7px 2px rgb(0 0 0 / 10%);
	box-shadow: 1px 3px 7px 2px rgb(0 0 0 / 10%);
	border-radius: 16px;
	overflow: hidden;
	background-color: $white;
	display: inline-block;

	.resource-card-img {
		width: 100%;
		overflow: hidden;
		height: 150px;
		background-size: cover;
		background-position: center;

		@include if-size("tablet") {
			height: 200px;
		}
	}
	.resource-card-bg {
		position: relative;
		padding: 0 spacing(2) spacing(2) spacing(2);
		color: $denim;
		height: 275px;
		max-height: 235px;
		text-align: left;
		@include if-size("tablet") {
			max-height: 275px;
		}
	}
	.resource-card-title {
		width: 100%;
		padding-top: 4px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		-webkit-line-clamp: 3;
		@include if-size("tablet") {
			padding-top: spacing(1);
		}
	}
	.resource-card-description {
		opacity: 50%;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		-webkit-line-clamp: 3;
		@include if-size("tablet") {
			padding-top: spacing(1);
		}
	}

	.resource-card-footer {
		position: absolute;
		bottom: 8px;
		width: 100%;
		display: flex;
		justify-content: space-between;

		@include if-size("desktop") {
			bottom: 11px;
			left: auto;
		}

		.resource-card-savings {
			color: $azure;
			margin: auto 0;
			@include text("18px");
			position: absolute;
			bottom: 25px;
			@include if-size("tablet") {
				position: relative;
				bottom: auto;
			}

			@include if-size("desktop") {
				@include text("18px");
			}
			@include if-size("desktop-lg") {
				@include text("24px");
			}
		}
		.resource-card-read-more {
			padding: 6px spacing(3) 0 0;

			@include if-size("tablet") {
				padding: 4px spacing(4) 0 0;
			}
			p {
				color: $azure;
				margin-right: 4px;
				@include text("12px");
				@include if-size("tablet") {
					margin-right: 8px;
				}
				@include if-size("desktop") {
					@include text("14px");
				}
			}
		}
	}
}

/* Fix to box shadow cutoff issue for Swiper*/
.outer-swiper-wrapper {
	overflow: hidden;
	margin: -8px;

	.swiper {
		overflow: visible;
		padding: 8px;
	}
}

.resource-error {
	margin-top: 10%;
	text-align: center;
}

.loading-container {
	grid-column: 1/-1;
	text-align: center;
}

/* Fix firefox issue for Swiper */
#about-team-section-slider-container,
.review-carousel-container,
.resources-more-cards {
	width: 100%;
	max-width: 100%;
	min-width: 0;
	min-height: 0;
	max-height: 100vh;
}
