#landing-cta2-section {
	background-color: $white;
}

.landing-cta-section {
	width: 100%;
	padding: spacing(5) 0 spacing(5);
	background-color: $sky-light;

	.landing-cta-section-btn {
		grid-column: 1/-1;
		margin-top: spacing(2);
		text-align: center;
	}

	@include if-size("tablet") {
		padding: spacing(8) 0 spacing(8);
	}

	.landing-cta-left {
		grid-column: 2 / 7;
		align-self: center;
	}

	.landing-cta-title {
		text-align: center;
		grid-column: 1 / -1;
		text-align: center;
	}

	.landing-cta-btn {
		// grid-column: 1 / -1;
		display: inline-block;
	}

	.landing-cta-img {
		position: relative;
		width: 100%;
		height: 100%;
		grid-column: 1 / -1;
		max-width: 100%;
		justify-self: center;
		margin-bottom: spacing(4);
		text-align: center;

		@include if-size("desktop") {
			grid-column: 7 / 12;
			margin-bottom: 0;
		}

		img,
		svg {
			max-height: 100%;
			max-width: 100%;
		}

		&-ga {
			max-width: 200px;

			@include if-size("desktop") {
				max-width: 272px;
			}

			svg {
				width: 178px;
				@include if-size("desktop") {
					width: 272px;
				}
			}
		}
	}
	.landing-cta-p {
		grid-column: 1/-1;
		text-align: center;
	}
}

.landing-no-video-cta {
	grid-column: 1/-1;
	text-align: center;

	.button {
		margin: 8px auto 0;
	}
}

#landing-video-cta {
	row-gap: 1;
	padding: spacing(8) 0;

	@include if-size("desktop") {
		padding: spacing(12) 0;
	}
	.landing-video-cta-left {
		grid-column: 1/-1;
		margin: 0 auto;
		width: 100%;

		@include if-size("desktop") {
			grid-column: 1/7;
		}

		iframe {
			object-fit: fill;
			// width: 100%;
			border-radius: 8px;
			max-width: 100%;
			min-width: 100%;

			@include if-size("desktop") {
				border-radius: 16px;
			}
		}
	}

	.landing-video-cta-right {
		grid-column: 1/-1;
		text-align: center;
		align-self: center;
		justify-self: center;

		@include if-size("desktop") {
			grid-column: 8/-1;
		}

		h2 {
			padding-top: spacing(2);
			padding-bottom: spacing(1);
			@include if-size("desktop") {
				padding-top: 0;
			}
		}
		h4 {
			padding: 0 spacing(1) spacing(2);
			max-width: 450px;
			margin: 0 auto;
			@include if-size("desktop") {
				padding: 0 spacing(1) spacing(3);
			}
		}

		#landing-video-cta-btn {
			margin: 0 auto;
		}
	}
}
