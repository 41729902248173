#landing-reviews-section {
	padding: spacing(12) 0;
	color: $black;

	@include respond-to("large") {
		padding: spacing(8) 0;
	}
}

#landing-reviews-img {
	max-width: 95%;
	margin-right: spacing(12);

	@include respond-to("large") {
		margin-right: 0;
		display: none;
	}
}

#landing-reviews-title {
	grid-column: 1 / -1;
	text-align: center;
	margin-bottom: spacing(4);
}

#landing-reviews-five-stars-img {
	grid-column: 1 / -1;
	margin: 0 auto spacing(4);
	width: 380px;

	@include if-size("tablet") {
		max-width: 400px;
	}

	@include respond-to("large") {
		max-width: 90%;
	}
}

#landing-reviews-cta {
	user-select: none;
	margin-top: spacing(3);
	text-align: center;

	a {
		display: inline-block;
	}
}

.review-carousel-container {
	grid-column: 1 / -1;
	display: flex;
	align-items: center;

	&.testimonials-review-carousel {
		max-height: none;
		.swiper {
			height: 100%;
		}
	}
}

.review-carousel-arrow {
	cursor: pointer;
	fill: rgba($denim, 0.2);
	height: auto;

	&-right {
		@extend .review-carousel-arrow;
		margin-left: 5px;
		width: 15px;
	}

	&-left {
		@extend .review-carousel-arrow;
		margin-right: 5px;
		width: 15px;
	}

	@include if-size("tablet") {
		&-right {
			margin-left: 20px;
			height: auto;
			width: 20px;
		}

		&-left {
			margin-right: 20px;
			height: auto;
			width: 20px;
		}
	}
}

.chevron {
	background-color: #fff;
	border: 1px solid rgba(228, 232, 235, 1);
	cursor: pointer;
	padding: 8px 10px;
	border-radius: 40px;
	z-index: 1000;
}

.reviews-google-logo {
	img {
		width: 30px;
		height: 30px;
		position: absolute;
		right: 15px;
		bottom: -7.5px;
		border: 1px solid rgba(230, 230, 230, 1);
		border-radius: 15px;
	}
}

.stars {
	text-align: center;
}

.review-text {
	margin: spacing(2) 0 spacing(1);
}

.review-author {
	color: $denim;
}

.star {
	width: 25px;
	height: 25px;
	user-select: none;
	color: #ee8572 !important;
}

.review {
	background-color: #fff;
	box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding: spacing(4) spacing(2);
	height: 290px;
	margin: 10px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include if-size("tablet") {
		height: 330px;
	}

	&.review-no-stars {
		height: calc(
			100% - 20px
		); //minus the margin of the card (for the boxshadow effect)

		.review-text {
			margin-top: 0;
		}
	}
}

.block-with-text {
	height: 160px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	-webkit-line-clamp: 7;

	@include if-size("tablet") {
		height: 198px;
	}
}
