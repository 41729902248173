#landing-vp4-section {
	background-color: $white;
	overflow-x: hidden;
	padding: spacing(8) 0 spacing(8);

	.grid {
		row-gap: 16px;
	}

	#landing-vp4-section-top-box-image {
		grid-column: span 8;

		place-self: center center;

		@include if-size("desktop") {
			grid-column: span 5;
			place-self: unset;
		}

		display: flex;
		justify-content: center;
	}

	#landing-vp4-section-top-box-content {
		grid-column: span 8;
		padding-bottom: 48px;

		@include if-size("desktop") {
			grid-column: 7 / 13;
			padding-bottom: 48px;
		}

		h2 {
			padding-bottom: 24px;
		}
	}

	.landing-vp4-card {
		box-shadow: 1px 3px 7px 2px rgba($denim, 0.05);
		border: 1px solid $cloud;
		padding: 16px;

		border-radius: 8px;

		grid-column: span 8;

		@include if-size("desktop") {
			grid-column: 2 / 12;
		}

		display: grid;

		grid-template-columns: 50px auto;
		column-gap: 12px;

		.landing-vp4-card-icon {
			grid-column: span 1;
			place-self: center;

			width: 50px;
			height: 50px;

			@include if-size("desktop") {
				grid-area: 1 / 1 / 3 / 2;
			}
		}

		h4 {
			font-weight: 600;
			grid-column: span 1;
			place-self: center start;
		}

		p {
			grid-column: span 2;

			@include if-size("desktop") {
				grid-column: span 1;
			}
		}
	}
}
