#about-join-our-team-section {
	width: 100%;
	background-color: $denim-lightest;
	padding: 24px 0 64px;

	#about-join-our-team-left,
	#about-join-our-team-right {
		grid-column: 1 / -1;
	}

	#about-join-our-team-left {
		display: flex;
		justify-content: center;
		margin-bottom: spacing(3);

		#about-join-our-team-img {
			margin: 0 auto;
			max-width: 100%;
			width: 250px;

			@include if-size("desktop") {
				max-width: 100%;
				width: 367px;
			}
		}
	}

	#about-join-our-team-right {
		p {
			padding: 16px 0 24px;
		}

		.button-v2 {
			background-color: $white;
		}
	}

	button {
		margin: 0 auto;
	}

	@include if-size("desktop") {
		padding: 96px 0;
		button {
			margin: 0;
		}

		#about-join-our-team-left {
			grid-column: 2 / 6;
			margin: 0;
		}

		#about-join-our-team-right {
			grid-column: 7 / 12;

			p {
				padding: 16px 0;
			}
		}
	}
}
