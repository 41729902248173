#landing-banner {
	width: 100%;
	background-color: $rust;
}

#landing-banner-container {
	text-align: center;
	padding: 12px 8px;
	@include if-size("desktop") {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 16px;
		padding: 12px 8px;
	}
	p {
		color: $cloud;
	}

	.landing-banner-timer {
		display: inline-block;
		font-size: 12px;
		border-radius: 8px;
		padding: 4px 8px;
		color: $cloud;
		border: 1px solid $cloud;
		margin: spacing(1) 0 0 0;
		@include if-size("desktop") {
			font-size: 14px;
			margin: 0;
		}
		&:hover {
			background-color: $salmon;
		}
	}
}

#landing-announcement-banner {
	width: 100%;
	background-color: $azure;
}

#landing-announcement-container {
	@extend #landing-banner-container;
	padding: 16px;

	@include if-size("desktop") {
		padding: 8px 16px;
	}
}
