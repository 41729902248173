#confirmation-service-card {
	width: 100%;
	border-radius: 16px;
	overflow: hidden;
	background: $denim-lightest;
	margin: spacing(7) 0;

	#confirmation-service-card-heading {
		background: $sky;
		text-align: center;
		padding: spacing(1);
	}

	#confirmation-service-card-icon {
		margin-right: spacing(2);
		max-width: 75px;
		max-height: 75px;
		width: 100%;
		height: auto;

		svg {
			height: 100%;
			width: 100%;
		}

		@include if-size("desktop") {
			max-width: 120px;
			max-height: 120px;
		}
	}

	#confirmation-service-card-body {
		padding: spacing(2) spacing(2) spacing(4);

		> .button-v2 {
			margin-top: 0;
		}
	}

	#confirmation-service-card-desktop {
		display: none;
		@include if-size("desktop") {
			display: block;
		}
	}

	#confirmation-service-card-mobile {
		@include if-size("desktop") {
			display: none;
		}
	}
}
