.properties-property-card {
	width: 100%;
	grid-column: 1;
	background-color: $denim-lightest;
	padding: spacing(2);
	border-radius: 16px;
	position: relative;
	margin-bottom: spacing(4);
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

	@include if-size("desktop") {
		padding: spacing(3) spacing(2);
	}

	&-inactive {
		@extend .properties-property-card;
		background-color: $sand;
	}

	&-needs-attention {
		@extend .properties-property-card;
		border: 2px solid $terracotta;
	}
}

.properties-property-card-needs-attention-container {
	border: 2px solid $terracotta;
	border-radius: 8px;
	background-color: rgba(247, 230, 226, 0.6392156);
	padding: spacing(2);
	margin: 12px 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 8px;

	.exemption-nars {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.exemption-nars-body {
		display: none;
	}
}

.properties-property-card-needs-attention-title {
	display: flex;
	align-items: center;

	svg {
		height: 22px;
		margin-left: spacing(1);
	}
}

.properties-property-card-title-section {
	position: relative;
	width: 100%;
	display: flex;
}

.properties-property-card-settings-icon-container {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 0 4px;
	cursor: pointer;

	> .property-settings-property-card-popover {
		top: 0;
		right: 0;
	}
}

.properties-property-card-title-section-address {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-right: spacing(2);

	address {
		font-style: normal;
	}
}

.properties-property-card-address1 {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 95%;
	display: block;
	line-height: 26px !important;

	@include if-size("tablet") {
		max-width: 100%;
		line-height: 32px !important;
	}
}

.properties-property-card-title-section-image {
	height: 80px;
	width: 96px;
	margin-right: spacing(2);

	border-radius: 8px;

	@include if-size("desktop") {
		width: 144px;
		height: 96px;
	}
}

.properties-property-card-service-section {
	margin-top: spacing(3);
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.properties-property-card-service-card {
	background-color: $white;
	padding: spacing(1);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: spacing(1);
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

	@include if-size("desktop") {
		padding: spacing(1) spacing(2);
	}
}

.properties-property-card-service-card-title {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	cursor: pointer;

	svg {
		width: 12px;
		height: auto;
	}
}

.properties-property-card-service-card-content {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	row-gap: spacing(1);
	column-gap: spacing(4);
}

// .body-tiny gets $denim color applied and I'm not 100% confident in removing that so this is the fix
.properties-property-card-service-card-data-point {
	.kelly {
		color: $kelly !important;
	}
	.terracotta {
		color: $terracotta !important;
	}
}

.properties-property-card-service-card-data-point-label {
	display: flex;
	align-items: center;

	.label {
		color: rgba($denim, 0.5);
		margin-bottom: spacing(0.5);
	}

	> .appeal-info-question-card-tooltip {
		margin-left: 4px;
		margin-top: 2px;

		.appeal-info-question-card-tooltip-text {
			margin: 0;
		}

		svg {
			height: 14px;
			width: 14px;
		}
	}
}
