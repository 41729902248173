.color-azure {
	color: $azure;
}

.fill {
	&-denim {
		fill: $denim;
		path {
			fill: $denim;
		}
	}

	&-slate {
		fill: $slate !important;
		path {
			fill: $slate !important;
		}
	}

	&-denim-5 {
		fill: $denim_5;
		path {
			fill: $denim_5;
		}
	}
}

.as-link {
	color: $azure;
	text-decoration: underline;

	&:hover {
		cursor: pointer;
	}
}

.opaque {
	opacity: 0.5;
}
