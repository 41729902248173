$pretty--color-primary: $azure;

@import "~pretty-checkbox/src/pretty-checkbox.scss";

.checkbox {
	position: relative;
	display: flex;
	align-items: center;

	//.pretty.
	.pretty {
		margin-right: 0.5em;
		line-height: 1.1;

		/* -- Checkbox - modified for custom Pretty Checkbox color -- */
		input:checked ~ .state.p-success label:after {
			background-color: $denim !important;
		}

		input {
			height: 16px;
		}
		.state {
			margin-top: -5px;

			label {
				line-height: 20px;
				color: $white;

				&:before {
					border: 2px solid $denim;
				}
			}

			.icon {
				top: 4px;
				left: -2px;
				transform: translateY(-50%);
				font-size: 0.9em;

				@include if-size("tablet") {
					top: 8px;
					left: -1px;
				}
			}
		}
	}

	> .label {
		padding-top: 1px;
		cursor: pointer;
		user-select: none;
	}

	> p {
		cursor: pointer;
	}
}
