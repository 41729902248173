#landing-commercial-contact-modal-underlay-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000000;
}

#landing-commercial-contact-modal-underlay {
	background-color: $denim;
	opacity: 0.75;
	height: 100%;
	width: 100%;
}

#landing-commercial-contact-modal {
	background-color: $white;
	border-radius: 8px;
	position: absolute;
	top: 15%;
	left: 50%;
	transform: translateX(-50%);
	opacity: 1;
	z-index: 1000001;
	max-width: 85%;
	width: 100%;

	@include if-size("tablet") {
		top: 25%;
		max-width: 560px;
	}
}

#landing-commercial-contact-modal-inner {
	position: relative;
	padding: spacing(4);
}

#landing-commercial-contact-modal-option-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: spacing(2);

	@include if-size("tablet") {
		flex-direction: row;
	}
}

.landing-commercial-contact-modal-option {
	border-radius: 4px;
	padding: spacing(2);
	margin: spacing(1);
	cursor: pointer;
	flex: 1;
	border: 2px solid $cloud;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	text-align: center;
	width: 100%;

	.label {
		cursor: pointer;
	}

	@include if-size("desktop") {
		max-width: 33%;
	}

	&:hover {
		border: 2px solid $azure;
	}
}

#landing-commercial-contact-modal-close {
	position: absolute;
	top: 12px;
	right: 16px;
	cursor: pointer;

	svg {
		height: 22px;
		width: 22px;
	}
}
