#mortgage-details {
	order: 2;
	position: relative;
	background-color: $white;
	box-shadow:
		-2px 13px 28px 0px #ababab1a,
		-7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d,
		-28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	border: solid 1px $slate;
	border-radius: 16px;
	padding: spacing(2);

	display: flex;
	flex-direction: column;
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: span 1;
		padding: spacing(3);
	}
	&.min-content-height {
		height: min-content;
	}

	.detail-list {
		display: flex;
		flex-direction: column;
		gap: spacing(2);
		list-style: none;
		padding: spacing(2);

		background-color: $denim_lightest;
		border-radius: 8px;
		margin-bottom: 16px;
		li {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}

	.status-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 12px;
		margin: 8px 0 16px;

		.monthly-payment {
			.amount {
				color: $royal;

				font-weight: 800;
				letter-spacing: -0.05em;
				font-size: 30px;
				@include if-size("desktop") {
					font-size: 48px;
				}

				sub {
					letter-spacing: 0;
					font-size: 14px;

					@include if-size("desktop") {
						font-size: 24px;
					}
				}
			}

			.subtitle {
				color: $royal;
				margin-top: 8px;
				font-weight: 700;
				line-height: 10px;
				font-size: 16px;
				@include if-size("desktop") {
					line-height: 12px;
					font-size: 16px;
				}
			}
		}

		.paid-off {
			color: $royal;

			font-weight: 800;
			font-size: 32px;
			line-height: 39.6px;

			@include if-size("desktop") {
				font-size: 38px;
				line-height: 45.6px;
			}
		}

		.ownwell-house {
			width: auto;
			max-width: 90px;
			height: auto;

			@include if-size("desktop") {
				width: 90px;
				height: auto;
			}
		}
	}

	&.skeleton {
		.status-row {
			@include shimmer-bg-white();
			width: 100%;
			height: 80px;
		}

		.detail-list {
			@include shimmer-bg-white();
			width: 100%;
			height: 100px;
		}
	}

	.btns-container {
		display: flex;
		gap: spacing(2);
		justify-content: center;
		align-items: center;
		.no-padding {
			padding-left: 4px;
			padding-right: 4px;
		}
	}
}
