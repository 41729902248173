#property-value-chart-and-feedback {
	.property-value-chart {
		.box {
			background-color: $denim_lightest;
			padding: 10px;
			border: solid 1px $slate;
			border-radius: spacing(1);
			display: flex;
			flex-direction: column;
			.box-label {
				color: $denim_5;
				font-size: spacing(2);
			}
			.box-value {
				color: $denim;
				font-size: spacing(3);
				font-weight: bold;
			}
		}
		.value-change-display {
			display: flex;
			gap: 8px;
			justify-content: start;
			.percent-change-wrapper {
				display: flex;
				gap: 8px;
				justify-content: center;
				align-items: center;
				color: $azure;
				background-color: $kelly-light;
				padding: 5px;
				border-radius: 6px;
				border: 1px solid $kelly;
				p {
					font-size: 12px;
					line-height: 18px;
					color: $kelly-dark;
				}
				svg {
					width: auto;
					height: 12px;
					margin: 0;
					fill: $kelly-dark;
					path {
						fill: $kelly-dark;
					}
				}
				&.rust {
					color: $rust-dark;
					background-color: $rust-lightest;
					border-color: $rust;
					p {
						color: $rust-dark;
					}
					svg {
						fill: $rust-dark;
						path {
							fill: $rust-dark;
						}
					}
				}
			}
		}
	}
}
