#ways-to-save {
	@include if-size("desktop-xl") {
		padding: spacing(3);
		border: 1px solid #d4dee3;
		position: relative;
		background-color: $white;
		box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
			-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
			-44px 319px 90px 0px #ababab00;
	}

	border-radius: 16px;
	display: block;
	position: relative;

	p.lg {
		margin-bottom: spacing(2);

		.number-badge {
			vertical-align: 2px;
			padding: 4px 12px 6px 12px;
			border-radius: 8px;
			background: $cloud;
			color: $denim-medium;
			line-height: 19px;
			font-size: 16px;
			font-weight: bold;
		}
	}

	.status-badge {
		&.dot {
			width: 10px;
			height: 10px;
			border-radius: 50%;
		}

		&.text-icon {
			font-size: 14px;
			line-height: 16px;
			font-weight: bold;

			padding: 4px 8px 6px 8px;
			border-radius: 8px;
			border-style: solid;
			border-width: 1.5px;

			display: flex;
			flex-direction: row;
			align-items: flex-end;
			gap: 4px;

			svg {
				width: 14px;
				height: 14px;
			}
		}

		&.icon {
			width: 20px;
			height: 20px;
			@include if-size("desktop") {
				width: 24px;
				height: 24px;
			}

			display: grid;
			place-items: center;
			border-radius: 50%;

			border-style: solid;
			border-width: 1px;
		}

		&-slate {
			&.icon {
				background-color: $slate;

				border-color: $denim-medium;
			}

			&.text-icon {
				background: $denim-extra-light;
				border-color: $slate;
				color: $denim-medium;

				svg {
					fill: $denim-medium;
				}
			}

			&.dot {
				background-color: $slate;
			}
		}

		&-azure {
			&.text-icon {
				background: $sky-light;
				border-color: $azure;
				color: $royal;

				svg {
					fill: $royal;
				}
			}

			&.dot {
				background-color: $azure;
			}
		}

		&-sunflower {
			&.icon {
				background-color: $sunflower-light;

				border-color: $sunflower-dark;
			}

			&.dot {
				background-color: $sunflower-light;
			}
		}

		&-kelly {
			&.icon {
				background-color: $kelly-light;

				border-color: $kelly-dark;
			}

			&.text-icon {
				background: $kelly-light;
				border-color: $kelly;
				color: $kelly-dark;

				svg {
					fill: $kelly-dark;
				}
			}

			&.dot {
				background-color: $kelly;
			}
		}

		&-rust {
			&.icon {
				background-color: $rust-lightest;

				border-color: $rust;
			}

			&.text-icon {
				background: $rust-lightest;
				border-color: $rust;
				color: $rust-dark;

				svg {
					fill: $rust-dark;
				}
			}

			&.dot {
				background-color: $rust;
			}
		}
	}

	.insurance-quotes-status-badge {
		width: 24px;
		height: 24px;
		border-radius: 58px;
		background: $kelly-light;
		border: 1px solid $kelly-dark;
		color: $kelly-dark;
		display: flex;
		justify-content: center;
		align-items: center;

		p.sm {
			margin-bottom: 2px;
		}
	}

	#ways-to-save-tab-view {
		display: flex;
		flex-direction: row;
		gap: spacing(1);

		.tabs {
			flex: 0 1;
			list-style: none;
			display: flex;
			flex-direction: column;
			gap: spacing(1);
			width: 100%;

			li {
				padding: 0;
				margin: 0;
				flex: 0;

				white-space: nowrap;

				width: 100%;
				padding: 8px 12px;
				position: relative;
				background: transparent;

				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;

				&:hover {
					cursor: pointer;
				}

				.tab-text {
					z-index: 3;

					max-width: calc(
						206px - 24px
					); // unselected tab max-width - x axis padding
					width: 100%;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: space-between;
					align-items: center;
					gap: spacing(1);
				}

				.selected {
					z-index: 2;
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: calc(100% + 24px);
					background: $denim_lightest;

					border-radius: 12px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}

				&:not(.selected) {
					min-width: 206px;
					width: 206px;
				}

				.border {
					z-index: 1;

					position: absolute;
					top: 0;
					left: 0;
					border: solid 1px $denim-extra-light;
					border-radius: 12px;

					width: 100%;
					height: 100%;
				}
			}
		}

		.selected-tab-content {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-grow: 1;
			background-color: $denim_lightest;
			border-radius: 8px;
			overflow: hidden;

			padding: spacing(2);
		}

		.quick-link.more-ways-to-save-link {
			margin-top: 12px;
			margin-bottom: 12px;

			.quick-link-link {
				font-size: 14px;
				line-height: 16px;
				font-weight: 600;
			}

			.quick-link-arrow {
				margin-left: 5px;
			}

			svg {
				width: 12px;
				height: 12px;
			}
		}
	}

	#ways-to-save-accordion {
		display: flex;
		flex-direction: column;
		gap: 12px;

		margin-bottom: 8px;

		border-bottom: solid 1px #ecece7;

		.accordion-item {
			&:hover {
				cursor: pointer;
			}

			padding: 12px spacing(2);
			padding-bottom: 0;
			&.open {
				padding-bottom: 16px;
			}

			border: solid 1px $slate;
			border-radius: 12px;
			// overflow: hidden;

			position: relative;
			background-color: $white;
			box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
				-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
				-44px 319px 90px 0px #ababab00;

			header {
				z-index: 3;

				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-items: center;
				gap: spacing(1);

				margin-bottom: 12px;

				.down-arrow-container {
					margin-left: auto;

					.down-arrow {
						fill: $denim;
						height: 16px;
						width: 16px;
					}
				}
			}
		}

		.quick-link.more-ways-to-save-link {
			margin: 0 auto;
			margin-top: 12px;
			margin-bottom: 24px;

			.quick-link-link {
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.05em;
			}

			.quick-link-arrow {
				margin-left: 4px;
			}

			svg {
				width: 12px;
				height: 12px;
			}
		}
	}

	#property-appeal-status-card {
		box-shadow: none;
		background-color: $white;
		border: none;
		border-radius: 8px;
	}

	#ways-to-save-accordion {
		.accordion-item {
			#property-appeal-status-card {
				border: none;
				padding: 0;
			}
		}
	}
	.active-service-badge {
		display: flex;
		align-items: center;
		gap: spacing(0.5);

		p.sm {
			color: $kelly-dark;
		}

		background-color: $kelly-lightest;
		padding: 6px 12px;
		border-radius: 8px;
		width: fit-content;
	}

	#bill-reduction-service-content {
		border-radius: 8px;
	}
}

#wts-bill-reduction-container {
	.quick-link {
		margin-left: auto;
		padding-top: 0;

		@include if-size("tablet") {
			padding-top: 8px;
		}
	}

	.average-savings-label {
		margin-top: 0;
	}

	#average-savings-banner {
		display: flex;
		justify-content: center;
		gap: 0;
		flex-direction: column;

		@include if-size("desktop") {
			flex-direction: row;
			gap: 24px;
		}

		.average-savings-left-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;

			@include if-size("desktop") {
				flex-direction: column;
				gap: 0;
			}
		}

		.average-savings-padded-boxes-container {
			display: flex;
			gap: spacing(1);
			margin-bottom: 4px;
			flex-wrap: wrap;
			justify-content: center;
			@include if-size("desktop") {
				flex-wrap: nowrap;
			}
		}

		.average-savings-padded-box {
			background-color: $sky;
			border-radius: 6px;
			padding: 0 14px;
			text-align: center;
		}
	}

	.wys-bill-reduction-provider-and-cta-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@include if-size("tablet") {
			flex-direction: row;
			gap: 16px;
		}
	}

	.wys-bill-reduction-provider-dropdown {
		flex: 1 1;
		width: 100%;
	}

	.wys-bill-reduction-cta {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: spacing(2);
	}
}

.bill-reduction-hide-on-desktop {
	display: block;
	@include if-size("desktop") {
		display: none;
	}
}

.ways-to-save-onboarding {
	> #ways-to-save-accordion > .accordion-item.open {
		z-index: 1000001;
		position: relative;

		> * {
			z-index: 10000002;
		}
	}

	@include if-size("desktop-xl") {
		z-index: 1000001;

		> * {
			z-index: 10000002;
		}
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

#ways-to-save-onboarding-underlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($denim, 0.75);
	z-index: 99999;
	animation: fade-in 1500ms;
	pointer-events: none;
}

#ways-to-save-onboarding-tooltip {
	position: absolute;
	left: 50%;
	transform: translateX(-50%) translateY(-100%);
	padding: spacing(4) spacing(3);
	z-index: 1000002;
	background: $white;
	border-radius: 16px;
	animation: fade-in 1500ms;
	width: 100%;
	top: calc(-1 * spacing(4));

	@include if-size("desktop") {
		width: fit-content;
		transform: translateX(-50%) translateY(100%);
		bottom: calc(-1 * spacing(4));
		top: unset;
	}

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		border-top: 12px solid white;
		left: 50%;
		bottom: 0;
		transform: translateY(100%);

		@include if-size("desktop") {
			left: 50%;
			top: 0;
			transform: translateY(-100%);
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-bottom: 12px solid white;
			border-top: unset;
		}
	}

	#ways-to-save-onboarding-tooltip-title-section {
		margin: 0 spacing(2);
	}

	#ways-to-save-onboarding-tooltip-cta-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: spacing(3);
	}

	#ways-to-save-onboarding-tooltip-cta-skip {
		cursor: pointer;
		color: $azure;

		&:hover {
			text-decoration: underline;
		}
	}
}

.service-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0;

	gap: spacing(1);
	@include if-size("desktop-xl") {
		gap: 12px;
	}

	.desktop-card {
		height: min-content;
		display: flex;
		flex-direction: column;
		gap: spacing(1);
		@include if-size("desktop-xl") {
			gap: spacing(2);
		}

		@include if-size("desktop-xl") {
			border-radius: 8px;
			background-color: $white;
			padding: spacing(2);
		}
	}

	.card-content {
		background-color: $denim_lightest;
		padding: spacing(2);
		border-radius: 8px;
		@include if-size("desktop-xl") {
			padding: 0;
			background-color: transparent;
			border-radius: 0;
		}

		svg {
			width: 16px;
			height: 16px;

			@include if-size("desktop-xl") {
				width: 20px;
				height: 20px;
			}

			margin-left: 4px;
			margin-top: 2px;

			&.rust-dark {
				fill: $rust-dark;
				path {
					fill: $rust-dark;
				}
			}

			&.denim-medium {
				fill: $denim-medium;
				path {
					fill: $denim-medium;
				}
			}

			&.gold {
				fill: $gold;
				path {
					fill: $gold;
				}
			}

			&.royal {
				fill: $royal;
				path {
					fill: $royal;
				}
			}

			&.kelly-dark {
				fill: $kelly-dark;
				path {
					fill: $kelly-dark;
				}
			}
		}
	}

	.bordered-content {
		border-width: 2px;
		border-style: solid;
		padding: spacing(2);
		border-radius: 8px;
		background-color: $white;

		@include if-size("desktop-xl") {
		}

		&.rust {
			border-color: $rust;
			background-color: $rust-lightest-64;
		}

		&.kelly {
			border-color: $kelly;
		}

		.exemptions-final-tax-refund-and-invoice-container {
			display: flex;
			justify-content: space-between;
			padding-top: spacing(2);
		}

		.exemptions-final-tax-refund-box {
			background-color: $kelly-light;
			border-radius: 8px;
			color: $denim;
			display: inline-block;
			padding: spacing(1) spacing(2);

			border: 1px solid $kelly;

			.label,
			p {
				color: $denim;
			}
		}
	}
}

.wts-bill-reduction-active {
	.wts-bill-reduction-active-title-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.wts-bill-reduction-current-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
