#actions-required-page {
	position: relative;
	padding: 16px 16px 0 16px;
	padding-top: 44px;

	@include if-size("desktop") {
		padding-top: 16px;
	}
}

#actions-required-mobile-filters-bar {
	z-index: 100;
	position: fixed;
	top: 44px;
	left: 0;
	width: 100%;
	background-color: $white;
	box-shadow: 1px 3px 7px 2px #0532490d;

	@include if-size("desktop") {
		display: none;
	}

	#view-hide-mobile-filters {
		flex: 1;
		display: flex;
		align-items: center;
		gap: spacing(1);

		&:hover {
			color: $azure;
			cursor: pointer;

			path {
				fill: $azure;
			}
		}

		p {
			color: $azure;
		}
	}

	#actions-required-mobile-tax-years-filter {
		z-index: 100;
	}
	#actions-required-mobile-states-filter {
		z-index: 100;
	}
	#actions-required-mobile-task-statuses-filter {
		z-index: 99;
		span:not(.no-selections) {
			text-transform: uppercase;
		}
	}
	#actions-required-mobile-task-types-filter {
		z-index: 98;
	}
}

#actions-required-mobile-top-section {
	@include if-size("desktop") {
		display: none;
	}
}

#actions-required-search-filter {
	margin-left: spacing(2);
	margin-right: spacing(2);

	@include if-size("desktop") {
		margin-right: 0;
		margin-left: auto;
	}
}

#actions-required-top-section {
	display: none;
	@include if-size("desktop") {
		display: flex;
	}

	padding-bottom: spacing(2);
	margin-top: 0;
	border-bottom: solid 1px $denim-extra-light;
	justify-content: space-between;
}

.arrow-down-icon {
	width: 20px;
	height: 20px;
}

.download-icon {
	width: 12px;
	height: 12px;

	@include if-size("desktop") {
		width: 16px;
		height: 16px;
	}
}

#actions-required-filters-bar {
	width: 100%;
	display: none;
	@include if-size("desktop") {
		display: flex;
	}

	margin-top: spacing(3);

	flex-direction: row;
	align-items: center;
	row-gap: spacing(2);
	column-gap: spacing(2);
	height: min-content;

	#actions-required-tax-years-filter {
		width: 100px;
	}

	#actions-required-states-filter {
		width: 120px;
	}

	#actions-required-task-statuses-filter {
		width: 140px;

		span:not(.no-selections) {
			text-transform: uppercase;
		}
	}

	#actions-required-task-types-filter {
		width: 220px;

		@include if-size("desktop-xl") {
			width: 320px;
		}
	}

	#actions-required-search-filter {
		width: 220px;

		@include if-size("desktop-xl") {
			width: 320px;
		}
	}
}

#actions-required-table {
	@include if-size("desktop") {
		padding-left: spacing(2);
		padding-right: spacing(2);
	}
}

#actions-required-table-view-container {
	max-width: 100%;
}

#actions-required-table-view {
	overflow-x: auto;
	@include styled-scrollbar;

	.task-status-tags {
		display: flex;
		flex-direction: row;
		gap: 12px;

		.task-status-tag {
			padding: 2px 12px;
			text-transform: uppercase;
			border-radius: 4px;
			white-space: nowrap;

			&-rust {
				@extend .task-status-tag;
				background-color: $rust;
				color: $white;
			}

			&-rust-lightest {
				@extend .task-status-tag;
				background-color: $rust-lightest;
				color: $rust;
			}

			&-sky-light {
				@extend .task-status-tag;
				background-color: $sky-light;
				color: $royal;
			}

			&-mint-light {
				@extend .task-status-tag;
				background-color: $mint-light;
				color: $kelly-dark;
			}
		}
	}

	table {
		border-collapse: collapse;

		th {
			background-color: $denim-lightest;

			label {
				white-space: nowrap;
				text-align: left;
				display: flex;
				justify-content: center;
				gap: 4px;
			}

			> div {
				padding: 12px 8px;
			}

			&:last-child label {
				text-align: center;
			}
		}

		td {
			height: 48px;
			@include if-size("tablet") {
				height: 56px;
			}
			background-color: $white;
			position: relative;

			> div {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow: hidden;
				padding: 0 8px;

				display: flex;
				align-items: center;
			}
		}

		td,
		th {
			z-index: 1;

			> div {
				border-left: solid 1px $sand;
				border-bottom: solid 1px $sand;
			}

			&:first-child > div {
				border-left: none;
			}

			&:last-child > div {
				border-right: none;
			}
		}

		td:last-child,
		th:last-child {
			position: sticky;
			right: 0;
			z-index: 2;

			> div {
				box-shadow: 0px 4px 16px 0px #7f939d2e;
			}
		}

		tr:hover {
			td {
				cursor: pointer;
				background-color: #fbfcfd;
			}
		}
	}

	.quick-link-link {
		&.semibold {
			font-weight: 700;
		}

		&.rust-dark {
			color: $rust-dark;

			svg path {
				fill: $rust-dark;
			}
		}

		&.azure {
			color: $azure;

			svg path {
				fill: $azure;
			}
		}

		&.kelly-dark {
			color: $kelly-dark;

			svg path {
				fill: $kelly-dark;
			}
		}
	}

	.quick-link-small {
		.quick-link-arrow {
			height: 6.85px !important;
			width: 8px !important;
			margin-left: 3px;
			margin-top: 4px;
		}
	}
}
