#notification-center {
	grid-column: span 1;

	@include if-size("desktop-xl") {
		grid-column: span 2;
	}
}

.notification {
	&-card {
		width: 100%;
		height: 100%;
		background-color: $white;
		border-radius: 8px;
		border: 1px solid #f8f8f2;
		box-shadow: 1px 3px 7px 2px #0532490d;
		padding: 16px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 4px;
	}

	&-address {
		color: $denim_5;

		font-weight: 600;

		font-size: 10px;
		line-height: 12px;

		@include if-size("desktop") {
			font-size: 12px;
			line-height: 16px;
		}

		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&-header {
		display: flex;
		align-items: center;
		gap: 8px;
		flex-wrap: nowrap;
	}

	&-subject {
		font-weight: 600;

		font-size: 16px;
		line-height: 20px;

		@include if-size("desktop") {
			font-size: 20px;
			line-height: 28px;
		}

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&-icon-container {
		flex: 1;

		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&-body {
		flex: 1 1 48px;
		color: $denim_5;

		font-size: 14px;
		line-height: 18px;

		@include if-size("desktop") {
			font-size: 16px;
			line-height: 24px;
		}

		display: -webkit-box;
		width: calc(100%);
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&-buttons {
		flex: 0;
		display: flex;
		gap: 14px;
	}

	&-button {
		padding: 3px 6px;
		background-color: transparent;
		border: none;
		font-family: $ff-secondary;
		border-radius: 4px;

		font-size: 16px;
		line-height: 18px;

		@include if-size("desktop") {
			font-size: 14px;
			line-height: 16px;
		}

		&:hover {
			cursor: pointer;
		}

		&.continue {
			color: $azure;
			&:hover {
				background-color: rgba($azure, 0.1);
			}
		}

		&.dismiss {
			color: $denim_5;
			&:hover {
				background-color: rgba($denim_5, 0.1);
			}
		}

		&.resolve {
			color: $rust;
			&:hover {
				background-color: rgba($rust, 0.1);
			}
		}
	}
}

.notification-announcement {
	@extend .notification-card;
}

.notification-needs-attention-reason {
	@extend .notification-card;

	background-color: $rust_1;
	border-color: $rust;
	border-width: 2px;
}
