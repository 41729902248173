.concierge-options-wrapper,
.concierge-page-wrapper,
.concierge-payment-wrapper,
.concierge-payment-modal-wrapper {
	.back-btn {
		cursor: pointer;
		display: flex;
		justify-content: start;
		align-items: center;
		width: fit-content;
		margin-bottom: 10px;

		svg {
			height: 24px;
			width: 24px;
		}

		&:hover {
			color: $azure;

			svg {
				fill: $azure;

				path {
					fill: $azure;
				}
			}
		}
	}

	.concierge-options-cards {
		margin-top: 24px;
		display: flex;
		gap: 16px;
		justify-content: space-between;
		flex-direction: column;
		max-width: 1400px;

		@include if-size("desktop") {
			flex-direction: row;
			gap: 24px;
		}

		.concierge-options-card {
			flex: 1;
			padding: 16px;
			display: flex;
			flex-direction: column;
			gap: 12px;
			border: 1px solid $slate;
			border-radius: 16px;
			height: fit-content;

			@include if-size("desktop") {
				padding: 16px 24px;
			}

			&.active-border {
				border-color: $azure;
			}

			.card-header {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.card-heading {
					font-size: 24px;
					font-weight: 800;
					line-height: 28px;
					letter-spacing: -0.01em;
					text-align: left;

					@include if-size("desktop") {
						font-size: 20px;
					}
				}

				.most-popular {
					padding: 6px 8px;
					border-radius: 8px;
					background-color: $sky-light;
					color: $royal;
				}
			}

			.pricing {
				.price {
					font-size: 32px;
					line-height: 40px;
					&.font-40 {
						font-size: 40px;
					}
					margin-bottom: 8px;

					sub {
						font-size: 14px;
						font-weight: 400;
						line-height: 8px;
					}
				}

				.strikeout-price {
					font-size: 14px;
					font-weight: 400;
					line-height: 16.1px;
					letter-spacing: -0.01em;
					text-align: left;
					color: $denim_5;
					text-decoration: line-through;
					margin-bottom: 8px;

					@include if-size("desktop") {
						height: 18px;
					}
				}

				.billing-cycle {
					font-size: 12px;
					font-weight: 400;
					line-height: 13.8px;
					letter-spacing: -0.01em;
					text-align: left;
					margin-top: 8px;

					@include if-size("desktop") {
						height: 14px;
					}
				}

				.percent-contingency {
					color: $denim;
					display: flex;
					gap: 4px;
					justify-content: start;
					align-items: center;
					font-size: 16px !important;
					margin-top: 8px;

					@include if-size("desktop") {
						height: 18px;
					}
				}
			}

			.info {
				border-top: 1px solid $slate;

				.description {
					padding-top: 8px;
					color: $denim_5;
				}
			}

			.features {
				padding: 12px;
				border-radius: 8px;
				background-color: $denim_lightest;

				.feature-heading {
					font-size: 20px;
					font-weight: 800;
					line-height: 28px;
					letter-spacing: -0.01em;
					text-align: left;
					color: $denim;
					margin-bottom: 4px;
				}

				.feature-list {
					display: flex;
					flex-direction: column;
					gap: 8px;

					.pre-requisite {
						font-size: 14px;
						font-weight: 700;
						line-height: 20px;
						letter-spacing: -0.015em;
						text-align: left;
						color: $denim;
					}

					.list-item-label {
						display: flex;
						gap: 4px;
						justify-content: start;
						align-items: center;

						span {
							font-size: 12px;
							font-weight: 400;
							line-height: 18px;
							letter-spacing: -0.02em;
							text-align: left;
							color: $denim;
						}

						.green-dot {
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background-color: $kelly-dark;
						}
					}

					.list-item {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.list-item-label {
							display: flex;
							justify-content: center;
							align-items: center;
							gap: 4px;

							.icon {
								width: 16px;
								height: auto;
								padding-top: 2px;
							}

							.subtext {
								font-size: 8px;
								font-weight: 400;
								line-height: 18px;
								letter-spacing: -0.02em;
								text-align: left;
							}
						}

						.list-item-value {
							padding: 2px 6px;
							background-color: $kelly_lightest;
							color: $kelly-dark;
							border-radius: 4px;
							font-size: 10px;
							font-weight: 700;
							line-height: 11.5px;
							letter-spacing: -0.02em;
							text-align: right;
							white-space: nowrap;
						}
					}
				}

				.results {
					margin-top: 4px;
					padding-top: 4px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-top: 1px solid $slate;

					.list-item-label {
					}

					.list-item-value {
						background-color: $sky-light;
						border-radius: 8px;
						padding: 6px 8px;
						color: $royal;
					}
				}
			}
		}
	}

	.skip-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 24px;

		p {
			color: $azure;
			cursor: pointer;
		}
	}

	.btn-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 24px;
		flex-direction: column;

		.cancel-subscription {
			margin-top: 16px;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.property-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.property-grid {
			display: flex;
			width: fit-content;
			justify-content: center;
			align-items: center;
		}

		.cancel-subsciption-modal-wrapper {
			width: 90%;
			max-width: 90%;
			margin: 0 auto;
			@include if-size("desktop") {
				width: 500px;
			}
			.confirmation-modal-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 8px;
				text-align: center;
				.warning-img-wrapper {
				}

				.btn-container {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}
				.close-btn {
					margin-top: 16px;
				}
			}
		}
	}
}

.concierge-confirmation-wrapper,
.concierge-confirmation-content {
	display: flex;
	flex-direction: column;
	gap: 16px;
	justify-content: center;
	align-items: center;

	.img-wrapper {
		margin-bottom: 24px;
		width: 90%;
		max-width: 600px;

		svg {
			width: 100%;
		}
	}
}

.concierge-payment-wrapper {
	display: flex;
	gap: 24px;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@include if-size("desktop") {
		align-items: flex-start;
		flex-direction: row;
	}

	.plan-details-card {
		box-shadow: 0px 4px 24px 0px #7f939d2e;
		border-radius: 16px;
		width: 100%;
		padding: 16px;
		position: relative;

		@include if-size("desktop") {
			width: 350px;
			max-width: 100%;
			padding: 24px;
			flex-shrink: 0;
		}

		.show-hide-toggle-btn {
			position: absolute;
			right: 8px;
			top: 16px;
			cursor: pointer;
			text-decoration: underline;
			color: $azure;
		}

		.card-title {
			font-weight: 800;
			margin-bottom: 16px;
		}

		.card-body {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.plan-pricing {
				display: flex;
				flex-direction: column;
				gap: 8px;
				.strike-out-amount {
					color: $rust;
					margin-bottom: 4px;

					.strike-out {
						text-decoration: line-through;
					}

					.xs {
						font-size: 14px;
					}
				}

				.plan-amount {
					color: $azure;

					&.font-40 {
						font-size: 40px !important;
					}

					.xs {
						font-size: 14px;
					}
				}

				.contingency {
					color: $denim;
					display: flex;
					gap: 4px;
					justify-content: start;
					align-items: center;
					font-size: 12px !important;
					margin-top: 8px;

					@include if-size("desktop") {
						height: 18px;
					}
				}

				.contingency-box {
					margin-bottom: 8px;
				}
			}

			.concierge-img-container {
				margin-bottom: -8px;
				svg {
					flex: 1;
					width: 100%;
					height: auto;
					max-height: 145px;
				}
			}
		}

		.card-footer {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.features {
				padding: 12px;
				border-radius: 8px;
				background-color: $denim_lightest;

				.feature-heading {
					font-size: 20px;
					font-weight: 800;
					line-height: 28px;
					letter-spacing: -0.01em;
					text-align: left;
					color: $denim;
					margin-bottom: 4px;
				}

				.feature-list {
					display: flex;
					flex-direction: column;
					gap: 8px;

					.pre-requisite {
						font-size: 14px;
						font-weight: 700;
						line-height: 20px;
						letter-spacing: -0.015em;
						text-align: left;
						color: $denim;
					}

					.list-item-label {
						display: flex;
						gap: 4px;
						justify-content: start;
						align-items: center;

						span {
							font-size: 12px;
							font-weight: 400;
							line-height: 18px;
							letter-spacing: -0.02em;
							text-align: left;
							color: $denim;
						}

						.green-dot {
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background-color: $kelly-dark;
						}
					}

					.list-item {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.list-item-label {
							display: flex;
							justify-content: center;
							align-items: center;
							gap: 4px;

							.icon {
								width: 16px;
								height: auto;
								padding-top: 2px;
							}

							.subtext {
								font-size: 8px;
								font-weight: 400;
								line-height: 18px;
								letter-spacing: -0.02em;
								text-align: left;
							}
						}

						.list-item-value {
							padding: 2px 6px;
							background-color: $kelly_lightest;
							color: $kelly-dark;
							border-radius: 4px;
							font-size: 10px;
							font-weight: 700;
							line-height: 11.5px;
							letter-spacing: -0.02em;
							text-align: right;
							white-space: nowrap;
						}
					}
				}

				.results {
					margin-top: 4px;
					padding-top: 4px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-top: 1px solid $slate;

					.list-item-label {
					}

					.list-item-value {
						background-color: $sky-light;
						border-radius: 8px;
						padding: 6px 8px;
						color: $royal;
					}
				}
			}
		}
	}

	.payment-card-wrapper {
		max-width: 600px;
		.warning-banner-wrapper {
			padding: 8px;
			gap: 8px;
			border-radius: 8px;
			border: 1px solid $azure;
			background: $sky;
			display: flex;
			flex-direction: column;
			gap: 4px;
			margin-bottom: 16px;
			.warning-banner-header {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 4px;
				svg {
					width: 24px;
					height: 24px;
					fill: $royal;
					path {
						fill: $royal;
					}
				}
			}
		}
		#pay-invoice-form-card {
			padding: 24px;
			background: white;
			box-shadow: 0px 4px 24px 0px #7f939d2e;

			.pay-card-heading {
				@extend .xl;
			}
		}
	}

	.payment-success-card {
		padding: 48px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.img-wrapper {
			margin-bottom: 24px;
			width: 90%;
			max-width: 600px;

			svg {
				width: 100%;
			}
		}

		.next-btn {
			margin-top: 24px;
		}
	}
}

.concierge-page-payment-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.contingency-box {
	border-radius: 8px;
	background-color: $denim-lightest;
	border: solid 1px $slate;
	padding: 6px 12px;
	display: flex;
	gap: 4px;
	align-items: center;

	.contingency-tooltip {
		.tooltip3-text {
			margin-top: 0;
			align-items: flex-end;

			.tooltip3-icon {
				svg {
					margin-left: 0.5ch;

					path {
						fill: $denim-medium;
					}
				}

				.triangle-top {
					left: 4.5px;
				}
			}

			p {
				@extend .xs;
				font-weight: 700;
				color: $denim-medium;
				margin-bottom: 1px;
			}
		}
	}
}

.saving {
	display: flex;
	gap: 4px;
	justify-content: start;
	align-items: flex-start;
	background-color: $sky-light;
	padding: 8px 12px;
	border-radius: 8px;

	.shield-icon {
		width: 16px;
		height: auto;
		margin-top: 4px;

		path {
			fill: $azure;
		}
	}

	.savings-content {
		display: flex;
		justify-content: start;
		flex-direction: column;
		gap: 2px;
		color: $azure;

		.savings-header {
			color: $azure;
		}

		.savings-text {
			color: $azure;
		}
	}
}

#concierge-payment-modal-wrapper {
	.account-property-modal-card {
		@include if-size("desktop") {
			max-width: 100%;
			margin-top: 0 !important;
		}
		.concierge-payment-modal-content-wrapper {
			padding: 8px;
			@include if-size("desktop") {
				padding: 24px;
			}
			.btn-container {
				margin-top: 0;
			}
		}
	}
}

.concierge-options-tab {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;

	.tab-label {
		display: none;

		@include if-size("desktop") {
			display: block;
		}
	}

	.tab-option {
		padding: 3px 4px;
		background-color: $denim_lightest;
		border-radius: 32px;
		display: flex;

		.tab {
			border-radius: 32px;
			color: $denim_5;
			padding: 4px 16px;
			cursor: pointer;
			border: 1px solid transparent;

			&.active {
				color: $azure;
				background-color: $sky;
				border: 1px solid $azure;
			}
		}
	}
}
