/* Colors */

// OLD
$white: #ffffff;
$black: #313131;
$teal: #63b7af;
$mid-teal: #346874;
$navy: #35495e;
$coral: #ee8572;
$sky: #d5eaff;
$cloud: #fbfbfb;

// NEW
$denim: #053249;
$denim_5: #7f939d; //denim-half
$denim_lightest: #f7f9fc;
$denim-lightest: #f7f9fc;
$denim_dark: #002335;
$azure: #1c7ce5;
$royal: #1a5ca3;
$seafoam: #b3e6d7;
$powder: #8cb3dc;
$lemon: #e1e78b;
$mint: #99edba;
$mint-light: #e6f7f2;
$terracotta: #da845f;
$sun: #f8d76a;
$blonde: #ffe6a1;
$blonde-light: #fcf4db;
$blonde-lightest: #fcf4db;
$midnight: #252525;
$sand: #eaeae4;
$sand-light: #ecece7;
$marshmallow: #f3f3eb;
$cloud: #f7f7f0;
$cotton: #fbfbf8;
$kelly: #57c975;
$kelly-dark: #249241;
$kelly-light: #e6f7f2;
$kelly-lightest: #e6f7f2;
$slate: #d4dee3;
$rust: #ce6549;
$rust-medium: #d8846d;
$rust-dark: #a42300;
$rust-lightest: #f7e6e2;
$rust-lightest-64: #f7e6e2a3;
$rust_1: #fcf6f5;
$salmon: #f4997d;
$light-blue: #d5eaff;
$sunflower: #efb920;
$sunflower-light: #ffe6a1;
$sunflower-lightest: #fcf4db;
$sunflower-dark: #bd7d00;
$denim-medium: #566c77;
$denim-extra-light: #bbccd4;
$sky-light: #edf6ff;
$gold: #bd7d00;
$denim-black: #000a0f;

$ff-primary: "moret";
$ff-secondary: "sofia-pro";
$ff-tertiary: "sofia-pro";

$primary-box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
$input-border-default: #b1b1bb;
$input-border-active: $denim;

$mobile-footer-height: 75px;
$mobile-header-height: 49px;
$mobile-properties-header-height: 42px;

$property-portal-navigation-bar-expanded-width: 220px;
$property-portal-navigation-bar-unexpanded-width: 60px;
$property-portal-page-padding-top: 16px;
$property-portal-page-padding-bottom: 32px;
$property-portal-top-navigation-bar-height: 44px;
