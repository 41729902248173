@mixin nav-text {
	font-family: $ff-tertiary;
	font-size: 14px;
	line-height: 18px;

	@include if-size("tablet") {
		font-size: 16px;
		line-height: 20px;
	}
}

#account {
	min-height: 100%;
	display: flex;
	flex-direction: column;

	@include if-size("desktop") {
		overflow-x: hidden;
	}

	#mobile-nav-header {
		position: sticky;
		z-index: 1000;
		top: 0;

		#mobile-nav-header-top {
			height: 50px;
			padding: spacing(1) 0;
			background-color: $slate;

			@include if-size("desktop") {
				display: none;
			}

			.grid {
				padding: 0 spacing(2);
				max-width: 100vw;
				@include if-size("tablet") {
					grid-template-columns: repeat(8, 1fr);
				}
			}

			#mobile-nav-header-logo {
				grid-column: 2 / -2;
				display: flex;
				justify-content: center;

				svg {
					max-width: 100px;
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	#mobile-nav-footer {
		position: sticky;
		bottom: 0;
		width: 100%;
		padding: spacing(1) 0;
		background-color: $denim;
		z-index: 10000;

		@include if-size("desktop") {
			display: none;
		}

		.nav-icon {
			grid-column: span 1;
			padding: spacing(1);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			max-width: 88px;
			width: 100%;
			margin: 0 auto;

			@include if-size("tablet") {
				grid-column: span 2;
			}

			.label {
				margin-top: 3px;
				color: $cloud;
				font-size: 10px;
			}

			&-active {
				@extend .nav-icon;
				background-color: $azure;
				border-radius: 8px;
				height: 100%;
			}

			&-disabled {
				@extend .nav-icon;
				opacity: 0.4;
			}
		}
	}

	$transition-time: 150ms;

	.desktop-nav-container {
		position: fixed;
		top: 0;
		left: 0;
		width: fit-content;
		z-index: 1000;

		/*
        // &:hover {
        //     transform: scaleX(3);
        //     transform-origin: left;
        // }
        */

		&-expanded {
			@extend .desktop-nav-container;
		}
	}

	// Commented out lines are from original CSS animation. Keeping them just in case we ever want to go back.
	#desktop-nav {
		height: 100vh;
		// width: $property-portal-navigation-bar-unexpanded-width;
		padding: spacing(4) spacing(1) spacing(3);
		background-color: $denim;
		display: none;
		flex-direction: column;
		position: relative;
		// transition: all $transition-time linear;

		&-expanded {
			@extend #desktop-nav;
			// width: $property-portal-navigation-bar-expanded-width;
		}

		@include if-size("desktop") {
			display: flex;
		}

		.nav-icon {
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: spacing(1);
			margin: spacing(1) 0;
			overflow: hidden;
			width: 100%;

			&:not(:last-of-type) {
				margin-bottom: spacing(1);
			}

			.icon-container {
				display: flex;
				justify-content: center;
				width: 24px;
				height: 24px;

				svg {
					height: 24px;
					width: 24px;
				}
			}

			.nav-icon-title {
				@include nav-text;
			}

			p {
				color: $white;
				margin-left: spacing(2);
				white-space: nowrap;
			}

			&-active {
				@extend .nav-icon;
				background-color: $azure;
				border-radius: 8px;
			}
		}

		.desktop-nav-bottom-links {
			margin-top: auto;
		}

		#desktop-nav-logo {
			cursor: pointer;
			margin-bottom: spacing(2);
			width: fit-content;

			svg {
				height: 28px;
				width: 44px;
				padding: 0 spacing(1);
			}
		}

		#desktop-nav-properties {
			cursor: pointer;
			display: flex;
			width: 100%;
			margin: spacing(1) 0;
			padding: spacing(1);
			align-items: center;

			p {
				@include nav-text;
				width: calc(100% - 24px);
				white-space: nowrap;
				color: $white;
				margin-left: spacing(1);
			}

			svg {
				fill: $cloud;
				height: 28px;
				width: 24px;
				min-height: 28px;
				min-width: 24px;
				margin: 0 2px;
			}

			&-active {
				@extend #desktop-nav-properties;
				background-color: $azure;
				border-radius: 8px;
			}
		}

		.property-list-scroll {
			border-bottom: 2px solid $white;
			border-top: 2px solid $white;
		}

		#desktop-nav-properties-list {
			margin-top: spacing(1);
			padding: spacing(2) 0;
			position: relative;
			overflow-y: hidden;

			&-expanded {
				@extend #desktop-nav-properties-list;
				@include styled-scrollbar;
			}

			.vertical-step-counter {
				.step {
					.step-label {
						display: none;
					}
				}
			}

			.desktop-nav-property {
				position: relative;
				height: 24px;
				padding-left: 14px;
				display: flex;
				align-items: center;
				cursor: pointer;

				&:not(:last-of-type) {
					margin-bottom: spacing(4);
				}

				p {
					@include nav-text;
					display: none;
					color: $white;
					margin-left: spacing(1);
					margin-bottom: 4px;
				}

				&:not(:last-of-type) {
					.desktop-nav-property-line {
						position: absolute;
						width: 2px;
						top: 16px;
						height: calc(100% + 24px);
						left: 18px;
						background-color: $white;
						z-index: 1;
					}
				}

				&-expanded {
					@extend .desktop-nav-property;
					p {
						display: block;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}

			.active-property {
				background-color: $azure;
				border-radius: 8px;

				.desktop-nav-property-dot {
					background-color: $white;
				}
			}

			.desktop-nav-property-dot {
				min-width: 10px;
				min-height: 10px;
				border: 2px solid $white;
				background-color: $denim;
				border-radius: 50%;
				z-index: 105;

				&.desktop-nav-property-dot-terracotta {
					background-color: $terracotta;
					border-color: $terracotta;
				}
			}
		}

		#desktop-nav-add-property {
			height: 32px;
			margin-top: spacing(3);
			margin-bottom: spacing(4);
			display: flex;
			align-items: center;
			cursor: pointer;

			svg {
				fill: $white;
			}
			p {
				color: $white;
				margin-bottom: 4px;
				margin-left: spacing(1);
				@include nav-text;
			}
		}
	}

	#desktop-nav-expanded {
		#desktop-nav-logo {
			svg {
				width: 130px;
			}
		}
	}

	#desktop-nav-expand-btn-container {
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		right: 0;
		top: 34px;
		background-color: $white;
		transform: translateX(40%) rotate(45deg);
		transition: transform 250ms ease-out;

		svg {
			cursor: pointer;
			fill: $denim;
			height: 24px;
			width: 24px;
			transform: translateX(-2px) translateY(-2px);
		}

		&-expanded {
			@extend #desktop-nav-expand-btn-container;
			transform: translateX(40%) rotate(90deg);
		}
	}

	@keyframes blur-up {
		from {
			background-color: rgba($white, 0);
		}
		to {
			background-color: rgba($white, 0.5);
		}
	}

	#account-page-container {
		@include styled-scrollbar;
		position: relative;
		flex: 1;

		@include if-size("desktop") {
			// padding: spacing(8) 0;
		}

		#account-page-overlay {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 999;
			background-color: rgba($white, 0.5);
			backdrop-filter: blur(4px);
			animation: blur-up $transition-time;

			@include if-size("desktop") {
				display: initial;
			}
		}
	}

	#account-page-header {
		grid-column: 1 / -1;
		display: flex;

		@include if-size("desktop") {
			grid-column: 2 / -2;
		}
	}

	#account-page-heading {
		grid-column: 1 / -1;
		display: flex;
		flex-direction: column;
		border-bottom: 2px solid $sand;
		padding: spacing(2) 0;

		@include if-size("desktop") {
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: center;
			grid-column: 2 / -2;
		}

		&.account-page-heading-no-show-on-desktop {
			display: block;
			@include if-size("desktop") {
				display: none;
			}
		}
	}

	#account-page-breadcrumb-container {
		display: flex;
		align-items: center;
		margin-top: 10px;
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 2 / -2;
		}

		p {
			white-space: nowrap;
		}

		.account-page-breadcrumb:last-of-type > p {
			font-weight: 600;
		}
	}

	.account-page-breadcrumb {
		display: flex;
		align-items: center;

		* {
			margin-right: 6px;

			@include if-size("desktop") {
				margin-right: 10px;
			}
		}

		.clickable-breadcrumb {
			cursor: pointer;
			text-decoration: underline;
		}

		p {
			font-size: 10px;
			@include if-size("tablet") {
				font-size: 12px;
			}
		}

		svg {
			margin-top: 1px;
			min-width: 4px;
			height: 8px;
			fill: $denim;

			@include if-size("tablet") {
				margin-top: 2px;
				height: 10px;
				min-width: 8px;
			}
		}
	}

	#account-page-title {
		grid-column: 3 / -3;
	}
}

#mobile-nav-header-properties {
	background-color: $cloud;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	@include if-size("desktop") {
		display: none;
	}

	#property-select-container {
		padding: spacing(1) 0;
		grid-column: 1 / -1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		p {
			display: flex;
			align-items: center;
			padding-bottom: 4px;
			white-space: nowrap;
		}

		#property-select-icon {
			height: 16px;
			margin-top: 3px;
			margin-right: spacing(1);

			path {
				fill: $denim;
			}
		}

		#property-down-caret {
			height: 6px;
			width: 12px;
			margin-right: 10px;
		}
	}
}

#mobile-nav-header-help {
	padding: 4px;
	svg {
		fill: $white;
		&:hover {
			cursor: pointer;
		}
	}
}

#mobile-nav-header-right {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 16px;

	grid-column: 4;
	@include if-size("tablet") {
		grid-column: 8;
	}

	div {
		width: fit-content;
	}
}

#mobile-nav-header-home {
	grid-column: 1;
	fill: $white;
	display: flex;
	align-items: center;

	svg {
		width: 21px;
		height: 21px;
	}

	.mobile-nav-header-home-container {
		padding: 4px;
		border-radius: 4px;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: spacing(0.5);

		&-back-icon {
			padding: 2px;
			width: 12px;
			height: 12px;
			fill: $white;
			margin-top: 2px;
		}

		p {
			color: $white;
			letter-spacing: 0.05em;
		}

		&:hover {
			cursor: pointer;
		}
	}

	.mobile-nav-header-home-container-selected {
		@extend .mobile-nav-header-home-container;
		background-color: $azure;
	}
}

#mobile-nav-header-settings-icon-container {
	padding: 4px;

	&:hover {
		cursor: pointer;
	}

	&-active {
		@extend #mobile-nav-header-settings-icon-container;
		background-color: $azure;
		border-radius: 4px;
	}
}

#property-expanded-container {
	grid-column: 1 / -1;

	.mobile-nav-property {
		width: 100%;
		background-color: $white;
		display: flex;
		border-radius: 8px;
		overflow: hidden;
		cursor: pointer;

		&:not(:last-of-type) {
			margin-bottom: spacing(2);
		}

		&-active {
			@extend .mobile-nav-property;
			background-color: $azure;

			p {
				color: $white;
			}
		}

		.mobile-nav-property-image {
			width: 25%;
			background-size: cover;
			background-position: center;
		}

		.mobile-nav-property-text {
			padding: spacing(3);
			flex: 1;
			min-width: 0;
			// https://css-tricks.com/flexbox-truncated-text/

			p.lg {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	.button-v2 {
		margin: spacing(4) auto;
	}

	@include if-size("desktop") {
		display: none;
	}
}

#mobile-properties-underlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: $cloud;

	@include if-size("desktop") {
		display: none;
	}
}

#properties-page {
	padding: $property-portal-page-padding-top spacing(2)
		$property-portal-page-padding-bottom;
	background-color: $white;

	@include if-size("desktop") {
		min-height: calc(100vh - $property-portal-top-navigation-bar-height);
		display: flex;
		flex-flow: column;
	}
}

#account-mobile-account-nav {
	padding: spacing(4) 0;
}

#account-invoice-nav {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#account-invoice-due-pill {
	background-color: $rust-lightest;
	padding: 4px 6px 6px;
	width: fit-content;
	border-radius: 6px;
	margin-left: 20px;

	span {
		color: $rust-dark !important;
		white-space: nowrap;
	}
}

#forceful-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999999;
	background: rgba(#053249, 0.75);
	overflow: auto;
}

#forceful-modal-content {
	min-height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
	padding: spacing(8) 0;
}

#premium-pricing-modal {
	padding: spacing(6) spacing(1);
	@include if-size("desktop") {
		padding: spacing(4) spacing(8);
	}
}

#premium-info-modal,
#premium-pricing-modal {
	overflow-x: visible;
	padding: spacing(6) spacing(2);
	@include if-size("desktop") {
		padding: spacing(4) spacing(8);
	}

	#premium-info-modal-title,
	#premium-pricing-modal-title {
		margin: 0 spacing(1) 4px spacing(1);

		@include if-size("desktop") {
			margin: 0 0 4px 0;
		}
	}

	#premium-info-modal-subtitle,
	#premium-pricing-modal-subtitle {
		margin: 0 spacing(1) 0 spacing(1);

		@include if-size("desktop") {
			margin: 0;
		}
	}

	#premium-pricing-comparison-table {
		margin-top: spacing(2);
	}

	#premium-info-modal-disclaimer,
	#premium-pricing-modal-disclaimer {
		margin: spacing(2) 0;
	}

	> button {
		display: block;
		@include if-size("desktop") {
			display: none;
		}
	}

	> button:nth-of-type(1) {
		margin-top: spacing(4);
	}

	> button:nth-of-type(2) {
		margin-top: spacing(2);
	}
}

#downgrade-confirmation {
	@include if-size("desktop") {
		width: 30%;
		margin: 0 auto;
		min-width: 600px;
	}

	padding: spacing(4) spacing(2);
	background-color: $sunflower-lightest;
	border: solid 3px $sunflower;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.sm {
		margin-top: spacing(2);
	}

	button.button-v2.primary {
		margin-top: spacing(4);
	}

	button.button-v2.primary-outline-filled {
		margin-top: spacing(2);
	}
}

#upgrade-confirmation {
	.premium-diamond {
		width: 53px;
		height: 46px;
	}

	@include if-size("desktop") {
		width: 30%;
		margin: 0 auto;
		min-width: 600px;
	}

	padding: spacing(4) spacing(2);
	background-color: $sky-light;
	border: solid 3px $azure;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.sm {
		margin-top: spacing(2);
		text-align: center;
	}

	button.button-v2.primary {
		margin-top: spacing(4);
	}

	button.button-v2.primary-outline {
		margin-top: spacing(2);
	}
}

#contingency-breakdown {
	margin-top: spacing(4);

	label.label {
		color: $denim_5;
	}
}

.property-preview-list {
	.property-preview-cards {
		margin-top: spacing(1);

		list-style: none;
		display: flex;
		flex-direction: column;
		gap: spacing(2);

		.property-preview-card {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: spacing(2);

			padding: spacing(2);
			border-radius: 16px;
			box-shadow: 1px 3px 7px 2px #0532490d;
			border: 1px solid #f8f8f2;

			.property-preview-card-contingency {
				border-radius: 8px;
				height: min-content;
				padding: 6px 12px;

				p:not(.tooltip2-body-text) {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 8px;
					white-space: nowrap;
				}

				&.essentials {
					background-color: $sunflower-lightest;
					border: solid 2px $sunflower;

					> p {
						color: $denim_dark;
					}
				}

				&.premium {
					background-color: $sky-light;
					border: solid 2px $azure;

					> p {
						color: $royal;

						.premium-diamond {
							margin-top: 4px;
							width: 16px;
							height: 16px;
						}
					}
				}

				.tooltip2 {
					.info-icon {
						width: 16px;
						height: 16px;

						path {
							fill: $denim_dark;
						}
					}
				}
			}
		}
	}

	.property-preview-list-load-more-container {
		margin-top: spacing(2);

		&:hover {
			cursor: pointer;
		}
	}
}

#tier-change-success-card {
	position: absolute;
	background: $white;
	border-radius: 16px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 16px;
	padding: spacing(4) spacing(8);
	width: fit-content;
	overflow: hidden;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	.premium-diamond {
		width: 56px;
		height: 48px;
	}
}

#membership-benefits {
	padding: spacing(4) 20px;
	background-color: $sky-light;
	border: solid 3px $azure;
	border-radius: 16px;

	#membership-benefits-title {
		display: flex;
		align-items: flex-end;
		gap: 4px;
	}

	.premium-diamond {
		width: 27.65px;
		height: 24px;
	}

	table {
		margin-top: spacing(4);
		tr td {
			width: 100%;

			&:nth-of-type(2) {
				text-align: right;
			}
		}
	}
}

#edit-membership {
	margin-top: 20px;
	width: 100%;

	display: flex;
	justify-content: flex-end;

	p {
		display: flex;
		align-items: flex-end;
		gap: 4px;

		font-size: 12px;
		line-height: 14px;
		@include if-size("desktop") {
			font-size: 14px;
			line-height: 16px;
		}

		color: $azure;
		width: min-content;
		white-space: nowrap;
		letter-spacing: 0.05em;
		&:hover {
			cursor: pointer;
		}
	}

	.edit-icon {
		fill: $azure;
		width: 12px;
		height: 12px;
	}
}
