.beta-tag-container {
	position: relative;
	width: fit-content;

	.beta-tag {
		width: fit-content;
		text-transform: uppercase;
		background-color: $kelly;
		color: $white;
		border-radius: 2px;

		margin-top: 6px;
		padding: 2px 8px;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.08em;
	}

	.beta-tooltip {
		.tooltip2-body,
		.triangle-top {
			background-color: $mint;
		}

		.tooltip2-body {
			z-index: 100000;

			label,
			p {
				color: $black;
			}

			.quick-link-small {
				margin-top: spacing(3);
				white-space: nowrap;
			}

			.quick-link-arrow {
				fill: $black;
			}
		}
	}
}
