#properties-card-view {
	@include if-size("desktop") {
		margin-top: spacing(6);
	}
}

.properties-card-view-section-header {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid $sand;
	padding-bottom: spacing(0.5);
	margin-bottom: spacing(4);
	margin-top: spacing(2);

	&-justify-start {
		@extend .properties-card-view-section-header;
		justify-content: flex-start;
	}
}

.properties-card-view-section-header-needs-attention {
	padding-bottom: spacing(2);
}
