#debt-card {
	.debt-card-header {
		margin-bottom: 16px;
		> p.bold {
			font-weight: 800;
			margin-bottom: 8px;
			@include if-size("desktop") {
				margin-bottom: 12px;
			}
		}
	}

	.debt-progress-chart {
		height: 150px;

		@include if-size("desktop-xl") {
			height: 200px;
		}
	}

	.detail-list {
		display: flex;
		flex-direction: column;
		gap: spacing(2);
		list-style: none;
		padding: spacing(2);

		background-color: $denim_lightest;
		border-radius: 8px;

		li {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 4px;
		}

		&.paid-off-theme {
			background: linear-gradient(
				180deg,
				rgba(153, 237, 186, 0.3) -5.43%,
				rgba(213, 234, 255, 0) 100%
			);
		}

		.hand-icon {
			font-size: 48px;
			font-weight: 600;
			line-height: 55.82px;
			letter-spacing: 0.01em;
			text-align: center;
		}

		.paid-off-text {
			color: $kelly-dark;
		}
	}

	&.skeleton {
		.status-row {
			@include shimmer-bg-white();
			width: 100%;
			height: 80px;
		}

		.detail-list {
			@include shimmer-bg-white();
			width: 100%;
			height: 100px;
		}
	}
}
