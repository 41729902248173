#invoice-profile-card-container {
	grid-column: span 1;
	border-radius: 16px;
	padding: spacing(2);
	width: 100%;

	@include if-size("desktop-xl") {
		grid-column: span 2;
	}

	&.multiple-banners-desktop {
		flex-wrap: wrap;
		.desktop-hidden {
			display: flex !important;
		}

		.mobile-hidden {
			display: none !important;
		}

		.invoice-profile-card-text-content {
			flex: 1 1;
			justify-content: center;
		}

		#invoice-profile-card {
			flex-wrap: wrap;
		}
	}
	a {
		width: 100%;
	}
	#invoice-profile-card {
		grid-column: span 1;
		border-radius: 16px;
		display: flex;
		flex-wrap: wrap;
		column-gap: 12px;
		row-gap: 8px;
		width: 100%;
		height: 100%;
		@include if-size("desktop") {
			flex-wrap: nowrap;
		}
	}

	svg {
		width: 48px;
		height: 48px;
		@include if-size("desktop") {
			width: 64px;
			height: 64px;
			flex: 0 0 64px;
		}
	}

	&.invoice-profile-card-green {
		border: 1px solid $kelly;
		background: $kelly-light;
		box-shadow: -44px 319px 90px 0px rgba(171, 171, 171, 0),
			-28px 204px 82px 0px rgba(171, 171, 171, 0.01),
			-16px 115px 70px 0px rgba(171, 171, 171, 0.05),
			-7px 51px 51px 0px rgba(171, 171, 171, 0.09),
			-2px 13px 28px 0px rgba(171, 171, 171, 0.1);
	}

	&.invoice-profile-card-red {
		border: 1px solid $rust;
		background: $rust-lightest;
		box-shadow: -44px 319px 90px 0px rgba(171, 171, 171, 0),
			-28px 204px 82px 0px rgba(171, 171, 171, 0.01),
			-16px 115px 70px 0px rgba(171, 171, 171, 0.05),
			-7px 51px 51px 0px rgba(171, 171, 171, 0.09),
			-2px 13px 28px 0px rgba(171, 171, 171, 0.1);
	}

	.invoice-profile-card-text-content {
		flex: 1 1;
		display: flex;
		flex-direction: column;

		@include if-size("desktop") {
			flex: 1 1 100%;
		}
	}

	.invoice-profile-card-body {
		flex: 1 1 100%;
		text-align: left;

		&.mobile-hidden {
			display: none;
			@include if-size("desktop") {
				display: flex;
			}
		}

		&.desktop-hidden {
			display: flex;
			@include if-size("desktop") {
				display: none;
			}
		}
	}

	#account-complete-profile-card-button {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 16px;

		.button-v2 {
			justify-content: flex-end;
		}
	}
}
