#refinance-monitoring-card {
	position: relative;
	background-color: $white;
	box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	border: solid 1px $slate;
	border-radius: 16px;
	padding: spacing(3);
	height: min-content;
	.refinance-monitoring-btn {
		margin-top: 10px;
	}
}
