$pricing-calculator-border: 1px solid $powder;
$pricing-calculator-secondary-border: 1px solid $denim-5;
#pricing-page {
	#pricing-hero-section {
		background: $sky-light;
		padding: 0 0 spacing(8);

		@include if-size("desktop") {
			padding: spacing(6) 0 spacing(8);
		}

		.grid {
			align-items: center;
		}

		#pricing-map-img {
			grid-column: 1 / -1;
			margin: 0 auto spacing(2);
			max-width: 70%;
			height: auto;

			@include if-size("desktop") {
				width: 100%;
				max-width: 390px;
				grid-column: -5 / -1;
				margin: 0;
				grid-row: 1;
			}
		}

		#pricing-title-container {
			grid-column: 1 / -1;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include if-size("desktop") {
				grid-row: 1;
				grid-column: 1 / -6;
			}

			#pricing-cta {
				margin: auto;
				@include if-size("desktop") {
					margin: 0;
				}
			}
		}

		#pricing-body {
			grid-column: 1 / -1;
			margin-bottom: 16px;

			@include if-size("desktop") {
				grid-column: 6 / -1;
				height: fit-content;
				margin-bottom: 24px;
				br {
					display: none;
				}
			}
		}

		#pricing-value-prop-card-container {
			display: flex;
			gap: spacing(2);
			margin-top: spacing(2);

			@include if-size("desktop") {
				margin-top: spacing(4);
			}

			.pricing-value-prop-card {
				flex: 1;
				background: rgba(255, 255, 255, 0.5);
				max-width: 190px;
				border: 1px solid $slate;
				border-radius: 16px;
				padding: spacing(2);
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				gap: 6px;

				img {
					width: 40px;
					height: 40px;

					@include if-size("desktop") {
						width: 64px;
						height: 64px;
					}
				}
			}
		}

		#pricing-calculator-input-container {
			display: flex;
			gap: spacing(2);

			.search-input-container {
				flex: 1;
			}
		}
	}

	#pricing-calculator {
		padding: spacing(4) 0;

		@include if-size("desktop") {
			padding: spacing(9) 0;
		}

		#pricing-calculator-input-section {
			grid-column: 1 / -1;

			@include if-size("tablet") {
				grid-column: 2/-2;
			}

			@include if-size("desktop") {
				grid-column: 4 / -4;
				max-width: 516px;
				margin: 0 auto;
			}

			h3 {
				text-align: center;
				margin-bottom: spacing(4);
				// font-size: 48px;

				// @include if-size
			}

			button > span {
				white-space: nowrap;
			}

			#pricing-calculator-input-container {
				display: flex;
				gap: spacing(2);
				flex-direction: column;
				align-items: center;

				@include if-size("desktop") {
					flex-direction: row;
					justify-content: space-between;
					padding: 0 8px;
				}

				.search-input-container {
					width: 100%;
				}

				button {
					width: 100%;

					@include if-size("desktop") {
						width: fit-content;
					}
				}
			}
		}

		#pricing-georgia-freeze-card {
			grid-column: 1/-1;
			margin-top: spacing(2);
			padding: spacing(2) spacing(3);
			background: $sky-light;
			border: 1px solid $powder;
			border-radius: 16px;
		}

		#pricing-georgia-freeze-pill {
			border: 1px solid $azure;
			background: $sky;
			padding: spacing(1) spacing(2);
			border-radius: 100px;
			width: fit-content;
			margin: 0 auto spacing(1);
		}

		.pricing-calculator-button-outlined {
			border-radius: 50px;
			border: 2px solid $azure;
			background: $white;
			color: $azure;
		}
	}
	#pricing-calculator-results-heading {
		grid-column: 1 / -1;
		margin: spacing(3) 0;
		text-align: center;

		@include if-size("desktop") {
			grid-column: 4 / -4;
		}
	}

	#pricing-calculator-results-footer {
		grid-column: 1 / -1;
		text-align: center;
		margin-top: spacing(4);

		@include if-size("desktop") {
			grid-column: 4 / -4;
			margin-top: spacing(5);
		}
	}

	#pricing-calculate-bill-breakdown {
		grid-column: 1 / -1;
		margin-top: 32px;
		padding: 32px 0 0;
		border-top: 1px solid $sand;

		@include if-size("tablet") {
			margin-top: 56px;
			padding: 56px 0 0;
		}

		#pricing-calculate-your-bill {
			margin-top: 0;
		}
	}

	.pricing-calculate-bill-breakdown-badge {
		background-color: $sunflower-light;
		padding: 8px 12px;
		display: inline-block;
		border-radius: 8px;

		p {
			color: $gold;
		}
	}

	.pricing-calculate-bill-breakdown-title-section {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column;
		gap: 16px;

		@include if-size("desktop") {
			flex-direction: row;
			gap: 0;
			align-items: center;
		}
	}

	#pricing-calculator-loading-container {
		grid-column: 1 / -1;
		display: flex;
		gap: spacing(2);
		margin-top: spacing(6);

		@include if-size("desktop") {
			grid-column: 4 / -4;
		}

		.pricing-calculator-loading-1 {
			@include shimmer;
			height: 150px;
			width: 200px;
			border-radius: 56px;
		}

		.pricing-calculator-loading-2-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.pricing-calculator-loading-2 {
			@include shimmer;
			height: 56px;
			width: 100%;
			border-radius: 56px;
		}
	}
	#pricing-calculate-your-bill
		.pricing-calculate-your-bill-tables
		table
		td:not(.pricing-calculate-your-bills-divider) {
		background-color: $denim_lightest;
	}

	#pricing-calculate-your-bill
		.pricing-calculate-your-bill-tables-final
		table
		tbody
		tr:last-child
		td {
		background-color: $denim;
		color: $white;
	}

	#pricing-calculate-your-bill
		.pricing-calculate-your-bill-tables
		table
		tr.pricing-calculate-your-bill-total-row
		> td {
		background-color: $sky-light;
	}

	#pricing-calculate-your-bill
		.pricing-calculate-your-bill-tables
		table
		tr:not(:last-child) {
		border-bottom: 4px solid $white;
	}

	table {
		border-radius: 8px;
		overflow: hidden;
	}

	#pricing-card-section {
		background-color: $cloud;
		padding: spacing(8) 0;
	}

	#pricing-waitlist-card {
		background: $sky-light;
		padding: spacing(4);
		border-radius: 16px;

		.pricing-waitlist-input-row {
			display: flex;
			flex-direction: column;
			gap: spacing(1);

			&-mt {
				@extend .pricing-waitlist-input-row;
				margin-top: spacing(1);

				@include if-size("desktop") {
					margin-top: spacing(2);
				}
			}

			@include if-size("desktop") {
				flex-direction: row;
				gap: spacing(2);
			}

			> div {
				flex: 1;
			}
		}

		.pricing-waitlist-input {
			margin-top: spacing(1);

			@include if-size("desktop") {
				margin-top: spacing(2);
			}
		}

		select.with-label {
			margin-top: 0;
		}
	}

	.pricing-left-side-price-info {
		padding-top: 12px;
		@include if-size("desktop") {
			padding-top: 24px;
		}
	}
}

#pricing-faq-section {
	padding: spacing(8) 0;

	.pricing-faq-section-title {
		grid-column: 1 / -1;
		margin-bottom: spacing(2);
	}

	#pricing-cta-2,
	#pricing-faq-have-questions,
	#pricing-faq-contact-us {
		grid-column: 1 / -1;
		margin: 0 auto;
	}

	#pricing-cta-2 {
		margin-top: spacing(4);
	}

	#pricing-faq-have-questions {
		margin: spacing(4) auto spacing(2);
	}
}

#pricing-investors-section {
	background: $denim_lightest;
	padding: spacing(3) 0;

	@include if-size("desktop") {
		padding: spacing(7) 0;
	}

	#pricing-investors-section-content {
		grid-column: 1 / -1;
		display: flex;
		flex-direction: column;

		@include if-size("desktop") {
			grid-column: 2 / -2;
			flex-direction: row;
			align-items: center;
			gap: spacing(6);
		}

		#pricing-investors-section-image {
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 100%;
				height: 100%;
				max-width: 200px;

				@include if-size("desktop") {
					width: 250px;
					height: 238px;
					max-width: 250px;
				}
			}
		}

		#pricing-investors-section-cta-container {
			text-align: center;

			h2 {
				margin-bottom: spacing(1);
				margin-top: spacing(3);

				@include if-size("desktop") {
					margin-top: 0;
				}
			}

			button {
				margin: spacing(2) auto 0;
			}
		}
	}
}

#pricing-calculate-your-bill {
	margin-top: spacing(4);

	h2 {
		grid-column: 1/-1;
		margin-bottom: 4px;
	}

	.pricing-caculate-your-bill-subheading {
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: 2 / -2;
		}
	}

	.pricing-calculate-your-bill-steps {
		grid-column: 1/-1;
		margin-top: spacing(3);

		@include if-size("desktop") {
			grid-column: 1/6;
			margin-top: spacing(4);
		}
	}

	.pricing-calculate-your-bill-exemption {
		@extend .pricing-calculate-your-bill-steps;
		margin-top: spacing(0);
	}

	.pricing-calculate-your-bill-tables-final {
		table {
			overflow: hidden;
			tbody {
				tr:not(:first-of-type):not(:last-of-type):not(:nth-last-child(3)):not(
						:nth-last-child(2)
					) {
					border-bottom: 4px solid white;
				}

				tr:last-child {
					td {
						background-color: $denim;
						color: $white;
					}
				}

				tr.custom-item-row {
					td.custom-item-cell {
						background-color: $slate;
						color: $denim;
					}
				}
			}
			&.custom-item-table {
				border-radius: unset;
			}
		}
	}
	.pricing-calculate-your-bill-tables {
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: 7/-1;
		}

		table {
			border-radius: 8px;
			background-color: rgba($slate, 0.5);
			color: $denim;
			@include text("14px");
			line-height: 24px;
			width: 100%;
			margin-top: spacing(1);

			@include if-size("tablet") {
				@include text("20px");
			}
			@include if-size("desktop") {
				margin-top: spacing(4);
			}

			td:nth-child(2) {
				width: 35%;
				// @include if-size("desktop"){

				// }
			}

			tr:nth-child(1) {
				border-bottom: 4px solid white;
			}

			.pricing-calculate-your-bills-divider {
				height: 4px;
				background-color: $denim;
				border-top: 2px solid white;
				border-bottom: 2px solid white;
			}

			td:not(.pricing-calculate-your-bills-divider) {
				padding: 8px;
				@include if-size("tablet") {
					padding: 8px 16px;
				}
			}
			td:nth-child(1):not(.pricing-calculate-your-bills-divider) {
				border-right: 4px solid white;
			}
		}
	}

	.pricing-calculate-your-bill-footnote {
		padding-top: spacing(2);
		grid-column: 1/-1;

		@include if-size("tablet") {
			padding-top: spacing(4);
		}

		@include if-size("desktop") {
			grid-column: 7/-1;
		}

		label {
			text-transform: none;
			letter-spacing: 0;
		}
	}

	&-small {
		@extend #pricing-calculate-your-bill;
		margin-top: 0;

		h2 {
			grid-column: 1 / -1;
			margin-bottom: 0;

			@include if-size("desktop") {
				grid-column: 2 / -2;
			}
		}

		.pricing-calculate-your-bill-steps {
			grid-column: 1/-1;

			@include if-size("desktop") {
				grid-column: 2/6;
			}
		}

		.pricing-calculate-your-bill-tables {
			grid-column: 1/-1;

			@include if-size("desktop") {
				grid-column: 7/-2;
			}
		}

		.pricing-calculate-your-bill-footnote {
			grid-column: 1/-1;

			@include if-size("desktop") {
				grid-column: 7/-2;
			}
		}
	}

	&-sand {
		@extend #pricing-calculate-your-bill;

		margin-top: spacing(4);

		table {
			background-color: $sand !important;
		}

		.pricing-calculate-your-bill-tables {
			table {
				tr:nth-child(1) {
					border-bottom: 4px solid $cloud;
				}

				.pricing-calculate-your-bills-divider {
					border-top: 2px solid $cloud;
					border-bottom: 2px solid $cloud;
				}

				td:nth-child(1):not(.pricing-calculate-your-bills-divider) {
					border-right: 4px solid $cloud;
				}

				tbody
					tr:not(:first-of-type):not(:last-of-type):not(:nth-last-child(3)):not(
						:nth-last-child(2)
					) {
					border-bottom: 4px solid $cloud;
				}
			}
		}

		&-exemption {
			@extend #pricing-calculate-your-bill-sand;
			margin-top: spacing(1);
		}
	}

	.pricing-calculate-your-bill-sample-overlay {
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		font-size: 80px;
		transform: rotate(-30deg);
		color: $denim_5;
		font-family: $ff-secondary;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		opacity: 0.08;
		@include if-size("tablet") {
			left: 70px;
			font-size: 100px;
		}
	}
}

.pricing-calculate-your-bill-td-with-math-symbols {
	display: flex;
	justify-content: space-between;
}

.pricing-hide-on-mobile {
	display: none;
	@include if-size("tablet") {
		display: block;
	}
}

.pricing-show-on-mobile {
	display: block;
	@include if-size("tablet") {
		display: none;
	}
}

.pricing-contingency-title {
	font-family: $ff-secondary;
	font-style: normal;
	font-weight: 800;
	line-height: 100%;
	background: var(
		--opaque-gradient-blue-default,
		linear-gradient(180deg, #1c7ce5 0%, #1a5ca3 100%)
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 64px;
	letter-spacing: -0.64px;
	margin-top: -8px;

	@include if-size("desktop") {
		font-size: 88px;
		letter-spacing: -2.64px;
		margin-bottom: 6px;
	}
}

.pricing-calculator-results-container {
	grid-column: 1 / -1;
	width: 100%;
	border: $pricing-calculator-border;
	border-radius: 16px;
	overflow: hidden;

	@include if-size("desktop") {
		display: flex;
	}

	.pricing-container-header {
		background-color: $sky;
		width: 100%;
		text-align: center;
		border-bottom: $pricing-calculator-border;
		border-right: none;
		padding: 24px 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include if-size("desktop") {
			width: 325px;
			border-right: $pricing-calculator-border;
			border-bottom: none;
		}
	}

	.pricing-container-services-content {
		flex: 1 1;
		margin: auto;

		.pricing-container-service-box {
			width: 100%;
			padding: 40px 24px;
			border-bottom: $pricing-calculator-border;
			cursor: pointer;
			user-select: none;

			&:last-child {
				border-bottom: none;
			}

			.pricing-container-service-more-info {
				.avg-savings-pill {
					display: block;
					margin-bottom: 16px;
					@include if-size("desktop") {
						display: none;
					}
				}
			}

			.pricing-container-service-more-info-hide {
				display: none;
			}
			.subheading {
				.pricing-content-subheading {
					font-size: 16px;
					font-weight: 700;
				}
			}
		}

		.pricing-container-service-content {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.pricing-container-service-content-right {
				display: flex;
				align-items: center;
				gap: 16px;
				svg {
					width: 24px;
					height: 24px;
				}
			}
		}

		.avg-savings-pill {
			display: none;
			@include if-size("desktop") {
				display: block;
			}
		}
	}

	&-green {
		border: $pricing-calculator-secondary-border;
		.pricing-container-header {
			background-color: $kelly-light;
			@include if-size("desktop") {
				border-right: $pricing-calculator-secondary-border;
			}

			.pricing-contingency-title {
				font-size: 56px;
				line-height: 100%;
				letter-spacing: -1.68px;
				background: var(
					--opaque-gradient-green-default,
					linear-gradient(180deg, #57c975 0%, #249241 100%)
				);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.pricing-container-services-content .pricing-container-service-box {
			border-bottom: $pricing-calculator-secondary-border;
		}
	}
}

.avg-savings-pill {
	background-color: $kelly-light;
	padding: spacing(1) spacing(2);
	width: fit-content;
	border-radius: 8px;
	white-space: nowrap;

	p {
		color: $kelly-dark;
	}
}

#pricing-calculator-results-wrapper {
	grid-column: 1/-1;
	@include if-size("desktop") {
		grid-column: 3/-3;
	}
}

.rotate-icon-45-deg {
	transform: rotate(45deg);
	transition: all 0.2s ease-out;
}

#pricing-calculator-cta {
	grid-column: 1/-1;

	.button-v2 {
		@include if-size("desktop") {
			width: 250px;
		}
	}
}
