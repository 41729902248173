.pricing-faq-card {
	width: 100%;
	padding: spacing(2);
	box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	margin-bottom: spacing(2);
	width: 100%;
	background-color: $white;

	&-open {
		@extend .pricing-faq-card;
		max-height: none;
	}
}

.pricing-faq-card-expand,
.pricing-faq-card-close {
	height: 16px;
	width: 16px;
	margin-right: spacing(2);
	transition: fill 200ms linear, transform 200ms linear;
}

.pricing-faq-card-expand {
	fill: $azure;
}

.pricing-faq-card-close {
	fill: $mint;
	transform: rotate(45deg);
}

.pricing-faq-card-title-section {
	display: flex;
	gap: spacing(2);
	align-items: center;
	min-height: 62px;
	cursor: pointer;
	position: relative;

	p.lg {
		padding-bottom: 6px;
	}

	.pricing-faq-card-right-icon {
		position: absolute;
		right: 0;
	}
}

.pricing-faq-card-body-section {
	margin-top: spacing(1);

	.pricing-faq-card-body {
		cursor: default;

		ul,
		ol {
			margin: spacing(1) 0;
			list-style-position: outside;
			padding-left: spacing(3);

			@include if-size("tablet") {
				padding-left: spacing(4);
			}

			li {
				margin: spacing(1) 0;
			}
		}
	}
}

.pricing-faq-card-col {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: span 6;
	}
}
