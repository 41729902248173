#tax-bill-management {
	padding: 0 spacing(2) spacing(4);
}

#tax-bill-management-desktop-header {
	display: none;

	@include if-size("desktop") {
		display: block;
		padding: spacing(2) 0;
	}

	#tax-bill-management-desktop-header-num-enrolled-pill {
		margin-left: spacing(2);
		background: $cloud;
		padding: 6px 8px;
		border-radius: 8px;
	}

	#tax-bill-management-desktop-header-tab-container {
		width: 100%;
		display: flex;
		margin-top: spacing(2);
		position: relative;

		.tax-bill-management-desktop-header-active {
			position: absolute;
			height: 20px;
			width: 50%;
			height: 100%;
			background: $denim-lightest;

			&::after {
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 50%;
				transform: translateX(-50%);
				content: "";
				height: 2px;
				background: $denim;
				z-index: 1001;
			}
		}

		.tax-bill-management-desktop-header-tab {
			flex: 1;
			padding: spacing(1) 0;
			text-align: center;
			cursor: pointer;
			border-bottom: 1px solid $slate;
			z-index: 100;

			&-active {
				@extend .tax-bill-management-desktop-header-tab;
				background: $denim-lightest;
				position: relative;
				cursor: auto;

				p.sm {
					font-weight: 600;
					z-index: 1001;
				}
			}

			&-disabled {
				@extend .tax-bill-management-desktop-header-tab;
				cursor: not-allowed;

				p.sm {
					color: $denim_5;
				}
			}
		}
	}
}

#tax-bill-management-mobile-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	margin: 0 -16px spacing(3);
	padding: spacing(1) spacing(2);

	@include if-size("desktop") {
		display: none;
	}
}

#tax-bill-management-overview-statistics-monitoring-desktop {
	display: none;

	@include if-size("desktop") {
		background: $denim-lightest;
		padding: spacing(4) spacing(3);
		border-radius: 16px;
		display: flex;
		justify-content: space-between;
		gap: spacing(2);
	}
}

#tax-bill-management-overview-statistics-monitoring-mobile {
	@include if-size("desktop") {
		display: none;
	}
}

#tax-bill-management-overview-statistics-card-container {
	display: flex;
	margin-top: spacing(2);
	gap: spacing(2);
	flex-wrap: wrap;

	@include if-size("desktop") {
		margin-top: spacing(3);
		gap: spacing(3);
	}
}

.tax-bill-management-overview-statistics-card {
	padding: spacing(2);
	border-radius: 12px;
	flex-grow: 1;
	width: calc(50% - 8px);
	height: 80px;

	@include if-size("desktop") {
		flex-grow: unset;
		width: fit-content;
		height: auto;
		padding: spacing(3);
	}

	.label {
		margin-bottom: 6px;
		color: $denim-medium;
	}

	&-cloud {
		@extend .tax-bill-management-overview-statistics-card;
		background: $cloud;

		@include if-size("desktop") {
			border: 1px solid $sand;
		}
	}

	&-sky-light {
		@extend .tax-bill-management-overview-statistics-card;
		background: $sky-light;

		@include if-size("desktop") {
			border: 1px solid $powder;
		}
	}
}

#tax-bill-management-overview-statistics-count-card-mobile {
	@include if-size("desktop") {
		display: none;
	}
}

#tax-bill-management-overview-statistics-filter-container-desktop {
	display: none;

	@include if-size("desktop") {
		display: flex;
		align-items: center;
		gap: spacing(2);
		justify-content: start;
	}
	.filter-dropdown-container {
		width: unset;
	}
	select {
		padding: 3px 11px;
		padding-right: spacing(5);
		box-sizing: border-box;
		font-size: unset;
		width: max-content;
	}
}

#tax-bill-management-payment-report-desktop {
	display: none;

	@include if-size("desktop") {
		display: block;
		padding: spacing(3);
		border-radius: 12px;
	}
}

#tax-bill-management-payment-report-mobile {
	display: block;
	padding: spacing(2);
	border-radius: 16px;

	@include if-size("desktop") {
		display: none;
	}
}

#tax-bill-management-payment-report-mobile,
#tax-bill-management-payment-report-desktop {
	margin-top: spacing(3);
	box-shadow: -44px 319px 90px 0px rgba(171, 171, 171, 0),
		-28px 204px 82px 0px rgba(171, 171, 171, 0.01),
		-16px 115px 70px 0px rgba(171, 171, 171, 0.05),
		-7px 51px 51px 0px rgba(171, 171, 171, 0.09),
		-2px 13px 28px 0px rgba(171, 171, 171, 0.1);
	border: 1px solid $slate;
}

#tax-bill-management-properties-table,
#tax-bill-management-payment-report-desktop-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: spacing(2);
	overflow: auto;
	position: relative;

	th,
	td {
		border-bottom: 1px solid $slate;

		&:not(:last-of-type) {
			border-right: 1px solid $slate;
		}
	}

	th {
		cursor: pointer;
		padding: spacing(3) spacing(1);
		background: $denim-lightest;
		text-align: start;
	}

	td {
		padding: spacing(1.5) spacing(1);
	}
}

#tax-bill-management-table-loading-bar {
	position: absolute;
	top: -5px;
	left: 0;
	height: 5px;
	width: 100%;
	border-bottom: none;
	background: $denim-lightest;
}

@keyframes borealisBar {
	0% {
		left: 0%;
		right: 100%;
		width: 0%;
	}
	10% {
		left: 0%;
		right: 75%;
		width: 25%;
	}
	90% {
		right: 0%;
		left: 75%;
		width: 25%;
	}
	100% {
		left: 100%;
		right: 0%;
		width: 0%;
	}
}

#tax-bill-management-table-loading-bar-inner {
	position: absolute;
	top: -0px;
	left: 0;
	height: 3px;
	background: $azure;
	animation: borealisBar 2s linear infinite;
}

#tax-bill-management-properties {
	display: none;

	@include if-size("desktop") {
		display: block;
		width: 100%;
	}
}

.tax-bill-management-status-badge {
	padding: spacing(1) spacing(1.5);
	border-radius: 8px;
	width: fit-content;

	&-due {
		@extend .tax-bill-management-status-badge;
		background: $rust-lightest;
		p.sm {
			color: $rust-dark;
		}
	}

	&-funded {
		@extend .tax-bill-management-status-badge;
		background: $sunflower-light;
		p.sm {
			color: $gold;
		}
	}

	&-paid {
		@extend .tax-bill-management-status-badge;
		background: $kelly-lightest;
		p.sm {
			color: $kelly-dark;
		}
	}

	&-monitoring {
		@extend .tax-bill-management-status-badge;
		background: $cloud;
		p.sm {
			color: $denim-medium;
		}
	}
}

#tax-bill-management-properties-filter-container {
	select {
		padding: 3px 11px;
		padding-right: spacing(5);
		box-sizing: border-box;
		font-size: unset;
		width: max-content;
	}
	.search-container {
		width: 320px;
		input {
			padding: 3px 11px;
			font-size: 16px;
		}
		svg {
			width: 13px;
			height: 13px;
		}
	}
}

.tax-bill-management-properties-filter-search {
	position: relative;

	input {
		padding-right: 42px;
	}

	&::before {
		position: absolute;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
		content: "";
		height: 13px;
		width: 13px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 20 20' fill='none'%3E%3Cg clip-path='url(%23clip0_1027_6855)'%3E%3Cpath d='M19.7556 18.5773L14.0682 12.8899C15.1698 11.5291 15.8332 9.7999 15.8332 7.9166C15.8332 3.55164 12.2815 0 7.91656 0C3.55161 0 0 3.55161 0 7.91656C0 12.2815 3.55164 15.8332 7.9166 15.8332C9.7999 15.8332 11.5291 15.1699 12.8899 14.0682L18.5773 19.7556C18.7398 19.9181 18.9531 19.9998 19.1665 19.9998C19.3798 19.9998 19.5932 19.9181 19.7557 19.7556C20.0815 19.4298 20.0815 18.9031 19.7556 18.5773ZM7.9166 14.1665C4.46996 14.1665 1.66666 11.3632 1.66666 7.91656C1.66666 4.46992 4.46996 1.66662 7.9166 1.66662C11.3632 1.66662 14.1665 4.46992 14.1665 7.91656C14.1665 11.3632 11.3632 14.1665 7.9166 14.1665Z' fill='%23053249'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1027_6855'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
		z-index: 10000;
	}
}

#tax-bill-management-properties-table-footer {
	width: 100%;
	background: $denim-lightest;
	padding: spacing(1.5) spacing(3);
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.tax-bill-management-properties-table-footer-pagination-item {
	height: 42px;
	width: 42px;
	border-radius: 420px;
	border: 1px solid $slate;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: $white;
	cursor: pointer;

	svg {
		fill: $denim;
	}

	&-disabled {
		@extend .tax-bill-management-properties-table-footer-pagination-item;

		svg {
			fill: $denim-extra-light;
		}
	}

	&-active {
		@extend .tax-bill-management-properties-table-footer-pagination-item;
		background: $azure;
		border-color: $azure;

		p.sm {
			color: $white;
		}
	}
}

#tax-bill-management-modal-underlay {
	@include if-size("tablet") {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($denim, 0.75);
		z-index: 10000;
	}
}

#tax-bill-management-modal {
	position: fixed;
	background: $white;
	overflow: hidden;
	top: 44px;
	height: 100%;
	left: 0;
	z-index: 10000;
	width: 100%;

	@include if-size("tablet") {
		height: fit-content;
		top: 15%;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 16px;
		width: 90%;
		max-width: 896px;
	}
}

#tax-bill-management-modal-header {
	padding: spacing(3);
	border-bottom: 1px solid $slate;
	text-align: center;
	position: relative;
}

#tax-bill-management-modal-header-close-container {
	position: absolute;
	right: spacing(3);
	top: 50%;
	transform: translateY(-50%);

	svg {
		cursor: pointer;
	}
}

#tax-bill-management-modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: spacing(3);

	@include if-size("desktop") {
		padding: spacing(3) spacing(8);
	}
}

#tax-bill-management-modal-footer {
	padding: spacing(3);
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid $slate;
}

#tax-bill-management-payment-report-desktop-table-monitoring-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($white, 0.5);
	backdrop-filter: blur(5px);
}

#tax-bill-management-payment-report-desktop-table-monitoring-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	text-align: center;
}

#tax-bill-management-overview-statistics-monitoring-mobile-banner {
	border-radius: 12px;
	border: 1px solid $sunflower;
	background: rgba(255, 230, 161, 0.8);
	padding: spacing(2);
}

#tax-bill-management-opt-in {
	max-width: 1240px;
	margin: 0 auto;
}

#tax-bill-management-opt-in-hero-content {
	border-radius: 16px;
	padding: spacing(2) 0;

	@include if-size("desktop") {
		padding: spacing(5) spacing(10);
		background: $denim-lightest;
	}

	#tax-bill-management-opt-in-hero-content-text-container {
		display: flex;
		flex-direction: column-reverse;

		@include if-size("desktop") {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: spacing(2);
		}
	}

	#tax-bill-management-opt-in-hero-content-text {
		@include if-size("desktop") {
			max-width: 650px;
		}
	}

	#tax-bill-management-opt-in-hero-icon {
		width: 120px;
		height: auto;
		margin: spacing(2) auto;

		@include if-size("desktop") {
			margin: 0;
			height: 310px;
			width: auto;
			min-width: 200px;
		}
	}

	#tax-bill-management-desktop-hero-cta {
		display: none;

		@include if-size("desktop") {
			display: block;
		}
	}

	#tax-bill-management-mobile-hero-cta {
		display: block;
		margin: 0 auto spacing(2);

		@include if-size("desktop") {
			display: none;
		}
	}

	.tax-bill-management-opt-in-hero-value-prop-row {
		margin-bottom: spacing(2);
		background: $denim-lightest;
		border-radius: 8px;
		padding: spacing(2);

		@include if-size("desktop") {
			padding: 0;
			background: none;
		}
	}
}

#tax-bill-management-opt-in-how-it-works {
	background: $denim-lightest;
	padding: spacing(2);
	border-radius: 8px;

	@include if-size("desktop") {
		padding: spacing(5) spacing(10);
		border-radius: 16px;
		margin-top: spacing(3);
	}

	.tax-bill-management-opt-in-how-it-works-badge {
		padding: spacing(0.75) spacing(1);
		background: $kelly-lightest;
		color: $kelly-dark;
		font-weight: 600;
		border-radius: 8px;
		white-space: nowrap;
		display: flex;
	}
}

#tax-bill-management-mobile-seperator {
	width: 100%;
	height: 1px;
	background: $sand;
	margin-bottom: spacing(4);

	@include if-size("desktop") {
		display: none;
	}
}

#tax-bill-management-opt-in-modal-container {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 100000;

	#tax-bill-management-opt-in-modal-underlay {
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: rgba($denim, 0.75);
		z-index: 100000;
	}

	#tax-bill-management-opt-in-modal {
		position: fixed;
		top: 35%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: $white;
		z-index: 100001;
	}
}
