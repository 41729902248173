#premium-pricing-comparison-table {
	table {
		width: 100%;
		border-spacing: 0;
		border-collapse: separate;
	}

	thead {
		// All Header Cells
		th {
			padding: spacing(3) spacing(2);
			@include if-size("desktop") {
				padding: 8px;
			}

			position: relative;

			p {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;

				.active-badge {
					display: none;
					font-weight: bold;
					@include if-size("desktop") {
						display: block;
						background-color: $kelly;
						color: $white;
						padding: 6px 8px;
						border-radius: 8px;
					}
				}

				.premium-diamond {
					display: none;
					width: 24px;
					height: 24px;

					@include if-size("desktop") {
						display: block;
					}
				}
			}

			.mobile-active-badge {
				background-color: $kelly;
				color: $white;
				padding: 4px;
				border-radius: 8px;
				position: absolute;
				top: -12px;
				left: 50%;
				transform: translateX(-50%);

				font-weight: bold;

				font-size: 10px;
				line-height: 12px;

				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;

				@include if-size("desktop") {
					display: none;
				}

				.premium-diamond {
					width: 14px;
					height: 12px;
				}
			}

			.absolute-premium-diamond {
				position: absolute;
				top: -14px;
				left: 50%;
				transform: translateX(-50%);

				width: 34px;
				height: 28px;

				@include if-size("desktop") {
					display: none;
				}
			}
		}

		// Essentials Header
		th:nth-child(2) {
			background-color: $cloud;
			border-radius: 16px 16px 0 0;
			border: 1px solid $sand;
			border-bottom: none;
		}

		// Premium Header
		th:nth-child(3) {
			background-color: $royal;
			border-radius: 16px 16px 0 0;
			border: 2px solid $royal;
			border-bottom: none;

			p {
				color: $white;
			}
		}
	}

	tr:first-of-type {
		// Essentials Column Rounded Top
		td:nth-child(1) {
			border-radius: 16px 0 0 0;
			border-top: 1px solid $sand;
		}

		td:nth-child(2) {
			border-top: 1px solid #eaeae4;
		}

		td:nth-child(3) {
			border-top: 1px solid $royal;
		}
	}

	tr {
		// All Columns
		td {
			padding: 16px 12px;
			@include if-size("desktop") {
				padding: 8px 16px;
			}
			text-align: center;
			vertical-align: middle;

			button.button-v2 {
				margin: 0 auto;
				font-size: 18px;
			}

			.premium-pricing-activate-premium-button {
				padding: 6px 32px 8px;
			}
		}

		// Feature Column
		td:nth-child(1) {
			border-bottom: 1px solid $sand;
			border-left: 1px solid $sand;

			> p {
				text-align: left;
			}

			p {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				gap: 8px;

				align-items: center;
				@include if-size("desktop") {
					align-items: baseline;
				}
			}

			p ~ ul {
				margin-top: spacing(1);
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				margin-left: spacing(1);
				@include if-size("desktop") {
					margin-left: spacing(2);
				}

				li {
					margin-bottom: 6px;
					text-align: left;
				}
			}
		}

		// Essentials Column
		td:nth-child(2) {
			border-bottom: 1px solid $sand;
			border-left: 1px solid $sand;
			border-right: 1px solid $sand;

			> p {
				margin: 0 auto;
			}
		}

		// Premium Column
		td:nth-child(3) {
			background-color: $sky;
			border-bottom: 1px solid $powder;
			border-left: 3px solid $royal;
			border-right: 3px solid $royal;

			> p {
				margin: 0 auto;
			}
		}
	}

	tr:last-of-type {
		// Feature Column
		td:nth-child(1) {
			border-radius: 0 0 0 16px;
		}

		// Premium Column
		td:nth-child(3) {
			border-bottom: 3px solid $royal;
			border-radius: 0 0 16px 0;
		}
	}

	.tooltip2 {
		.info-icon {
			width: 16px;
			height: 16px;

			path {
				fill: $denim_5;
			}
		}
	}

	.feature-check {
		width: 24px;
		height: 24px;
	}
}
