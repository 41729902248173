.two-column-grid {
	display: grid;
	grid-template-columns: 100%;
	grid-auto-flow: row dense;
	gap: spacing(4);

	margin: 0 auto;
	width: 100%;
	max-width: 100%;

	@include if-size("desktop") {
		gap: spacing(0);
	}

	@include if-size("desktop-xl") {
		grid-template-columns: 2fr 1fr;
		gap: spacing(2);
		max-width: 100%;
	}
}

.two-column-grid-with-nav {
	display: grid;
	grid-template-columns: 100%;
	grid-auto-flow: row dense;
	gap: spacing(4);

	margin: 0 auto;
	width: 100%;
	max-width: 100%;

	@include if-size("desktop") {
		gap: spacing(0);
	}

	@include if-size("desktop-xl") {
		gap: spacing(2);
	}

	@include if-size("monitor") {
		grid-template-columns: minmax(706px, 932px) 446px;
	}

	@include if-size("monitor-lg") {
		grid-template-columns: 932px minmax(446px, 558px);
	}

	@include if-size("monitor-xl") {
		grid-template-columns: 990px 636px;
	}
}
