.carousel-wrapper {
	position: relative;

	overflow: auto hidden;
	scroll-behavior: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

.carousel-scroll {
	width: 200%;
	height: 1px;
}

.carousel-content {
	position: sticky;
	left: 0;
	max-width: 100%;
	height: 100%;
}

.carousel-items {
	width: 100%;
	height: 100%;
}

.carousel-item {
	position: absolute;
	list-style: none;
}

.carousel-btn {
	width: 28px;
	height: 28px;

	@include if-size("desktop") {
		width: 32px;
		height: 32px;
	}

	background-color: rgba($slate, 0.5);
	border-radius: 50%;
	cursor: pointer;
	border: none;
	display: grid;
	place-content: center;

	&.carousel-prev {
		position: absolute;
		left: 0;

		top: calc(50% - 14px);

		@include if-size("desktop") {
			top: calc(50% - 16px);
		}
	}

	&.carousel-next {
		position: absolute;
		right: 0;
		top: calc(50% - 14px);

		@include if-size("desktop") {
			top: calc(50% - 16px);
		}
	}
}
