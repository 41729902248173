#comparison-page {
	.comparison-page-header {
		background-color: $sky-light;
		padding: 40px 0;

		h1 {
			font-family: $ff-secondary;
		}
	}

	.comparison-page-primary-button {
		padding: 16px 48px;
	}

	.comparison-page-header_inner_left {
		grid-column: -1/1;
		order: 2;

		@include if-size("desktop") {
			order: 1;
			grid-column: 1/9;
		}

		h1 {
			padding-bottom: spacing(2);
		}

		.comparison-page-header-subtitle {
			padding-bottom: 20px;
			display: flex;
			gap: spacing(1);

			svg {
				width: 32px;
				height: 32px;
			}
		}
	}
	.comparison-page-header_inner_right {
		order: 1;
		grid-column: -1/1;
		text-align: center;
		@include if-size("desktop") {
			grid-column: 9/-1;
			order: 2;
			text-align: left;
		}
		svg {
			height: 200px;
			width: auto;
			margin-bottom: 8px;
			@include if-size("desktop") {
				height: 292px;
			}
		}
	}

	.comparison-page-header-reviews {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	.comparison-page-header-reviews-number {
		color: $denim_5;
		text-decoration: underline;
	}

	.comparison-page-header-full-width {
		grid-column: -1/1;
		padding-top: spacing(2);

		.button-v2 {
			margin: auto;
		}
	}

	.comparison-page-property-value {
		padding: 40px 0;
		grid-column: -1/1;

		@include if-size("desktop") {
			padding: 80px 0;
		}
	}

	.comparison-page-titles {
		color: $royal;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;

		@include if-size("desktop") {
			font-size: 40px;
		}
	}

	.comparison-page-property-value-boxes {
		display: flex;
		gap: 16px;
		flex-wrap: wrap;
		padding: 24px 0;

		@include if-size("desktop") {
			flex-wrap: nowrap;
		}
	}

	.comparison-page-property-value-scroller-container {
		padding: 24px 0 0;
	}

	.comparison-page-property-value-box {
		border-radius: 16px;
		padding: 24px;
		flex: 1 1 0px;
	}

	.comparison-page-property-value-box-normal {
		@extend .comparison-page-property-value-box;
		background: var(--light-cloud, #f8f8f2);
	}

	.comparison-page-property-value-box-highlighted {
		@extend .comparison-page-property-value-box;
		border: 1px solid var(--accent-colors-powder, #8cb3dc);
		background: rgba(213, 234, 255, 0.5);
		flex: 0 0 100%;

		@include if-size("desktop") {
			flex: 1 1 0px;
		}
	}

	.comparison-page-property-value-box-with-logo {
		display: flex;
		gap: 8px;
		padding: 8px 0 6px 0;

		p {
			font-size: 20px;
			line-height: 34px;
			font-weight: 727;
			@include if-size("desktop") {
				font-size: 32px;
			}
		}

		svg {
			width: 32px;
			height: 32px;
		}
	}

	.comparison-page-faqs {
		grid-column: -1/1;
		padding-bottom: 80px;
		@include if-size("desktop") {
			padding-bottom: 156px;
		}
	}
}

.comparison-faq-card {
	border-radius: 16px;
	background: $denim_lightest;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	padding: 16px;
	margin-bottom: spacing(2);

	.comparison-faq-card-title {
		padding-bottom: 8px;
	}

	.expand-button {
		margin-left: auto;
		.expand-label {
			font-size: 14px;
		}

		svg {
			margin-top: 2px;
		}
	}
}

.comparison-page-property-value-scroller-pricing {
	display: flex;
	justify-content: space-between;
	padding-top: 8px;
}

.comparison-page-full-grid {
	grid-column: -1/1;
}

#competitor-table-container {
	width: 100%;
	overflow-x: auto;
	max-width: 1500px;
	margin: 0 auto 24px;
	@include if-size("desktop") {
		margin: auto;
	}
}

#competitor-table {
	margin-bottom: 2px;
	width: 100%;
	border: 0;
	border-collapse: collapse;

	th,
	td {
		border-collapse: collapse;
	}

	@include if-size("desktop") {
		margin: 32px 14px 24px;
		width: 98%;
	}

	tr:hover {
		background-color: $sky-light;
		.competitor-table-no-hover-background {
			background-color: $white !important;
		}
	}

	th {
		border-left: 1px solid $powder;
		border-right: 1px solid $powder;
		text-align: center;
		padding: 8px;
		@include if-size("desktop") {
			padding: 8px 16px;
		}
	}

	th:first-child,
	tr > .competitor-table-td-multi-row-td {
		border-left: 0;
	}

	tr:last-child > td,
	.competitor-table-td-no-border-bottom {
		border-bottom: 0;
	}

	th:last-child,
	tr > td:last-child {
		border-right: 0;
	}
	td {
		&:not(:first-child) {
			border-left: 1px solid $powder;
		}

		vertical-align: middle;
		border-top: 1px solid $powder;
		padding: 8px;
		color: $denim;

		@include if-size("desktop") {
			padding: 32px 16px;
		}

		> .cell-label {
			font-weight: bold;
			white-space: nowrap;

			font-size: 14px;
			line-height: normal;

			@include if-size("desktop") {
				font-size: 24px;
				line-height: 28px;
			}
		}
	}

	.competitor-checkmark-icon {
		width: 16px;
		height: 12px;

		@include if-size("desktop") {
			width: 30px;
			height: 22px;
		}
	}

	.competitor-crossout-icon {
		fill: #a1a1a1;
		width: 16px;
		height: 16px;
		@include if-size("desktop") {
			width: 24px;
			height: 24px;
		}
	}

	.competitor-table-best-choice-badge {
		width: 95px;
		height: 18px;
		@include if-size("desktop") {
			width: 115px;
			height: 22px;
		}
	}

	.competitor-table-highlighted {
		background-color: $sky;
	}

	.competitor-table-td-centered {
		text-align: center;
	}

	.competitor-table-td-p-max-width {
		max-width: 140px;
		margin: auto;
	}
}

.competitor-table-header-ownwell {
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	padding-bottom: 6px;

	p {
		color: $royal;
	}

	svg {
		width: 24px;
		height: 24px;
	}
}

#detailed-comparison-table-view {
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	height: 300px;
	max-height: 300px;
	position: relative;

	@include if-size("desktop") {
		height: 400px;
		max-height: 400px;
	}

	&.detailed-comparison-table-view-open {
		height: auto;
		max-height: 100%;
		transition: max-height 1s ease-in-out;

		.detailed-comparison-table-view-button {
			display: none;
		}
		.detailed-comparison-table-view-overlay {
			display: none;
		}
	}

	.comparison-page-primary-button-container {
		position: absolute;
		width: 100%;
		bottom: 0;
		z-index: 10;

		@include if-size("desktop") {
			bottom: 26px;
		}
	}
	.detailed-comparison-table-view-button {
		background-color: $white;
		margin: auto;

		&:hover {
			background-color: $denim;
		}
	}

	.detailed-comparison-table-view-overlay {
		position: absolute;
		bottom: 0;
		height: 400px;
		width: 100%;
		background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
		opacity: 0.5;
	}

	.detailed-comparison-table-ownwell-show-less-button {
		text-align: center;
		margin-top: 24px;
		.button-tiny {
			color: $azure;
			cursor: pointer;
			display: inline-block;
			&:hover {
				color: $denim;
			}
		}
	}
}
#detailed-comparison-table {
	border-collapse: separate;
	.detailed-comparison-table-ownwell {
		display: flex;
		align-items: center;
		gap: 8px;
		svg {
			height: 32px;
			width: 32px;
		}
	}

	thead > th:first-child {
		border-bottom: 0;
	}

	thead > th:nth-child(2) {
		background-color: $sky;
		border-top-left-radius: 24px;
		border-top-right-radius: 24px;
		border-left: 4px solid $azure;
		border-right: 4px solid $azure;
		border-top: 4px solid $azure;
	}
	tr > td:nth-child(2) {
		background-color: $sky;
		border-left: 4px solid $azure;
		border-right: 4px solid $azure;
	}

	tr:hover {
		background-color: $cloud;
	}

	tr:last-child > td:nth-child(2) {
		border-bottom: 4px solid $azure;
		border-bottom-left-radius: 24px;
		border-bottom-right-radius: 24px;
	}

	th,
	td {
		padding: 8px;
		border-bottom: 1px solid $powder;
		@include if-size("desktop") {
			padding: 24px;
		}
	}
	tr:last-child > td {
		border-bottom: 0;
	}
}

.comparison-page-quick-facts {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	padding: 16px 0 48px;
	width: 100%;

	@include if-size("desktop") {
		padding: 32px 0;
	}

	.comparison-page-quick-facts-card {
		border-radius: 16px;
		background: $denim_lightest;
		padding: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		img {
			width: 80px;
			height: 80px;
		}

		@include if-size("desktop") {
			padding: 24px;
			img {
				width: 120px;
				height: 120px;
			}
		}
	}
	.comparison-page-quick-facts-card-1 {
		width: calc(50% - 4px);
	}
	.comparison-page-quick-facts-card-2 {
		width: calc(50% - 4px);
	}
	.comparison-page-quick-facts-card-other {
		width: calc(33.3333% - 6px);
	}
}

#comparison-page-reviews-section {
	padding: 40px 0;
	@include if-size("desktop") {
		padding: 94px 0 60px;
	}
}

.comparison-gray-text {
	color: #a1a1a1;
}

.comparison-page-property-value-scroller-tooltip {
	&.rc-tooltip {
		opacity: 1;
		background-color: transparent;
	}

	&.rc-tooltip-placement-top .rc-tooltip-arrow,
	.rc-tooltip-arrow,
	.rc-tooltip-arrow {
		border-top-color: $sky;
	}
}

#comparison-page-property-value-scroller-tooltip {
	border-radius: 8px;
	background: $sky;
	padding: spacing(2);
	border: none;

	&::before {
		border-color: red;
		width: 20px;
	}

	p {
		color: $midnight;
	}
}
