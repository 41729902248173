.grid {
	display: grid;
	column-gap: 16px;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 1fr;
	margin: 0 auto;
	place-content: center;
	max-width: 86%;
	width: 100%;

	> .grid {
		max-width: 100%;
		margin: 0;
	}

	@include if-size("tablet") {
		grid-template-columns: repeat(8, 56px);
		max-width: calc(100vw - 112px);
	}

	@include if-size("desktop") {
		grid-template-columns: repeat(12, minmax(56px, 80px));
		min-width: calc(960px - 112px);
	}
}

.span-full-grid {
	grid-column: 1 / -1;
}

//Special grid adjustment
.grid.grid-mobile-full-width {
	display: block;
	max-width: 100%;
	column-gap: 16px;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 1fr;
	margin: 0 auto;
	place-content: center;
	width: 100%;

	> .grid {
		max-width: 100%;
		margin: 0;
	}

	@include if-size("tablet") {
		display: grid;
		max-width: 86%;
		grid-template-columns: repeat(8, 56px);
		max-width: calc(100vw - 112px);
	}

	@include if-size("desktop") {
		grid-template-columns: repeat(12, minmax(56px, 80px));
		min-width: calc(960px - 112px);
	}
}
