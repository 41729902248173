.appeal-already-signed-up-card {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 /-3;
	}

	background-color: $cloud;
	padding: spacing(4);
	border-radius: 16px;
	margin-bottom: spacing(4);

	.appeal-already-signed-up-card-title {
		font-size: 20px;
		margin-bottom: 4px;
	}

	p {
		font-size: 16px;
	}
}

.appeal-already-signed-up-cta {
	margin-top: spacing(2);
	grid-column: span 4;
	justify-self: center;

	@include if-size("tablet") {
		grid-column: 2 / span 6;
	}

	@include if-size("desktop") {
		grid-column: 4 / span 6;
	}
}
