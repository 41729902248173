#top-nav {
	display: flex;
	height: $property-portal-top-navigation-bar-height;
	width: 100%;
	z-index: 10000;
	align-items: center;
}

.top-nav-contents {
	background-color: $denim-lightest;
	height: $property-portal-top-navigation-bar-height;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	padding: 0 spacing(2);
	position: fixed;
	top: 0;
	left: 0;
	border-bottom: solid 1px $slate;
	@include if-size("desktop") {
		gap: 16px;
	}
	.lifetime-savings {
		display: flex;
		align-items: center;
		padding: 6px 16px 4px 16px;
		gap: 4px;
		border-radius: 50px;
		background: #99edba4d;
		cursor: pointer;
		.savings-text {
			margin-bottom: 2px;
		}
	}
}

.top-nav-separator {
	border: solid 1px $sand;
	border-radius: 8px;
	margin-right: 6px;
	height: 24px;
}

.top-nav-link,
.top-nav-logo,
.top-nav-button {
	display: none;
	align-items: center;
	gap: 8px;
	padding: 3px 8px;
	cursor: pointer;

	svg {
		margin-top: 2px;
		fill: $denim;
	}

	&.mr-auto {
		margin-right: auto;
	}

	@include if-size("desktop") {
		display: flex;
	}
}

.top-nav-link a,
.top-nav-logo a,
.top-nav-button {
	display: flex;
	align-items: center;
	gap: 8px;
}

.top-nav-link-active,
.top-nav-button-active {
	background-color: $sky;
	padding: 7px 8px;
	border-radius: 24px;

	p {
		color: $denim;
		font-weight: 800;
	}

	svg {
		fill: $denim;
		color: $denim;
	}
}

.top-nav-link:hover {
	background-color: rgba(213, 234, 255, 0.4) !important;
	@extend .top-nav-link-active;
}

.top-nav-button:hover {
	cursor: pointer;
	@extend .top-nav-button-active;
}

#top-nav-menu {
	position: relative;
	margin-left: auto;

	@include if-size("desktop") {
		margin-left: 0;
	}

	.top-nav-menu-container {
		position: absolute;
		top: 42px;
		z-index: 1000001;
		background-color: $white;
		border-radius: 0 0 12px 12px;
		box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		width: 100vw;
		right: -16px;

		@include if-size("tablet") {
			width: 250px;
			right: 16px;
			border-radius: 12px;
		}

		p {
			white-space: nowrap;
			color: $denim;
		}

		&-item,
		&-item-active {
			padding: 16px 40px;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;

			.icon {
				position: relative;
				top: 1px;
				min-width: 25px;
			}

			p {
				font-size: 16px;
				line-height: 24px;
			}

			&:hover {
				background-color: rgba(213, 234, 255, 0.4);
			}
		}

		&-item-active {
			background-color: $sky-light;
			p {
				color: $royal;
				font-weight: 800;
			}
			.icon > svg {
				fill: $royal !important;
			}

			&.mobile-only {
				@include if-size("desktop") {
					display: none;
				}
			}
		}

		&-item {
			&.mobile-only {
				@include if-size("desktop") {
					display: none;
				}
			}
		}

		&-item-active {
			background-color: $sky-light;
			p {
				color: $royal;
			}
			svg {
				fill: $royal;
			}
		}

		.top-nav-menu-container-item:last-of-type {
			border-top: 1px solid $slate;
			p,
			svg {
				color: $rust-dark;
				fill: $rust-dark;
			}
		}
	}
}

#top-nav-premium-button {
	display: none;
	position: relative;
	padding: 6px 8px;

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		border-radius: 4px;
		padding: 1px; /* control the border thickness */
		background: linear-gradient(45deg, #57c975, #1c7ce5);
		-webkit-mask:
			linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}
	&:hover {
		background-color: transparent;
		font-weight: 800;
	}

	&.top-nav-button {
		gap: 4px;
	}

	@include if-size("desktop") {
		display: flex;
	}

	.premium-diamond,
	.premium-diamond-outline {
		width: 21px;
		height: 18px;
	}
}

#top-nav-settings {
	border-radius: 40px;
	gap: 4px;
	&:hover {
		background-color: $sky;
	}

	&.top-nav-button-active {
		background-color: $sky;
	}
}

#top-nav-settings-icon {
	background-color: $rust;
	box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
	border-radius: 100px;
	width: 24px;
	height: 24px;
	overflow: hidden;
}

#top-nav-mobile-nav-header-left {
	display: flex;
	gap: 18px;
	align-items: center;
	@include if-size("desktop") {
		display: none;
	}

	.mobile-nav-header-home-container-icon {
		position: relative;
		top: 2px;
		cursor: pointer;
	}
}

#top-nav-contents-nav-links-container {
	display: none;

	@include if-size("desktop") {
		flex: 1;
		display: flex;
	}
}

#top-nav {
	.savings-overview-modal {
		.modal-wrapper {
			margin: 100px 0;
			height: calc(100% - 200px);
			overflow-y: auto; /* Enables vertical scrolling when content overflows */
			display: flex;
			gap: 16px;
			flex-direction: column;
			padding-right: 16px;
			width: 90%;
			@include if-size("tablet") {
				width: 450px;
			}
			&::-webkit-scrollbar {
				width: 5px;
			}
			&::-webkit-scrollbar-track {
				border-radius: 5px !important;
			}
			&::-webkit-scrollbar-thumb {
				background: #bbccd4;
				border-radius: 5px !important;
			}
		}
		.saving-value-wrapper {
			flex: 1;
			max-width: 300px;
		}
		.close-btn {
			.button-v2 span {
				display: flex;
				gap: 4px;
				justify-content: center;
				align-items: center;
				svg {
					width: 10px;
					height: 10px;
					fill: $azure;
					@include if-size("desktop") {
						margin-bottom: 4px;
					}
				}
			}
		}
		.total-savings-all-properties {
			display: flex;
			gap: 8px;
			justify-content: space-between;
			align-items: center;
			background: $denim-dark;
			padding: 16px;
			border-radius: 16px;
			opacity: 1;
			.content {
				display: flex;
				flex-direction: column;
				gap: 4px;
				justify-content: start;
				align-items: start;
			}
		}
		.property-savings-card {
			background: white;
			padding: 24px;
			border-radius: 16px;
			position: relative;
			.house-address {
				display: flex;
				gap: 8px;
				justify-content: start;
				align-items: start;
				cursor: pointer;
				.house-icon {
					path {
						fill: $denim;
					}
				}
				.address {
					display: flex;
					flex-direction: column;
					gap: 8px;
					justify-content: start;
				}
			}
		}
		.green-graph {
			z-index: 0;
			position: absolute;
			mask-image: linear-gradient(to top, transparent 0%, black 10%);
			//margin-right: spacing(4);
			top: 60px;
			right: 0;
			min-width: 100%;
			min-height: 136px;
		}

		* {
			position: relative;
			z-index: 2;
		}

		.total-savings-amount {
			margin-bottom: 4px;
			font-weight: 727;
			font-size: 48px;
			line-height: 56px;
			letter-spacing: -0.01em;
			@include if-size("desktop") {
				font-size: 64px;
				line-height: 74px;
				letter-spacing: -0.03em;
			}
		}

		.total-savings-title {
			margin-bottom: spacing(3);
			@include if-size("desktop") {
				margin-bottom: spacing(4);
			}

			font-weight: 622;
			font-size: 16px;
			line-height: 19px;
			color: $denim;

			@include if-size("desktop") {
				font-size: 20px;
				line-height: 28px;
			}
		}

		.savings-categories {
			margin-bottom: spacing(3);

			> * + * {
				margin-top: 6px;
				@include if-size("desktop") {
					margin-top: spacing(1);
				}
			}
		}

		.savings-category {
			display: flex;
			justify-content: space-between;
			gap: spacing(4);
			@include if-size("tablet") {
				//margin-left: spacing(4);
			}
			.category {
				color: $denim-medium;
				font-weight: 622;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: -0.015em;

				@include if-size("desktop") {
					font-size: 16px;
					line-height: 22px;
					letter-spacing: -0.01em;
				}
			}

			.savings-amount {
				color: $kelly-dark;
				background-color: $kelly-light;
				padding: 2px 12px;
				border-radius: 8px;

				font-weight: 622;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: -0.015em;

				@include if-size("desktop") {
					font-size: 16px;
					line-height: 22px;
					letter-spacing: -0.01em;
				}
			}
		}
	}
}

.left-side-items-wrapper {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}
