.terms-of-use {
	@include flex("flex-start", "flex-start", "column");
	width: 80%;
	max-width: 800px;
	min-height: max(calc(100vh - 250px), 250px);
	margin: spacing(8) auto;

	* {
		font-family: "sofia-pro";
	}

	@include respond-to("large") {
		margin: spacing(6) auto;
	}
}

.terms-of-use-mobile {
	@include flex("flex-start", "flex-start", "column");
	width: 85%;
	max-width: 800px;
	min-height: max(calc(100vh - 250px), 250px);
	margin: auto;
}

.terms-of-use-title {
	font-family: "sofia-pro";
	font-size: 32px;
	width: 100%;
	padding: 30px 0px 30px 0px;
}

.terms-of-use-subtitle {
	h4 {
		font-size: 18px;
	}
	width: 100%;
	padding: 10px 0px 10px 0px;
}

.terms-of-use-category {
	h2 {
		font-size: 20px;
	}
	width: 100%;
	padding: 30px 0px 30px 0px;
}

.terms-of-use-text {
	@include text("24px", bolder);
	width: 100%;
	padding: 30px 0px 30px 0px;
	text-align: center;
}

.terms-of-use-desc {
	@include text("16px", 300);
	padding: 0px 0px 10px 0px;

	ul,
	ol {
		padding-left: spacing(6);

		@include respond-to("medium") {
			padding-left: spacing(4);
		}
	}
}
