#landing-vp1-section {
	display: flex;
	justify-content: center;
	overflow-x: hidden;
	padding: spacing(12) spacing(6);
	background-color: $cloud;

	@include respond-to("large") {
		padding: spacing(8) spacing(4);
		flex-direction: column;
		align-items: center;
	}
}

#landing-vp1-grid {
	width: 90%;
	display: flex;
	justify-content: space-between;

	@include respond-to("large") {
		flex-direction: column;
	}
}

.landing-vp1-col {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&-stretch {
		@extend .landing-vp1-col;
	}

	h3 {
		text-align: center;
		font-size: 32px;
		line-height: 37px;
	}

	.top-text {
		margin-bottom: 58px;
	}

	.bottom-text {
		margin-top: 32px;
	}

	&:not(:last-of-type) {
		margin-right: min(2.5%, 120px);
	}

	#landing-vp1-cta {
		margin: 64px auto 0px;
	}
}

#landing-m-vp1-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	#landing-m-vp1-top {
		min-height: 475px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		overflow: hidden;
		margin-bottom: 32px;
	}
}

@keyframes slide-in {
	100% {
		right: 0px;
		left: 0px;
	}
}

@keyframes unhide {
	100% {
		top: 50%;
		left: 0%;
		right: 0%;
	}
}

.landing-m-vp1-carousel-item {
	position: absolute;
	top: 50%;
	width: 100%;
	height: auto;
	touch-action: pan-y;
	transform: translateY(-50%);
	transition: left linear 0.5s, right linear 0.5s;
	transform-origin: 50% 200vw;
}

.landing-m-carousel-img {
	display: flex;
	pointer-events: none;
	justify-content: center;
	user-select: none;
	margin: 0 auto;
}

.landing-m-carousel-text {
	text-align: center;
	line-height: 35px;
	font-weight: bold;
	width: min(450px, 90%);
	margin: 50px auto 0;
	user-select: none;
	font-size: 36px;

	@include respond-to("large") {
		font-size: 24px;
	}
}

.landing-m-vp1-bottom {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.landing-m-vp1-dot-container {
		display: flex;
		justify-content: center;

		.landing-m-carousel-dot {
			width: 12px;
			height: 12px;
			margin: 0 8px;
			border-radius: 50%;
			background-color: #bebebe;
			border: 2px solid #bebebe;
			cursor: pointer;

			&-active {
				@extend .landing-m-carousel-dot;
				background-color: $white;
			}
		}
	}
}

#landing-m-vp1-cta {
	margin: 32px auto 0;
}
