#home-insurance-hero {
	background: $sky-light;
	padding: spacing(6) 0 spacing(12);

	@include if-size("desktop") {
		padding: spacing(6) 0 spacing(15);
	}

	.grid {
		align-items: center;
	}

	#home-insurance-hero-img {
		grid-column: 1 / -1;
		margin: 0 auto spacing(2);
		max-width: 70%;
		height: auto;

		@include if-size("desktop") {
			width: 100%;
			max-width: 390px;
			grid-column: -5 / -1;
			margin: 0;
			grid-row: 1;
		}
	}

	#home-insurance-title-container {
		grid-column: 1 / -1;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include if-size("desktop") {
			grid-row: 1;
			grid-column: 1 / -6;
		}
	}

	#address-search-input-button {
		height: 32px;
		padding: spacing(1) spacing(3);
		font-size: 14px;
		line-height: 20px;

		@include if-size("desktop") {
			height: 48px;
			padding: 12px 40px;
			font-size: 18px;
			line-height: 26px; /* 144.444% */
			letter-spacing: -0.36px;
		}
	}
}

#home-insurance-landing {
	.home-insurance-common-spacing {
		padding: 0 0 spacing(8);
		@include if-size("desktop") {
			padding: 0 0 spacing(12);
		}
	}

	#landing-hero-address-search-with-results {
		max-width: none;
	}

	#landing-hero-address-input {
		margin-left: 0;
		margin-right: 0;
		padding: spacing(2) 0 0 0;
	}

	#landing-hero-value-props {
		margin-top: -40px;
		.lg {
			font-size: 14px;
			@include if-size("desktop") {
				font-size: 18px;
			}
		}
	}

	#landing-stat-section {
		margin: spacing(8) 0;
		@include if-size("desktop") {
			margin: 52px 0 96px;
		}
	}

	#generalized-signup-steps-section {
		padding: spacing(12) 0;
	}

	#landing-signup-steps-steps {
		align-items: flex-start;
	}

	.denim-lightest-bg {
		background-color: $denim-lightest;
	}

	.home-insurance-feature-row {
		@extend .home-insurance-common-spacing;

		&-top-row-only {
			padding-top: spacing(8);
			@include if-size("desktop") {
				padding-top: spacing(12);
			}
		}

		.home-insurance-feature-row-text {
			grid-column: 1/-1;
			@include if-size("desktop") {
				grid-column: 1/8;
				order: 1;
			}
		}
		.home-insurance-feature-row-img {
			grid-column: 1/-1;
			padding-bottom: spacing(3);
			text-align: center;
			@include if-size("desktop") {
				grid-column: 9/-1;
				order: 2;
				padding-bottom: 0;
			}

			svg {
				max-width: 300px;
				@include if-size("desktop") {
					max-width: none;
				}
			}
		}
	}

	.home-insurance-feature-row-left {
		@extend .home-insurance-common-spacing;
		.home-insurance-feature-row-left-text {
			grid-column: 1/-1;
			@include if-size("desktop") {
				margin: auto;
				grid-column: 6/-1;
				order: 2;
			}
		}
		.home-insurance-feature-row-left-img {
			grid-column: 1/-1;
			text-align: center;
			padding-bottom: spacing(3);
			@include if-size("desktop") {
				grid-column: 1/5;
				order: 1;
				padding-bottom: 0;
			}

			svg {
				max-width: 300px;
				@include if-size("desktop") {
					max-width: none;
				}
			}
		}
	}

	#landing-faq-section {
		padding-top: spacing(8);
		margin-bottom: 0;
		//padding-bottom: 0;
	}

	#home-insurance-disclaimer {
		padding-bottom: spacing(8);
		.disclaimer {
			grid-column: 1 / -1;
			color: $denim;
			font-size: 14px;
			line-height: 18px;
		}
	}
}
