$left-padding: spacing(2);

.vertical-step-counter {
	grid-column: 1 / -1;
	position: relative;
	padding-left: $left-padding;
	height: fit-content;

	@include if-size("desktop") {
		&:nth-of-type(1) {
			grid-column: 2 / 6;
		}

		&:nth-of-type(2) {
			grid-column: 7 / -2;
		}
	}

	.vertical-step-line {
		position: absolute;
		height: calc(100% - 25px);
		width: 2px;
		background-color: $azure;
		top: 12px;
		left: calc(5px + $left-padding);
		@include if-size("tablet") {
			left: calc(7px + $left-padding);
		}
	}

	.step {
		display: flex;
		align-items: center;

		&:not(:last-of-type) {
			margin-bottom: spacing(4);
		}

		.step-dot {
			min-width: 12px;
			height: 12px;
			width: 12px;
			border: 2px solid $azure;
			background-color: $white;
			margin: 3px spacing(2) 0 0;
			border-radius: 50%;
			z-index: 100;

			@include if-size("tablet") {
				width: 16px;
				height: 16px;
				min-width: 16px;
			}
		}

		.step-label {
			&:first-of-type {
				line-height: 1;
			}
		}
	}

	.step-active {
		@extend .step;

		.step-dot {
			background-color: $azure;
		}
	}
}

.vertical-step-counter-green {
	@extend .vertical-step-counter;

	.vertical-step-line {
		background-color: $mint;
	}

	.step {
		.step-dot {
			border: 2px solid $mint;
		}
	}

	.step-active {
		@extend .step;

		.step-dot {
			background-color: $mint;
		}
	}
}
