#magic-confirm-otc-page {
	.title-box {
		grid-column: -1 / 1;

		@include if-size("desktop") {
			grid-column: -3 / 3;
		}
	}

	#signature-page-properties-count {
		margin-top: spacing(6);
		grid-column: 1 / -1;
		color: $denim_5;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}
	}
}
