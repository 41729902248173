#share-referral-link {
	padding: spacing(2) spacing(4) spacing(2) spacing(4);
	background-color: $cloud;
	border-radius: 8px;

	&-header {
		display: flex;
		align-items: center;
		margin-bottom: spacing(1);
	}

	#referral-reflink-section {
		padding-top: spacing(2);
	}

	#referral-socials-section {
		margin-top: spacing(1);
	}
}
