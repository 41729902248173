.quick-link {
	width: fit-content;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	color: $azure;
	cursor: pointer;
	font-weight: 700;
	line-height: 1.5;

	.quick-link-arrow,
	.quick-link-cycle {
		display: block;
		fill: $azure;
		transition: transform 0.1s ease;
	}

	&-extra-large,
	&-large,
	&-default,
	&-small,
	&-extra-small,
	&-tiny {
		@extend .quick-link;
	}

	&-extra-large {
		font-size: 20px;
		.quick-link-arrow,
		.quick-link-cycle {
			height: 24px;
			width: 24px;
		}
	}

	&-large {
		font-size: 18px;
		.quick-link-arrow,
		.quick-link-cycle {
			height: 20px;
			width: 20px;
		}
	}

	&-default {
		font-size: 16px;
		.quick-link-arrow,
		.quick-link-cycle {
			height: 18px;
			width: 18px;
		}
	}

	&-small {
		font-size: 14px;
		.quick-link-arrow,
		.quick-link-cycle {
			margin-top: 2px;
			height: 16px;
			width: 16px;
		}
	}

	&-extra-small {
		font-size: 12px;
		.quick-link-arrow,
		.quick-link-cycle {
			height: 14px;
			width: 14px;
		}
	}

	&-tiny {
		font-size: 10px;
		.quick-link-arrow,
		.quick-link-cycle {
			margin-top: 1px;
			height: 12px;
			width: 14px;
		}
	}

	@include if-size("desktop") {
		&-extra-large {
			font-size: 24px;
			.quick-link-arrow,
			.quick-link-cycle {
				height: 24px;
				width: 24px;
			}
		}

		&-large {
			font-size: 20px;
			.quick-link-arrow,
			.quick-link-cycle {
				height: 20px;
				width: 20px;
			}
		}

		&-default {
			font-size: 18px;
			.quick-link-arrow,
			.quick-link-cycle {
				height: 18px;
				width: 18px;
			}
		}

		&-small {
			font-size: 16px;
			.quick-link-arrow,
			.quick-link-cycle {
				height: 16px;
				width: 16px;
			}
		}

		&-extra-small {
			font-size: 14px;
			.quick-link-arrow,
			.quick-link-cycle {
				height: 14px;
				width: 14px;
			}
		}

		&-tiny {
			font-size: 12px;
			.quick-link-arrow,
			.quick-link-cycle {
				height: 12px;
				width: 12px;
			}
		}
	}

	.quick-link-link {
		color: $azure;
		display: block;
		line-height: auto;
		display: flex;
		align-items: center;
		font-size: inherit;
		line-height: inherit;
	}

	.quick-link-arrow,
	.quick-link-cycle {
		display: block;
		fill: $azure;
		transition: transform 0.1s ease;

		&-reverse {
			@extend .quick-link-arrow;
			@extend .quick-link-cycle;

			transform: rotate(180deg);
		}
	}

	&-disabled {
		@extend .quick-link;
		cursor: not-allowed;
		opacity: 0.5;

		transition: none;

		&:hover {
			* {
				fill: $azure;
				color: $azure;
			}

			.quick-link-arrow,
			.quick-link-cycle {
				transform: scale(1) !important;
			}

			.quick-link-arrow-reverse,
			.quick-link-cycle-reverse {
				transform: rotate(180deg) scale(1) !important;
			}
		}
	}

	&:hover {
		* {
			fill: $royal;
			color: $royal;
		}

		.quick-link-arrow,
		.quick-link-cycle {
			transform: scale(1.2);
		}

		.quick-link-arrow-reverse,
		.quick-link-cycle-reverse {
			transform: rotate(180deg) scale(1.2);
		}
	}
}
