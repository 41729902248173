.accordion {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.accordion-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		font-weight: bold;
		.accordion-icon {
			height: 24px;
			width: 24px;
		}
		.xl {
			font-weight: 800;
		}
	}
	.accordion-content {
		ul li {
			margin-bottom: 4px;
		}
	}
}
