#landing-success-stories {
	&-section {
		width: 100%;
		padding: spacing(8) 0 spacing(8);

		@include respond-to("large") {
			padding: spacing(8) spacing(8);
		}
	}

	&-title {
		text-align: center;
		margin-bottom: 50px;
	}

	&-story-box {
		display: flex;
		justify-content: center;

		@include respond-to("large") {
			flex-direction: column;
			align-items: center;
		}

		.landing-success-stories-story-box-col:last-of-type {
			margin-left: 50px;
			max-width: 450px;

			@include respond-to("large") {
				margin-left: 0;
			}
		}

		.landing-success-stories-story-box-label {
			color: $azure;
			margin-top: spacing(6);
			margin-bottom: spacing(1);
		}

		.landing-success-stories-story-box-title {
			color: $royal;
			margin-bottom: spacing(1);
		}

		.landing-success-stories-story-box-description {
			margin-bottom: 1em;
		}

		.landing-success-stories-story-box-savings {
			color: $royal;
			margin-bottom: 1em;
		}

		.landing-success-stories-story-box-more {
			font-size: 18px;
			line-height: 24px;
			&:hover {
				text-decoration: underline;
			}
		}

		.landing-success-stories-img-container {
			position: relative;
			max-width: 576px;
		}
	}
}
