.flex {
	display: flex;

	&-column {
		@extend .flex;
		flex-direction: column;
	}
}

@each $breakpoint, $query in $new-breakpoints {
	@include if-size($breakpoint) {
		.#{$breakpoint}-flex {
			&-column {
				display: flex;
				flex-direction: column;
			}

			&-row {
				display: flex;
				flex-direction: row;
			}
		}
	}
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse;
}

.justify {
	&-center {
		justify-content: center;
	}

	&-between {
		justify-content: space-between;
	}

	&-around {
		justify-content: space-around;
	}

	&-start {
		justify-content: flex-start;
	}

	&-end {
		justify-content: flex-end;
	}
}

.items {
	&-center {
		align-items: center;
	}

	&-between {
		align-items: space-between;
	}

	&-around {
		align-items: space-around;
	}

	&-start {
		align-items: flex-start;
	}

	&-end {
		align-items: flex-end;
	}

	&-baseline {
		align-items: baseline;
	}
}

.align-self {
	&-center {
		align-self: center;
	}

	&-start {
		align-self: flex-start;
	}

	&-end {
		align-self: flex-end;
	}
}

.flex-gap {
	@for $i from 0 through 10 {
		&-#{$i} {
			gap: spacing($i);
		}

		&-#{$i - 1}_5 {
			gap: spacing($i - 0.5);
		}
	}
}

.flex-col-gap {
	@for $i from 0 through 10 {
		&-#{$i} {
			column-gap: spacing($i);
		}

		&-#{$i - 1}_5 {
			column-gap: spacing($i - 0.5);
		}
	}
}

.flex-row-gap {
	@for $i from 0 through 10 {
		&-#{$i} {
			row-gap: spacing($i);
		}

		&-#{$i - 1}_5 {
			row-gap: spacing($i - 0.5);
		}
	}
}

.flex-grow {
	@for $i from 0 through 10 {
		&-#{$i} {
			flex-grow: $i;
		}
	}
}
