#average-savings-banner {
	background-color: $sky-light;
	border: solid 1px $azure;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: spacing(1);
	color: $royal;

	border-radius: 8px;
	padding: 6px 12px;
	@include if-size("desktop-xl") {
		border-radius: 12px;
		padding: spacing(1) spacing(3);
	}

	h1 {
		font-size: 36px;
		line-height: 36px;
		font-family: "Moret";
		color: $royal;

		@include if-size("desktop-xl") {
			font-size: 50px;
			line-height: 50px;
		}
	}

	sup {
		font-size: 20px;
		line-height: 20px;
		font-family: "Moret";
		color: $royal;

		@include if-size("desktop-xl") {
			font-size: 32px;
			line-height: 32px;
		}
	}

	label {
		color: $royal;
		font-weight: bold;

		margin-top: 4px;
		@include if-size("desktop-xl") {
			margin-top: 12px;
		}
	}

	svg {
		margin-top: 4px;
		height: 28px;
		width: 28px;
		@include if-size("desktop-xl") {
			margin-top: 12px;
			height: 32px;
			width: 32px;
		}
	}
}
