#signature-page {
	padding-bottom: spacing(4);

	@include if-size("tablet") {
		padding-bottom: spacing(8);
	}

	#appeal-never-overpay-value-prop-section {
		background-color: $cloud;

		margin-top: 24px;
		padding: 64px 24px;

		display: flex;
		flex-direction: column;

		* {
			margin: 0 auto;
			max-width: 900px;
		}
	}

	#agreement-section {
		padding-top: 20px;
	}

	.your-information {
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}
	}

	#signature-page-title-section {
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}

		.title-section-spacing {
			padding-bottom: spacing(1);
		}

		.title-button-section {
			padding-top: spacing(4);

			button {
				margin: auto;
			}
		}
	}

	.signature-page-content-section {
		margin-top: spacing(3);
		background-color: $cloud;
		padding: spacing(2);
		border-radius: 8px;

		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}

		.body-spacing {
			padding-top: spacing(2);

			@include if-size("tablet") {
				padding-top: spacing(4);
			}

			&:last-of-type {
				padding-bottom: spacing(3);
			}
		}

		.title-spacing {
			padding-bottom: 0;

			@include if-size("tablet") {
				padding-bottom: spacing(1);
			}
		}
	}

	#signature-page-user-info {
		margin-top: spacing(3);
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}

		display: flex;
		flex-wrap: wrap;
		border-radius: 8px;
		background-color: $denim-lightest;
		padding: spacing(2);

		gap: spacing(2);

		.info-column {
			min-width: 100%;
			flex: 1;

			@include if-size("desktop") {
				min-width: calc(50% - spacing(2));
				flex: 1;
			}

			display: flex;
			flex-direction: column;
			gap: spacing(2);

			.info-field p {
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.info-field label {
				color: $denim_5;
			}
		}
	}

	#signature-page-properties-count {
		margin-top: spacing(4);
		grid-column: 1 / -1;
		color: $denim_5;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}
	}

	#signature-page-referral-section {
		grid-column: 1 / -1;

		border-radius: 8px;
		background-color: $cloud;
		padding: spacing(2);

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}

		margin-top: spacing(8);

		p.lg {
			margin-bottom: spacing(1);
		}

		#referral-reflink-section {
			margin-bottom: spacing(2);
		}
	}

	#signature-page-back-to-properties {
		padding: spacing(2) 0;
		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}

		.button-v2 {
			margin: spacing(2) auto;
		}
	}

	#appeal-contact-input-container {
		margin-top: spacing(6);
	}

	#signature-page-authorization-agreement {
		margin-top: spacing(6);
		margin-bottom: spacing(2);

		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 3 / -3;
		}

		ul {
			margin-left: spacing(4);
		}
	}

	#signature-page-authorization-agreement-phrase {
		margin-top: spacing(6);
		margin-bottom: spacing(2);

		grid-column: 1 / -1;

		@include if-size("desktop") {
			grid-column: 4 / -4;
		}
	}

	.appeal-legal-signature-container {
		max-width: unset;
	}
}
