#invoice-page {
	padding: spacing(4) 0 spacing(8);

	#invoice-reviews-banner {
		grid-column: 1 / -1;
		background-color: $denim;
		padding: spacing(2);
		border-radius: 16px;
		margin-bottom: spacing(4);

		p {
			color: $white;
		}

		.button-v2 {
			margin: spacing(2) auto 0;
			&:hover {
				background-color: $mint;
				color: $denim;
			}
		}

		@include if-size("desktop") {
			grid-column: 2 / -2;
			display: flex;
			flex-direction: row;
			padding: spacing(4);
			justify-content: space-between;
			margin-bottom: spacing(8);

			.button-v2 {
				margin: 0;
			}
		}
	}

	#invoice-contact-text {
		margin: 0 0 spacing(2);
		grid-column: 1/-1;
		display: flex;
		justify-content: space-between;

		@include if-size("desktop") {
			margin: 0 0 spacing(2);
			grid-column: 2 / -2;
		}
	}

	#invoice-no-invoices-banner {
		grid-column: 1 / -1;
		background-color: $azure;
		padding: spacing(2);
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		margin-bottom: spacing(4);

		p {
			color: $white;
		}
		p.lg {
			margin-bottom: spacing(1);
		}

		@include if-size("desktop") {
			grid-column: 2 / -2;
			margin-bottom: spacing(8);
		}
	}
}

#account {
	@media print {
		width: 100%;
		#top-nav,
		.button-v2,
		#invoice-contact-text,
		#mobile-nav-header,
		#invoice-show-paid {
			display: none;
		}

		#invoice-page {
			padding: 0;
		}

		.grid {
			max-width: 100%;
			display: block;
			min-width: auto !important; //needed for Safari to not cut off the right side of the page
		}
	}
}

.invoice-card {
	grid-column: 1 / -1;
	margin-bottom: spacing(4);

	@include if-size("desktop") {
		grid-column: 2 / -2;
	}

	.invoice-card-top {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		background-color: $denim;
		padding: spacing(2) spacing(4);
		border-radius: 16px;
		transition: border-radius 0.5s;

		@include if-size("tablet") {
			flex-direction: row;
			justify-content: space-between;
		}

		.invoice-card-amount-due-container {
			width: fit-content;
			text-align: right;
			margin: 0 auto;

			@include if-size("desktop") {
				margin: 0;
				margin-left: auto;
			}

			p,
			.label {
				text-align: center;
			}
		}

		.invoice-card-top-past-due-container {
			@extend .invoice-card-amount-due-container;
			background-color: $terracotta;
			padding: spacing(1) spacing(2);
			border-radius: 8px;
		}

		.invoice-title {
			margin: 4px 0;
		}

		p.expand-label:hover {
			color: $white !important;
		}

		p,
		.label,
		h3 {
			color: $white;
			text-align: center;

			&:hover {
				color: $white;
			}

			@include if-size("tablet") {
				text-align: initial;
			}
		}

		.invoice-card-top-address {
			padding-bottom: spacing(2);
			margin-bottom: spacing(2);
			border-bottom: 2px solid $white;

			@include if-size("tablet") {
				max-width: 50%;
			}

			.invoice-title {
				overflow-x: hidden;
				text-overflow: ellipsis;
				min-height: 31px;
			}

			@include if-size("tablet") {
				padding-bottom: 0;
				margin-bottom: 0;
				border-bottom: 0;
			}

			.invoice-card-top-address-line {
				display: flex;
				align-items: center;
				justify-content: center;

				@include if-size("tablet") {
					justify-content: flex-start;
				}

				svg {
					fill: $white;
					height: 16px;
					width: 16px;
					margin-right: 4px;
					margin-top: 4px;
				}
			}
		}

		.invoice-card-top-amount {
			.expand-button {
				margin: 0 auto;

				@include if-size("tablet") {
					margin: 0;
				}

				svg {
					fill: $white;
				}
			}
		}
	}

	.invoice-card-bottom {
		background-color: $denim-lightest;
		padding: spacing(4);
		border-bottom-right-radius: 16px;
		border-bottom-left-radius: 16px;

		.invoice-card-bottom-pending-banner {
			background-color: $terracotta;
			border-radius: 8px;
			width: 100%;
			text-align: center;
			padding: spacing(1) spacing(2);
			margin-bottom: spacing(4);

			p {
				color: $white;
			}
		}

		.invoice-card-bottom-cta {
			display: flex;
			flex-direction: column;
			align-items: center;
			border-bottom: 2px solid $white;
			padding-bottom: spacing(4);

			@include if-size("desktop") {
				flex-direction: row;
				justify-content: center;
			}

			.invoice-card-bottom-cta-btn-container {
				margin-bottom: spacing(2);
			}
			.invoice-card-bottom-cta-info-container {
				text-align: center;
				.label {
					margin-bottom: 4px;
				}
			}

			.invoice-card-bottom-cta-seperator {
				display: none;
				width: 2px;
				align-self: stretch;
				margin: 0 spacing(8);
				background-color: $white;

				@include if-size("desktop") {
					display: block;
				}
			}
		}
		.invoice-card-bottom-breakdown {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding-top: spacing(4);

			@include if-size("desktop") {
				flex-direction: row;
			}

			.invoice-card-bottom-breakdown-details {
				min-width: 25%;
				border-bottom: 2px solid $white;
				margin-bottom: spacing(4);

				@include if-size("desktop") {
					border-bottom: 0;
					margin-bottom: 0;
				}

				h5,
				.invoice-breakdown-data-point {
					margin-bottom: spacing(4);
					* {
						max-width: 30ch;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
			}
			.invoice-card-bottom-breakdown-line-items {
				flex: 1;

				@include if-size("desktop") {
					margin-left: spacing(6);
					padding-left: spacing(6);
					border-left: 2px solid $white;
				}
			}
		}
	}

	&.invoice-card-variant-minimal {
		.invoice-card-bottom {
			&.collapsed {
				display: none;
			}

			.view-invoice-details-button {
				display: none;
			}
		}
	}

	&.invoice-card-variant-full {
		.invoice-card-top {
			border-radius: 16px 16px 0 0;

			.expand-button {
				display: none;
			}
		}

		.invoice-card-bottom {
			position: relative;

			&.collapsed {
				max-height: 144px;
				overflow: clip;

				.invoice-card-bottom-opacity-fade {
					height: 100%;
					width: 100%;
					background: linear-gradient(to bottom, transparent, white);
					position: absolute;
					top: 0;
					left: 0;
				}

				.view-invoice-details-button {
					display: block;
					position: absolute;
					bottom: 32px;
					left: 50%;
					transform: translateX(-50%);
					z-index: 20;
				}
			}

			.view-invoice-details-button {
				display: block;
				margin: 0 auto;
				margin-top: spacing(2);
			}
		}
	}

	.invoice-card-bottom-breakdown-footnote {
		margin-top: spacing(4);
	}
}

.invoice-card-expanded {
	.invoice-card-top {
		border-radius: 16px 16px 0 0;
	}
}

.invoice-card-paid {
	.invoice-card-top {
		background-color: $slate;
		p,
		.label,
		h3,
		path {
			color: $denim;
			fill: $denim;

			&:hover {
				color: $denim;
			}
		}
	}
}

#invoice-show-paid {
	grid-column: 1 / -1;
	justify-content: center;
	.expand-button {
		margin: spacing(2) auto spacing(4);
	}
}

.invoice-card-breakdown-total-savings {
	display: flex;
	justify-content: center;
}

.download-contingency-fee-button,
.invoice-print-button {
	cursor: pointer;
	color: $azure;
	display: flex;
	align-items: center;
	gap: 4px;

	p {
		display: inline-block;
		color: $azure;
	}
	svg {
		margin-top: 2px;
		height: 16px;
		width: 16px;
	}
}

.invoice-card-show-on-print-only {
	display: none;
}

#invoice-page,
#pay-invoice-page {
	@media print {
		* {
			font-family: "Times New Roman", Times, serif !important;
		}

		#pay-invoice-other-invoices {
			display: none;
		}

		.invoice-card {
			display: none;
		}
		.invoice-card-print {
			display: flex;
			flex-direction: column-reverse;
		}

		.label,
		p,
		h5 {
			color: black !important;
		}

		.expand-button,
		.invoice-card-top-address {
			display: none;
		}

		.invoice-card-top-amount {
			display: none;
		}

		.invoice-card-bottom-cta {
			display: none;
		}

		.invoice-card-bottom {
			display: block;
			padding: 8px;
			background-color: none;
		}

		.invoice-card-bottom-breakdown {
			padding-top: 0;
			flex-direction: column;
		}

		.invoice-breakdown-data-point {
			margin: 0;
		}

		.invoice-card-bottom-breakdown-details {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-top: 6px;
			border-top: 2px solid black;

			h5 {
				display: none;
			}

			p {
				font-size: 14px;
			}
		}

		.invoice-card-bottom-breakdown-line-items {
			margin-left: 0;
			padding-left: 0;
			border-left: 0;

			h5 {
				font-size: 18px;
			}

			p {
				font-size: 14px;
			}

			table {
				border-radius: 0 !important;
			}

			tr {
				border: 0 !important;
			}

			td {
				border: 1px solid black !important;
				font-size: 16px;
				color: black !important;
				padding: 2px 4px !important;
				background-color: none;
			}

			.pricing-calculate-your-bill-td-with-math-symbols {
				display: table-cell;

				.pricing-calculate-your-bill-math-symbols {
					float: right;
				}
			}

			.pricing-calculate-your-bills-divider {
				display: none;
			}
		}

		#pricing-calculate-your-bill,
		.pricing-calculate-your-bill-steps {
			margin-top: 8px;
		}

		#pay-invoice-page-header,
		#pay-invoice-form-card,
		#pay-invoice-pay-by-check-card,
		#pay-invoice-questions-container,
		.invoice-print-button,
		.invoice-card-hide-on-print {
			display: none;
		}

		.invoice-card-show-on-print-only {
			display: block;
		}
	}
}

@media print {
	#minimal-site-footer {
		display: none;
	}

	#pay-invoice-page {
		padding: 0;
	}
	#minimal-site-header {
		padding: 0;
	}

	iframe,
	#launcher {
		display: none !important;
		height: 0 !important;
		width: 0 !important;
		border: 0 !important;
		position: absolute !important;
		top: -10000px !important;
	}
}
