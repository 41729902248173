/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * moret:
 *   - http://typekit.com/eulas/00000000000000007735cc79
 *   - http://typekit.com/eulas/00000000000000007735cc7c
 *   - http://typekit.com/eulas/00000000000000007735cc7f
 * sofia-pro:
 *   - http://typekit.com/eulas/00000000000000007735f999
 *   - http://typekit.com/eulas/00000000000000007735f993
 *   - http://typekit.com/eulas/00000000000000007735f992
 *   - http://typekit.com/eulas/00000000000000007735f99b
 *   - http://typekit.com/eulas/00000000000000007735f98d
 *   - http://typekit.com/eulas/00000000000000007735f98c
 *   - http://typekit.com/eulas/00000000000000007735f99a
 *   - http://typekit.com/eulas/00000000000000007735f994
 * turbinado-pro:
 *   - http://typekit.com/eulas/00000000000000007735c812
 *
 * © 2009-2024 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2024-04-08 16:20:05 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=bvl3tlq&ht=tk&f=24539.24540.24545.24546.24547.24548.24549.24552.43128.43130.43131.44563&a=102005953&app=typekit&e=css");

//-- This and another font causes issues when printing
@font-face {
	font-family: "sofia-pro";
	src: url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
			format("woff"),
		url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 800;
	font-stretch: normal;
}

@font-face {
	font-family: "sofia-pro";
	src: url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
			format("woff"),
		url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
			format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 800;
	font-stretch: normal;
}

@font-face {
	font-family: "sofia-pro";
	src: url("https://use.typekit.net/af/19ced7/00000000000000007735f992/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/19ced7/00000000000000007735f992/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
			format("woff"),
		url("https://use.typekit.net/af/19ced7/00000000000000007735f992/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: "sofia-pro";
	src: url("https://use.typekit.net/af/33e8a4/00000000000000007735f99b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/33e8a4/00000000000000007735f99b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
			format("woff"),
		url("https://use.typekit.net/af/33e8a4/00000000000000007735f99b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
			format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: "sofia-pro";
	src: url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("woff"),
		url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "sofia-pro";
	src: url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
			format("woff"),
		url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
			format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}

// --- This also causes issues when printing
@font-face {
	font-family: "sofia-pro";
	src: url("https://use.typekit.net/af/262452/00000000000000007735f99a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/262452/00000000000000007735f99a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("woff"),
		url("https://use.typekit.net/af/262452/00000000000000007735f99a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: "sofia-pro";
	src: url("https://use.typekit.net/af/36f509/00000000000000007735f994/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/36f509/00000000000000007735f994/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
			format("woff"),
		url("https://use.typekit.net/af/36f509/00000000000000007735f994/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
			format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: "moret";
	src: url("https://use.typekit.net/af/4874f9/00000000000000007735cc79/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/4874f9/00000000000000007735cc79/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("woff"),
		url("https://use.typekit.net/af/4874f9/00000000000000007735cc79/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "moret";
	src: url("https://use.typekit.net/af/1bb177/00000000000000007735cc7c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/1bb177/00000000000000007735cc7c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
			format("woff"),
		url("https://use.typekit.net/af/1bb177/00000000000000007735cc7c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: "moret";
	src: url("https://use.typekit.net/af/006c2b/00000000000000007735cc7f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/006c2b/00000000000000007735cc7f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
			format("woff"),
		url("https://use.typekit.net/af/006c2b/00000000000000007735cc7f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
			format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: "turbinado-pro";
	src: url("https://use.typekit.net/af/f7048b/00000000000000007735c812/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/f7048b/00000000000000007735c812/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
			format("woff"),
		url("https://use.typekit.net/af/f7048b/00000000000000007735c812/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 300;
	font-stretch: normal;
}

.tk-sofia-pro {
	font-family: "sofia-pro", sans-serif;
}
.tk-moret {
	font-family: "moret", serif;
}
.tk-turbinado-pro {
	font-family: "turbinado-pro", sans-serif;
}
