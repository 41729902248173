.feedback-feature {
	background-color: $white;
	border: 1px solid $slate;
	width: 100%;
	border-radius: 8px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	padding: spacing(2);
	animation: fade-in 1s ease-in 2s 1 normal both;
	-webkit-animation: fade-in 1s ease-in 2s 1 normal both;

	.feedback-feature-button-container {
		display: flex;
		flex-direction: row;
		margin-top: spacing(2);
		gap: 8px;
	}

	.feedback-feature-button {
		border: 1px solid;
		border-radius: 4px;
		background-color: transparent;
		padding: spacing(1) spacing(2);
		cursor: pointer;
		width: 100%;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	.feedback-feature-button-thumbs-down {
		border-color: $rust;

		&:hover {
			background-color: rgba(206, 101, 73, 0.1);
		}
	}

	.feedback-feature-button-thumbs-up {
		border-color: $kelly;
		padding: 11px 16px 8px 16px;
		&:hover {
			background-color: rgba(87, 201, 117, 0.1);
		}
	}

	.feedback-comment {
		&-text-area {
			width: 100%;
			resize: none;
			margin-top: spacing(1);
			padding: spacing(1) spacing(2);

			border: solid 1px $denim_5;

			font-size: 14px;
			line-height: 18px;
			@include if-size("desktop-xl") {
				font-size: 16px;
				line-height: 24px;
			}

			height: 90px;
			@include if-size("desktop-xl") {
				height: 84px;
			}
		}

		&-submit-button {
			margin-top: spacing(1);
		}
	}

	&::placeholder {
		color: $denim_5;
	}
}

.feedback-feature-rating-given {
	width: 100%;
	background-color: $white;
	display: flex;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid $slate;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	padding: spacing(2);
	overflow: hidden;

	@keyframes partypopper {
		0% {
			transform: rotate(10deg);
		}
		25% {
			transform: rotate(-20deg);
		}
		50% {
			transform: rotate(10deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}

	@keyframes shrink {
		0% {
			height: 58px;
			opacity: 1;
		}
		100% {
			height: 0px;
			padding: 0px;
			border-width: 0px;
			opacity: 0;
			display: hidden;
			margin: 0;
		}
	}
	animation: fade-in 0.5s ease-in-out, shrink 0.5s ease-out 5s 1 normal forwards;

	svg {
		width: 24px;
		height: 24px;
		animation: partypopper 1s ease-in-out;
		-webkit-animation: partypopper 1s ease-in-out;
	}
}

.feedback-feature-sean-ellis {
	@extend .feedback-feature;
	grid-column: 1/-1;
	animation: none;

	.feedback-feature-button-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
		@include if-size("desktop") {
			flex-direction: row;
		}
	}

	.feedback-feature-button {
		display: flex;
		gap: 8px;
		justify-content: center;
		border-color: $slate;

		&:hover {
			border-color: $azure;
			background-color: $sky-light;
		}
	}

	svg {
		width: 16px;
		height: 16px;
		@include if-size("desktop") {
			width: 24px;
			height: 24px;
			margin-top: 1px;
		}
	}

	.feedback-comment-text-area::placeholder {
		text-align: center;
	}
}
