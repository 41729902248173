.radial-progress-chart {
	position: relative;
	width: 100%;

	&-center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;

		&-value {
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;

			@include if-size("desktop-xl") {
				font-size: 40px;
				line-height: 48px;
			}
		}

		&-label {
			text-align: center;
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;

			@include if-size("desktop-xl") {
				font-size: 16px;
				line-height: 24px;
			}
		}
	}
}
