#landing-vp2-section {
	background-color: $sky-light;
	overflow-x: hidden;
	padding: spacing(8) 0 spacing(8);

	@include respond-to("large") {
		padding: spacing(8) 0;
	}
}

#landing-vp2-grid {
	width: 90%;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;

	@include respond-to("large") {
		max-width: 600px;
		flex-direction: column;
	}
}

#landing-vp2-title {
	text-align: center;
	grid-column: 1 / -1;

	@include if-size("desktop") {
		margin-bottom: spacing(5);
		text-align: center;
		padding: 0 12px;
	}
}

.landing-vp2-col {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: span 4;
	}

	.landing-vp2-img-container {
		position: relative;
		width: 208px;
		margin-bottom: spacing(1);
		display: flex;
		align-items: flex-end;
		margin-top: spacing(5);

		@include if-size("desktop") {
			min-height: 220px;
			margin-top: 0;
		}

		.landing-vp2-step-counter {
			position: absolute;
			bottom: 0;
			left: 20px;
			height: 46px;
			width: 46px;
			background-color: $azure;
			border-radius: 4px;
			padding: 6px;
			display: flex;
			justify-content: center;
			align-items: center;

			h3 {
				color: $white;
			}
		}
	}

	.landing-vp2-col-title {
		text-align: center;
		color: $royal;
		margin: spacing(4) 0 spacing(2);

		white-space: pre-wrap;

		&.landing-vp2-col-title-commercial {
			min-height: auto;
		}

		@include if-size("desktop") {
			min-height: 80px;
		}
	}

	// TODO: Maybe put back once text-sizes change
	.landing-vp2-col-body {
		padding: 0 spacing(2);

		white-space: pre-wrap;
	}
}
#landing-vp2-cta-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: spacing(8);

	@include respond-to("large") {
		margin-top: spacing(6);
	}
}
