.file-upload-container {
	.file-upload-label {
		margin-bottom: spacing(1);
		margin-left: spacing(1);
	}

	.file-upload-error-row {
		margin-top: spacing(1);
		display: flex;
		align-items: center;

		svg {
			min-width: 12px;
			margin: 3px 6px 0 0;
		}

		.file-upload-error-text {
			color: $terracotta;
		}
	}

	.file-upload {
		width: 100%;
		border-radius: 8px;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%230532497D' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
		padding: spacing(3);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #fff;

		&-active {
			@extend .file-upload;
			background-color: $sand;
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%231C7CE5FF' stroke-width='6' stroke-dasharray='6%2c 14' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
		}

		&-error {
			@extend .file-upload;
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23DA845FFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='13' stroke-linecap='square'/%3e%3c/svg%3e");
		}

		&-row {
			&:nth-of-type(1),
			&:nth-of-type(2) {
				@include if-size("tablet") {
					display: flex;
				}
			}

			display: flex;
			justify-content: center;
			align-items: center;
		}

		.file-upload-active-text {
			color: $azure;
		}

		.file-upload-icon {
			margin-right: spacing(1);
			min-width: 14px;
			min-height: 16px;
			width: 14px;
			height: 14px;
		}

		.file-upload-icon-active {
			width: 32px;
			height: 32px;
		}

		.file-upload-or,
		.file-upload-filetypes {
			color: $denim;
			opacity: 0.5;
		}

		.file-upload-filetypes {
			margin-top: spacing(1);
		}
	}

	.file-upload-results {
		margin-top: spacing(2);

		label {
			margin-top: spacing(3);
			margin-bottom: spacing(1);
		}
	}
}

.file-upload-results-file {
	background-color: #fff;
	box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding: spacing(2);
	margin-bottom: spacing(1);
	display: flex;
	align-items: center;
	justify-content: space-between;

	gap: spacing(2);
	position: relative;
	overflow: hidden;

	.file-upload-results-file-left-side {
		max-width: 70%;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		row-gap: spacing(1);
		column-gap: spacing(2);

		@include if-size("tablet") {
			max-width: calc(100% - 36px);
		}
	}

	&.file-error {
		border: 1px solid $rust;

		.file-upload-results-file-icon {
			path {
				fill: $rust;
			}
		}

		.file-icon-name-status p {
			color: $rust;
		}
	}

	&.file-success {
		.file-upload-results-file-icon {
			path {
				fill: $azure;
			}
		}

		.file-icon-name-status p {
			font-weight: bold;
			color: $azure;
		}
	}

	.file-icon-name-status {
		max-width: 100%;
		z-index: 1;
		display: flex;
		align-items: center;
		gap: spacing(1);

		p {
			flex: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: fit-content;
			padding-bottom: 4px;
			line-height: unset;
		}
	}

	.file-upload-results-status-progress-bar {
		z-index: 0;
		background-color: $sky-light;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}

	.file-upload-results-status-spinner {
		z-index: 1;
		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}

		width: 16px;
		height: 16px;
		border: 2px solid $azure;
		border-top: 2px solid $white;
		border-radius: 50%;
		animation: spin 1s linear infinite;
	}

	.file-upload-results-file-icon {
		height: 16px;
		width: 16px;
		min-width: 16px;
	}

	.file-upload-icon {
		width: 14px;
		min-width: 14px;
	}

	.file-upload-results-try-again {
		cursor: pointer;
		white-space: nowrap;

		border-color: $rust;
		color: $rust;
	}

	.file-upload-results-remove {
		flex: 0;
		display: flex;
		align-items: center;
		cursor: pointer;
		opacity: 0.5;

		svg {
			height: 20px;
			width: 20px;

			path {
				fill: #bbccd4;
			}
		}
	}

	.file-upload-file-name-p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.file-upload-results-spinner {
	animation: spin 2s linear infinite;
}

.file-upload-results-icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	max-height: 20px;
	max-width: 20px;
	height: fit-content;
	width: fit-content;
	margin-left: auto;

	svg {
		height: 100%;
		width: 100%;
	}
}

.ownwell-uploaded-file-pill {
	display: flex;
	justify-content: center;
	align-items: center;
	@include if-size("tablet") {
		background-color: $slate;
		padding: spacing(0.5) spacing(1);
		border-radius: 9999px;
		margin: 0 spacing(1);
	}

	label.label {
		padding: 0;
		white-space: pre;
		display: none;

		@include if-size("tablet") {
			margin-left: spacing(1);
			display: block;
		}
	}

	svg {
		height: 16px;
		width: 16px;

		@include if-size("tablet") {
			height: 12px;
			width: 12px;
		}
	}
}

.file-upload-title-row {
	display: flex;
	align-items: center;
	gap: spacing(2);
}
