#neighborhood-page-desktop,
#permits-tab-desktop,
#crime-tab-desktop {
	display: none;

	@include if-size("desktop") {
		display: block;
	}
	.crime-content-wrappper {
		&.flex-col-reverse {
			display: flex;
			flex-direction: column-reverse;
			gap: 20px;
		}
	}
}

#neighborhood-page-mobile,
#permits-tab-mobile,
#crime-tab-mobile {
	display: block;

	@include if-size("desktop") {
		display: none;
	}
}

#neighborhood-feedback {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	@include if-size("desktop") {
		margin-bottom: 16px;
		position: unset;
	}
}

.neighborhood-page-heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.neighborhood-page-table-heading-hover-sort-container {
	background: $sky;
	padding: 2px;
	margin-left: 4px;
	border-radius: 1000px;
	height: 16px;
	width: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-left: 0px !important;
	}
}

#neighborhood-page-crime-card {
	padding: spacing(3);
	border-radius: 16px;
	border: 1px solid $slate;
	box-shadow:
		-44px 319px 90px 0px rgba(171, 171, 171, 0),
		-28px 204px 82px 0px rgba(171, 171, 171, 0.01),
		-16px 115px 70px 0px rgba(171, 171, 171, 0.05),
		-7px 51px 51px 0px rgba(171, 171, 171, 0.09),
		-2px 13px 28px 0px rgba(171, 171, 171, 0.1);
}

#neighborhood-page-crime-card-heading {
	display: flex;
	align-items: center;
	margin-bottom: spacing(2);

	#neighborhood-page-crime-card-heading-count-pill {
		padding: 6px spacing(1);
		border-radius: 8px;
		margin-left: spacing(2);

		&-rust {
			@extend #neighborhood-page-crime-card-heading-count-pill;
			background: $rust-lightest;

			> p.sm {
				color: $rust-dark;
			}
		}

		&-kelly {
			@extend #neighborhood-page-crime-card-heading-count-pill;
			background: $kelly-lightest;
			border: 1px solid $kelly;

			> p.sm {
				color: $kelly-dark;
			}
		}
	}
}

#neighborhood-page-crime-card-switch {
	width: calc(100% - 64px);
	margin: 0 auto;
	display: flex;
	background: $white;
	border-radius: 10000px;
	padding: 4px;
	border: 1px solid $denim-lightest;
	z-index: 999;
}

.neighborhood-page-crime-card-switch-item {
	flex: 1;
	padding: spacing(0.5) spacing(2);
	border-radius: 10000px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		height: 14px;
		width: 14px;
		margin-top: 2px;
		margin-right: 4px;
		path {
			fill: $denim_5;
		}
	}

	p {
		color: $denim_5;
		white-space: nowrap;
		user-select: none;
	}

	&-active {
		@extend .neighborhood-page-crime-card-switch-item;
		background: $sky;
		border: 1px solid $azure;

		svg {
			path {
				fill: $azure;
			}
		}

		p {
			color: $azure;
		}
	}
}

#neighborhood-page-list-view {
	display: flex;
	flex-direction: column-reverse;

	@include if-size("desktop") {
		flex-direction: row;
		gap: spacing(2);
		min-height: 485px;
		margin-bottom: spacing(4);
	}
}

#neighborhood-page-crime-maps {
	width: 100%;
	position: relative;
	flex: 1;
	overflow: hidden;
	border-radius: 8px;
}

#neighborhood-page-crime-table {
	flex: 1;
	position: relative;
	display: flex;
	overflow: auto;
	max-height: 300px;

	@include if-size("desktop") {
		max-height: 500px;
	}
}

.neighborhood-page-table {
	table-layout: fixed;
	width: 100%;
	height: fit-content;
	border-bottom: 1px solid $slate;

	thead,
	tbody {
		width: 100%;
	}

	thead {
		z-index: 100;
		position: sticky;
		top: 0;
	}

	th {
		background: $denim-lightest;
		padding: spacing(2);
		color: $denim;
		text-align: left;

		&:not(:last-of-type) {
			border-right: 1px solid $sand;
		}
	}

	.neighborhood-page-table-heading {
		&:hover {
			background: $sky-light;
		}
	}

	.neighborhood-page-table-sort-desc,
	.neighborhood-page-table-sort-asc {
		margin-left: 4px;
	}

	.neighborhood-page-table-sort-asc {
		transform: rotate(180deg);
	}

	tr {
		z-index: 99;
		width: 100%;
		cursor: pointer;
		height: fit-content;

		&:not(:last-of-type) {
			border-bottom: 1px solid $sand;
		}

		&:hover {
			background: rgba(#f7f9fc, 0.5);
		}
	}

	td {
		overflow: hidden;
	}

	.highlighted-row {
		background: $denim-lightest;
		overflow: hidden;

		.neighborhood-page-table-cell:not(:last-of-type) {
			border-right-color: $sky;
		}
	}

	.incident-dot {
		min-height: 8px;
		min-width: 8px;
		margin-right: 16px;
		border-radius: 24px;
		background: none;

		&-new {
			@extend .incident-dot;
			background: $rust;
		}
	}

	.incident-category-badge {
		padding: 4px 6px;
		border-radius: 6px;
		background: $sky-light;
		width: fit-content;
		margin-top: spacing(1);

		p.sm {
			color: $royal;
		}

		p.xs {
			font-size: 12px;
		}
	}

	.unhighlighted-row {
		position: relative;
		//
		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	background: $denim;
		// 	opacity: 0.2;
		// }
	}

	.neighborhood-page-table-cell {
		padding: spacing(1);
		z-index: 99;
		width: 100%;

		&:not(:last-of-type) {
			border-right: 1px solid $sand;
		}
	}

	.incident_address_cell {
		p {
			text-decoration: underline;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.incident_date,
	.incident_distance {
		width: 110px;
		font-size: 14px;
	}

	.permit_type {
		width: 200px;
	}

	.distance {
		width: 130px;
	}

	.incident_address,
	.incident_offense {
		width: 230px;
	}

	.incident_description {
		* > p {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.incident_description > div > p.sm {
		font-size: 12px;

		@include if-size("desktop") {
			font-size: 14px;
		}
	}
}

#neighborhood-page-crime-bar-chart {
	min-height: 500px;
}

#neighborhood-crime-bar-chart-tooltip {
	background: $white;
	padding: spacing(2);
	border-radius: 8px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.15);
}

#neighborhood-maps-highlighted-incident-info {
	position: absolute;
	left: 50%;
	bottom: 32px;
	transform: translateX(-50%);
	padding: spacing(2);
	background: $white;
	border-radius: 12px;
	max-width: min(calc(100% - 32px), 600px);
	border: 1px solid #d4dee3;
	width: 100%;

	@include if-size("desktop") {
		bottom: 16px;
	}

	.neighborhood-maps-highlighted-incident-info-timestamp {
		width: fit-content;
		white-space: nowrap;
	}

	@include if-size("desktop") {
		.incident-info-address {
			white-space: nowrap;
		}

		.incident-info-distance {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	#neighborhood-maps-highlighted-incident-info-bottom-row {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 4px;

		@include if-size("desktop") {
			flex-direction: row;
			align-items: center;
		}
	}
}

#crime-tab-mobile {
	#neighborhood-page-crime-maps {
		position: absolute;
		top: 42px;
		left: 0;
		height: calc(100% - 42px);
		border-radius: 0px;
	}

	#neighborhood-page-mobile-list {
		padding: spacing(9) spacing(2) spacing(4);
		background: $denim-lightest;
		height: 100%;
		overflow: auto;
	}
}

.neighborhood-page-mobile-list-item {
	padding: spacing(2);
	box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
	border-radius: 12px;
	border: 1px solid $slate;
	background: $white;

	svg {
		margin-top: 2px;
		margin-left: spacing(1);
	}

	&:not(:last-of-type) {
		margin-bottom: spacing(2);
	}

	.label {
		font-size: 16px;
		color: $rust-dark;
	}
}

.neighborhood-page-mobile-list-item-expanded {
	margin-top: spacing(2);
	background: $denim-lightest;
	padding: spacing(2);
	border-radius: 8px;
}

#neighborhood-page-crime-card-switch {
	position: absolute;
	top: 52px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;
}

#neighborhood-maps-error-overlay {
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 90%;
	height: fit-content;
	background: rgba($white, 0.6);
	z-index: 1000;
	text-align: center;
	border: 1px solid $slate;
	border-radius: 16px;
	backdrop-filter: blur(8px);
	padding: spacing(6) spacing(2);

	@include if-size("desktop") {
		width: 80%;
		height: 80%;
	}

	#neighborhood-maps-error-overlay-animation {
		width: 80px;

		@include if-size("desktop") {
			width: 100px;
		}
	}
}

#neighborhood-page-dead-state-card {
	height: 100%;
	width: 80%;
	border-radius: 16px;
	border: 1px solid $slate;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include if-size("desktop") {
		height: fit-content;
	}
}

#neighborhood-crime-trends-pie-chart-card {
}

#neighborhood-crime-trends-pie-chart-container {
	g,
	path {
		&:focus {
			outline: none;
		}
	}

	foreignObject {
		overflow: visible;
	}

	.neighborhood-crime-trends-pie-chart-active-label {
		background: $marshmallow;
		border-radius: 8px;
		display: none;
		width: max-content;
		height: fit-content;
		padding: 4px 8px;

		@include if-size("desktop") {
			display: block;
		}

		p.extra-small {
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

#neighborhood-page-pie-chart {
	width: 100%;
	border: 1px solid $slate;
	border-radius: 16px;
	padding: spacing(4);

	@include if-size("desktop-xl") {
		width: 50%;
	}
}

#neighborhood-page-legend {
	margin-top: spacing(3);
	width: 100%;
	border: 1px solid $slate;
	border-radius: 16px;
	padding: spacing(2) spacing(8);
}

#neighborhood-crime-trends-legend-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@include if-size("desktop") {
		grid-template-columns: repeat(
			auto-fill,
			max(calc(20% - spacing(1)), 180px)
		);
	}

	.neighborhood-crime-trends-legend-item {
		display: flex;
		align-items: center;
		gap: spacing(1);
		grid-column: span 1;
	}

	&-vertical {
		@extend #neighborhood-crime-trends-legend-container;
		gap: spacing(2);

		.neighborhood-crime-trends-legend-item {
			flex: 0 0 50%;
		}
	}

	&-horizontal {
		@extend #neighborhood-crime-trends-legend-container;
		justify-content: space-between;
		row-gap: spacing(1);
		column-gap: spacing(2);

		.neighborhood-crime-trends-legend-item {
			width: calc(20% - spacing(2));
			white-space: nowrap;
		}
	}

	.legend-dot {
		margin-top: 2px;
		min-width: 14px;
		min-height: 14px;
		width: 14px;
		height: 14px;
		border-radius: 28px;
	}
}

#neighborhood-page-bar-chart {
	flex: 1;
	border: 1px solid $slate;
	border-radius: 16px;
	padding: spacing(4);
	width: 100%;

	@include if-size("desktop-xl") {
		width: 50%;
	}

	#neighborhood-crime-trends-bar-chart {
		height: 100%;
		width: 100%;
	}
}

.neighborhood-page-trends-container {
	display: flex;
	gap: spacing(4);
	flex-direction: column;

	@include if-size("desktop-xl") {
		flex-direction: row;
	}
}

#neighborhood-page-mobile-crime-trends-bar-chart,
#neighborhood-page-mobile-crime-trends-pie-chart {
	height: 100%;
	overflow: auto;
	margin-top: spacing(10);
	border-radius: 16px;
	border: 1px solid $slate;
	padding: spacing(2);
}

.neighborhood-crime-trends-pie-chart-active-label-mobile {
	background: $marshmallow;
	border-radius: 8px;
	width: max-content;
	height: fit-content;
	padding: 4px 8px;
	margin: -16px auto 16px;

	@include if-size("desktop") {
		display: none;
	}
}

#neighborhood-page-desktop-nav {
	display: flex;
	gap: spacing(2);
	width: 100%;
	border-bottom: 1px solid $slate;

	.neighborhood-page-desktop-nav-tab {
		padding-bottom: spacing(2);
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;

		&-active {
			@extend .neighborhood-page-desktop-nav-tab;
			border-bottom: 2px solid $denim;
		}

		.neighborhood-page-desktop-nav-pill {
			padding: 4px 8px;
			border-radius: 8px;
			display: flex;
			align-items: center;

			&-rust {
				@extend .neighborhood-page-desktop-nav-pill;
				background: $rust-lightest;

				p.extra-small {
					color: $rust-dark;
				}
			}
		}
	}
}

#neighborhood-page-mobile-nav {
	position: absolute;
	top: 0;
	left: 0;
	height: 42px;
	width: 100%;
	display: flex;

	.neighborhood-page-mobile-nav-tab {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&-active {
			@extend .neighborhood-page-mobile-nav-tab;
			border-bottom: 2px solid $denim;
			background: $denim-lightest;
		}

		&:first-of-type {
			padding-left: spacing(1);
		}

		&:last-of-type {
			padding-right: spacing(1);
		}

		.neighborhood-page-mobile-nav-pill {
			margin-left: 8px;
			padding: 4px 8px;
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-items: center;

			p.extra-small {
				font-weight: 600;
			}

			&-rust {
				@extend .neighborhood-page-mobile-nav-pill;
				background: $rust-lightest;

				p.extra-small {
					color: $rust-dark;
				}
			}
		}
	}
}

#neighborhood-page-card {
	padding: spacing(3);
	border-radius: 16px;
	border: 1px solid $slate;
	box-shadow:
		-44px 319px 90px 0px rgba(171, 171, 171, 0),
		-28px 204px 82px 0px rgba(171, 171, 171, 0.01),
		-16px 115px 70px 0px rgba(171, 171, 171, 0.05),
		-7px 51px 51px 0px rgba(171, 171, 171, 0.09),
		-2px 13px 28px 0px rgba(171, 171, 171, 0.1);
}

#permits-tab-list-view {
	display: flex;
	flex-direction: column-reverse;

	@include if-size("desktop") {
		margin-top: spacing(2);
		flex-direction: row;
		gap: spacing(2);
		min-height: 485px;
		margin-bottom: spacing(4);
	}
}

#permits-tab-table {
	flex: 1;
	position: relative;
	display: flex;
	overflow: auto;
	max-height: 300px;

	@include if-size("desktop") {
		max-height: 650px;
	}
}

#permits-tab-desktop-map {
	width: 100%;
	position: relative;
	flex: 1;
	overflow: hidden;
	border-radius: 8px;
	display: flex;
	flex-direction: column;

	#permits-map {
		flex: 1;
		position: relative;
	}
}

.permit-tag {
	padding: 2px 4px;
	background: $sky-light;
	border: 1px solid $powder;
	border-radius: 6px;

	p.xs {
		color: $royal;
	}
}

#permits-trends-container {
	display: flex;
	gap: spacing(2);
	margin-top: spacing(2);
	flex-direction: column;

	@include if-size("desktop") {
		flex-direction: row;
	}

	.permits-trends-item {
		padding: spacing(2) spacing(4);
		flex: 1;
		border-radius: 16px;
		border: 1px solid $slate;
		background: $white;
	}
}

#permits-tab-mobile-content-container {
	position: absolute;
	top: 42px;
	left: 0;
	height: calc(100% - 42px);
	width: 100%;
	border-radius: 0px;
	background: $denim-lightest;
}

#permits-tab-mobile-map-container {
	height: 100%;
	width: 100%;
}

#permits-tab-mobile-table-container {
	padding: spacing(9) spacing(2) spacing(4);
	background: $denim-lightest;
	height: 100%;
	overflow: auto;
}

.permit-table-tag {
	font-weight: 600;
}

#county-trends-tab {
	.trends-map-card-and-map-stats {
		@include if-size("desktop") {
			flex-wrap: nowrap;
		}
	}

	section,
	.section-divider {
		margin-top: spacing(3);
	}

	.section-divider {
		width: 100%;
		height: 1px;
		background-color: $sand;
	}

	.trends-table-container {
		&::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}

		&::-webkit-scrollbar-thumb {
			width: 10px;
			height: 10px;
		}
	}
}
