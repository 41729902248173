#about-investors-section {
	padding: spacing(8) 0 spacing(8);
	margin-bottom: spacing(8);
	background-color: $cloud;

	#about-investors-header {
		grid-column: 1 / -1;
		margin: 0 auto spacing(4);
		color: $denim;

		@include if-size("desktop") {
			margin: 0 auto spacing(4);
		}
	}

	#about-investors-img-container {
		grid-column: 1 / -1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		/* changes image color to denim color */
		filter: brightness(0) saturate(100%) invert(15%) sepia(47%) saturate(1340%)
			hue-rotate(165deg) brightness(92%) contrast(98%);

		@include if-size("desktop") {
			.about-investor-img-wrapper {
				margin: spacing(2);
			}
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}

		.about-investor-img-wrapper {
			margin: spacing(2);
			width: 170px;

			@include if-size("tablet") {
				width: 204px;
			}

			&:first-child {
				width: 75px;

				@include if-size("tablet") {
					width: 100px;
				}
			}

			&.about-investor-img-wrapper-mobile-smaller {
				padding: 0 12px;
				@include if-size("tablet") {
					padding: 0;
					width: 188px;
				}
			}
		}
	}
}

.investor-landing-partner-banner,
.commercial-landing-partner-banner {
	margin-top: 90px;
}
