$tabular-when-navbar-expands-width: 1500px; //keep in sync with const defaultNavExpandedWindowWidth in DesktopNav.tsx

@mixin full-grid-xl {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 2 / -2;
	}

	@media only screen and (min-width: 1600px) {
		grid-column: 1 / -1;
	}
}

.multi-property-card {
	@include full-grid-xl;
	width: 100%;
	background-color: $cloud;
	border-radius: 16px;
	overflow: hidden;
	margin-top: spacing(2);
	display: none;
	cursor: pointer;

	@include if-size("desktop") {
		display: flex;
	}

	.multi-property-card-image {
		width: 30%;
		height: 100%;
		background-color: grey;
		background-position: center;
		background-size: cover;
	}

	.multi-property-card-details-cta {
		margin-top: spacing(2);
	}

	.multi-property-card-details {
		flex: 1;
		padding: spacing(4) spacing(2);

		.multi-property-card-details-address {
			display: flex;
			align-items: center;
			margin-bottom: spacing(4);

			svg {
				height: 24px;
				width: 24px;
				top: 3px;
				position: relative;
				top: 2px;
				path {
					fill: $denim;
				}
			}
			p {
				margin: 0 spacing(1);
			}

			.tax-year {
				white-space: nowrap;
				margin-left: auto;
				align-self: flex-start;
				color: $azure;
			}
		}

		.multi-property-card-details-bottom {
			display: flex;

			.multi-property-card-details-status {
				flex: 1;
				margin-right: spacing(4);

				p {
					margin-top: spacing(1);
				}

				.progress-bar > .progress-bar-container {
					background-color: $cloud;
				}
			}

			.multi-property-card-unverified {
				p.lg {
					margin-bottom: spacing(1);
				}
				.quick-link {
					margin-top: spacing(1);
				}

				.multi-property-card-unverified-title {
					display: flex;
					align-items: center;

					svg {
						max-width: 24px;
						max-height: 24px;
						height: 100%;
						width: 100%;
					}

					svg.mr {
						margin-right: 2ch;
					}
					svg.ml {
						margin-left: spacing(2);
					}
				}
			}
		}
	}
}

.multi-property-card-needs-attention {
	border: 3px solid $terracotta;
}

#properties-page-search-and-tools-container {
	grid-column: 1;
	flex: 0 1 auto;
	height: fit-content;

	&-full-width {
		@extend #properties-page-search-and-tools-container;
		grid-column: 1 / -1;
		width: 100%;
		max-width: 100%;
		margin: 0 auto;

		@include if-size("desktop-xl") {
			max-width: 706px;
		}

		@include if-size("monitor") {
			max-width: 932px;
		}

		@include if-size("monitor-xl") {
			max-width: 1078px;
		}
	}
}

#properties-page-search-container {
	@include full-grid-xl;
	margin: spacing(2) 0;

	.search-input-container > div > input {
		padding: 6px 48px 8px;
	}
}

#properties-page-pagination-container {
	grid-column: 8 / -2;

	@media only screen and (min-width: 1600px) {
		grid-column: 8 / -1;
	}

	display: flex;
	justify-content: flex-end;
	align-items: center;
}

#properties-view-type-switch {
	display: none;
	grid-column: 2 / 6;

	@media only screen and (min-width: 1600px) {
		grid-column: 1 / 6;
	}

	@include if-size("desktop") {
		display: block;
	}
}

#properties-page-switch-and-download {
	grid-column: 1/-1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: fit-content;
}

.properties-page-switch-and-download-left-side {
	display: flex;
	align-items: center;
}

#properties-page-action-needed {
	display: none;
	@include if-size("desktop") {
		display: block;
		margin-left: 18px;
		color: $terracotta;
		font-weight: 600;
	}
}

#properties-page-tabular-csv-and-year-container {
	display: flex;
	justify-content: space-between;
	gap: 4px;
	margin-top: spacing(2.5);

	> .flex {
		align-items: center;
	}
}
.property-select-appeal-tax-year-dropdown-select {
	align-items: center;

	.label {
		position: relative;
		top: 2px;
		padding-right: 6px;
	}

	select {
		padding: 4px 24px 4px 8px;
		margin-top: 0;
		font-size: 16px;
		background-image: url("data:image/svg+xml;utf8,<svg fill='%23b1b1bb' width='8' height='4' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
		background-position: calc(100% - 8px) center;
		border-radius: 4px;
		background-color: transparent;
	}

	select:hover {
		background-image: url("data:image/svg+xml;utf8,<svg fill='%23053249' width='8' height='4' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
	}
}

.status-card {
	.property-select-appeal-tax-year-dropdown-select-small {
		display: flex;
		justify-content: flex-end;
		select {
			padding: 4px 30px 4px 8px;
		}
	}
}

.property-select-appeal-tax-year-dropdown-select-small {
	select {
		font-size: 12px;
		font-family: $ff-secondary;
	}
}

.property-select-appeal-tax-year-dropdown {
	position: relative;
}

.property-select-appeal-tax-year-dropdown-nar-bubble {
	height: 10px;
	width: 10px;
	border-radius: 10px;
	background-color: $terracotta;
	position: absolute;
	right: -3px;
	top: -3px;
}

#properties-page-pagination-page-size-select-container {
	height: fit-content;
	margin-left: spacing(4);
	display: flex;
	align-items: center;

	p {
		margin-right: spacing(1);
	}

	select {
		width: 150px;
	}
}

#properties-page-pagination-page-select-container {
	display: flex;
	align-items: center;
	gap: 8px;

	* {
		user-select: none;
	}

	.pagination-dots {
		margin: 3px 2px 0px;
	}

	svg {
		height: 12px;
		width: 12px;
		fill: $denim;
		cursor: pointer;

		&:first-of-type {
			margin-right: spacing(1);
		}
		&:last-of-type {
			margin-left: spacing(1);
		}
	}

	.page-select {
		display: inline-block;
	}

	.pagination-item-container {
		position: relative;
		color: $azure;
		cursor: pointer;
		border-bottom: 2px solid transparent;

		&-active {
			@extend .pagination-item-container;
			border-bottom-color: $azure;
		}

		display: grid;

		.pagination-item {
			color: inherit;
		}
	}
}

#properties-page-tabular-view {
	overflow: auto;
	margin: spacing(2.5) 0 0 0;
	grid-column: 1 / -1;
	border: 2px solid $denim;
	border-radius: 8px;
	flex: 1 1 0%;
	max-width: 1760px;

	#properties-page-tabular-view-table-container {
		@include styled-scrollbar;
		overflow: auto;
		height: 100%;
	}

	#properties-page-tabular-view-table-heading {
		th:not(:first-of-type)::before {
			border-left: 2px solid $denim;
		}
		th:not(:last-of-type)::before {
			border-right: 2px solid $denim;
		}
		th {
			position: relative;
			background-color: $white;
			padding: 8px 8px;
			border-bottom: none;
			border-left: 1px solid $white;
			border-right: 1px solid $white;

			&::before {
				content: " ";
				position: absolute;
				top: 0px;
				left: 0px;
				right: 0px;
				bottom: 0px;
			}
		}
	}

	#properties-page-tabular-view-table-subheading {
		th:not(:first-of-type)::before {
			border-left: 2px solid $denim;
		}

		th:not(:last-of-type)::before {
			border-right: 2px solid $denim;
		}

		th:not(:first-of-type) {
			border-left: 1px solid $white;
		}
		th:not(:last-of-type) {
			border-right: 1px solid $white;
		}
	}

	table {
		max-width: 100%;
		border-bottom-width: 8px;
		border-collapse: separate;

		thead {
			position: sticky;
			top: 0;

			.round-left {
				border-bottom-left-radius: 8px;
			}

			.round-right {
				border-bottom-right-radius: 8px;
			}

			.properties-page-tabular-view-th {
				position: relative;
				background-color: $denim;
				padding: 8px 8px;
				border-spacing: 0px;
				border-top: none;

				.label {
					margin-right: spacing(3);
					color: $white;
					white-space: nowrap;
				}

				.properties-page-tabular-view-header-col {
					display: flex;
					justify-content: space-between;
				}

				.properties-page-tabular-view-sorting {
					margin-bottom: 8px;

					label {
						text-align: left;
					}
				}
			}
		}

		tbody {
			height: 100%;

			tr {
				height: 100%;

				&:nth-of-type(even) {
					background-color: $cotton;
				}
			}

			td {
				height: 50px;
				padding: 10px 8px;
				vertical-align: middle;
				border-bottom: 2px solid $sand;
			}

			.properties-tabular-view-border-bottom-needs-attention {
				border-bottom: 2px solid $terracotta;
			}

			.td-body {
				height: 100%;
				width: 100%;
			}
		}
	}
}

.account-properties-status-column-icon {
	height: 16px;
	width: 16px;
	min-width: 16px;
	margin-top: 2px;
	margin-right: 6px;
}

#properties-page-tabular-view-table-heading {
	background-color: $sand;
	margin-top: spacing(4);
	padding: spacing(1) 0;
}

.properties-page-tabular-view-filter-btn {
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translateY(-50%);

	svg {
		height: 12px;
		width: 12px;
		fill: $mint;
	}
}

.properties-page-tabular-view-filter-dropdown {
	position: absolute;
	left: 0px;
	top: calc(100% + 2px);
	background-color: $cloud;
	padding: 16px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 4px;

	* > .label {
		color: $denim !important;
		width: fit-content;
	}

	hr {
		margin: 4px 0 3px;
		border: 1px solid $denim;
	}
}

$view-type-transition-time: 180ms;

#properties-view-type-switch {
	.switch {
		position: relative;
		width: fit-content;
		padding: 2px;
		border: 2px solid $denim;
		display: flex;
		border-radius: 99999999px; // Hack to get nicely rounded corners
		overflow: hidden;
	}

	.view-option {
		padding: 1px 14px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10;
		cursor: pointer;
		user-select: none;

		p {
			color: $denim;
			transition: color $view-type-transition-time;
		}

		svg {
			margin-right: 8px;
			fill: $denim;
			height: 12px;
			width: 12px;
			transition: fill $view-type-transition-time;
		}
	}

	.active-view {
		cursor: default;

		p {
			color: $white;
		}

		svg {
			fill: $white;
		}
	}

	#switch-handle {
		position: absolute;
		top: 2px;
		left: 2px;
		background-color: $azure;
		border-radius: 500000px;
		height: calc(100% - 4px);
		width: calc(50% - 2px);
		z-index: 1;
		transition: left $view-type-transition-time
			cubic-bezier(0.05, 0.73, 0.99, 0.39);
	}

	.active-left {
		left: 2px !important;
	}

	.active-right {
		left: 50% !important;
	}
}

#properties-download-csv {
	display: flex;
	align-items: center;
	width: fit-content;
	cursor: pointer;
	margin-right: spacing(2);
	margin-top: 4px;

	p {
		color: $azure;
		line-height: 25px;
	}

	svg {
		height: 12px;
		width: 12px;
		margin-right: 8px;
	}
}

.properties-page-tabular-view-sorting-checkbox > label {
	text-transform: none;
	@include _create_typography("14px", "20px");
	font-family: $ff-tertiary;

	@include if-size("tablet") {
		@include _create_typography("16px", "24px");
	}
}

.properties-page-tabular-view-generic-filter-container {
	padding-top: spacing(1);
}

.properties-page-grid-tabular {
	max-height: 100vh;
	height: calc(
		100vh - $property-portal-page-padding-bottom -
			$property-portal-page-padding-top -
			$property-portal-top-navigation-bar-height
	);
	grid-template-rows: auto auto 1fr;
}

.multi-property-card-unverified-cta {
	margin-top: spacing(2);
}

.multi-property-more-info-cta {
	margin-top: 14px;
}

#properties-page-left-column-container {
	display: flex;
	flex-direction: column;

	&-full-width {
		@extend #properties-page-left-column-container;
		grid-column: 1 / -1;
		margin: 0 auto;
		max-width: 100%;
		width: 100%;

		// Keep these widths in sync wit the left column width for .property-grid in _propertyGrid.scss
		@include if-size("desktop-xl") {
			max-width: 706px;
		}

		@include if-size("monitor") {
			max-width: 932px;
		}

		@include if-size("monitor-xl") {
			max-width: 1078px;
		}
	}
}
