.review-stars {
	display: flex;
	align-items: center;
}

.ratings-text {
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 12px;

	p {
		font-family: Arial, Helvetica, sans-serif;
		line-height: initial;
		font-size: 12px;
		color: #a9a9a9;
		margin-top: 2px;
	}
}

.google-rating {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	padding: 8px;
	width: auto;
	border-radius: 5px;
	flex-wrap: nowrap;
}
