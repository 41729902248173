.landing-state-page {
	#landing-faq-section,
	#generalized-signup-steps-section,
	#landing-property-tax-101-section {
		padding-top: 40px;
		@include if-size("desktop") {
			padding-top: 96px;
		}
	}
	#generalized-signup-steps-section {
		padding-bottom: 40px;
		@include if-size("desktop") {
			padding-bottom: 96px;
		}
	}
	#landing-property-tax-101-section {
		background-color: $white;
	}
}
#landing-counties-served {
	padding: 40px 0;

	@include if-size("desktop") {
		padding: 96px 0;
	}
	#counties-served-title {
		grid-column: 1/-1;
		padding-bottom: 24px;
		@include if-size("desktop") {
			padding-bottom: 32px;
		}
	}
	#counties-served-map {
		grid-column: 1/-1;
		padding-bottom: 16px;
		@include if-size("desktop") {
			grid-column: 1/8;
		}
	}

	#counties-served-content {
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: 8/-1;
		}
	}

	.landing-counties-served-county-box {
		border: double 1px transparent;
		border-radius: 8px;
		background-image: linear-gradient(white, white),
			linear-gradient(45deg, #57c975, #1c7ce5);
		background-origin: border-box;
		background-clip: content-box, border-box;
		display: inline-block;
		transition: background-image 0.5s ease-in-out;
		&:hover {
			background-image: linear-gradient(white, white),
				linear-gradient(45deg, #1c7ce5, #57c975);
		}
	}

	#counties-served-boxes {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
	}

	.landing-counties-served-county-text {
		padding: 8px 30px;
		font-weight: 700;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
}

#landing-counties-dates {
	padding: 40px 0;
	background-color: $white;
	@include if-size("desktop") {
		padding: 96px 0;
	}

	#landing-counties-dates-title {
		grid-column: 1/-1;
	}

	.landing-counties-paragraph {
		grid-column: 1/-1;
		padding-bottom: 32px;
	}

	.landing-counties-dates {
		grid-column: 1/-1;
		border-radius: 16px;
		background: $white;
		box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
		padding: 24px;

		.landing-counties-dates-table-container {
			overflow-y: auto;
			max-height: 328px;
		}

		.landing-counties-dates-table {
			width: 100%;

			td {
				padding: 12px 16px;
				border-bottom: 1px solid $sand-light;
				&:not(:last-of-type) {
					border-right: 1px solid $sand-light;
				}
			}
		}

		.landing-counties-dates-header {
			background-color: $denim_lightest;
			padding: 12px 16px;
			text-align: left;
		}
		.landing-counties-dates-header:not(:last-of-type) {
			border-right: 1px solid $sand-light;
		}
	}
}

#landing-excellence-award {
	padding: 40px 0;
	background-color: $denim_lightest;
	@include if-size("desktop") {
		padding: 96px 0;
	}

	.landing-excellence-award-title {
		grid-column: 1/-1;
	}
	.landing-excellence-award-image {
		grid-column: 1/-1;
		max-height: 240px;
		text-align: center;
		margin-bottom: 32px;
		border-radius: 8px;
		overflow: hidden;

		@include if-size("tablet") {
			max-height: 400px;
			max-width: 480px;
			border-radius: 0;
		}

		@include if-size("desktop") {
			grid-column: 1/7;
			margin-bottom: 0;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.landing-excellence-award-content {
		grid-column: 1/-1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include if-size("desktop") {
			grid-column: 7/-1;
		}

		h4 {
			font-weight: 800;
		}
	}
}
