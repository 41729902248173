#error {
	text-align: center;
	padding: spacing(12) 0;
	height: 100%;
	place-items: center;
	@include if-size("desktop") {
		grid-row: 1;
		max-width: 560px;
	}

	.error-img {
		grid-column: 1/-1;
		gap: 1;
		grid-row: 1;
		max-width: 200px;
		@include if-size("desktop") {
			max-width: none;
			grid-column: 1/5;
		}
	}
	.error-body {
		grid-column: 1/-1;
		gap: 1;
		grid-row: 2;
		text-align: left;
		padding-top: spacing(2);
		@include if-size("desktop") {
			grid-column: 6/-1;
			grid-row: 1;
			padding-top: spacing(0);
		}
		.error-title {
			padding-bottom: spacing(1);
		}

		.error-text {
			padding-bottom: spacing(4);
		}
	}
}
