.help-center-search-container {
	background-color: $sky-light;
	margin-bottom: 23px;
}

.help-center-search-grid {
	position: relative;

	.help-center-search {
		padding-top: spacing(4);
	}
}

.help-center-search,
.help-center-often-searched {
	grid-column: 1/-1;
	@include if-size("desktop") {
		grid-column: 2/-2;
	}

	a {
		color: $denim;
	}

	.help-center-often-searched-links {
		text-decoration: underline;
		&:hover {
			color: $azure;
		}
	}
}

.help-center-no-searchbar {
	padding-bottom: spacing(4);
}

.help-center-often-searched {
	padding-top: spacing(1);
}
.help-center-search-input {
	margin-top: calc(-23px + spacing(1));
	position: relative;
	bottom: -23px;

	input {
		width: 100%;
	}

	&-active {
		border: 1px solid $denim;
		width: 100%;
	}
}

.help-center-search-icon-right {
	position: absolute;
	right: 10px;
	top: 12px;
	width: 18px;
	height: 18px;
	cursor: pointer;

	@include if-size("tablet") {
		top: 10px;
		right: 16px;
		width: 30px;
		height: 28px;
	}
}

.help-center-featured-categories,
.help-center-featured-articles {
	grid-column: 1/-1;
	padding: spacing(4) 0;

	@include if-size("tablet") {
		padding: spacing(4) 0;
	}
}

#help-center-featured-articles-grid {
	max-width: 100%;
}

.help-center-cards {
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 16px;
	background-color: $white;
	padding: spacing(2);
	border: 2px solid $white;

	&:hover {
		border: 2px solid $azure;

		.expand-label {
			text-decoration: underline;
		}
	}
}

.help-center-featured-categories {
	@include if-size("desktop") {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
	}

	div {
		flex: calc(50% - 16px);
	}

	.help-center-featured-categories-award-icon {
		width: 48px;
		height: 48px;
		align-self: center;
		margin-right: 16px;

		@include if-size("desktop") {
			width: 80px;
			height: 80px;
		}
	}

	.help-center-featured-categories-cards-wrapper {
		margin-bottom: spacing(2);
		@include if-size("desktop") {
			margin-bottom: 0;
		}
	}
	.help-center-featured-categories-cards {
		display: flex;
		cursor: pointer;
		@extend .help-center-cards;
		height: 100%;
	}

	.help-center-featured-categories-cards-body {
		color: $denim;
		opacity: 50%;
	}
}

.help-center-featured-articles {
	h4 {
		margin-bottom: spacing(2);
	}

	.help-center-articles-card-link {
		height: 100%;
		padding-bottom: 16px;
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: span 6;
		}

		.help-center-articles-cards {
			margin-bottom: 0;
		}

		.help-center-articles-card-body {
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;

			@include if-size("tablet") {
				-webkit-line-clamp: 2;
			}
		}
	}
}

.help-center-articles-cards {
	cursor: pointer;
	grid-column: 1/-1;
	height: 100%;
	margin-bottom: spacing(2);
	position: relative;
	padding-bottom: spacing(5);
	@extend .help-center-cards;
	@include if-size("desktop") {
		grid-column: span 6;
	}
	.help-center-articles-card-body {
		padding-top: 4px;
		color: $denim;
		opacity: 50%;
	}

	.expand-button {
		position: absolute;
		right: 12px;
		bottom: 6px;
	}
}

.help-center-featured-articles-grid-container {
	background-color: $denim_lightest;
}

.help-center-new-request {
	padding: spacing(7) 0;
}

#help-center {
	.loading-component {
		grid-column: 1/-1;
	}
}

.view-more-right-arrow-cta {
	display: flex;
	padding-top: 2px;
	position: relative;
	padding-left: 4px;

	p {
		display: inline-block;
		color: $azure;
	}

	svg {
		width: 12px;
		height: 12px;
		margin-left: 6px;
		position: relative;
		top: 7px;

		@include if-size("tablet") {
			width: 16px;
			height: 15.6px;
			margin-left: 12px;
			top: 9px;
		}
	}
}

.help-center-search-autocomplete {
	margin-top: 32px;
	border: 1px solid $denim;
	border-top: none;
	background-color: $white;
	border-radius: 0 0 8px 8px;
	top: 0px;
	width: 100%;
	padding-top: spacing(1);
	z-index: 1000;
	overflow: hidden;
	position: absolute;

	@include if-size("tablet") {
		margin-top: 38px;
	}

	.help-center-search-autocomplete-result {
		margin-top: spacing(1);
		padding: 0 spacing(2) spacing(1);
		cursor: pointer;
		&:hover,
		&-active {
			background-color: $sand;
		}

		&-snippet {
			opacity: 50%;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			overflow: hidden;
		}

		&:hover {
			div {
				.help-center-search-autocomplete-result-title {
					text-decoration: underline;
				}
			}
		}
	}
	.help-center-search-autocomplete-result-active {
		background-color: $sand;
	}

	.help-center-search-autocomplete-no-results {
		background-color: $azure;
		color: $cloud;
		padding: 8px 16px;
	}
	.help-center-search-autocomplete-no-results-route-to-contact {
		cursor: pointer;

		&:hover {
			background-color: $denim;
		}
	}
}

/* Search Results */
#help-center-search-results {
	padding-bottom: spacing(8);

	em {
		background-color: #d5eaff;
	}

	.help-center-articles-card-body {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		-webkit-line-clamp: 3;

		@include if-size("tablet") {
			-webkit-line-clamp: 2;
		}

		@include if-size("desktop") {
			-webkit-line-clamp: 1;
		}
	}
}

.help-center-search-results-container {
	grid-column: 1/-1;
	padding-top: spacing(4);

	.help-center-search-results-container-title {
		padding-bottom: spacing(2);
	}

	.help-center-search-try-searching {
		@include if-size("tablet") {
			display: flex;
			align-items: center;

			.button-small {
				padding-left: 20px;
			}
		}
		.button-small {
			top: 2px;
			position: relative;

			svg {
				fill: $azure;
			}
		}
	}
}

.help-center-breadcrumbs {
	padding-bottom: spacing(2);

	.breadcrumb-link {
		display: inline-block;
		margin-top: 4px;
		color: $denim;
	}

	.breadcrumb-link a {
		color: $denim;
		text-decoration: underline;
		&:hover {
			color: $azure;
		}
	}

	.help-center-right-caret {
		padding: 0 10px;
	}
}

/* Category */
.help-center-category-page {
	padding-top: spacing(4);
	.pricing-faq-card {
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: span 6;
		}
	}

	.help-center-category-page-articles {
		margin-bottom: spacing(2);

		&:hover {
			a {
				color: $azure;
				text-decoration: underline;
			}
			.help-center-article-icon {
				fill: $azure;
			}
		}

		.help-center-featured-star {
			width: 16px;
			height: 16px;
			fill: $azure;
			position: relative;
			top: 1px;
			margin-left: 8px;
		}

		a {
			color: $denim;
		}

		.help-center-article-icon {
			width: 16px;
			height: 16px;
			fill: $denim;
			margin-right: spacing(1);
		}
	}

	.help-center-category-page-see-all-articles {
		display: flex;
		p {
			color: $azure;
		}
		svg {
			fill: $azure;
			width: 16px;
			height: 16px;
			position: relative;
			top: 4px;
			margin-left: 8px;

			@include if-size("tablet") {
				width: 13px;
				height: 13px;
				top: 10px;
			}
		}

		&:hover {
			p {
				color: $denim;
				text-decoration: underline;
			}
			svg {
				fill: $denim;
			}
		}
	}
}

/* Section */
.help-center-section-page {
	padding-top: spacing(4);

	.help-center-section-card {
		@extend .help-center-cards;
		grid-column: 1/-1;
		flex-wrap: wrap;

		@include if-size("desktop") {
			display: flex;
			gap: spacing(4);
		}

		&:hover {
			border: 2px solid $white;
		}
	}

	.help-center-section-article {
		flex: calc(50% - 16px);
		padding-bottom: spacing(2);

		@include if-size("tablet") {
			padding-bottom: 0;
		}

		&:hover {
			.help-center-article-icon {
				fill: $azure;
			}
			a {
				color: $azure;
				text-decoration: underline;
			}
		}

		.help-center-article-icon {
			width: 16px;
			height: 16px;
			fill: $denim;
			margin-right: spacing(1);

			&:hover {
				.help-center-article-icon {
					fill: $azure;
				}
			}
		}

		.help-center-featured-article {
			width: 16px;
			height: 16px;
			fill: $azure;
			margin-left: 4px;
		}

		a {
			color: $denim;
		}
	}
}

/* Article */
.help-center-article-wrapper {
	position: relative;
	top: -46px;
}
.help-center-article-container {
	grid-column: 1/-1;
	padding: spacing(2) spacing(2) spacing(4);
	background-color: $white;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 8px 8px 0px 0px;
	@include if-size("desktop") {
		grid-column: 1/10;
		padding: spacing(4);
		border-radius: 8px;

		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
	.help-center-article-inner {
		p:not(:first-of-type),
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			padding-top: spacing(2);
		}

		h1,
		h2,
		h3 {
			@extend .h5;
		}

		a:hover {
			color: $denim;
			text-decoration: underline;
		}

		ul {
			list-style: none;
			padding-top: spacing(2);
		}

		ul li::before {
			content: "\2022";
			color: $azure;
			font-weight: 600;
			display: inline-block;
			width: 1em;
			margin-left: 1em;
		}

		ol {
			list-style: none;
			counter-reset: steps;
		}

		ol li {
			counter-increment: steps;
			margin: 0 spacing(2) 0 spacing(4);
			padding-top: spacing(2);
			position: relative;
			padding-left: 12px;

			@include if-size("tablet") {
				margin: 0 spacing(4) 0 48px;
				padding-left: 18px;
			}
		}

		ol li::before {
			content: counter(steps);
			position: absolute;
			top: 18px;
			left: -18px;
			font-family: $ff-secondary;
			margin-right: 8px;
			background: $azure;
			color: $white;
			width: 1.8em;
			height: 1.8em;
			border-radius: 50%;
			display: inline-grid;
			place-items: center;
			font-size: 12px;

			@include if-size("tablet") {
				width: 1.6em;
				height: 1.6em;
				font-size: 16px;
				top: 19px;
				left: -18px;
			}
		}

		iframe {
			margin: auto;
			display: flex;
			width: 100%;
		}

		table {
			width: 100%;
			border-radius: 8px;
			text-align: left;
			color: $denim;
			display: block;
			overflow-x: scroll;

			@include if-size("tablet") {
				overflow-x: unset;
				display: table;
				overflow: hidden;
			}

			@include if-size("tablet") {
				width: 95%;
			}

			th {
				background-color: $sand;
				padding: spacing(1) spacing(2);
				border-right: 4px solid $white;
				font-family: $ff-secondary;
				font-weight: 400;

				&:last-of-type {
					border-right: 0;
				}
			}

			td {
				background-color: $cloud;
				padding: spacing(1) spacing(2);
				border-right: 4px solid $white;
				border-top: 4px solid $white;

				&:last-of-type {
					border-right: 0;
				}
			}
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}
}
.help-center-article-featured-articles-section {
	grid-column: 1/-1;
	background-color: $sky;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 0px 0px 8px 8px;
	padding: spacing(4) spacing(2);
	@include if-size("desktop") {
		grid-column: 10/-1;
		border-radius: 8px;
	}
}

.help-center-article-featured-articles-section-article {
	padding-bottom: spacing(1);
	display: flex;

	&:hover {
		a {
			color: $azure;
			text-decoration: underline;
		}
		svg {
			fill: $azure;
		}
	}

	.help-center-article-icon {
		width: 13px;
		height: 13px;
		fill: $denim;
		position: relative;
		top: 5px;
		margin-right: 8px;
		@include if-size("tablet") {
			width: 16px;
			height: 16px;
		}
	}

	.help-center-featured-article {
		width: 13px;
		height: 13px;
		fill: $azure;
		position: relative;
		top: 4px;
		margin-left: 4px;
		@include if-size("tablet") {
			width: 16px;
			height: 16px;
			top: 5px;
		}
	}

	a {
		color: $denim;
	}
}

.help-center-article-featured-articles-section-title {
	padding-bottom: spacing(1);
}

.help-center-article-nav-container {
	padding: spacing(4) 0 0;
	justify-content: space-between;

	@include if-size("tablet") {
		display: flex;
	}

	svg {
		width: 12px;
		height: 12px;
		fill: $azure;
		position: relative;

		@include if-size("tablet") {
			width: 16px;
			height: 16px;
			top: 1px;
		}
	}

	.help-center-nav-buttons {
		color: $azure;
	}

	.help-center-nav-next {
		@extend .help-center-nav-buttons;
		padding-right: 12px;
	}

	.help-center-nav-prev {
		@extend .help-center-nav-buttons;
		padding-left: 12px;
		padding-bottom: spacing(2);
	}

	.help-center-article-nav-prev,
	.help-center-article-nav-next {
		@include if-size("tablet") {
			width: 48%;
		}

		&:hover {
			span {
				color: $denim;
				text-decoration: underline;
			}
			svg {
				fill: $denim;
			}
		}
	}

	.help-center-article-nav-prev {
		padding-bottom: spacing(1);
	}

	.help-center-article-nav-next {
		text-align: right;
	}
}

.help-center-article-recently-viewed {
	padding-top: spacing(3);

	.lg {
		padding-bottom: spacing(1);
	}
}

.help-center-right-caret {
	position: relative;
	top: 1px;
	fill: $denim;
	svg {
		height: 12px;
		width: 12px;
	}
}

#contact-section {
	padding: 0 0 spacing(6);
	@include if-size("tablet") {
		padding: spacing(4) 0 spacing(6);
	}
}

.contact-step .help-center-submit-a-request-message-box {
	grid-column: 1/-1;
	margin-bottom: 16px;

	@include if-size("desktop") {
		grid-column: 3/-3;
		margin-bottom: 24px;
	}
}

#contact-us-phone-states .help-center-submit-a-request-message-box {
	grid-column: 1/-1;
	margin-bottom: 32px;
	@include if-size("desktop") {
		grid-column: 2/-2;
	}
}

.help-center-submit-a-request-message-box {
	background-color: $sand;
	border-radius: spacing(2);
	padding: spacing(2);
	text-align: left;
}

.help-center-submit-a-request-message-box-title-section {
	display: flex;
	align-items: center;
	cursor: pointer;

	svg {
		width: 16px;
		height: 16px;
		fill: $azure;
		margin-left: 12px;
		position: relative;
		top: 2px;
	}
}

.help-center-submit-a-request-message-box-title {
	font-weight: 600;
	color: $azure;
}

.help-center-submit-a-request-message-box-title-body {
	padding-top: 4px;
	cursor: pointer;
}
