.language-picker-dropdown-container {
	position: relative;
}

.language-picker-dropdown {
	padding: spacing(1) spacing(1.5);
	border: 1px solid $slate;
	border-radius: 100000px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	margin-left: spacing(2);
	cursor: pointer;

	.language-picker-dropdown-locale {
		color: $denim_5;
		margin-left: spacing(1);
	}

	&:hover {
		border-color: $powder;
		background: $sky-light;

		svg > path {
			fill: $powder;
		}

		.language-picker-dropdown-locale {
			color: $powder;
		}
	}
}

.language-picker-dropdown-list {
	position: absolute;
	right: 0;
	background: $white;
	padding: spacing(1);
	margin-top: spacing(1);
	border-radius: 16px;

	.language-picker-dropdown-list-item {
		cursor: pointer;
		padding-bottom: spacing(1);

		&:not(:last-of-type) {
			border-bottom: 1px solid $sand;
		}

		&:not(:first-of-type) {
			padding-top: spacing(1);
		}

		.language-picker-dropdown-list-item-icon {
			margin-right: spacing(0.5);
		}

		p.sm {
			white-space: nowrap;
		}
	}
}

.language-picker-switch {
	border-radius: 100000px;
	padding: spacing(0.5);
	background: $white;
	display: flex;
	align-items: center;

	.language-picker-switch-item {
		border-radius: 100000px;
		padding: spacing(0.25) spacing(2);
		background: $white;
		border: 1px solid $white;

		p.sm {
			font-size: 14px;
		}

		&-active {
			@extend .language-picker-switch-item;
			background: $sky;
			border: 1px solid $azure;

			p.sm {
				color: $azure;
			}
		}
	}
}
