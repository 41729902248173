#appeals-page {
	#landing-hero-value-props {
		margin-top: -66px;
		@include if-size("desktop") {
			margin-top: -39px;
		}
	}

	#generalized-signup-steps-section {
		@extend .appeals-page-title-default-padding;
		background-color: $denim-lightest;
	}

	#landing-view-pricing-section {
		background-color: $white;
	}

	#landing-faq-section {
		@extend .appeals-page-title-default-padding;
	}
}

.appeals-page-experts-section {
	@extend .appeals-page-title-default-padding;
	background-color: $denim-lightest;
	.appeals-page-experts-text {
		grid-column: 1/-1;
		h3 {
			padding-bottom: 12px;
		}
	}

	#landing-experts-section {
		background-color: $denim-lightest;
		padding: 32px 0 0;
	}
}

.appeals-page-title-default-padding {
	padding: 64px 0;
	@include if-size("desktop") {
		padding: 96px 0;
	}
}

#appeals-page-title-section {
	padding: 48px 0 calc(48px + 55px);
	background-color: $sky-light;
	@include if-size("desktop") {
		padding: 96px 0 calc(96px + 39px);
	}

	.appeal-page-title-content {
		grid-column: 1/-1;
		order: 2;
		@include if-size("desktop") {
			grid-column: 1/8;
			order: 1;
		}
	}

	.appeal-page-title-img {
		grid-column: 1/-1;
		order: 1;
		text-align: center;
		padding-bottom: 24px;

		@include if-size("desktop") {
			grid-column: 9/-1;
			order: 2;
		}

		svg {
			max-width: 300px;
			@include if-size("desktop") {
				max-width: 368px;
			}
		}
	}

	#landing-hero-address-input {
		padding: 16px 0;
	}

	#landing-hero-address-input #landing-hero-address-search-with-results {
		max-width: unset;
	}
}

#appeals-page-monitoring {
	padding: 64px 0 96px;
	@include if-size("desktop") {
		padding: calc(96px - 39px) 0 96px;
	}
	.appeals-page-monitoring-img {
		grid-column: 1/-1;
		text-align: center;
		padding-bottom: 16px;
		@include if-size("desktop") {
			grid-column: 1/6;
			padding-bottom: 0;
		}

		svg {
			max-width: 465px;
		}
	}
	.appeals-page-monitoring-content {
		grid-column: 1/-1;
		margin: auto;
		@include if-size("desktop") {
			grid-column: 7/-1;
		}
	}

	.appeals-page-tax-bill-content {
		grid-column: 1/-1;
		order: 2;
		padding-bottom: 64px;

		@include if-size("desktop") {
			grid-column: 1/7;
			order: 1;
			margin: auto;
			padding-bottom: 0;
		}
	}

	.appeals-page-tax-bill-img {
		grid-column: 1/-1;
		text-align: center;
		padding: 50px 0 16px;
		order: 1;
		@include if-size("desktop") {
			grid-column: 8/-1;
			padding: 88px 0 96px;
			order: 2;
		}

		svg {
			max-width: 464px;
		}
	}

	.appeals-page-cta-centered-copy {
		grid-column: 1/-1;
		text-align: center;
		order: 3;
		@include if-size("desktop") {
			grid-column: 3/-3;
		}
	}
}

#appeal-page-appeal-process-section {
	background-color: $denim-lightest;
	padding-bottom: 64px;

	@include if-size("desktop") {
		padding-bottom: 96px;
	}
}

#appeal-page-appeal-process {
	grid-column: 1/-1;

	h3 {
		padding-bottom: 4px;
	}

	.appeal-page-appeal-process-steps {
		display: flex;
		flex-direction: column;
		padding-top: 24px;
		display: flex;
		gap: 16px;

		@include if-size("desktop") {
			gap: 24px;
		}
	}

	.appeal-process-shifted-cards {
		border-radius: 8px;
		background: $white;
		box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
		width: 100%;
		display: flex;
		gap: 16px;
		align-items: flex-start;
		padding: 16px;
		flex-direction: column;

		@include if-size("desktop") {
			padding: 24px;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			width: 100%;
			transition: width 0.4s ease-in-out;
		}

		@include if-size("desktop-xl") {
			width: 954px;
			margin: 0;
			position: relative;
		}
	}

	.appeal-process-shifted-cards-icon {
		width: 40px;
		height: 40px;
		flex: none;
	}
}

#appeals-page-casestudies-posts {
	@extend .appeals-page-title-default-padding;
	.resources-common-grid-divider {
		grid-column: 1/-1;
	}

	.appeals-page-casestudies-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.appeals-page-casestudies-btn-hide-on-mobile {
		display: none;
		@include if-size("desktop") {
			display: block;
		}
	}

	.resources-more-cards-button {
		@include if-size("desktop") {
			display: none;
		}
	}
}

#appeals-page-reasons-to-use-ownwell {
	.appeals-page-save-img {
		grid-column: 1/-1;
		padding-top: 64px;
		text-align: center;
		@include if-size("desktop") {
			grid-column: 1/5;
			padding-top: 96px;
		}

		svg {
			max-width: 300px;

			@include if-size("desktop") {
				max-width: 363px;
			}
		}
	}
	.appeals-page-save-text {
		grid-column: 1/-1;
		margin: auto;
		padding-top: 24px;
		@include if-size("desktop") {
			grid-column: 6/-1;
			padding: 96px 0 0;
		}
	}

	.appeals-page-strength-text {
		grid-column: 1/-1;
		margin: auto;
		order: 4;
		padding-bottom: 64px;
		@include if-size("desktop") {
			grid-column: 1/8;
			order: 3;
			padding: 192px 0 96px;
		}

		p {
			line-height: 28px;
			letter-spacing: -0.2px;
		}
	}

	.appeals-page-strength-img {
		grid-column: 1/-1;
		padding-top: 128px;
		order: 3;
		text-align: center;
		@include if-size("desktop") {
			grid-column: 9/-1;
			padding: 192px 0 96px;
			order: 4;
		}

		svg {
			max-width: 300px;

			@include if-size("desktop") {
				max-width: 368px;
			}
		}
	}
}
