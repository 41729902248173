#realtor-partner-referral-banner {
	background-color: $light-blue;

	padding: 16px 0px;

	label {
		margin-bottom: 6px;
		@include if-size("desktop") {
			margin-bottom: spacing(1);
		}
	}

	&-profile {
		grid-column: 1 / 5;

		@include if-size("tablet") {
			grid-column: 1 / 6;
		}

		display: flex;
		gap: 16px;

		#realtor-partner-referal-banner-image-container {
			border-radius: 50%;
			margin: auto 0;
			width: 75px;
			height: 75px;
			overflow: hidden;

			@include if-size("tablet") {
				width: 100px;
				height: 100px;
			}
		}

		.profile-photo {
			position: relative;
			width: 100%;
			height: auto;
			min-height: 100%;
		}
	}

	&-testimonial {
		display: none;

		@include if-size("desktop") {
			display: block;
			grid-column: 6 / 13;
		}
	}
}
