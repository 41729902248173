#expenses-modal {
	#expenses-modal-progress-bar {
		label {
			text-align: center;
			margin-top: 4px;
		}
	}

	#expenses-modal-header {
		margin-bottom: spacing(3);
		max-width: 500px;
		margin: 0 auto;

		.progress-bar > .progress-bar-container {
			background: $white;
		}
	}

	#expenses-modal-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: spacing(3);

		svg {
			height: 16px;
			width: 16px;
			margin-right: 4px;

			path {
				fill: $denim_5;
			}
		}
	}

	#expenses-modal-equity-question {
		margin-top: spacing(3);
	}

	#expenses-modal-question-field-container {
		display: flex;
		margin: spacing(3) 0;

		#expenses-modal-question-fields {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: spacing(2);
		}

		#expenses-modal-escrow-question-fields,
		#expenses-modal-mortgage-question-fields {
			display: flex;
			flex-direction: column;
			gap: spacing(2);
			padding: spacing(2);
			background: $denim_lightest;
			border-radius: 16px;

			&-paid-off {
				display: flex;
				flex-direction: column;
				gap: spacing(2);
				padding: 0;
				background: none;
			}
		}

		#expenses-modal-question-chart {
			flex: 1;
			display: none;

			@include if-size("desktop") {
				display: flex;
				max-width: 50%;
				min-height: 330px;
				justify-content: center;
				align-items: center;
			}
		}
	}

	#expenses-modal-total-mortgage-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: spacing(2);
		border-bottom: 1px solid $sand;
	}

	#expenses-modal-confirmation-animation-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: spacing(3) 0;
	}

	#expenses-modal-confirmation-animation {
		max-height: 250px;
		max-width: 250px;
	}

	.expenses-modal-escrow-question-fields-sub-label-bottom {
		padding-top: 6px;
		display: flex;
		justify-content: space-between;
	}
}

.estimated-field {
	position: relative;

	&::after {
		content: "ESTIMATED";
		font-family: "sofia-pro";
		font-size: 10px;
		background: $slate;
		margin-right: 4px;
		position: absolute;
		bottom: 14px;
		right: 8px;
		padding: spacing(0.5) spacing(1);
		border-radius: 2px;

		@include if-size("desktop") {
			font-size: 12px;
			bottom: 12px;
		}
	}
}

.paid-off-field {
	position: relative;

	&::after {
		content: "PAID OFF";
		font-family: "sofia-pro";
		font-size: 10px;
		background: $slate;
		margin-right: 4px;
		position: absolute;
		bottom: 14px;
		right: 8px;
		padding: spacing(0.5) spacing(1);
		border-radius: 2px;

		@include if-size("desktop") {
			font-size: 12px;
			bottom: 12px;
		}
	}
}
