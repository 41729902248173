.resource {
	@include flex("flex-start", "flex-start", "column");
	min-height: max(calc(100vh - 250px), 250px);

	.resource-post {
		margin: auto;
		width: 85%;
		max-width: 800px;
		padding-bottom: spacing(8);

		@include if-size("desktop") {
			padding-bottom: spacing(12);
		}

		.resource-top-card {
			margin-top: spacing(2);
			border-radius: 16px;
			background-color: $cloud;
			overflow: hidden;

			.resource-top-card-text {
				padding: spacing(2);
				@include if-size("desktop") {
					padding: spacing(4);
				}
			}
			.resource-top-title {
				color: $denim;
				text-align: left;
				-webkit-text-size-adjust: 100%;

				@include if-size("desktop") {
					padding-bottom: spacing(1);
				}
			}
			.resource-top-card-subheader {
				@include text("18px", "initial", 400);
				color: $denim;
				text-align: left;
				@include if-size("desktop") {
					@include text("24px", "initial", 400);
				}
			}
			.resource-top-card-hero-img {
				width: 100%;
				max-width: 100%;
			}
		}

		.resource-post-casestudy-subtitle {
			color: $denim;
			text-align: left;
			-webkit-text-size-adjust: 100%;
			padding-bottom: spacing(4);
		}

		.resource-post-text {
			text-align: left;
			-webkit-text-size-adjust: 100%;
			padding-top: spacing(2);
			padding-bottom: spacing(1);

			h1,
			h2,
			h3,
			h4 {
				@extend .h5;
				padding-bottom: 3px;
			}

			h3 {
				padding-top: 16px;
				padding-bottom: 16px;
			}
			img {
				max-width: 100%;
			}
			ol,
			ul {
				padding-left: 20px;
			}

			p,
			li {
				@include text("18px");
				padding-bottom: spacing(2);
				line-height: 28px;
			}

			li > p {
				padding-bottom: 0;
			}

			blockquote {
				background: $cloud;
				margin: 32px 0;
				width: 100%;
				font-style: italic;
				color: $denim;
				padding: 32px;
				line-height: 28px;
				border-radius: 16px;
				position: relative;
				@include if-size("desktop") {
					width: auto;
					margin: 48px 100px;
				}
			}
			blockquote p {
				display: inline;
			}
			blockquote::before {
				@include text("4em");
				content: "\201C";
				color: $azure;
				position: absolute;
				left: 10px;
				top: -5px;
			}
			blockquote::after {
				@include text("4em");
				content: "\201D";
				color: $azure;
				font-size: 4em;
				position: absolute;
				right: calc(10px + 1rem);
				bottom: -25px;
			}
			blockquote span {
				display: block;
				color: #333333;
				font-style: normal;
				font-weight: bold;
				margin-top: 1em;
			}
		}
		.resource-post-casestudy-table {
			max-width: 600px;
			border-collapse: collapse;
			width: 100%;
			margin: 0 auto;
			color: $denim;
			td {
				border-radius: 6px;
				border-bottom: 4px solid $white;
				border-right: 4px solid $white;
				background-color: $cloud;
				@include text("16px", inherit);
				text-align: left;
				padding: 8px;

				@include if-size("desktop") {
					@include text("20px", inherit);
					padding: 10px 12px;
				}
			}
			tr td:last-child {
				border-right: none;
			}
			tr:last-child td {
				border-bottom: 0;
				background-color: $azure;
				color: $white;
			}
		}
	}
}

.resource-return {
	padding-top: spacing(4);
	font-size: 14px;
	a {
		color: $denim;
	}
	svg {
		vertical-align: middle;
		margin-bottom: 2px;
	}
}

.resource-info {
	padding: spacing(2) 0;
	.resource-info-type {
		float: left;
		color: $azure;
	}
	.resource-info-date {
		float: right;
		opacity: 50%;
	}
}

.resource-social {
	padding: 14px 0 spacing(4) 0;
	.resource-social-label {
		padding-bottom: spacing(1);
		font-size: 12px;
	}
	.resource-social-medias a {
		padding-right: spacing(2);

		@include if-size("desktop") {
			padding-right: spacing(4);
		}

		svg {
			width: 32px;
			height: 32px;
			fill: $denim;
		}

		.icon-test:hover {
			fill: $azure;
		}
	}
}
