#about-title-section {
	background-color: $sky-light;
	padding: 24px 0 64px;
	align-items: center;

	@include if-size("desktop") {
		padding: 56px 0 96px;
	}
}

#about-title-container {
	grid-column: 1 / -1;
	grid-row: 2;
	text-align: left;

	@include if-size("desktop") {
		grid-row: 1;
		// max-width: 560px;
		grid-column: 1/8;
		text-align: left;
	}
}

#about-hero-img {
	grid-column: 1 / -1;
	grid-row: 1;
	max-width: 358px;
	margin: 0 auto spacing(2);
	width: 100%;
	height: auto;

	svg {
		width: 100%;
		height: 100%;
	}

	@include if-size("desktop") {
		grid-column: 8 / -1;
		max-width: 430px;
		width: 100%;
		margin: 0;
		padding: 0;
	}
}
