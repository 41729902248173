@mixin shimmer() {
	@keyframes shimmer {
		0% {
			background-position: -468px 0;
		}

		100% {
			background-position: 468px 0;
		}
	}
	border-radius: 8px;
	overflow: hidden;
	background: $sand;
	background-image: linear-gradient(
		to right,
		$sand 0%,
		$cloud 20%,
		$sand 40%,
		$sand 100%
	);
	// background-repeat: no-repeat;
	background-size: 800px 100%;
	// display: inline-block;
	position: relative;
	width: fit-content;
	border-color: $sand !important; // !important needed to work on elements like <Button />

	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-name: shimmer;
	-webkit-animation-timing-function: linear;
}

@mixin shimmer-denim() {
	@include shimmer();

	background: $denim_5;
	background-image: linear-gradient(
		to right,
		$denim_5 0%,
		rgba($denim, 0.25) 20%,
		$denim_5 40%,
		$denim_5 100%
	);

	-webkit-animation-duration: 8s;
}

@mixin shimmer-bg-denim-lightest() {
	@include shimmer();

	background: $denim-extra-light;
	background-image: linear-gradient(
		to right,
		$denim-extra-light 0%,
		rgba($denim_lightest, 0.25) 20%,
		$denim-extra-light 40%,
		$denim-extra-light 100%
	);

	-webkit-animation-duration: 16s;
}

@mixin shimmer-bg-white() {
	@include shimmer();

	background: $white;

	background-image: linear-gradient(
		to right,
		$denim_lightest 0%,
		rgba($white, 0.25) 20%,
		$denim_lightest 40%,
		$denim_lightest 100%
	);

	-webkit-animation-duration: 8s;
}

[data-skeleton="true"] {
	@include shimmer();
	&[data-skeleton-denim] {
		@include shimmer-denim();
	}

	&[data-skeleton-bg-denim-lightest] {
		@include shimmer-bg-denim-lightest;
	}

	&[data-skeleton-bg-white] {
		@include shimmer-bg-white();
	}

	color: transparent !important;
	fill: transparent;

	* {
		color: transparent;
		fill: transparent;
	}

	svg,
	svg > path {
		fill: transparent;
	}

	&:after {
		content: attr(data-skeleton-placeholder);
		display: block;
	}
}

[data-mold="true"] {
	filter: blur(5px);

	&:after {
		content: attr(data-mold-placeholder);
		display: block;
	}
}
