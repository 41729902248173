#site-footer {
	width: 100%;
	background: $denim;
	border-top: 1px solid $cloud;
	padding: 42px;

	&.dark-footer {
		background-color: $denim-black;
	}

	.grid {
		row-gap: none;

		@include if-size("desktop") {
			row-gap: spacing(8);
		}
	}

	#footer-title-section {
		grid-row: 1;
		grid-column: 1 / -1;
		width: fit-content;
		height: fit-content;

		@include if-size("desktop") {
			grid-column: -5/-1;
		}

		@include if-size("desktop-lg") {
			grid-column: -4/-1;
		}

		#footer-logo {
			@include flex("center", "center");
			max-width: 270px;
			max-height: 75px;
			font-size: 3.5em;
			font-weight: bold;
			fill: $cloud;

			@include if-size("desktop") {
				max-width: 270px;
				max-height: 74px;
			}
		}
	}

	#footer-nav-link-row {
		grid-column: span 2;
		width: 50%;
		min-width: 500px;
	}

	#site-footer-disclaimer {
		grid-column: 1/ -1;
		flex-wrap: wrap;
		line-height: 1.2em;

		* {
			color: $cloud;
		}

		p,
		a {
			margin: 0 0.15rem;
		}

		a:hover {
			text-decoration: underline;
		}

		@include if-size("tablet") {
			text-align: left;
		}

		@include if-size("desktop") {
			grid-column: -5/-1;
		}

		@include if-size("desktop-lg") {
			grid-column: -4/-1;
		}
	}

	.footer-nav-link-column-container {
		grid-column: 1 / -1;
		cursor: pointer;
		padding-top: spacing(4);
		border-bottom: 1px solid $cloud;

		&:nth-of-type(4) {
			border-bottom: none;
			margin-bottom: spacing(4);
		}

		@include if-size("tablet") {
			grid-column: span 2;
			border-bottom: none;

			&:nth-of-type(3) {
				margin-bottom: spacing(4);
			}
		}

		.mobile-footer-nav-link-column-title {
			color: $cloud;
			font-family: "sofia-pro";
			user-select: none;
			margin-bottom: 22px;
		}

		.footer-nav-link-column-title {
			font-weight: normal;
			margin-bottom: spacing(2);
			color: $cloud;
		}

		.footer-nav-link-column {
			height: 100%;

			.footer-nav-link {
				font-size: 18px;
				line-height: 24px;
				margin-bottom: spacing(2);
				color: $cloud;
				cursor: pointer;

				@include if-size("tablet") {
					margin-bottom: spacing(1);
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	#footer-reviews {
		grid-column: 1/-1;
		width: fit-content;
		margin: 0 0 spacing(4);

		@include if-size("desktop") {
			grid-column: 1 / 6;
			margin-bottom: none;
		}

		#footer-reviews-inner {
			border-radius: 4px;
			background: $white;
			width: fit-content;

			@include if-size("tablet") {
				display: flex;
				flex-wrap: nowrap;
			}
		}
	}

	.rating {
		width: 190px;
	}

	#footer-social-links {
		margin-top: spacing(4);
		display: flex;
		justify-content: space-between;
		padding-left: spacing(1);

		@include if-size("desktop") {
			margin-top: spacing(2);
			margin-bottom: 33px;
		}
	}
}

#minimal-site-footer-terms-privacy {
	margin-bottom: spacing(2);
}

#minimal-site-footer-disclaimer {
	margin: 0 auto;
	text-align: center;

	* {
		color: $cloud;
	}

	a:hover {
		text-decoration: underline;
	}
}

#minimal-site-footer {
	width: 100%;
	background: $denim;
	padding: spacing(4) 0;
	margin-top: auto;

	@include if-size("desktop") {
		padding: spacing(8) 0;
	}
}

#desktop-footer-language-picker-container {
	display: none;

	@include if-size("desktop") {
		display: block;
		margin-top: spacing(4);
	}
}
