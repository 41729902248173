#property-taxes-101 {
	.property-taxes-101-title-section {
		padding: spacing(8) 0;

		@include if-size("desktop") {
			padding: spacing(12) 0;
		}

		&-img {
			grid-column: 1/-1;
			text-align: center;
			padding-bottom: spacing(2);

			@include if-size("tablet") {
				grid-column: 3/-3;
			}

			@include if-size("desktop") {
				grid-column: 1/4;
				padding-bottom: 0;
			}

			svg {
				max-width: 240px;

				@include if-size("desktop") {
					max-width: auto;
				}
			}
		}

		&-body-section {
			grid-column: 1/-1;
			@include if-size("desktop") {
				grid-column: 5/-1;
			}

			p {
				padding-top: spacing(2);
			}
		}

		a {
			text-decoration: underline;
		}

		.button-v2 {
			margin: spacing(4) auto 0;
			text-decoration: none;
		}
	}

	.property-taxes-101-title-section-body-full-section {
		grid-column: 1/-1;
		padding-top: spacing(2);

		@include if-size("tablet") {
			padding-top: spacing(4);
		}
		p:not(:first-child) {
			padding-top: spacing(2);
		}
	}

	.property-taxes-faq-section {
		background-color: $sky-light;
		padding-top: spacing(8);

		.pricing-faq-card-body-section a {
			text-decoration: underline;
		}

		@include if-size("tablet") {
			padding-top: spacing(12);
		}
		.property-taxes-faq-section-container {
			grid-column: 1/-1;

			&-title {
				padding-bottom: spacing(6);
			}

			&-btn {
				padding-top: spacing(5);

				a {
					margin: auto;
				}
			}

			.pricing-faq-card-body {
				p {
					padding-top: spacing(2);
				}
				p:first-child {
					padding-top: 0;
				}
				.tax-table-rounded-edges-small-accordion-sections-inner {
					p {
						padding-top: 0;
					}
				}

				.property-taxes-faq-steps-container {
					p {
						padding-top: 8px;
					}

					.property-taxes-no-top-padding {
						padding-top: 0;
					}
				}
			}
		}

		.property-taxes-faq-card-special-paragraph {
			padding: spacing(2) spacing(2) 0;
			.property-taxes-faq-card-special-paragraph-title {
				font-weight: 600;
			}

			.property-taxes-faq-card-special-paragraph-subtext {
				font-style: italic;
			}
		}
	}

	#property-taxes-featured-box-section {
		position: relative;
		margin: 0 auto;
		grid-column: 1/-1;
		width: 100%;
		padding-top: spacing(8);

		@include if-size("desktop") {
			padding-top: spacing(12);
			grid-column: 2/12;
		}

		.property-taxes-featured-box-section-container {
			background-color: $white;
			text-align: center;
			border-radius: 32px 32px 0 0;
			font-size: 14px;
			color: $denim;
			width: 100%;
			padding: 16px 14px;

			&-now-serving {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				padding-top: spacing(2);
				gap: 8px 16px;
				margin: 0 auto;

				@include if-size("tablet") {
					padding-top: 8px;
					gap: 8px 32px;
				}
			}
		}
	}

	.property-taxes-review-section {
		padding: spacing(8) 0;

		@include if-size("tablet") {
			padding: spacing(12) 0;
		}

		&-title {
			grid-column: 1/-1;
			padding-bottom: spacing(2);
		}
	}

	.template-pricing-card-section {
		background-color: $sky-light;
		padding: spacing(8) 0;

		&-btn {
			padding-top: spacing(4);
			grid-column: 1/-1;
			margin: auto;

			@include if-size("desktop") {
				padding-top: 48px;
			}
		}
	}
	.property-taxes-101-faq-subtext {
		overflow-wrap: break-word;
	}

	.template-pricing-card-section-subtext {
		padding-top: spacing(2);
		grid-column: 1/-1;
		text-align: center;

		&-link {
			color: $denim;
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.template-pricing-card-section-btn {
		a {
			margin: auto;
		}
	}
}

#property-taxes-101-carousel {
	padding-top: spacing(2);
}

.property-taxes-faq-steps-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.property-taxes-faq-steps-sections {
		padding-top: spacing(3);

		label {
			padding-top: spacing(1);
		}
	}
}

.tax-table-rounded-edges {
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	text-align: left;

	@include if-size("tablet") {
		width: 95%;
	}

	th {
		background-color: $sand;
		padding: spacing(1) spacing(2);
		border-right: 4px solid $white;
		&:last-of-type {
			border-right: 0;
		}
	}

	td {
		background-color: $cloud;
		padding: spacing(1) spacing(2);
		border-right: 4px solid $white;
		border-top: 4px solid $white;

		&:last-of-type {
			border-right: 0;
		}

		a {
			color: $denim;
			text-decoration: underline;
		}
	}

	.tax-table-rounded-edges-small-accordion-sections {
		display: none;

		&-active {
			display: block;
		}
	}

	.tax-table-rounded-edges-accordion-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		svg {
			width: 12px;
			height: 12px;
		}
	}

	.tax-table-rounded-edges-small-accordion-sections-inner {
		padding-top: 16px;
	}
}

#property-taxes-101-faq-table-desktop {
	margin: spacing(4) auto;
	display: none;
	@include if-size("desktop") {
		display: revert;
	}
}

#property-taxes-101-faq-table-mobile {
	margin: spacing(4) auto;
	@include if-size("desktop") {
		display: none;
	}
}
