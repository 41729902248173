#full-screen-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($denim, 0.75);
	z-index: 100000000;
	display: grid;
	place-items: center;
}
