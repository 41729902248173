.authenticate-card {
	grid-column: 1 / -1;
	background-color: $denim-lightest;
	padding: spacing(2);
	border-radius: 16px;
	margin-top: spacing(4);

	@include if-size("desktop") {
		padding: spacing(3);
		grid-column: -3 /3;
	}

	ul {
		padding-left: spacing(2);
		list-style: inside;
	}

	#appeal-authenticate-password-requirements-blurb {
		margin-top: spacing(2);
	}

	.input-error-text {
		margin-top: spacing(1);
	}

	.appeal-authenticate-password-field {
		margin-top: spacing(2);
		select {
			margin-top: 0;
		}
	}

	#password-requirements-card {
		margin-top: 0;
		label {
			color: $denim;
			text-transform: none;
			font-weight: bold;
			letter-spacing: 0;
			font-size: 16px;
		}
		background: transparent;
		border: 2px solid $white;
		padding: spacing(2);
		border-radius: 8px;

		height: min-content;

		ul {
			li {
				white-space: nowrap;
			}
		}
	}

	.authenticate-card-password-row {
		display: flex;
		flex-direction: column;
		gap: spacing(2);

		@include if-size("desktop") {
			flex-direction: row;
			gap: spacing(4);
		}
	}

	fieldset {
		flex: 1;

		label.label {
			color: $denim;
		}
	}
}

#appeal-authenticate-cta-container {
	grid-column: 1 / -1;
	margin-top: spacing(2);
	display: flex;
	flex-direction: column;
	align-items: center;

	.login-with-google {
		margin-top: spacing(2);
	}

	@include if-size("desktop") {
		grid-column: -3 /3;
		margin-top: spacing(4);
	}
}

#appeal-authenticate-forgot-password-link {
	margin-top: spacing(2);
}

#obscured-portal {
	height: 100%;

	.top-nav-contents {
		z-index: 1000;
		.top-nav-logo {
			display: block;
		}
	}
}

.obscured-portal-layout {
	height: 100%;
	overflow: auto;
	position: relative;
	background-color: $slate;

	label.label {
		color: $denim_5;
	}

	#obscured-portal-layout-content {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		z-index: 110;
		width: 100%;
	}

	#obscured-portal-layout-sidebar {
		@extend #property-nav;
		z-index: 0 !important;
		background-color: $slate !important;
		width: 100%;
		height: 142px;
		overflow: hidden !important;
		top: 0 !important;

		@include if-size("desktop") {
			width: auto;
			height: 100%;
		}
	}

	.obscured-portal-layout-sidebar-image {
		width: 100%;
		background-color: $denim-lightest;
		height: 80px;
		z-index: 10;
		display: none;
		@include if-size("desktop") {
			display: block;
		}
	}

	.obscured-portal-layout-sidebar-content {
		background-color: $denim-lightest;
		width: 41px;
		height: 15px;
		border-radius: 40px;
		// display: none;
		@include if-size("desktop") {
			width: 143px;
			height: 22px;
			display: block;
		}
	}

	.mobile-obscured-portal-layout-sidebar-content {
		display: flex;
		justify-content: space-between;
		width: 100%;

		@include if-size("desktop") {
			display: none;
		}
		.obscured-portal-layout-sidebar-content {
			display: block;
		}
	}

	.obscured-portal-layout-sidebar-content-short {
		@extend .obscured-portal-layout-sidebar-content;
		width: 71px;
		height: 16px;
	}

	.obscured-portal-layout-sidebar-gap-container {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.obscured-portal-layout-sidebar-footer {
		display: none;
		padding: 16px;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		align-self: stretch;
		border-radius: 16px;
		border: 1px solid $denim-lightest;
		margin-top: auto;

		@include if-size("desktop") {
			display: flex;
		}
	}

	#obscured-portal-background-layout-content {
		width: 100%;
		padding: 32px;
		margin-top: 30%;
		overflow: hidden;
		@include if-size("desktop") {
			display: block;
			margin-top: 0;
		}
	}

	.obscured-portal-background-layout-content-large-box {
		width: 100%;
		@extend #ways-to-save;
		z-index: 0;

		/* Card Shadow */
		box-shadow: -44px 319px 90px 0px rgba(171, 171, 171, 0),
			-28px 204px 82px 0px rgba(171, 171, 171, 0.01),
			-16px 115px 70px 0px rgba(171, 171, 171, 0.05),
			-7px 51px 51px 0px rgba(171, 171, 171, 0.09),
			-2px 13px 28px 0px rgba(171, 171, 171, 0.1);
		@include if-size("desktop") {
			height: 500px;
		}
	}
	.obscured-portal-background-layout-content-small-boxes {
		border: 1px solid #d4dee3;
		width: 100%;
		height: 300px;
		@extend #ways-to-save;
		z-index: 0;
		box-shadow: -2px 13px 28px 0px rgba(171, 171, 171, 0.1019607843),
			-7px 51px 51px 0px rgba(171, 171, 171, 0.0901960784),
			-16px 115px 70px 0px rgba(171, 171, 171, 0.0509803922),
			-28px 204px 82px 0px rgba(171, 171, 171, 0.0117647059),
			-44px 319px 90px 0px rgba(171, 171, 171, 0);
	}

	.obscured-portal-background-layout-content-lower-container {
		width: 100%;
		display: none;
		gap: 24px;
		padding-top: 24px;

		@include if-size("desktop") {
			display: flex;
		}
	}
}

#obscured-portal-layout-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	background-color: $denim;
	opacity: 0.75;
	z-index: 100;
}

#obscured-portal-background-layout-container {
	display: flex;
	flex-direction: column;
	height: calc(100% - 50px);

	@include if-size("desktop") {
		flex-direction: row;
		justify-content: space-between;
		height: calc(100% - 44px);
		background-color: $white;
	}
}

.authenticate-modal {
	margin-left: spacing(2);
	margin-right: spacing(2);
	margin-top: spacing(8);
	margin-bottom: spacing(4);

	padding: spacing(4) spacing(2);
	@include if-size("desktop") {
		padding: spacing(4);
		margin-left: auto;
		margin-right: auto;
	}

	max-width: 944px;
	min-height: 464px;
	border-radius: 16px;
	background: #ffffff;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: spacing(1);
	z-index: 10;

	.account-leftovers-property-card {
		margin-top: 0;

		border: 1px solid #f8f8f2;
		box-shadow: 1px 3px 7px 2px #0532490d;
	}

	.account-leftovers-property-card-address {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.authenticate-confirmation-code-expired-card {
		border: 2px solid $rust;
		border-radius: 8px;
		width: 100%;
		padding: spacing(1);
		margin-top: spacing(2);
	}
}

.obscured-portal-layout-nav-box {
	border-radius: 4px;
	background-color: $sky-light;
	width: 120px;
	height: 24px;
	cursor: default !important;

	&:hover {
		background-color: $sky-light !important;
	}
}
