.logout-page {
	width: 100%;
	height: 100%;
	position: relative;

	.content-container {
		margin-top: spacing(8);

		@include if-size("desktop") {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	h4 {
		text-align: center;
		margin-bottom: spacing(4);
	}

	.helper-text {
		width: 20%;
		max-width: 250px;
		margin-top: 80px;

		@include respond-to("large") {
			display: none;
		}

		h2 {
			margin-bottom: 1em;
		}
	}

	.logout-btn-container {
		@include flex("center", "center", "column");
		width: fit-content;
		margin: 0 auto;

		button {
			width: 100%;

			&:first-of-type {
				margin-bottom: spacing(2);
			}
		}
	}
}
