#all-appeals-property-card {
	z-index: 1000000;
	position: fixed;
	height: 85vh;
	width: 100%;
	left: 0;
	bottom: 0;
	top: auto;
	background: $white;
	margin-top: 0;
	box-shadow: 0px 0px 100px 0px rgba(5, 50, 73, 0.2);
	display: flex;
	flex-direction: column;

	@include if-size("desktop") {
		top: 0;
		right: 0;
		left: auto;
		height: 100%;
		width: 550px;
		border-left: 1px solid $slate;
	}
}

#all-appeals-property-card-header {
	position: relative;

	#all-appeals-property-card-header-close-container {
		position: absolute;
		top: 24px;
		right: 16px;
		padding: 10px;
		cursor: pointer;
		z-index: 1000001;
		padding: spacing(1);
		background: $white;
		border-radius: 100px;

		svg {
			height: 20px;
			width: 20px;
		}
	}

	#all-appeals-property-card-header-image {
		height: 165px;
		width: 100%;
	}
}

#all-appeals-property-card-content {
	padding: spacing(3) spacing(2) spacing(5);
	overflow-y: auto;
	flex: 1;

	.status-card {
		padding: 0;
		border: none;
	}
}

#all-appeals-property-card-separator {
	height: 1px;
	width: 100%;
	background: $slate;
	margin: spacing(3) 0;
}

#all-appeals-property-card-appeal-year-select {
	height: 30px;
	padding: spacing(0.5) spacing(1);
	padding-right: spacing(5);
	font-size: 16px;
	margin-left: spacing(2);
}

#all-appeals-property-card-mobile-underlay {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: calc(1000000 - 1);
	background: rgba(5, 50, 73, 0.75);
	margin-top: 0;

	@include if-size("desktop") {
		display: none;
	}
}

.all-appeals-reduction-badge {
	background: $kelly-lightest;
	border-radius: 4px;
	padding: 2px 6px;
	display: flex;
	gap: 2px;
	border: 1px solid $kelly-dark;
	align-items: center;
	p {
		color: $kelly-dark;
	}
}
