.i-want-page {
	#i-want-hero-section {
		margin: 0;
		max-width: 100%;
		background: $sky-light;
		padding: spacing(2) 0;
		row-gap: spacing(2);

		@include if-size("desktop") {
			padding: spacing(8) 0;
		}

		#i-want-hero-section-address-container {
			grid-column: 1 / -1;
			grid-row: 2;

			@include if-size("desktop") {
				grid-row: 1;
				grid-column: 1 / 8;
				margin-right: 50px;
			}

			#landing-hero-address-input {
				padding: 0;
				gap: 0;
				margin: spacing(3) 0;
				align-items: flex-start;
			}

			#landing-hero-address-search-with-results {
				max-width: 100%;

				@include if-size("desktop") {
					max-width: 500px;
				}
			}

			#i-want-hero-section-value-prop-list {
				list-style: inside;
				li {
					color: $azure;
				}
			}
		}

		#i-want-hero-section-img-container {
			grid-column: 1 / -1;
			grid-row: 1;

			@include if-size("desktop") {
				grid-column: 8 / -1;
			}

			img {
				width: 100%;
				height: auto;
				border-radius: 16px;
				overflow: hidden;
			}
		}
	}

	#i-want-what-you-get {
		margin: spacing(10) auto;

		.comparison-page-full-grid {
			h3 {
				font-family: "sofia-pro";
			}
		}

		.comparison-page-quick-facts {
			flex-direction: column;

			@include if-size("desktop") {
				flex-direction: row;
			}
		}

		.comparison-page-quick-facts-card {
			width: 100%;
			background: $denim_lightest;

			@include if-size("desktop") {
				width: calc(33% - 8px);
			}
		}
	}

	.marquee-stats-section {
		padding-top: spacing(2);
		background: $white;
	}

	#landing-testimonials-section {
		margin-bottom: 0;
	}

	#i-want-value-props-section {
		background: $white;
		padding-top: spacing(8);
	}

	#i-want-quick-and-easy-section {
		background: $denim_lightest;
		padding: spacing(10) 0;
		display: flex;
		flex-direction: column;

		@include if-size("desktop") {
		}

		#i-want-quick-and-easy-title {
			grid-column: 1/-1;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include if-size("desktop") {
				grid-column: 1 / 5;
			}

			h3 {
				font-family: "sofia-pro";
				font-size: 32px;
				line-height: 32px;
				margin-bottom: spacing(1);

				@include if-size("desktop") {
					font-size: 48px;
					line-height: 48px;
				}
			}
		}

		#i-want-quick-and-easy-list {
			grid-column: 1 / -1;
			margin-top: spacing(4);

			@include if-size("desktop") {
				margin-top: 0;
				grid-column: 7 / -1;
			}
		}

		.i-want-quick-and-easy-list-card {
			background: $white;
			padding: spacing(2);
			border-radius: 8px;
			box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

			&:not(:last-of-type) {
				margin-bottom: spacing(2);
			}
		}
	}

	#landing-faq-section {
		background: $denim_lightest;
		padding-top: spacing(9);
	}

	#i-want-ownwell-solution {
		background: $white;
		padding: spacing(6) 0;

		h2 {
			font-family: "sofia-pro";
			margin-bottom: spacing(2);
		}
	}
}
