@function spacing($value) {
	$result: $value * 8px;

	@return $result;
}

.w-min {
	width: min-content;
}

.space-y {
	@for $i from 0 through 10 {
		&-#{$i} {
			> * + * {
				margin-top: spacing($i);
			}
		}

		&-#{$i - 1}_5 {
			> * + * {
				margin-top: spacing($i - 0.5);
			}
		}
	}
}
@for $i from 0 through 10 {
	@each $breakpoint, $query in $new-breakpoints {
		@include if-size($breakpoint) {
			.#{$breakpoint}-space-y-#{$i} {
				> * + * {
					margin-top: spacing($i);
				}
			}

			.#{$breakpoint}-space-y-#{$i - 1}_5 {
				> * + * {
					margin-top: spacing($i - 0.5);
				}
			}
		}
	}
}

.py {
	@for $i from 0 through 12 {
		&-#{$i} {
			padding-top: spacing($i);
			padding-bottom: spacing($i);
		}
	}
}
