#expenses-card {
	background: $cloud;
	padding: spacing(3) spacing(2);
	border-radius: 16px;
	border: 1px solid #d4dee3;
	box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	@include if-size("desktop") {
		padding: spacing(3);
	}

	&-title-row {
		display: flex;
		justify-content: space-between;
		align-items: center;

		> div:first-child {
			margin-right: spacing(1);
		}

		> div:last-child > .quick-link-small {
			white-space: nowrap;
		}
	}
}

#expenses-skelton-placeholder {
	height: 320px;
	width: 100%;
	background: $white;
	margin-top: spacing(1);
	padding: spacing(2);
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	border-radius: 8px;
	position: relative;
	overflow: hidden;

	@include if-size("desktop") {
		height: 420px;
		flex-direction: row;
	}

	.expenses-chart-placeholder {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.expenses-list-placeholder {
		display: none;

		@include if-size("desktop") {
			display: flex;
			flex: 1;
			flex-direction: column;
		}
	}

	.expenses-list-item-placeholder {
		flex: 1;
		padding: spacing(1) spacing(2);
		border: 1px solid $slate;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:not(:last-of-type) {
			margin-bottom: spacing(1);
		}

		&-child {
			background: $sand;
			border-radius: 16px;
			height: 22px;

			&:first-child {
				width: 50%;
			}

			&:last-child {
				width: 20%;
			}
		}
	}

	.expenses-chart-legend-placeholder {
		margin-top: spacing(1);
		display: flex;
		justify-content: center;
		gap: spacing(2);
		width: 100%;
	}

	.expenses-chart-legend-item-placeholder {
		height: 20px;
		border-radius: 20px;
		width: 15% !important;
		background: $sand;

		@include if-size("desktop") {
			width: 20% !important;
		}
	}

	#expenses-list-item-placeholder-blur-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		backdrop-filter: blur(10px);
		z-index: 998;
	}

	#expenses-placeholder-content {
		position: absolute;
		top: 0;
		left: 50%;
		height: 100%;
		transform: translateX(-50%);
		width: 100%;
		margin: 0 auto;
		z-index: 999;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: spacing(5);

		@include if-size("desktop") {
			padding: spacing(9) spacing(8);
		}
	}

	#expenses-placeholder-slider-tooltip-wrapper {
		width: 100%;
		position: relative;
		height: 58px;
		margin-bottom: spacing(1.5);

		@include if-size("desktop") {
			margin-bottom: spacing(2);
		}
	}

	#expenses-placeholder-slider {
		width: 100%;
		position: relative;

		#expenses-placeholder-slider-tooltip {
			position: absolute;
			transform: translateX(-50%);

			left: 60%;

			@include if-size("desktop") {
				left: 80%;
			}

			// margin-left: 60%;
			// transform: translateX(-60%);
			//
			// @include if-size("desktop") {
			// 	margin-left: 80%;
			// 	transform: translateX(-80%);
			// }

			&-body {
				position: relative;
				padding: spacing(2);
				background: $sky;
				border-radius: 8px;
				width: fit-content;
				white-space: nowrap;

				&::before {
					content: "";
					display: block;
					width: 0;
					height: 0;
					position: absolute;
					border-bottom: 8px solid transparent;
					border-right: 8px solid transparent;
					border-left: 8px solid transparent;
					border-top: 8px solid $sky;
					left: 50%;
					bottom: -16px;
				}
			}
		}

		#expenses-placeholder-slider-container {
			display: flex;
			gap: 4px;
			position: relative;

			.expenses-placeholder-slider-segment {
				height: 8px;
				border-radius: 8px;

				&:nth-child(1) {
					background: $kelly;
					flex: 1;
				}

				&:nth-child(2) {
					background: $sunflower;
					flex: 2;
				}

				&:nth-child(3) {
					background: $rust;
					flex: 1;
				}
			}

			#expenses-placeholder-slider-thumb {
				position: absolute;
				height: 20px;
				width: 20px;
				border-radius: 10px;
				background: $sky;
				border: 4px solid $azure;
				top: 50%;
				transform: translateY(-50%);
				left: 60%;

				@include if-size("desktop") {
					left: 80%;
				}
			}

			.expenses-placeholder-slider-range-label {
				position: absolute;
				top: 10px;
				transform: translateX(-50%);

				&-1 {
					@extend .expenses-placeholder-slider-range-label;
					left: 25%;
				}

				&-2 {
					@extend .expenses-placeholder-slider-range-label;
					left: 75%;
				}
			}
		}
	}
}
