#insights-texas-multi-family-analysis {
	display: block;
}

#insights-texas-multi-family-analysis-hero-section-underlay {
	padding: spacing(8) 0;
	width: 100%;
	background: $sky-light;
}

#insights-texas-multi-family-analysis-hero-section {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	gap: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
	}

	button {
		margin: spacing(2) auto 0;

		@include if-size("desktop") {
			margin: spacing(2) 0 0;
		}
	}

	img {
		max-width: 343px;
		max-height: 327px;

		@include if-size("desktop") {
			max-width: 463px;
			max-height: 442px;
		}
	}
}

#insights-texas-multi-family-analysis-stats-section-underlay {
	padding: spacing(8) 0;
}

#insights-texas-multi-family-analysis-stats-section {
	h3 {
		text-align: center;
		margin-bottom: spacing(8);
	}
}

#insights-texas-multi-family-analysis-stats-section-stats-container {
	display: flex;
	gap: spacing(2);
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@include if-size("desktop") {
		flex-direction: row;
	}
}

.insights-texas-multi-family-analysis-stats-section-stats-stat {
	flex: 1;
	text-align: center;
}

.insights-texas-multi-family-analysis-stats-section-stats-stat-value {
	font-size: 62px;
	line-height: 110%;
	text-align: center;
	font-weight: 900;
}

.insights-texas-multi-family-analysis-stats-section-stats-stat-description {
	b {
		font-weight: 900;
	}
}

#insights-texas-multi-family-analysis-whats-inside-section-underlay {
	background: $denim-dark;
	padding: spacing(8) 0;
	position: relative;
	overflow: hidden;

	&::before {
		width: 50%;
		left: 50%;
		bottom: -70%;
		transform: translateX(-50%);
		padding-top: 50%;
		content: "";
		position: absolute;
		border-radius: 1000000px;
		filter: blur(160px);
		background: rgba(0, 73, 141, 0.6);
		z-index: 1;
	}
}

#insights-texas-multi-family-analysis-whats-inside-content {
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
	gap: spacing(4);
	z-index: 10;

	> * {
		z-index: 10;
	}

	@include if-size("desktop") {
		flex-direction: row;
	}
}

#insights-texas-multi-family-analysis-whats-inside-section-value-props-container {
	display: flex;
	flex-direction: column;
	gap: spacing(3);
}

.insights-texas-multi-family-analysis-whats-inside-section-value-prop-banner {
	position: relative;
	background: $sunflower;
	display: flex;
	align-items: center;
	gap: spacing(1);
	width: fit-content;
	padding: spacing(1);

	&:before {
		content: "";
		position: absolute;
		width: 10000px;
		height: 100%;
		background: $sunflower;
		z-index: 10;
		transform: translateX(-10000px);
	}
}

.insights-texas-multi-family-analysis-whats-inside-section-value-prop-icon {
	svg {
		height: 32px;
	}
}

.insights-texas-multi-family-analysis-whats-inside-section-value-prop-label {
	margin-top: -6px;
}

.insights-texas-multi-family-analysis-whats-inside-section-value-prop-items {
	list-style-position: outside;
	padding-left: spacing(2);
	padding-top: spacing(1);
	z-index: 10000;

	li {
		z-index: 1000;
	}

	li:not(:last-child) {
		margin-bottom: spacing(1);
	}

	* {
		color: $white;
	}
}

#insights-texas-multi-family-analysis-whats-inside-section-cta-container {
	width: fit-content;
}

#insights-texas-multi-family-analysis-form-section-underlay {
	padding: spacing(8) 0;
	background: $denim-lightest;
}

#insights-texas-multi-family-analysis-form-section-card {
	display: flex;
	flex-direction: column-reverse;
	max-width: fit-content;
	height: fit-content;
	padding: spacing(4) spacing(2);
	box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
	background: $white;
	border-radius: 16px;
	margin: 0 auto;
	gap: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
		padding: spacing(4) spacing(7);
	}

	> img {
		flex: 1;

		@include if-size("desktop") {
			width: 50%;
		}
	}

	> form {
		width: 100%;
		flex: 1;

		> button {
			margin: 0 auto;
		}

		@include if-size("desktop") {
			width: 50%;

			> button {
				margin: spacing(4) 0 0;
			}
		}
	}

	.text-field-input-wrapper:not(:last-child) {
		margin-bottom: spacing(2);
	}
}

#insights-texas-multi-family-analysis-about-the-data-section-underlay {
	padding: spacing(8) 0;
}
