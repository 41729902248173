#faq-page {
	padding: spacing(8) 0;
}

.faq-title,
.faq-section-title {
	grid-column: 1 / -1;
}

.faq-title {
	text-align: center;
	margin-bottom: spacing(5);
}

.faq-section-title {
	margin: spacing(5) spacing(0);
}
