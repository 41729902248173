#account-property-profile-property-details-2 {
	.button-v2 {
		margin: 0 auto;
	}

	.account-property-profile-input-container {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin: spacing(4) 0 spacing(2);

		.question-section-title {
			width: 100%;
			margin-bottom: spacing(2);
		}
	}
}

.profile-feature-multiselect-section {
	width: 100%;
	max-width: 192px;
	margin-bottom: spacing(4);

	.row {
		justify-content: space-between;
		display: flex;
		flex-wrap: wrap;

		.checkbox {
			width: 77px;
		}

		&:first-of-type {
			margin-bottom: spacing(1);
		}
	}

	@include if-size("desktop") {
		max-width: 244px;
	}
}
