.appeal-flow-next-steps-hero-img {
	grid-column: 1/-1;
	max-width: 150px;
	margin: 0 auto 31px;

	@include if-size("desktop") {
		grid-column: 2/4;
		max-width: 100%;
		margin: none;
	}
}

.appeal-flow-next-steps-hero-text-section {
	grid-column: 1/-1;
	@include if-size("desktop") {
		grid-column: 4/12;
	}
}

.appeal-flow-next-steps-hero-text-section-btns {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: spacing(3) 0 spacing(4);
	gap: 24px;
	@include if-size("tablet") {
		flex-direction: row;
		justify-content: left;
		padding-bottom: spacing(8);
	}
}

.appeal-flow-next-steps-whats-next-wrapper {
	background-color: $cloud;
	padding: spacing(5) 0;
	position: relative;
}

.appeal-flow-next-steps-whats-next {
	grid-column: 1/-1;
	position: relative;
	@include if-size("desktop") {
		grid-column: 3/-3;
	}
}

.appeal-flow-next-steps-whats-next-upper-body {
	padding: 4px 0 8px;
}

.appeal-flow-next-steps-cards-wrapper {
	display: flex;
	align-items: center;
	gap: 12px;
}
.appeal-flow-next-steps-cards {
	background-color: $white;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 8px;
	padding: 12px 16px;
	margin: 8px 0;
	width: 232px;
	min-height: 96px;
	display: flex;
	align-items: center;
	@include if-size("tablet") {
		padding: 24px 16px;
		width: 400px;

		&-sm-padding {
			padding: 12px 16px;
		}
	}

	@include if-size("desktop") {
		width: 520px;
	}

	svg {
		margin-right: spacing(2);
		height: 24px;
		width: 24px;
		min-width: 24px;
		min-height: 24px;
	}
}

.appeal-flow-next-steps-cards-arrow {
	width: 20px;
	height: 20px;
	min-width: 20px;
	@include if-size("tablet") {
		width: 24px;
		height: 24px;
		min-width: 24px;
	}
}

.appeal-flow-whats-next-bottom-p {
	padding: 8px 0 24px;
}

.appeal-flow-whats-next-bottom-btn-wrapper {
	text-align: center;
}

.appeal-flow-whats-next-bottom-btn-link {
	display: inline-block;
}

.appeal-flow-whats-next-tooltip {
	position: absolute;
	padding: 16px;
	width: 162px;
	height: 168px;
	background: #1c7ce5;
	border-radius: 8px;
	display: none;

	@include if-size("desktop") {
		display: block;
		left: -192px;
		top: 100px;
	}

	&:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-width: 10px;
		border-style: solid;
		border-color: transparent transparent transparent $azure;
		top: 11px;
		right: -20px;
	}

	.label,
	p {
		color: $white;
	}

	.label {
		padding-bottom: 2px;
	}
}

#appeal-flow-next-steps-premium-pricing {
	grid-column: 1/-1;
	display: flex;
	flex-direction: column;

	button.button-v2.primary {
		margin-top: spacing(4);
	}

	button.button-v2.primary-outline {
		margin-top: spacing(2);
	}

	a,
	button.button-v2 {
		margin: auto;
	}

	#premium-pricing-comparison-table {
		margin-top: spacing(8);
	}
}
