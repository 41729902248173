#equity-card {
	> p.bold {
		margin-bottom: 8px;
		@include if-size("desktop") {
			margin-bottom: 12px;
		}
	}

	.equity-progress-chart {
		height: 150px;

		@include if-size("desktop-xl") {
			height: 200px;
		}
	}

	.calculation-breakdown {
		display: flex;
		flex-direction: column;

		list-style: none;
		padding: spacing(2);

		background-color: $denim_lightest;
		border-radius: 8px;

		li {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 4px;
		}
	}

	.equity-box {
		position: relative;
		.equity-amount {
			font-size: 48px;
			font-weight: 800;
			line-height: 55.2px;
			letter-spacing: -0.03em;
			text-align: left;
			background: linear-gradient(180deg, #57c975 0%, #249241 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			&[data-mold="true"] {
				filter: blur(10px);
			}
		}
		.equity-empty-overlay {
			position: absolute;
			bottom: 0;
			display: flex;
			gap: 8px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			width: 100%;
		}
		&.extra-padding-bottom {
			padding-bottom: 80px;
		}

		.data-change {
			display: flex;
			justify-content: space-around;
			align-items: center;
			text-align: center;
			gap: 24px;
			margin: 24px 0;
			.vertical-divider {
				width: 1px;
				border-right: 1px solid $sand;
				height: 60px;
			}
		}
	}
}
