#arbitration-page {
	position: relative;

	#arbitration-page-header {
		// background: $cloud;
	}

	#arbitration-page-header-underlay {
		position: absolute;
		width: 100%;
		height: 100%;
		transform: translateX(0%);
		top: 0;
		z-index: -1;

		background: $cloud;
	}

	#arbitration-page-header-assessment-container {
		display: flex;
		margin: spacing(5) 0;
		gap: spacing(1);
		flex-direction: column;

		@include if-size("desktop") {
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}

	.arbitration-assessment-card {
		padding: spacing(1);
		border-radius: 8px;
		border: 1px solid $slate;
		background: $white;
		width: 100%;

		@include if-size("desktop") {
			width: 396px;
		}

		&-ownwell {
			@extend .arbitration-assessment-card;
			background: $sky-light;

			h4 {
				color: $azure;
			}
		}
	}

	#arbitration-benefits-card {
		grid-column: 1/-1;
		border-radius: 8px;
		padding: spacing(3) spacing(8);
		box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
		background: $white;
		margin-top: spacing(3);

		@include if-size("desktop") {
			grid-column: 2/-2;
		}

		#arbitration-benefits-card-body {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			column-gap: spacing(3);
			row-gap: spacing(1.5);

			.arbitration-benefits-card-value-prop {
				display: flex;
				align-items: center;

				svg {
					height: 24px;
					width: 24px;
				}

				p {
					color: $azure;
					font-weight: bold;
					margin-bottom: 2px;
					margin-left: 8px;
				}
			}
		}

		#arbitration-benefits-card-value-prop-upfrontcost {
			// text-decoration: line-through $rust;
			position: relative;

			&::after {
				position: absolute;
				content: "";
				left: 0;
				top: 55%;
				width: 100%;
				height: 2px;
				background: $rust;
			}
		}
	}

	#arbitration-faq-section {
		margin: spacing(6) auto;

		.pricing-faq-card {
			svg {
				height: 48px;
				width: auto;
				max-height: 48px;
				max-width: 48px;
			}
		}

		&.arbitration-faq-cards-full-width {
			.pricing-faq-card-col {
				grid-column: 1/-1;
			}
		}
	}

	#arbitration-property-preview-section {
		background: $cloud;
		padding: spacing(2);
		border-radius: 8px;

		.account-leftovers-property-card {
			background: $white;
			margin-bottom: spacing(2);
		}
	}
}

#arbitration-confirmation-page {
	#arbitration-confirmaton-page-heading {
		grid-column: 1 / -1;
	}
}
