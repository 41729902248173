.progress-bar {
	.progress-bar-container {
		width: 100%;
		height: 8px;
		margin-top: 4px;
		border-radius: 8px;
		background-color: $sand;
		overflow: hidden;
		box-shadow: inset 0px 0px 0px 1px $azure;

		.progress-bar-bar {
			height: 100%;
			background-color: $azure;
			border-radius: 8px;
			height: calc(100% + 1px);
			transition: width 0.5s;
		}
	}
}

.progress-bar-mint {
	@extend .progress-bar;

	.label {
		color: $white;
	}

	.progress-bar-container {
		background-color: $denim;
		box-shadow: inset 0 0 0 1px $mint;

		.progress-bar-bar {
			background-color: $mint;
		}
	}
}

.progress-bar-mint2 {
	@extend .progress-bar;

	.progress-bar-container {
		background-color: $denim;
		box-shadow: inset 0 0 0 1px $mint;

		.progress-bar-bar {
			background-color: $mint;
		}
	}
}
