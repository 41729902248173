#amortization-table-card {
	order: 6;
	position: relative;
	background-color: $white;
	box-shadow:
		-2px 13px 28px 0px #ababab1a,
		-7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d,
		-28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	border: solid 1px $slate;
	border-radius: 16px;
	padding: spacing(2);
	//height: min-content;
	@include if-size("desktop") {
		padding: spacing(3);
	}
	.card-subtext {
		color: $denim_5;
		font-weight: 400;
	}
	.amortization-table {
		margin-top: 20px;
	}
}
