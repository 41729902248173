.status-card {
	height: min-content;
	border: 1px solid $slate;
	position: relative;
	box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	padding: spacing(3) spacing(2);
	@include if-size("desktop") {
		padding: spacing(3);
	}
	width: 100%;
	background-color: $white;
	border-radius: 16px;

	.status-card-status-top-row {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: spacing(3);

		#status-card-title {
			margin-bottom: 0;
		}

		.collapse-history {
			margin-left: 20px;
			display: none;
			place-self: center end;

			width: 16px;
			height: 16px;

			@include if-size("desktop") {
				height: 24px;
				width: 24px;
				margin-left: 40px;
			}

			&.show {
				display: unset;
				cursor: pointer;
			}
		}
	}

	.status-card-status-top-row-appeal-year {
		display: flex;
		gap: 4px;
		align-items: center;
		margin-left: auto;
	}

	.status-card-status-row {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> .label {
			place-self: center center;
			font-size: 20px;
			text-transform: capitalize;
		}

		&-needs-attention {
			@extend .status-card-status-row;
			.step-counter {
				.step-counter-line {
					background-color: $terracotta;
				}
				.step {
					border-color: $terracotta;
					&-active {
						background-color: $terracotta !important;
					}
				}
			}
		}
	}

	.status-card-icon {
		margin-top: 2px;
		height: 16px;
		width: 16px;

		@include if-size("desktop") {
			height: 20px;
			width: 20px;
		}

		&.royal {
			path {
				fill: $royal;
			}
		}

		&.rust-dark {
			path {
				fill: $rust-dark;
			}
		}
	}

	.status-card-title-row {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	.status-card-body {
		margin-top: spacing(1);
	}

	.status-card-won-body {
		margin: spacing(2) auto 0;
		width: fit-content;

		.status-card-won-summary {
			border: solid 1px $denim;
			border-radius: 8px;
			overflow: hidden;
			display: flex;
			flex-direction: row;

			.status-card-original-assessment {
				padding: spacing(2);
			}

			.status-card-new-assessment {
				padding: spacing(2);
				padding-left: 0;
			}

			.status-card-won-savings {
				padding: spacing(2);
				background-color: $denim;

				label,
				p {
					color: $white;
				}
			}
		}
	}

	.status-card-expanded-body {
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		gap: 14px;

		ul {
			padding-left: spacing(3);
			margin-top: spacing(1);
		}
	}

	.status-card-cta {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include if-size("tablet") {
			flex-direction: row;
			justify-content: space-between;
		}

		&-expand-history {
			display: flex;
			margin-top: 16px;

			@include if-size("tablet") {
				margin-left: auto;
			}

			&.hide {
				display: none;
			}
		}

		&:not(:empty) {
			margin-top: spacing(2);
		}
	}

	.status-card-expand-history {
		display: flex;
		position: absolute;
		bottom: 0;
		right: 0;

		@include if-size("desktop-xl") {
			bottom: spacing(3);
			right: spacing(3);
		}

		&.hide {
			display: none;
		}

		&.special-padding {
			bottom: spacing(2);
			right: spacing(2);
			@include if-size("desktop-xl") {
				bottom: spacing(3);
				right: spacing(3);
			}
		}
	}

	#status-card-title {
		margin-bottom: 0;
	}
}

.status-card-urgent {
	.needs-attention-uploading-docs-pill {
		margin-top: spacing(2);
		margin-bottom: 0;
	}

	.mobile-card-color-underlay {
		height: calc(100% + spacing(8)) !important;
		top: calc(-1 * spacing(4)) !important;
	}

	@include if-size("desktop") {
		margin: 0;

		.mobile-card-color-underlay {
			height: 100%;
			top: 0;
		}
	}

	.status-card-box {
		background-color: $rust-lightest-64;
		border-color: $rust;
	}
}

.status-card-box {
	margin-top: spacing(2);
	margin-bottom: spacing(2);
	padding: spacing(2);
	gap: 8px;
	background: $denim_lightest;
	border: solid 1px $slate;
	border-radius: spacing(1);
}
