#referral-socials-section {
	padding: spacing(1) 0;
}

#referral-socials-container {
	img {
		width: 30px;
		height: 30px;
	}

	.share-btn {
		width: 30px;
		height: 30px;

		svg {
			fill: $azure;
			width: 32px;
			height: 32px;
		}
	}

	.share-btn:not(:last-child) {
		margin-right: spacing(2);
		@include if-size("tablet") {
			margin-right: spacing(4);
		}
	}
}
