#account-property-profile-property-details-1 {
	.button-v2 {
		margin: 0 auto;
	}

	#profile-property-details1-skip {
		cursor: pointer;
		margin: spacing(4) auto 0;
		text-align: center;
		color: $azure;
	}

	.account-property-profile-input-container {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin: spacing(4) 0 spacing(2);

		#property-image-container {
			width: 100%;
			margin-bottom: spacing(4);

			#property-image {
				border-radius: 16px;
				height: 200px;
				background-size: cover;
				background-position: center;
			}

			p {
				margin-top: spacing(2);
			}

			@include if-size("tablet") {
				width: calc(50% - spacing(1));
				margin-right: spacing(1);
			}
		}

		#property-title-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-bottom: spacing(2);

			h4 {
				margin-bottom: spacing(2);
			}

			@include if-size("tablet") {
				width: calc(50% - spacing(1));
				margin-left: spacing(1);
				margin-bottom: 0;
				height: 200px;
			}
		}

		#profile-home-sqft-question {
			position: relative;

			&::after {
				width: fit-content;
				content: "Sq Ft";
				position: absolute;
			}
		}
	}
}
