.bbb-letter {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(0, 90, 120); /* Custom color to match BBB logo */
	border-radius: 6px;
	height: 44px;
	width: 50px;

	p {
		color: #fff;
		line-height: 1.9;
		text-align: center;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 29px;
		font-weight: 600;
	}
}

.bbb-rating {
	display: flex;
	align-items: center;
	padding: 8px;
	border-radius: 5px;
	text-decoration: none;
}
