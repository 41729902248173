.exemptions-survey-wrapper,
#exemptions-survey-modal,
#end-to-end-exemptions-flow {
	.appeal-legal-signature-container {
		margin-top: spacing(2);
	}

	.profile-modal-back {
		top: 18px;
		left: 18px;
		font-size: 14px;

		@include if-size("desktop") {
			top: unset;
			left: unset;
			span {
				font-size: 16px;
			}
		}
	}
	.property-profile-question-card-title-container {
		margin-bottom: 0;
	}
	.property-profile-question-card-title-container > p {
		font-weight: 600;
	}
	.property-profile-question-card-subtitle {
		opacity: 0.5;
		color: $denim;

		@include if-size("tablet") {
			width: 80%;
		}
	}

	.exemptions-card-input {
		margin-top: spacing(2);
	}

	.vehicle-registration-entries {
		li:not(:last-of-type) {
			padding-bottom: spacing(3);
			border-bottom: 2px solid $white;
		}

		.vehicle-registration-entry {
			position: relative;
			@include if-size("desktop") {
				gap: spacing(2);
			}

			.exemptions-card-input {
				flex: 1;
				width: auto;
			}

			.remove-vehicle-registration-entry {
				position: absolute;
				top: spacing(2);
				right: 0px;

				@include if-size("desktop") {
					top: unset;
					right: unset;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: flex-end;
				}

				svg {
					margin-bottom: 12px;
					height: 16px;
					width: 16px;
					cursor: pointer;

					@include if-size("desktop") {
						height: 24px;
						width: 24px;
					}
				}
			}
		}
	}

	.exemption-survey-card {
		.exemption-question-card-subtitle {
			@extend .property-profile-question-card-subtitle;
			width: 100%;
		}

		textarea {
			width: 100%;
		}

		select.with-label {
			margin-top: 0px;
		}
	}

	.exemption-survey-question-income-producing {
		margin-bottom: 14px;
	}

	.exemption-ownership-field-container {
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		@include if-size("desktop") {
			flex-direction: row;
		}

		input {
			width: 70px;
			position: relative;
		}

		.exemption-ownership-field-owner-text {
			padding: 7px 0 0 8px;
		}
	}

	.exemption-ownership-field-input-label {
		padding: 8px 0 2px 8px;
	}

	.exemption-ownership-field-input-wrapper {
		display: flex;
		&:first-of-type {
			margin-bottom: spacing(2);
			@include if-size("desktop") {
				margin-bottom: 0;
			}
		}
	}

	.exemption-special-field-percent-container {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		@include if-size("desktop") {
			flex-direction: row;
		}

		input {
			width: 70px;
			position: relative;
		}

		.exemption-special-field-percent-text {
			padding: 7px 0 0 8px;
		}
	}

	.exemption-special-field-percent-input-label {
		padding: 8px 0 2px 8px;
	}

	.exemption-special-field-percent-input-wrapper {
		display: flex;
		&:first-of-type {
			margin-bottom: spacing(2);
			@include if-size("desktop") {
				margin-bottom: 0;
			}
		}
	}

	.exemptions-question-card {
		margin-bottom: 32px;
	}

	p.exemptions-disclaimer-footer {
		text-align: left;
	}
}

.exemptions-survey-modal-inner-container {
	margin: 0;
	@include if-size("desktop") {
		margin: 0 64px 0;
	}
}

.exemptions-question-card {
	@extend .property-profile-question-card;
}

.exemptions-intro-animation-box {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	gap: 16px;
	@include if-size("desktop") {
		flex-direction: row;
	}
}

.exemptions-intro-animation-box-reversed {
	@extend .exemptions-intro-animation-box;
	@include if-size("desktop") {
		flex-direction: row-reverse;
	}
}

.exemptions-intro-animation {
	min-width: 120px;
	width: 120px;
}

.exemptions-question-card-white-card {
	background-color: $white;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 16px;
	padding: spacing(2);

	ul {
		padding-left: 22px;

		li {
			font-size: 16px;
		}
	}
}

.exemptions-notification-message {
	border-radius: 16px;
	padding: 16px;
	margin: -32px 0 32px;
	color: $white;

	&-azure {
		@extend .exemptions-notification-message;
		background-color: $azure;
	}

	&-rust {
		@extend .exemptions-notification-message;
		background-color: $rust;
	}
}

.property-profile-question-card + .exemptions-notification-message {
	text-align: left;
	padding-bottom: 16px;
}

.exemptions-estimate-card-savings-text {
	width: auto;
	margin-right: 0;
	@include if-size("tablet") {
		width: 100px;
		margin-right: 6px;
	}
}

.exemptions-estimate-card-savings-text-retro {
	@extend .exemptions-estimate-card-savings-text;
}

.exemptions-estimate-card-saving-amt {
	font-family: "Moret";
	font-weight: 400;
	font-size: 48px;
	line-height: 100%;
	text-align: center;
	color: $azure;

	@include if-size("tablet") {
		font-size: 75px;
		line-height: 87px;
	}
}

.exemptions-estimate-card-future-yearly-savings-container {
	margin: auto;

	@include if-size("tablet") {
		margin: 0 auto 0 0;
	}

	h1 {
		color: $azure;
	}

	.exemptions-estimate-card-future-yearly-savings-flex {
		display: flex;
		align-items: center;
		flex-direction: column;

		@include if-size("tablet") {
			flex-direction: row;
			gap: 8px;

			p {
				max-width: 122px;
			}
		}
	}
}

.exemptions-input-flex-container {
	margin-top: 0px;
}

#exemptions-savings-box {
	#savings-loading-container {
		border-top: none;
	}
}
.exemptions-homestead-incentives-cards-container {
	display: flex;
	justify-content: space-between;
	text-align: center;
	flex-direction: column;
	gap: spacing(2);
	@include if-size("desktop") {
		align-items: flex-start;
		flex-direction: row;
	}
}

.exemptions-homestead-incentives-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 1 50%;

	svg {
		width: 48px;
		height: 48px;
	}
}

.exemptions-survey-retro-body {
	padding-top: 6px;
}

.exemptions-box-expand-btn {
	@extend .appeal-savings-box-expand-btn;
	.exemptions-box-expand-btn-inner-info {
		padding-top: 20px;
		text-align: left;
	}
}

.exemption-survey-skip {
	.quick-link {
		margin: 20px auto 0;
	}
}

.exemptions-continue-button {
	margin: 32px auto 0;
}

.exemptions-azure-card {
	padding: 16px;
	background: $azure;
	border: 1px solid $azure;
	border-radius: 16px;
}

.exemptions-residency-proof-checkbox-section {
	.checkbox {
		margin-bottom: 6px;
		align-items: flex-start;

		p {
			position: relative;
			top: -9px;
		}
	}
}

.exemptions-survey-rare-situations-checkbox-section {
	.checkbox {
		margin-bottom: 6px;
		align-items: flex-start;

		p {
			position: relative;
			top: -6px;
		}
	}

	div:last-of-type {
		.checkbox {
			p {
				font-weight: 600;
			}
		}
	}
}

.exemptions-survey-checkbox-secondary {
	padding-left: 22px;
}

#exemptions-agreement-page-estimate-card {
	.appeal-savings-box-label {
		flex-direction: column;
	}
}

.exemptions-survey-agreement-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 8px;
}

.exemptions-survey-agreement-info-owner {
	word-break: break-all;
}

.exemptions-survey-agreement-info-owner1-label {
	min-width: 120px;
}
.exemptions-survey-agreement-info-owner2-label {
	min-width: 70px;
}

.exemptions-survey-agreement-info-right-align {
	text-align: right;
}

.exemptions-survey-agreement-info-drivers-license {
	.label {
		padding-left: spacing(1);
	}
}

.exemptions-survey-legal-terms {
	margin-bottom: 8px;

	@include if-size("tablet") {
		max-width: 516px;
		margin: 0 auto 8px;
	}

	ul {
		margin-left: 24px;
	}
}

.exemptions-survey-agreement-info-uploaded {
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		color: $kelly;
	}

	svg {
		width: 16px;
		height: 12px;
		margin-left: 4px;
	}
}

.exemptions-survey-agreement-review-details-inner-container {
	display: flex;
	flex-direction: column;

	@include if-size("desktop") {
		flex-direction: row;
		gap: 16px;
	}

	.exemptions-question-card-white-card {
		width: 100%;
	}
}

#exemptions-card {
	position: relative;
	margin-bottom: 0;
}
.exemptions-card-special-top-styling {
	border-radius: 16px 16px 0 0;
	padding: 12px 8px;
	position: relative;

	p {
		text-align: center;
		position: relative;
		z-index: 15;
	}

	.exemptions-display-dollar {
		display: inline;
	}

	&.exemptions-card-special-top-styling-denim {
		background-color: $denim;

		p {
			color: $white;
		}
	}

	&.exemptions-card-special-top-styling-sand {
		background-color: $sand;

		p {
			color: $denim;
		}
	}
}

.exemption-card-survey-link {
	.quick-link-arrow {
		position: relative;
		top: 1px;
	}
}

.exemptions-card-body-with-animation {
	padding-top: 4px;
	display: flex;
	flex-direction: column-reverse;
	@include if-size("desktop") {
		flex-direction: row;
	}
	.exemptions-card-animation-container {
		display: flex;
		align-items: center;
		gap: 16px;
		margin-bottom: 8px;

		@include if-size("desktop") {
			margin: auto 0 auto 16px;
		}

		img {
			width: 75px;
			height: 64px;
			@include if-size("desktop") {
				min-width: 120px;
				width: 120px;
			}
		}
	}
	.exemptions-card-body-with-animation-title-mobile {
		display: block;
		@include if-size("desktop") {
			display: none;
		}
	}

	.exemptions-card-body-with-animation-title-desktop {
		display: none;
		@include if-size("desktop") {
			display: block;
		}
	}

	p {
		margin-bottom: 4px;
	}
}

#property-save-more-card.exemptions-card-special-top-styling-styling-cut-off {
	border-radius: 0 0 16px 16px;
	background-color: $cloud;
	padding: 16px 16px 32px;
	.progress-bar {
		margin-bottom: 16px;

		.progress-bar-container {
			background-color: $cloud;
		}
	}
}

#exemption-survey-savings-estimate-loading-animation {
	width: 50%;
	margin: auto;
}

.exemptions-cards-tax-exemptions-label {
	padding-top: spacing(2);
}

.exemptions-cards-tax-exemptions-white-card {
	padding: 16px;
	border-radius: 8px;
	margin-top: 8px;
	background-color: $white;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

	.exemptions-cards-tax-exemptions-white-card-inner {
		display: flex;
		align-items: center;
		gap: 8px;

		svg {
			width: 24px;
			height: 24px;
			margin-top: 2px;
		}
	}
}

.exemptions-cards-terracotta {
	border: 2px solid $terracotta;
}

.exemptions-cards-tax-exemptions-right-section {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 12px;

	.exemptions-cards-tax-exemptions-caret {
		width: 12px;
		height: 6px;
		position: relative;
		top: 1px;
	}
}

.exemptions-cards-tax-exemptions-expanded-content {
	padding-top: 8px;
}

.exemptions-cards-tax-exemptions-green-bordered-box {
	border: 2px $kelly solid;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 8px;
	padding: 16px;
	margin-top: 16px;
}

.exemptions-terracotta-box {
	border-radius: 8px;
	border: 2px solid $terracotta;
	background-color: $white;
	padding: spacing(2);

	.bold {
		padding-bottom: 4px;
	}
}
.exemptions-cards-tax-exemptions-grey-bordered-box {
	border: 2px $slate solid;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 8px;
	padding: 16px;
	margin-top: 16px;
}

.exemptions-survey-general-error-msg {
	text-align: center;
	padding-top: spacing(1);
}

.exemptions-question-input-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding-top: 8px;
	gap: spacing(1);

	@include if-size("tablet") {
		flex-direction: row;
		gap: spacing(2);
	}

	.exemptions-question-input-container-split {
		width: 100%;
	}
}

.exemptions-final-tax-refund-and-invoice-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: spacing(2);
}

.exemptions-final-tax-refund-box {
	background-color: $denim;
	border-radius: 8px;
	color: $white;
	display: inline-block;
	padding: spacing(1) spacing(2);

	.label,
	p {
		color: $white;
	}
}

#exemptions-file-submitting {
	position: relative;
	text-align: center;

	img {
		height: 200px;
		width: auto;
	}

	.exemptions-show-timeout-button {
		margin: auto;
	}
}

#exemptions-issue-verifying-driver-license-message {
	ul {
		margin-left: 28px;
	}
}

#exemptions-service-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0;

	gap: spacing(1);
	@include if-size("desktop-xl") {
		gap: 12px;
	}

	.desktop-card {
		height: min-content;
		display: flex;
		flex-direction: column;
		gap: spacing(1);
		@include if-size("desktop-xl") {
			gap: spacing(2);
		}

		@include if-size("desktop-xl") {
			border-radius: 8px;
			background-color: $white;
			padding: spacing(2);
		}
	}

	.card-content {
		background-color: $denim_lightest;
		padding: spacing(2);
		border-radius: 8px;
		@include if-size("desktop-xl") {
			padding: 0;
			background-color: transparent;
			border-radius: 0;
		}

		svg {
			width: 16px;
			height: 16px;

			@include if-size("desktop-xl") {
				width: 20px;
				height: 20px;
			}

			margin-left: 4px;
			margin-top: 2px;

			&.rust {
				fill: $rust;
				path {
					fill: $rust;
				}
			}

			&.rust-dark {
				fill: $rust-dark;
				path {
					fill: $rust-dark;
				}
			}

			&.denim-medium {
				fill: $denim-medium;
				path {
					fill: $denim-medium;
				}
			}

			&.gold {
				fill: $gold;
				path {
					fill: $gold;
				}
			}

			&.royal {
				fill: $royal;
				path {
					fill: $royal;
				}
			}

			&.kelly-dark {
				fill: $kelly-dark;
				path {
					fill: $kelly-dark;
				}
			}
		}
	}

	.bordered-content {
		border-width: 2px;
		border-style: solid;
		padding: spacing(2);
		border-radius: 8px;
		background-color: $white;

		@include if-size("desktop-xl") {
		}

		&.rust {
			border-color: $rust;
			background-color: $rust-lightest-64;
		}

		&.kelly {
			border-color: $kelly;
		}

		.exemptions-final-tax-refund-and-invoice-container {
			display: flex;
			justify-content: space-between;
			padding-top: spacing(2);
		}

		.exemptions-final-tax-refund-box {
			background-color: $kelly-light;
			border-radius: 8px;
			color: $denim;
			display: inline-block;
			padding: spacing(1) spacing(2);

			border: 1px solid $kelly;

			.label,
			p {
				color: $denim;
			}
		}
	}
}

#exemption-address-autocomplete-container {
	width: 100%;
	margin-top: spacing(1);
	position: relative;

	&.show-results {
		#autocomplete-address-line1 {
			border-bottom: none;
			border-radius: 6px 6px 0px 0px;
		}
	}

	#exemption-address-autocomplete-results-wrapper {
		overflow-x: hidden;
		position: absolute;
		border: 1px solid $input-border-active;
		border-top: none;
		width: 100%;
		border-radius: 0px 0px 6px 6px;
		z-index: 1000;
		background-color: $white;
		top: calc(100% - 3px);
		@include styled-scrollbar;
	}

	#exemption-address-autocomplete-results-container {
		overflow-y: auto;
		overscroll-behavior: contain;
		animation: slide-in linear 5s;

		.address-autocomplete-item {
			padding: spacing(2) spacing(3);

			font-size: 16px;
			cursor: pointer;

			&-active {
				@extend .address-autocomplete-item;
				background-color: $sand;
			}

			&:hover {
				background-color: $sand;
			}
		}
	}
}
