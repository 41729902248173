#public-property-page {
	padding-bottom: spacing(10);

	h4 {
		color: $denim;
		@include if-size("desktop") {
			font-size: 32px;
			font-style: normal;
			font-weight: 727;
			line-height: 40px; /* 125% */
			letter-spacing: -0.96px;
		}
	}
}

#public-property-page-header {
	background-color: $sky-light;
	padding: spacing(1) 0 spacing(2);

	@include if-size("desktop") {
		padding: spacing(2) 0 spacing(4);
	}

	#public-property-page-header-address-section {
		grid-column: 1 / -1;
		display: flex;
		flex-direction: column-reverse;
		gap: spacing(2);

		@include if-size("desktop") {
			flex-direction: row;
			justify-content: space-between;
			gap: 0;
		}
	}
}

[data-skeleton="true"] {
	@include shimmer();
	color: transparent !important;
	fill: transparent !important;

	* {
		color: transparent !important;
		fill: transparent !important;
	}

	svg,
	svg > path {
		fill: transparent;
	}

	&:after {
		content: attr(data-skeleton-placeholder);
		display: block;
	}
}

#public-property-page-nav-bar {
	position: sticky;
	margin: 0 0 spacing(2);
	z-index: 2999;
	top: 0px;
	transition: top 0.3s;

	ul {
		height: 58px;
		grid-column: 1 / -1;
		list-style: none;
		display: flex;
		gap: spacing(5);
		overflow-x: auto;
		scrollbar-width: 0;
		-ms-overflow-style: none;
		border-bottom: 1px solid $slate;
		background: $white;

		&::-webkit-scrollbar {
			display: none;
		}

		li {
			flex: 1;
			white-space: nowrap;
			padding: spacing(2) spacing(3);
			cursor: pointer;

			// &:hover {
			// 	font-weight: 700;
			// }
		}

		li.active {
			border-bottom: 2px solid $denim;
		}
	}
}

#public-property-page-property-tax-taxable-values-container {
	grid-column: 1 / -1;
	margin-top: spacing(3);
	display: flex;
	flex-direction: column;
	gap: spacing(3);

	@include if-size("desktop") {
		grid-column: 1 / -5;
	}

	.chart-card {
		height: fit-content;
		background-color: $denim_lightest;
		border: 0;
		box-shadow: none;
	}

	.taxes-chart {
		background: rgba($white, 1);
	}

	.recharts-responsive-container {
		background: rgba($white, 1);
		filter: blur(18px);
		pointer-events: none;
		position: relative;
		user-select: none;
	}

	.chart-card-badge > p.lg {
		filter: blur(8px);
		pointer-events: none;
		user-select: none;
	}
}

.public-property-page-sign-up-to-view {
	position: absolute;
	text-align: center;
	cursor: pointer;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	span {
		display: flex;
		align-items: center;
		margin: 0 auto;
		width: fit-content;

		svg {
			height: 20px;
			width: 20px;
			margin-top: 4px;

			path {
				fill: $azure;
			}
		}
	}
}

#public-property-page-exemption-status-card {
	background: $denim_lightest;
	border-radius: 16px;
	padding: spacing(2) spacing(2) spacing(3);
	display: flex;
	gap: spacing(2);
}

#public-property-page-exemption-status-animation-container {
	max-width: 110px;
	max-height: 100px;
}

#public-property-page-exemption-status-card-animation {
	height: 100%;
	width: auto;
}

#public-property-page-exemption-section {
	.quick-link {
		font-size: 16px;
	}
}

.public-property-page-exemption-section-title,
.public-property-page-property-details-title {
	margin-bottom: spacing(2);
	color: $powder;
	font-size: 16px;
	font-style: normal;
	font-weight: 622;
	line-height: normal;
	letter-spacing: 1.28px;
	text-transform: uppercase;
}

#public-property-page-mortgage-card {
	background: $denim_lightest;
	border-radius: 16px;
	padding: spacing(3);
	grid-column: 1 /-1;

	@include if-size("desktop") {
		grid-column: 1 / -5;
	}
}

.public-property-page-mortgage-card-row {
	display: flex;
	justify-content: space-between;

	&:first-of-type {
		padding-bottom: spacing(2);
		border-bottom: 1px solid $sand;
	}

	&:last-of-type {
		padding-top: spacing(2);
	}
}

#public-property-page-neighborhood-data {
	grid-column: 1 / -1;
	list-style: none;

	@include if-size("desktop") {
		grid-column: 1/ -5;
	}

	li {
		width: 100%;
		display: flex;
		justify-content: space-between;

		&:not(:last-of-type) {
			margin-bottom: spacing(1);
		}

		span.bold {
			text-align: right;
		}
	}
}

#property-tax,
#mortgage,
#resources,
#neighborhood,
#property-details,
#faq {
	padding-bottom: spacing(5);
}

.public-property-page-section-seperator {
	grid-column: 1 / -1;
	height: 1px;
	background: $sand;
	margin-bottom: spacing(5);

	@include if-size("desktop") {
		grid-column: 1 / -5;
	}
}

.public-property-page-property-details-card {
	grid-column: 1 / -1;
	padding: spacing(3);
	border: 1px solid #f4f8fc;
	border-radius: 16px;

	@include if-size("desktop") {
		grid-column: 1 / -5;
		padding: spacing(4);
	}
}

.public-property-page-property-details-card-row {
	display: flex;
	flex-wrap: wrap;
	row-gap: spacing(3);

	&:first-of-type {
		margin-bottom: spacing(3);
	}

	&-signed-into-view {
		@extend .public-property-page-property-details-card-row;
		position: relative;

		.public-property-page-property-details-card-data-divider {
			visibility: hidden;
		}
	}
}

.public-property-page-property-details-card-data-point {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 50%;
	@include if-size("desktop") {
		width: 25%;
	}

	&-blurred {
		@extend .public-property-page-property-details-card-data-point;

		p.lg {
			filter: blur(12px);
			opacity: 0.5;
		}
	}
}

.public-property-page-property-tax-sign-up-to-view {
	height: 100%;
	width: 100%;
	background: rgba($white, 0.5);
}

.public-property-page-property-details-card-data-divider {
	height: 1px;
	width: 53px;
	background: $denim_5;
	margin: spacing(1) 0;
}

#public-property-page-property-details-general-information {
	grid-column: 1 / -1;
	max-height: 112px;
	overflow: hidden;
	position: relative;

	&::before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: linear-gradient(transparent 35px, $white);
	}

	ul {
		list-style: inside;

		li {
			svg {
				height: 16px;
				width: 16px;
				margin-left: spacing(0.5);

				path {
					fill: #7f939d80;
				}
			}
		}
	}

	&-expanded {
		@extend #public-property-page-property-details-general-information;
		max-height: none;

		&::before {
			all: unset;
		}
	}

	@include if-size("desktop") {
		grid-column: 1 / -5;
	}
}

.county-resources-table {
	overflow: hidden;
	grid-column: 1 / -1;
	margin-top: spacing(3);
	table-layout: fixed;
	border-radius: 16px;
	border: 1px solid $slate;
	border-spacing: 0;
	border-collapse: separate;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

	@include if-size("desktop") {
		grid-column: 1 / -5;
		margin-top: spacing(3);
	}

	thead {
		background: $sky-light;

		th {
			text-align: left;
			padding: spacing(2) spacing(3);
		}
	}

	tbody {
		background: $white;

		tr {
			&:not(:last-of-type) {
				border-bottom: 1px solid $denim_lightest;
			}
		}

		td {
			padding: spacing(2);
			line-height: 1.5;
			border-bottom: 1px solid $denim_lightest;

			@include if-size("desktop") {
				padding: spacing(2) spacing(3);
			}

			&:not(:last-of-type) {
				width: 130px;
				border-right: 1px solid $denim_lightest;

				@include if-size("desktop") {
					width: 215px;
				}
			}
		}

		td.no-border {
			border: none;
		}
	}
}

.public-property-page-resources-card-data-section {
	background: $white;
	padding: spacing(1);
	margin-top: spacing(1);
	display: flex;
	flex-direction: column;
	gap: spacing(1.5);
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 8px;
}

.public-property-page-resources-card-data-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#public-property-page-sign-up-today {
	grid-column: 1 / -1;
	grid-row: 2;
	margin-top: spacing(5);

	@include if-size("desktop") {
		grid-column: -5 / -1;
		grid-row: 2;
	}
}

#public-property-page-sign-up-today-container {
	z-index: -10;
}

#public-property-page-sign-up-today-card {
	padding: spacing(3);
	border-radius: 16px;
	border: 1px solid $denim;

	@include if-size("desktop") {
		margin-left: spacing(2);
	}

	.button-v2 {
		color: $white;
		background-color: $denim;
		border-color: $denim;
		width: 100%;
	}
}

.public-property-page-faq-card-container {
	grid-column: 1 / -1;
	cursor: pointer;

	&:not(:first-of-type) {
		margin-top: spacing(2);
	}

	@include if-size("desktop") {
		grid-column: 1 / -5;
	}

	.public-property-page-faq-card {
		background-color: $denim_lightest;
	}
}

.public-property-page-faq-card {
	background: $cloud;
	padding: spacing(2);
	border-radius: 16px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

	.expand-button {
		margin-left: auto;
	}

	.body-tiny {
		color: $denim_5;
	}
}

#public-property-page-modal-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($denim, 0.5);
	z-index: 10000000;
	display: flex;
	justify-content: center;
	align-items: center;
}

#public-property-page-modal-card {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: $sand;
	padding: spacing(4);
	border-radius: 16px;
	width: 90%;

	@include if-size("desktop") {
		width: 50%;
	}
}

#public-property-page-modal-input-container {
	.clipboard-copy-container {
		background: $sand;
	}

	input {
		&:disabled {
			opacity: 1;
		}
	}
	p {
		z-index: 100000000;
		cursor: pointer;
	}
}

#public-property-page-header-map {
	height: 210px;
	width: 100%;
	border-radius: 16px;

	@include if-size("desktop") {
		width: 447px;
		height: 100%;
	}
}

#only-at-ownwell-pill {
	display: flex;
	align-items: center;
	background: $slate;
	padding: 2px spacing(1);
	margin-left: spacing(2);
	margin-top: spacing(0.5);
	border-radius: 2px;

	svg {
		margin-left: spacing(0.5);

		> path {
			fill: $denim;
		}
	}
}

.public-property-page-property-details-sign-in-to-view {
	top: 110px;
	left: 0;
	transform: translateY(-50%);
	width: fit-content;

	@include if-size("desktop") {
		top: -20px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.chart-card-top-custom {
	display: flex;
	padding-bottom: 8px;
	gap: 4px;
	flex-wrap: wrap;

	@include if-size("desktop") {
		padding-bottom: 30px;
		flex-wrap: nowrap;
	}
}
