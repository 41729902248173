.button.primary {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	letter-spacing: 0.05em;
	font-size: 16px;
	line-height: 22px;
	font-family: $ff-secondary;
	font-weight: normal;
	padding: 5px 32px 11px;
	color: $white;
	cursor: pointer;
	background-color: $azure;
	border: 2px solid $azure;
	box-shadow: 2px 3px 4px rgba(52, 116, 116, 0.15);
	border-radius: 50px;
	height: fit-content;
	width: fit-content;

	@include if-size("tablet") {
		padding: 9px spacing(5) 13px;
		font-size: 18px;
		line-height: 26px;
		border-width: 2px;
	}
}

.button.extra-small {
	display: flex;
	padding: 3px 24px 5px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	flex-shrink: 0;
	border-radius: 50px;
	border: 1px solid var(--Primary-Azure, #1c7ce5);
	background: var(--Primary-Azure, #1c7ce5);
	box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);

	span {
		color: $white;
		margin-top: -2px;
		font-family: $ff-secondary;
		font-size: 12px;
		font-style: normal;
		font-weight: 433;
		line-height: 18px;
		letter-spacing: -0.21px;
	}
}

button.button {
	&:disabled {
		border-color: $sand;
		background-color: $sand;
		cursor: not-allowed;
		box-shadow: 2px 3px 4px $sand;
	}

	&:enabled {
		&:hover {
			border-color: $denim;
			background-color: $denim;
		}
	}

	&.w-100 {
		width: 100%;
	}
}

a.button:hover {
	border-color: $denim;
	background-color: $denim;
}

.button.secondary,
.secondary-button {
	@extend .primary;
	padding: 6px spacing(4) spacing(1);
	border-color: $azure;
	background-color: transparent;
	color: $azure;

	&:disabled {
		color: $white;
	}

	&:enabled {
		&:hover {
			background-color: $denim;
			border-color: $denim;
			color: $white;
		}
	}
}

.button.large {
	height: 48px;
	font-size: 18px;
	line-height: 26px;
	border-width: 2px;
}

.button.navy {
	border-color: $navy;
	background-color: $white;
	color: $navy;

	&:enabled {
		&:hover {
			background-color: $navy;
			color: $white;
		}
	}
}

.button.azure {
	border-color: $azure;
	background-color: $white;
	color: $azure;

	&:enabled {
		&:hover {
			border-color: $denim;
			background-color: $denim;
			color: $white;
		}
	}
}

.article-primary-button-font {
	font-family: $ff-secondary;
	font-weight: normal;
}

.button-small,
.button.small {
	font-family: $ff-secondary;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	letter-spacing: 0.005em;
	border-width: 1px;
	@include if-size("tablet") {
		font-size: 16px;
		border-width: 2px;
	}
}

.button.small {
	padding: 5px spacing(3) 7px;
	line-height: 20px;

	@include if-size("tablet") {
		padding: 8px spacing(4);
		line-height: 22px;
	}
}

.button.terracotta {
	background-color: $terracotta;
	border-color: $terracotta;
}

.button.rust {
	background-color: $rust;
	border-color: $rust;
}

// ---- Button V2 ---- //
.button-v2 {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	text-align: center;
	height: fit-content;
	width: fit-content;
	cursor: pointer;
	font-family: $ff-secondary;
	box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);
	border-radius: 50px;
	font-weight: 433;
	border-width: 2px;
	@extend .button-primary;
	padding: 5px 32px 11px;
	font-size: 16px;
	font-style: normal;
	line-height: 22px;
	letter-spacing: -0.2px;
	img,
	svg {
		width: 24px;
		height: 24px;
		position: relative;
		top: 1px;
	}

	@include if-size("desktop") {
		font-size: 18px;
		font-style: normal;
		font-weight: 433;
		line-height: 26px;
		letter-spacing: -0.36px;
		padding: 9px spacing(5) 13px;
		border-width: 2px;
	}

	&.w-100 {
		width: 100%;
	}
}

/* --- Desktop and Mobile Styling -- */
.mobile-extra-small-styling {
	font-size: 12px;
	font-style: normal;
	line-height: 18px; /* 142.857% */
	letter-spacing: -0.21px;
	padding: 3px 24px 5px;
	border-width: 1px;
	img,
	svg {
		width: 16px;
		height: 16px;
		position: relative;
		top: 3px;
	}
}

.mobile-small-styling {
	font-size: 14px;
	font-style: normal;
	line-height: 20px; /* 137.5% */
	letter-spacing: -0.16px;
	padding: 5px spacing(3) 7px;
	border-width: 1px;
	img,
	svg {
		width: 16px;
		height: 16px;
		position: relative;
		top: 1px;
	}
}

.mobile-default-styling {
	font-size: 16px;
	font-style: normal;
	line-height: 22px;
	letter-spacing: -0.36px;
	padding: 5px 32px 11px;
	border-width: 2px;
	img,
	svg {
		width: 16px;
		height: 16px;
		position: relative;
		top: 1px;
	}
}

.mobile-large-styling {
	padding: 9px spacing(5) 13px;
	font-size: 18px;
	font-style: normal;
	line-height: 26px;
	letter-spacing: -0.2px;
	border-width: 2px;
	img,
	svg {
		width: 24px;
		height: 24px;
		position: relative;
		top: 1px;
	}
}

.desktop-extra-small-styling {
	@include if-size("desktop") {
		font-size: 14px;
		font-style: normal;
		line-height: 20px; /* 142.857% */
		letter-spacing: -0.21px;
		padding: 5px spacing(3) 7px;
		border-width: 1px;
		img,
		svg {
			width: 16px;
			height: 16px;
			position: relative;
			top: 3px;
		}
	}
}

/* --- Button sizes --- */
.button-v2.large {
	@extend .mobile-large-styling;
	@include if-size("desktop") {
		padding: 11px 48px 19px;
		font-size: 20px;
		font-style: normal;
		font-weight: 433;
		line-height: 28px;
		letter-spacing: -0.2px;
		border-width: 2px;
		img,
		svg {
			width: 24px;
			height: 24px;
			position: relative;
			top: 1px;
		}
	}
}

.button-v2.small {
	@extend .mobile-default-styling;
	@include if-size("desktop") {
		font-size: 16px;
		font-style: normal;
		line-height: 22px; /* 137.5% */
		letter-spacing: -0.16px;
		padding: 5px 32px 11px;
		border-width: 2px;
		img,
		svg {
			width: 16px;
			height: 16px;
			position: relative;
			top: 1px;
		}
	}
}

.button-v2.extra-small {
	@extend .mobile-small-styling;
	@extend .desktop-extra-small-styling;
}

.button-v2.mobile-extra-small {
	@extend .mobile-extra-small-styling;
	@extend .desktop-extra-small-styling;
}

/* --- Button Variants --- */
.button-primary {
	background-color: $azure;
	border: 2px solid $azure;
	color: $white;
	box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);

	&:hover {
		background-color: $royal;
		border-color: $royal;
	}
	&:active {
		border-color: $powder;
		background-color: $azure;
	}
	&:disabled {
		opacity: 0.5;
		background: $denim-5;
		border-color: $denim-5;
		cursor: not-allowed;
	}
	&.loading {
		cursor: wait;
		opacity: 0.5;
		background: $denim-5;
		border-color: $denim-5;
	}
}

.button-v2.secondary {
	background-color: $white;
	border: 2px solid $white;
	color: $azure;

	&:hover {
		background-color: $sky;
		border-color: $sky;
		color: $royal;
	}
	&:active {
		border-color: $powder;
		background-color: $white;
	}
	&:disabled {
		opacity: 0.5;
		background: $white;
		border-color: $white;
		cursor: not-allowed;
		color: $slate;
	}
	&.loading {
		cursor: wait;
		opacity: 0.5;
		background: $white;
		border-color: $white;
		color: $slate;
	}
}

.button-v2.destructive {
	background-color: $rust;
	border: 2px solid $rust;
	color: $white;

	&:hover {
		background-color: $rust-medium;
		border-color: $rust-medium;
		color: $white;
	}
	&:active {
		border-color: $rust-dark;
		background-color: $rust-dark;
	}
	&:disabled {
		opacity: 0.5;
		background: $denim-5;
		border-color: $denim-5;
		cursor: not-allowed;
	}
	&.loading {
		cursor: wait;
		opacity: 0.5;
		background: $denim-5;
		border-color: $denim-5;
	}
}

.button-v2.primary-outline,
.button-v2-primary-outline {
	background-color: transparent;
	border-color: $azure;
	color: $azure;
	box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);

	&:hover {
		color: $royal;
		border-color: $royal;
	}
	&:active {
		border-color: $azure;
		color: $azure;
		background-color: $sky;
	}
	&:disabled {
		opacity: 0.5;
		background: transparent;
		border-color: $denim-5;
		color: $denim-5;
		cursor: not-allowed;
	}
	&.loading {
		cursor: wait;
		opacity: 0.5;
		background: transparent;
		border-color: $denim-5;
		color: $denim-5;
	}
}

.button-v2.primary-outline-filled {
	@extend .button-v2-primary-outline;
	background-color: $white;
}

.button-v2.secondary-outline {
	background-color: transparent;
	border: 2px solid $white;
	color: $white;
	box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);

	&:hover {
		color: $slate;
		border-color: $slate;
	}
	&:active {
		border-color: $white;
		color: $white;
		background-color: $denim-5;
	}
	&:disabled {
		opacity: 0.5;
		background: transparent;
		border-color: $denim-5;
		color: $denim-5;
		cursor: not-allowed;
	}
	&.loading {
		cursor: wait;
		opacity: 0.5;
		background: transparent;
		border-color: $denim-5;
		color: $denim-5;
	}
}

.button-v2.destructive-outline {
	background-color: transparent;
	border: 2px solid $rust;
	color: $rust;
	box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);

	&:hover {
		color: $rust-medium;
		border-color: $rust-medium;
	}
	&:active {
		border-color: $rust-dark;
		color: $rust-dark;
		background-color: rgba(164, 35, 0, 0.32);
	}
	&:disabled {
		opacity: 0.5;
		background: transparent;
		border-color: $denim-5;
		color: $denim-5;
		cursor: not-allowed;
	}
	&.loading {
		cursor: wait;
		opacity: 0.5;
		background: transparent;
		border-color: $denim-5;
		color: $denim-5;
	}
}

.button-v2.primary-ghost {
	background-color: transparent;
	border: 2px solid transparent;
	color: $azure;
	box-shadow: none;

	&:hover {
		color: $royal;
	}
	&:active {
		border-color: transparent;
		color: $azure;
		border-radius: 50px;
		background: rgba(213, 234, 255, 0.32);
		box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);
	}
	&:disabled {
		opacity: 0.5;
		background: transparent;
		border-color: transparent;
		color: $denim-5;
		cursor: not-allowed;
	}
	&.loading {
		cursor: wait;
		opacity: 0.5;
		background: transparent;
		border-color: transparent;
		color: $denim-5;
	}
}

.button-v2.secondary-ghost {
	background-color: transparent;
	border: 2px solid transparent;
	color: $white;
	box-shadow: none;

	&:hover {
		color: $marshmallow;
	}
	&:active {
		border-color: transparent;
		color: $white;
		background: $sand-light;
		box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);
	}
	&:disabled {
		opacity: 0.5;
		background: transparent;
		border-color: transparent;
		color: $slate;
		cursor: not-allowed;
	}
	&.loading {
		cursor: wait;
		opacity: 0.5;
		background: transparent;
		border-color: transparent;
		color: $slate;
	}
}

.button-v2.destructive-ghost {
	background-color: transparent;
	border: 2px solid transparent;
	color: $rust;
	box-shadow: none;

	&:hover {
		color: $rust-medium;
		border-color: transparent;
	}
	&:active {
		border-color: transparent;
		color: $rust-dark;
		background-color: rgba(164, 35, 0, 0.32);
		box-shadow: 0px 4px 16px 0px rgba(127, 147, 157, 0.18);
	}
	&:disabled {
		opacity: 0.5;
		background: transparent;
		border-color: transparent;
		color: $denim-5;
		cursor: not-allowed;
	}
	&.loading {
		cursor: wait;
		opacity: 0.5;
		background: transparent;
		border-color: transparent;
		color: $denim-5;
	}
}
