#partners-referral-banner {
	background-color: $sky;
	padding: 16px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 6px;

	@include if-size("tablet") {
		gap: 24px;
	}

	img {
		max-height: 50px;
		width: auto;
		max-width: 100%;

		&.str-partner-logo {
			max-height: 80px;
		}
	}

	.partners-referral-display-name {
		font-weight: 700;
		@include if-size("desktop") {
			margin-bottom: 4px;
		}
	}
}
