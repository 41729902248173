.mortgage-card {
	background-color: $white;
	border-radius: 16px;
	border: 1px solid $slate;
	padding: spacing(1);
	@include if-size("desktop") {
		min-width: 390px;
		padding: spacing(3);
	}

	position: relative;
	box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	display: flex;
	flex-direction: column;
	gap: 12px;

	&-equity-debt {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 36px;
		overflow: hidden;

		padding: spacing(1);
		background-color: $denim_lightest;
		border-radius: 8px;

		&-details {
			width: 100%;
			p.sm {
				margin-bottom: 4px;
			}

			.separator {
				border-bottom: solid 1px $sand;
				margin: 14px 0;
			}

			.equity-percentage {
				border: 1.5px solid $kelly;
				background-color: $kelly-light;
				border-radius: 8px;
				color: $kelly-dark;
				padding: 2px 4px 3px 4px;
				margin-left: 4px;

				font-size: 14px;
				line-height: 16.8px;
			}
		}
	}

	.blur {
		display: none;
	}

	.start-tracking-equity-graphic {
		display: none;
	}

	.equity-house {
		max-width: 240px;
		width: 100%;
	}

	&.card {
		height: 100%;
		padding: spacing(2);
		@include if-size("desktop") {
			padding: spacing(3);
		}

		.mortgage-card-equity-debt {
			@include if-size("desktop") {
				padding: spacing(3);
				background-color: $denim_lightest;
				border-radius: 8px;
			}
		}

		.mortgage-card-equity-debt-cta {
			display: none;
		}

		&.start-tracking {
			.blur {
				backdrop-filter: blur(4px);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 12px;

				padding: spacing(2);
			}

			.mortgage-card-equity-debt-cta {
				display: block;
				margin-top: 20px;
			}

			.start-tracking-equity-graphic {
				display: block;
				width: 150px;
				height: 150px;

				@include if-size("desktop") {
					width: 175px;
					height: 175px;
				}
			}

			.equity-house {
				display: none;
			}

			.mortgage-card-equity-debt {
				padding: spacing(2);
			}

			.mortgage-card-equity-debt-details {
				display: none;
			}

			.quick-link,
			.quick-link-small {
				display: none;
			}
		}
	}
}

.mortgage-equity-debt-radial-chart {
	position: relative;

	width: 240px;
	height: 240px;

	margin: -24px;

	&-center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;

		width: 40px;
		height: 40px;
	}
}

#property-page .mortgage-card .quick-link-small {
	@include if-size("monitor") {
		position: absolute;
		bottom: 24px;
		right: 24px;
	}
}
