#landing-vp3-section {
	// background-color: $cloud;
	padding-top: spacing(8);

	@include if-size("desktop") {
		padding-top: spacing(12);
	}
}

#landing-vp3-section-partner {
	padding: 0;
}

#landing-vp3-section,
#value-prop-cards {
	width: 100%;
	position: relative;
	z-index: 100;

	h2,
	h4,
	p {
		text-align: center;
	}

	h2,
	h4 {
		text-align: center;
	}

	h2 {
		margin-bottom: spacing(1);
	}
}

#landing-vp3-commercial {
	padding: spacing(6) 0;
	text-align: center;

	h4 {
		margin: 0 auto;
		max-width: 80%;
	}
}

#landing-vp3-partner {
	padding-bottom: 96px;
}

#landing-vp3-title {
	z-index: 10;
	grid-column: 1 / -1;
	text-align: center;
	margin: 0 auto spacing(4);
}

#landing-vp3-subtitle {
	margin: spacing(4) auto 0;
	text-align: center;
}

.landing-vp3-card {
	z-index: 10;
	grid-column: span 4;
	background-color: $royal;
	border-radius: 16px;
	padding: spacing(4);
	display: flex;
	flex-direction: column;
	align-items: center;

	&-icon {
		margin-bottom: spacing(2);
		height: 125px;
		width: 125px;
	}

	&-title {
		max-width: 225px;
		color: $cloud;
		text-align: left;
	}

	&.landing-vp3-card-commercial {
		background-color: $sand;

		.landing-vp3-card-icon {
			margin-bottom: spacing(2);
			height: 64px;
			width: 64px;
		}
		.landing-vp3-card-title {
			color: $denim;
			@include if-size("desktop") {
				max-width: 200px;
			}
		}
	}

	&.landing-vp3-card-partner {
		background-color: $cloud;

		.landing-vp3-card-icon {
			margin-bottom: spacing(2);
			height: 125px;
			width: 125px;
		}
		.landing-vp3-card-title {
			color: $denim;
			@include if-size("desktop") {
				max-width: 200px;
			}
		}
	}
}

.landing-vp3-m-card {
	z-index: 10;
	grid-column: 1 / -1;
	max-width: 500px;
	background-color: $royal;
	border-radius: 16px;
	margin: 0 auto;
	padding: spacing(4) spacing(4);
	display: flex;
	flex-direction: column;
	align-items: center;

	&-section {
		width: 100%;
		display: flex;
		align-items: center;

		&:not(:last-of-type) {
			padding: 0 0 spacing(2);
			margin: 0 0 spacing(2);
			border-bottom: 2px solid $powder;
		}
	}

	&-icon {
		display: block;
		height: 56px;
		width: 56px;
		flex-shrink: 0;
	}

	&-title {
		text-align: left !important;
		margin-left: spacing(2);
		color: $cloud;
	}

	&.landing-vp3-card-commercial {
		background-color: $sand;

		.landing-vp3-m-card-section {
			&:not(:last-of-type) {
				border-bottom: 2px solid $cloud;
			}
		}
		.landing-vp3-m-card-title {
			color: $denim;
			padding-left: spacing(2);
		}
	}

	&.landing-vp3-card-partner {
		background-color: $cloud;

		.landing-vp3-m-card-section {
			&:not(:last-of-type) {
				border-bottom: 2px solid $azure;
			}
		}

		.landing-vp3-m-card-title {
			color: $denim;
			padding-left: spacing(2);
		}
	}
}

#landing-vp3-cta {
	margin: spacing(3) auto 0;
	text-align: center;

	a,
	button {
		display: inline-block;
	}

	@include if-size("desktop") {
		margin: spacing(2) auto 0;
	}
}

.landing-vp3-card-rating {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@include if-size("desktop") {
		justify-content: center;
	}
}
