.pricing-card {
	max-width: 680px;
	margin: auto;
	background-color: $cloud;
	padding: 18px 0;
	border-radius: 16px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	padding: 24px;
	margin: 0 24px;

	@include if-size("tablet") {
		margin: auto;
		background-color: $white;
		padding: spacing(4);
	}

	h2 {
		color: $azure;
		text-align: center;
	}

	#pricing-card-info-container {
		border-top: 2px solid $sand;
		border-bottom: 2px solid $sand;
		text-align: center;
		padding: spacing(2) 0;

		div {
			width: 140px;
		}

		@include if-size("tablet") {
			border-top: none;
			border-bottom: none;
			border-right: 2px solid $sand;
			padding-right: spacing(4);
		}

		h1,
		h2 {
			color: $azure;
		}
	}

	#pricing-calculator-input-container {
		display: flex;
		gap: 16px;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 16px 0;
		@include if-size("tablet") {
			flex-direction: row;
			padding: 40px 0;
		}

		.search-input-container {
			max-width: 340px;
			width: 100%;
		}

		button {
			max-width: 340px;
			width: 100%;
			@include if-size("tablet") {
				width: auto;
			}
		}
	}

	&.pricing-card-white {
		background-color: $white;
	}

	.pricing-card-inner-container {
		background-color: $denim-lightest;
		padding: 8px;
		border-radius: 16px;
		display: flex;
		justify-content: space-evenly;
		flex-direction: column;
		align-items: center;
		@include if-size("tablet") {
			flex-direction: row;
			padding: 16px;
		}

		.pricing-card-inner-container-savings-or-free {
			text-align: center;
			@include if-size("tablet") {
				p {
					max-width: 209px;
				}
			}

			svg {
				width: 32px;
				height: 32px;

				@include if-size("tablet") {
					width: 40px;
					height: 40px;
				}
			}
		}
		.pricing-card-inner-container-description {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
}

#pricing-sub-card {
	max-width: 600px;
	margin: 0 auto;
	padding: spacing(2) spacing(4);
	box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
	border-radius: 0px 0px 16px 16px;
	background-color: $sky;
	display: flex;
	gap: 8px;
	width: 80%;
	flex-direction: column;
	align-items: center;

	@include if-size("tablet") {
		flex-direction: row;
	}

	#pricing-sub-card-title-container {
		display: flex;
		gap: 8px;
		align-items: center;
		flex: 0 0 auto;
		h3 {
			max-width: 100%;
			@include if-size("tablet") {
				max-width: 140px;
			}
		}

		svg {
			width: 40px;
			height: 36px;

			@include if-size("tablet") {
				width: 60px;
				height: 54px;
			}
		}
	}

	.pricing-sub-card-properties {
		text-align: center;
		@include if-size("tablet") {
			text-align: left;
		}
	}

	.quick-link {
		padding-top: 4px;
		margin: auto;

		@include if-size("tablet") {
			margin: 0;
		}
		p {
			color: $azure;
		}
		svg {
			margin-left: 0.5ch;
			margin-top: 3px;
		}
	}
}

#pricing-card-cta {
	margin: spacing(6) auto 0;
	grid-column: 1 / -1;
}

.pricing-card-subtitle {
	text-align: center;
	padding: 0 16px 16px;
}

#exemptions-pricing-card {
	grid-column: 1/-1;
	border-radius: 16px;
	background: #fff;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	padding: 32px 0;
	h3 {
		margin-bottom: 4px;
	}
	@include if-size("desktop") {
		grid-column: 4/-4;
	}

	ul {
		list-style-position: inside;
	}

	#pricing-card-body {
		display: flex;
		justify-content: center;
	}
}

.exemptions-pricing-sub-card {
	border-radius: 0px 0px 16px 16px;
	background: $denim;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	padding: spacing(2) spacing(4);
	grid-column: 1/-1;
	margin: 0 32px;
	text-align: center;

	@include if-size("desktop") {
		grid-column: 4/-4;
	}

	p {
		color: $white;
	}
}
