#property-only-savings-savings-boxes {
	display: flex;
	flex-direction: column;
	margin-top: spacing(3);
	gap: spacing(1);

	@include if-size("desktop") {
		gap: spacing(2);
		flex-direction: row;
	}
}

.property-only-savings-savings-box {
	position: relative;
	flex: 1;
	padding: spacing(2);
	border-radius: 8px;
	border: 1px solid $cloud;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	opacity: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
}

.property-only-savings-box-line-item {
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;

	margin-bottom: spacing(1);
}

#property-only-savings-explainer-section {
	margin-top: spacing(8);
	width: 100%;
	background: $denim-lightest;
}

#property-only-savings-explainer-section-content {
	grid-column: 1 / -1;
	padding: spacing(8) 0;

	@include if-size("desktop") {
		grid-column: 2 / -2;
	}
}

#property-only-savings-explainer-section-box-container {
	display: flex;
	flex-direction: column;
	margin: spacing(6) 0 0;
}

.property-only-savings-box-explainer {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: spacing(4);
	border-radius: 16px;
	background-color: $white;

	&:not(:last-of-type) {
		margin-bottom: spacing(2);
	}

	@include if-size("desktop") {
		flex-direction: row;
		align-items: center;
	}
}

.property-only-savings-box-explainer-copy {
	padding-bottom: spacing(2);
	flex: 2;

	@include if-size("desktop") {
		padding-bottom: unset;
	}

	p {
		display: flex;
		justify-content: space-between;
		padding-bottom: 8px;
	}
}

.property-only-savings-box-explainer-divider {
	width: 100%;
	background-color: $cloud;
	height: 2px;

	@include if-size("desktop") {
		margin-left: spacing(4);
		height: unset;
		width: 2px;
		align-self: stretch;
	}
}

.property-only-savings-box-explainer-savings {
	flex: 1;
	padding-top: spacing(2);

	@include if-size("desktop") {
		padding-top: unset;
		padding-left: spacing(4);
	}
}

.property-only-savings-free-pill {
	padding: 4px 16px;
	border-radius: 4px;
	background-color: $denim_5;
	color: $white;

	font-weight: 600;
}

.savings-box-right-savings-box-line-item {
	justify-content: space-between;
}
