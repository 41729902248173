#landing-featured-section {
	gap: 1;
	background-color: $sky-light;
	max-width: 100%;
	padding: 0 24px;

	&.landing-featured-section-commercial {
		padding-top: 51px;

		@include if-size("desktop") {
			padding-top: spacing(8);
		}

		#landing-featured-box {
			#landing-featured-title {
				#landing-featured-logos {
					.landing-featured-logos-img:not(:first-child) {
						margin-left: 0;

						@include if-size("tablet") {
							margin-left: spacing(2);
						}

						@include if-size("desktop") {
							margin-left: spacing(5);
						}
					}
				}
			}
		}
	}

	#landing-featured-box {
		position: relative;
		margin: 0 auto;
		grid-column: 1/-1;
		width: 100%;

		@include if-size("desktop") {
			grid-column: 2/12;
		}
	}

	#landing-featured-title {
		background-color: $white;
		text-align: center;
		border-radius: 32px 32px 0 0;
		font-size: 14px;
		color: $denim;
		padding: spacing(2) spacing(5) spacing(2);
		width: 100%;

		&.landing-featured-counties {
			padding: 16px 14px;

			label {
				opacity: 1;
			}
		}

		label {
			opacity: 50%;
			padding-bottom: 12px;
		}

		#landing-featured-logos {
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 50%;
			flex-wrap: wrap;
			padding-top: spacing(2);
			gap: 16px;
			margin: 0 auto;

			@include if-size("desktop") {
				padding-top: 0;
				flex-wrap: nowrap;
				gap: none;
			}

			&.landing-now-serving {
				flex-wrap: wrap;
				opacity: 1;
				gap: 8px 16px;
				@include if-size("tablet") {
					padding-top: 8px;
					gap: 8px 32px;
				}
			}

			.landing-featured-logos-img {
				max-width: 100px;
				width: 100%;

				@include if-size("desktop") {
					max-width: 120px;
				}

				&:not(:first-child) {
					margin-left: 0;

					@include if-size("desktop") {
						margin-left: spacing(8);
					}
				}

				&.landing-featured-logos-img-wallstreet {
					padding: 0 10px;
				}

				&.landing-featured-atlanta-journal {
					max-width: 150px;
					width: 100%;

					@include if-size("desktop") {
						max-width: 175px;
					}
				}
			}
		}
	}
}
