$commercial_case_study_black: #020c11;
$commercial_case_study_max_width_desktop: 1136px;

#commercial-case-study {
	max-width: calc(100% - 32px);
	width: 100%;
	margin: 0 auto;

	@include if-size("desktop") {
		max-width: $commercial_case_study_max_width_desktop;
	}

	.resource-social {
		position: relative;
		z-index: 100;
	}
}

#commercial-case-study-hero-section {
	position: relative;
	z-index: 100;
	overflow: hidden;
	max-width: 100%;

	#commercial-case-study-hero-card {
		position: relative;
		z-index: 100;
		background-color: $commercial_case_study_black;
		border-radius: 16px;
		overflow: hidden;

		img {
			width: 100%;
			height: auto;
			max-height: 420px;
			border: 9px solid #000;
			border-radius: 16px;
			object-fit: cover;
		}
	}

	#commercial-case-study-hero-card-header {
		padding: spacing(2);
		overflow: hidden;
		max-width: 100%;

		* {
			color: $white;
		}

		@include if-size("desktop") {
			padding: spacing(4);
		}
	}
}

#commercial-case-study-body-content-container {
	position: relative;
	z-index: 10;
	padding: 0 spacing(1) spacing(12);

	p,
	li,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	.label {
		color: $slate;
	}

	#commercial-case-study-body-underlay {
		position: absolute;
		z-index: -1;
		width: calc(100vw - var(--scrollbar-width, 17px));
		height: calc(100% + 210px);
		top: -210px;
		transform: translateX(-24px);
		background-color: $commercial_case_study_black;

		@media screen and (min-width: $commercial_case_study_max_width_desktop) {
			// This line is very funky.. i know
			// We want to move the underlay to the left by half of the difference between the viewport width and the max width of the commercial case study
			// This should result in a full-width underlay.
			transform: translateX(
				calc(
					(
							(
									(100vw + spacing(2) - var(--scrollbar-width, 17px)) -
										$commercial_case_study_max_width_desktop + 1px
								) / 2
						) * -1
				)
			);
		}
	}

	#commercial-case-study-body-info {
		display: flex;
		flex-direction: column;
		gap: spacing(6);

		@include if-size("desktop") {
			max-width: 944px;
			margin: 0 auto;
		}
	}

	#commercial-case-study-body-assessment-info-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: spacing(3);

		@include if-size("desktop") {
			flex-direction: row;
			justify-content: center;
		}

		.commercial-case-study-body-assessment-info-item {
			flex: 1;
		}
	}

	#commercial-case-study-body-client-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			text-align: center;
		}

		@include if-size("desktop") {
			align-items: flex-start;
			p {
				text-align: start;
			}
		}
	}

	#commercial-case-study-body-client-quote {
		background: rgba(86, 108, 119, 0.25);
		border-radius: 8px;
		padding: spacing(3);

		.lg {
			letter-spacing: -0.3px;
		}
	}

	#commercial-case-study-body-content {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: spacing(6);
		}

		p:not(:empty) {
			margin: spacing(2) 0 0;
		}

		ol {
			margin: spacing(1) 0;
			list-style-position: outside;
			padding-left: spacing(4);

			li > p {
				display: inline;
			}
		}
	}

	#commercial-case-study-assessment-info-table {
		width: fit-content;
		margin: spacing(6) auto;

		tr > td {
			border: 4px solid $commercial_case_study_black;
			color: $white;
			padding: spacing(2);
			background: rgba(86, 108, 119, 0.5);
		}

		tr:first-child > td {
			&:first-child {
				border-top-left-radius: 16px;
			}

			&:last-child {
				border-top-right-radius: 16px;
			}
		}

		tr:last-child > td {
			background: $azure;

			&:first-child {
				border-bottom-left-radius: 16px;
			}

			&:last-child {
				border-bottom-right-radius: 16px;
			}
		}
	}

	#commercial-case-study-footer-images-container:not(:empty) {
		margin-top: spacing(6);
		display: flex;
		flex-direction: column;
		gap: spacing(2);
		flex-wrap: wrap;

		@include if-size("desktop") {
			flex-direction: row;
		}

		.commercial-case-study-footer-image-wrapper {
			flex: 1;
			border-radius: 16px;
			overflow: hidden;
		}

		.commercial-case-study-footer-image {
			width: 100%;
			height: auto;
		}

		.commercial-case-study-footer-image-footer {
			background: rgba(86, 108, 119, 0.5);
			padding: spacing(2);
		}
	}
}
