#site-layout {
	min-height: 100vh;
	max-width: 100%;
	display: flex;
	flex-direction: column;

	&.fancy,
	&.fancy-w-banner {
		#site-header {
			> .grid {
				height: 90px;
			}
		}
	}

	&.fancy-w-banner {
		#site-header {
			height: 130px;
			display: flex;
			flex-direction: column;
		}

		#header-hamburger-menu {
			top: 130px;
		}

		#site-banners {
			padding-top: 130px;
		}
	}
}

#site-body {
	flex: 1 1 0;
	height: 100%;
}

#site-banners {
	padding-top: 90px;
}

#minimal-site-body {
	height: 100%;
	padding-top: spacing(4);

	@include if-size("desktop") {
		padding-top: spacing(6);
	}

	.appeal-legal-signature-container {
		background-color: $denim-lightest;
	}
}
