.login-page {
	@include flex("center", "center");
	min-height: calc(100vh - 240px);
	width: 100%;
	padding: 16px 5%;

	.login-card {
		@include flex("flex-start", "center", "column");
		padding: 16px;
		border-radius: 10px;
		width: 100%;
		max-width: 400px;
		min-height: 250px;
		padding-bottom: 100px;

		.input-container-box {
			background-color: $denim-lightest;
			border-radius: 16px;
			padding: 32px;
			width: 100%;
			box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
		}

		.input-container {
			@include flex("flex-end", "center", "column");
			width: 100%;
			margin: 0 auto;
			flex: 1;

			.sent-reset-container {
				text-align: left;

				.sent-reset-container-subtitle {
					padding-top: spacing(2);
					font-weight: 600;
				}
			}

			> div {
				width: 100%;
			}

			.input-container-fields {
				// If the password requirements card is not present, add margin to the bottom of the input fields
				&:not(:has(+ #password-requirements-card)) {
					margin-bottom: spacing(4);
				}
			}
		}

		.forgot-container {
			@include flex("center", "center", "column");
			flex: 2;
		}

		.message-container {
			@include flex("flex-end", "center", "column");
			flex: 3;
			margin: 24px 0 12px;
			width: 100%;

			&-error {
				@extend .button-container;
				margin: 0 0 12px;
			}
		}
		.button-container {
			@include flex("flex-end", "center", "column");
			flex: 1;
			margin-top: 12px;
			width: 100%;

			&-error {
				@extend .button-container;
				margin: 0 0 12px;
			}
		}
		.error-message {
			padding-bottom: spacing(2);

			.reset-link {
				@include text("16px", 800);
				color: $denim;
			}
		}

		.login-submit {
			flex: 1;
			width: 100%;
		}

		.login-with-google {
			flex: 1;
			width: 100%;
			margin: spacing(4) auto 0;
			display: flex;
			justify-content: center;
		}

		.create-account-div {
			padding-top: spacing(3);
		}

		.login-signup {
			flex: 1;
			margin: 8px auto 0;
			p {
				color: $azure;
				display: inline-block;
			}
			svg {
				width: 18px;
				position: relative;
				margin-left: 8px;
				top: 3px;

				@include if-size("tablet") {
					top: 2px;
					width: 18px;
					height: 18px;
				}
			}
		}

		.forgot-password {
			margin-top: 4px;
		}

		.sign-up-container {
			// text-align: center;
			.sign-up-link {
				color: $ra-teal;
				text-decoration: underline;
			}
		}

		.login-title {
			align-self: flex-start;
			margin: 0 0 8px;
		}

		.login-subtitle {
			padding-bottom: spacing(1);
		}

		.login-description {
			width: 100%;
			color: $ra-navy;
		}
		.spinner {
			height: 35px;
			width: auto;
		}

		.success-img {
			height: 75px;
			width: auto;
			margin-bottom: 12px;
		}

		.login-link {
			color: $ra-teal;
		}

		.signup-step-container {
			width: 100%;
			margin: 10px 0;
			padding-top: spacing(2);

			.signup-step {
				counter-reset: step;
			}
			.signup-step li {
				cursor: pointer;
				list-style-type: none;
				width: 50%;
				float: left;
				font-size: 12px;
				position: relative;
				text-align: center;
				text-transform: uppercase;
				color: $denim;
			}
			.signup-step li:before {
				width: 16px;
				height: 16px;
				// content: counter(step);
				content: "";
				counter-increment: step;
				line-height: 20px;
				border: 2px solid $azure;
				display: block;
				margin: 0 auto 8px auto;
				border-radius: 50%;
				background-color: $white;
			}

			.signup-step li:after {
				width: 100%;
				height: 2px;
				content: "";
				position: absolute;
				background-color: $azure;
				top: 10px;
				left: -50%;
				z-index: -1;
			}
			.signup-step li:first-child:after {
				content: none;
			}
			// .signup-step li.active {
			//   color: $ra-teal;
			// }
			.signup-step .active:before {
				// border-color: $ra-teal;
				background-color: $azure;
			}
		}
	}

	.login-btn-seperator {
		width: 70%;
		margin: 20px auto 20px;
		text-align: center;
		position: relative;

		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			height: 2px;
			width: 100%;
			content: "";
			background: $sand;
			z-index: -5;
		}

		p {
			z-index: 5;
			width: 40px;
			margin: 0 auto;
		}
	}
}

.login-back-to-sign-in {
	padding-top: spacing(4);
	color: $azure;
}

.login-error-icon {
	width: 12px;
	height: 12px;
	margin-right: 4px;
}

.error-message {
	width: 100%;
	color: $terracotta;

	svg {
		width: 12px;
		height: 12px;
		margin-right: 4px;
	}

	&-padding-bottom {
		padding-bottom: spacing(1);
	}
}

#signup-password-len-message {
	width: 100%;
	margin-top: spacing(1);
}

#signup-privacy-message {
	margin-top: spacing(1);
	color: rgba($denim, 0.5);
}

.set-password-requirements-card {
	margin-bottom: spacing(2);
}
