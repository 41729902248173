#bill-reduction-hero {
	background: $sky-light;
	padding: 48px 0 96px;

	@include if-size("desktop") {
		padding: 96px 0 118px;
	}

	.grid {
		align-items: center;
	}

	#bill-reduction-hero-img {
		grid-column: 1 / -1;
		margin: 0 auto spacing(2);
		max-width: 70%;
		height: auto;

		@include if-size("desktop") {
			width: 100%;
			max-width: 390px;
			grid-column: -5 / -1;
			margin: 0;
			grid-row: 1;
		}
	}

	#bill-reduction-title-container {
		grid-column: 1 / -1;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include if-size("desktop") {
			grid-row: 1;
			grid-column: 1 / -6;
		}
	}

	#address-search-input-button {
		height: 32px;
		padding: 8px 24px;
		font-size: 14px;
		line-height: 20px;

		@include if-size("desktop") {
			height: 48px;
			padding: 12px 40px;
			font-size: 18px;
			line-height: 26px; /* 144.444% */
			letter-spacing: -0.36px;
		}
	}
}

.customer-savings-banner {
	background-color: $denim;
	padding: 16px;

	.customer-savings-banner-text {
		height: 37.5px;
		display: flex;
		align-items: center;
		border-right: 1px solid $kelly-lightest;
		gap: 24px;
		margin-right: 24px;
		padding-right: 24px;

		@include if-size("desktop") {
			gap: 32px;
			margin-right: 32px;
			padding-right: 32px;
		}
	}
}

#bill-reduction-landing {
	.bill-reduction-common-spacing {
		padding: 0 0 64px;
		@include if-size("desktop") {
			padding: 0 0 96px;
		}
	}

	#landing-hero-address-search-with-results {
		max-width: none;
	}

	#landing-hero-address-input {
		margin-left: 0;
		margin-right: 0;
		padding: 16px 0 0 0;
	}

	#landing-hero-value-props {
		margin-top: -40px;
	}

	#landing-stat-section {
		margin: 64px 0;
		@include if-size("desktop") {
			margin: 52px 0 96px;
		}
	}

	#generalized-signup-steps-section {
		padding: 96px 0;
	}

	#landing-signup-steps-steps {
		align-items: flex-start;
	}

	.denim-lightest-bg {
		background-color: $denim-lightest;
	}

	.bill-reduction-wifi-bill-and-reduce-from-anywhere {
		@extend .bill-reduction-common-spacing;

		&-wifi-bill-only {
			padding-top: 64px;
			@include if-size("desktop") {
				padding-top: 96px;
			}
		}

		.bill-reduction-wifi-bill-and-reduce-from-anywhere-text {
			grid-column: 1/-1;
			@include if-size("desktop") {
				grid-column: 1/8;
				order: 1;
			}
		}
		.bill-reduction-wifi-bill-and-reduce-from-anywhere-img {
			grid-column: 1/-1;
			padding-bottom: 24px;
			text-align: center;
			@include if-size("desktop") {
				grid-column: 9/-1;
				order: 2;
				padding-bottom: 0;
			}

			svg {
				max-width: 300px;
				@include if-size("desktop") {
					max-width: none;
				}
			}
		}
	}

	.bill-reduction-cellphone-bill {
		@extend .bill-reduction-common-spacing;
		.bill-reduction-cellphone-bill-text {
			grid-column: 1/-1;
			@include if-size("desktop") {
				margin: auto;
				grid-column: 6/-1;
				order: 2;
			}
		}
		.bill-reduction-cellphone-bill-img {
			grid-column: 1/-1;
			text-align: center;
			padding-bottom: 24px;
			@include if-size("desktop") {
				grid-column: 1/5;
				order: 1;
				padding-bottom: 0;
			}

			svg {
				max-width: 300px;
				@include if-size("desktop") {
					max-width: none;
				}
			}
		}
	}

	#landing-view-pricing-section {
		background-color: $white;
	}
}
