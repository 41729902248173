#casestudies-commercial-section {
	padding: spacing(2) spacing(2) spacing(10) spacing(2);
	position: relative;

	.resources-more-cards {
		grid-column: 1/-1;
		gap: 1;
		position: relative;

		#card-slider-nav-prev {
			left: -32px;
			@include if-size("tablet") {
				left: -40px;
			}
		}
		#card-slider-nav-next {
			right: -32px;
			@include if-size("tablet") {
				right: -40px;
			}
		}

		.resource-card-bg {
			max-height: 200px;
			@include if-size("tablet") {
				max-height: 275px;
			}
		}
	}

	.resources-more-cards-button {
		grid-column: 1/-1;
		padding-top: spacing(4);
		margin: 0 auto;
		@include if-size("desktop") {
			padding-top: spacing(8);
		}
	}

	.casestudies-commercial-spinner {
		text-align: center;
		grid-column: 1/-1;
	}
}
