#about-value-props {
	position: relative;

	#about-value-props-underlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		background-color: $cloud;
	}
}
