div.Toastify__toast {
	border-radius: 8px;
}

div.Toastify__toast-theme--light {
	color: $denim;
}

div.Toastify__progress-bar--success {
	background: $azure;
}

div.Toastify__toast-icon {
	svg {
		fill: $azure;
	}
}

.toastify-toast-close-icon {
	width: 14px;
	height: 16px;
	fill: $denim;
	opacity: 50%;
}
