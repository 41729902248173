#exemptions-landing-page {
	#landing-title-subtitle {
		padding-bottom: 32px;
		@include if-size("desktop") {
			padding-bottom: 0;
		}
	}

	#landing-title-section {
		position: relative;
		padding-bottom: 84px;
		@include if-size("desktop") {
			padding-bottom: 118px;
		}
	}

	#landing-title-input-section {
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: 1/8;
		}
	}

	#exemptions-landing-title-hero-img {
		grid-column: 1/-1;
		padding: 32px;
		@include if-size("desktop") {
			grid-column: 9/-1;
			padding: 0;
		}
	}

	#landing-hero-address-input {
		padding: 0;
		margin: 0;
	}

	#landing-hero-address-search-with-results {
		max-width: 100%;
	}

	#landing-hero-value-props {
		position: absolute;
		bottom: -40px;
		left: 50%;
		transform: translateX(-50%);
	}

	#landing-marquee-section {
		padding-top: 56px;
		padding-bottom: 85px;
	}

	#landing-signup-steps-section {
		grid-column: 1/-1;
		#landing-signup-steps-steps {
			align-items: flex-start;
		}
	}

	#landing-stat-section-stats {
		padding: 64px 0;

		@include if-size("desktop") {
			padding: 96px 0 64px;
			align-items: flex-start;
		}
	}

	#appeal-page-appeal-process-section {
		padding-top: 64px;
		@include if-size("desktop") {
			padding-top: 96px;
		}
	}
	#landing-faq-section {
		padding-top: 64px;
		@include if-size("desktop") {
			padding-top: 96px;
		}
	}
}
#exemptions-value-prop-1 {
	@extend #investors-value-prop-1;
}

#exemptions-data-card-section {
	position: relative;
	background-color: $sky-light;
}

.exemptions-value-prop-1-button-container {
	margin-top: 32px;
}

.exemptions-value-prop-1-button {
	margin: auto;
}

#exemptions-data-card-underlay {
	@extend #investor-data-card-underlay;
	background-color: $white;
	z-index: 100;
}

#exemptions-pricing-card-section {
	background-color: $sky-light;
	padding: spacing(8) 0;

	@include if-size("desktop") {
		padding: spacing(12) 0;
	}
}

#exemptions-value-prop-2 {
	background-color: $sky-light;
	margin-bottom: spacing(0);
	padding-bottom: spacing(8);
	@include if-size("desktop") {
		padding-bottom: spacing(12);
	}
}

#exemptions-ratings-footer {
	@extend #appeal-flow-ratings-footer;
	max-width: 100%;
	padding-bottom: spacing(12);
}

#exemptions-landing-page-spacer {
	margin-top: 90px;
}
