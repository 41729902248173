#current-mortgage-rates-card {
	order: 1;
	position: relative;
	background-color: $white;
	box-shadow:
		-2px 13px 28px 0px #ababab1a,
		-7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d,
		-28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	border: solid 1px $slate;
	border-radius: 16px;
	padding: spacing(2);
	height: min-content;
	display: flex;
	flex-direction: column;
	gap: 12px;
	@include if-size("desktop") {
		grid-column: span 1;
		order: 4;
		padding: spacing(3);
	}

	.mortgage-rate-box {
		flex: 1;
		padding: 12px 16px 14px 12px;
		border-radius: 8px;
		background: linear-gradient(
			180deg,
			#bbccd4 -41.01%,
			rgba(247, 249, 252, 0) 100%
		);
	}

	.your-current-mortgage-box {
		border-radius: 8px;
		border: solid 1px $slate;
		padding: spacing(2);
	}

	.positive {
		&::before {
			content: "+";
		}

		color: $rust;
	}

	.negative {
		color: $kelly-dark;
	}

	.quick-link {
		.quick-link-link {
			font-size: 14px;
			line-height: 16px;
			font-weight: 600;
			@include if-size("desktop") {
				font-size: 16px;
			}
		}

		.quick-link-arrow {
			margin-left: 5px;
		}

		svg {
			width: 12px;
			height: 12px;
		}
	}

	&.skeleton {
		.mortgage-rate-box {
			@include shimmer-bg-white;
			height: 75px;
		}

		.your-current-mortgage-box {
			@include shimmer-bg-white;
			height: 75px;
			width: 100%;
			border: none;
		}
	}

	.refi-calc-info-box,
	.refi-monitoring-info-box {
		border: 1px solid $slate;
		padding: 16px;
		border-radius: 8px;
		display: flex;
		gap: 12px;
		justify-content: space-between;
		align-items: start;
		.box-content {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}
}
