#property-nav {
	background: $white;
	color: $denim;
	overflow-x: hidden;
	border-right: 1px solid $slate;
	flex-direction: column;
	gap: 16px;
	padding: spacing(2);
	display: flex;
	position: absolute;
	min-width: none;
	width: 85%;
	max-width: 100%;
	z-index: 1000;
	height: 100dvh;
	top: 0;
	display: none;

	@include if-size("desktop") {
		display: flex;
		position: relative;
		top: 0;
		min-width: 224px;
		max-width: 224px;
		overflow-y: auto;
		height: 100%;
	}

	&.mobile-show {
		display: flex;
		position: fixed;
		top: 0px;
		background-color: $denim-lightest;
		overflow: initial;
		z-index: 1000001;
		max-width: 350px;

		.property-nav-mobile-one-property {
			border-radius: 8px;
			background-color: $white;
			padding: 12px;
		}

		.property-nav-mobile-select-label {
			color: $denim-medium;
		}

		.property-nav-mobile-select {
			padding-top: 4px;
			width: 100%;

			select {
				padding-right: 28px;
				font-size: 16px;
			}
		}

		.property-details-box {
			background-color: $white;
			border: 1px solid transparent;
			padding: 12px;
			&:hover {
				border: 1px solid $slate;
			}
		}

		#top-nav-premium-button {
			display: flex;
		}
		#property-nav-mobile-logo-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.ownwell-logo {
				fill: $denim;
			}
		}
	}

	.property-location {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 16px;

		address {
			font-style: normal;

			.property-street {
				color: $denim;

				font-weight: 600;

				font-size: 20px;
				line-height: 24px;

				@include if-size("tablet") {
					font-size: 20px;
					line-height: 28px;
				}
			}

			.property-city-state {
				color: $denim;

				font-size: 14px;
				line-height: 18px;

				@include if-size("tablet") {
					font-size: 12px;
					line-height: 16px;
				}
			}
		}
	}

	.add-property-button {
		margin-left: 16px;
	}

	.property-details-box {
		border: solid 1px $slate;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		margin-top: auto;
		border-radius: 8px;
		padding: spacing(2);
		width: 100%;
		flex-wrap: wrap;
		background-color: $white;

		&:hover {
			cursor: pointer;
			background-color: rgba(247, 249, 252, 0.4);

			.view-more-text,
			.view-more-add {
				color: $royal !important;
				fill: $royal;
			}
		}

		.property-attribute {
			display: flex;
			flex-direction: column;

			&-name {
				color: $denim;
				white-space: nowrap;
				text-transform: uppercase;

				&:nth-child(2) {
					color: $denim-medium;
				}
			}
		}

		.view-more {
			margin-left: auto;
			@include if-size("desktop") {
				margin: 0;
				display: flex;
				align-items: center;
				gap: 4px;
				width: auto;
			}

			&:hover {
				cursor: pointer;
			}

			svg {
				fill: $azure;
			}

			&-text {
				display: none;
				@include if-size("desktop") {
					display: unset;
				}
			}

			&-add {
				width: 12px;
				height: 12px;
				@include if-size("desktop") {
					width: 12px;
					height: 12px;
					margin-top: 4px;
				}
			}
		}
	}

	.property-nav-links {
		width: 100%;
		display: flex;
		gap: 6px;
		align-items: center;
		padding: 0;
		flex-direction: column;
		align-items: flex-start;

		&::-webkit-scrollbar {
			display: none;
		}

		.property-nav-link {
			width: 100%;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 8px;
			width: 100%;

			p {
				padding: 8px 16px;
			}

			svg {
				margin-top: 2px;
				fill: $denim;
			}

			&.mr-auto {
				margin-right: auto;
			}
		}

		.property-nav-link-active {
			background-color: $sky-light;
			border-radius: 4px;

			a,
			p {
				color: $royal;
				font-weight: 600;
			}

			a > svg {
				fill: $royal;
				color: $royal;
			}
		}

		.property-nav-link:hover {
			@extend .property-nav-link-active;
		}
	}

	&.animation-disabled {
		&.collapsed {
			.property-details-box {
				display: none;
			}

			.property-satellite-image {
				display: none;
			}
		}
	}

	$transition-time: 500ms;
	&.animation-enabled {
		height: 190px;
		transition:
			height $transition-time,
			padding-top $transition-time;
		@include if-size("tablet") {
			height: 216px;
		}
		@include if-size("desktop") {
			height: auto;
			transition: none;
		}

		.property-location {
			transition: gap $transition-time;
			@include if-size("desktop") {
				transition: none;
			}

			.property-satellite-image {
				transition: width $transition-time;
				@include if-size("desktop") {
					transition: none;
				}
			}
		}

		.property-details-box {
			position: absolute;
			bottom: 58px;
			opacity: 1;
			transition:
				bottom $transition-time,
				opacity $transition-time;
			@include if-size("tablet") {
				bottom: 62px;
			}
			@include if-size("desktop") {
				position: unset;
				bottom: unset;
				opacity: unset;
				transition: none;
			}
		}

		.property-nav-links {
			transition: bottom $transition-time;
		}

		&.collapsed {
			height: 116px;
			padding-top: 0px;
			@include if-size("tablet") {
				height: 124px;
			}
			@include if-size("desktop") {
				height: auto;
				padding-top: spacing(4);
			}

			.property-location {
				gap: 0px;
				@include if-size("desktop") {
					gap: spacing(2);
				}
				.property-satellite-image {
					width: 0;
					@include if-size("desktop") {
						width: 192px;
					}
				}
			}

			.property-details-box {
				position: absolute;
				bottom: 16px;
				opacity: 0;
				pointer-events: none;
				@include if-size("desktop") {
					position: unset;
					bottom: unset;
					opacity: unset;
					pointer-events: unset;
				}
			}
		}
	}

	.property-nav-new-badge {
		display: none;

		@include if-size("desktop") {
			display: block;
			background: $sky;
			padding: 2px spacing(1);
			margin-left: spacing(1);
			border-radius: 4px;
		}

		.body-tiny {
			color: $royal !important;
		}
	}
}

.property-satellite-image,
.obscured-portal-layout-sidebar-image {
	width: 96px;
	height: 64px;

	@include if-size("tablet") {
		width: 144px;
		height: 80px;
	}

	@include if-size("desktop") {
		width: 192px;
		height: 80px;
	}

	border-radius: 8px;
	border: 1px solid $slate;
	box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
}

#premium-property-nav-box {
	padding: 12px 13px;
	border-radius: 8px;
	background: $sky-light;
	position: relative;
	cursor: pointer;
	display: block;

	&.disabled {
		pointer-events: none;
	}
	/*this is to create a border radius with the gradient border 
	+ is compatible for opaque backgrounds*/
	&::before {
		content: "";
		position: absolute;
		inset: 0;
		border-radius: 8px;
		padding: 1px; /* control the border thickness */
		background: linear-gradient(45deg, #57c975, #1c7ce5);
		-webkit-mask:
			linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}

	&:hover {
		background-color: $sky;

		.premium-property-nav-box-link > p {
			color: $royal;
		}
	}

	.premium-diamond-icon {
		width: 25px;
		height: 19px;
		position: relative;
		top: 2px;
	}

	.premium-property-nav-box-diamond-bg {
		position: absolute;
		top: 0;
		right: 0;
	}

	.premium-property-nax-box-title {
		padding-left: 2px;
	}

	.premium-property-nav-box-link {
		display: flex;
		align-items: center;
		padding-left: 28px;
		gap: 1px;

		@include if-size("desktop") {
			justify-content: center;
			padding-left: 0;
		}

		p {
			color: $azure;
		}

		svg {
			fill: $azure;
			width: 12px;
			height: 12px;
			position: relative;
			top: 1px;
		}
	}
}

#propery-nav-mobile-overlay {
	background-color: rgba(5, 50, 73, 0.8);
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1000000;
	position: fixed;
	cursor: pointer;
}

.property-nav-mobile-overlay-close-icon {
	position: absolute;
	right: -45px;
	top: 16px;
	z-index: 1000000;
	background-color: $white;
	padding: 7px 8px;
	border-radius: 100px;

	@include if-size("tablet") {
		right: -60px;
	}
}

.add-property-footer {
	display: flex;
	align-items: center;
	gap: 1px;
	cursor: pointer;

	&:hover {
		p {
			color: $royal;
		}
		svg {
			fill: $royal;
		}
	}
	p {
		color: $azure;
	}
	svg {
		width: 12px;
		height: 12px;
		fill: $azure;
		position: relative;
		top: 2px;
	}
}
