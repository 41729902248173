input {
	font-size: 16px;
	border-radius: 8px;
	border: 1px solid $input-border-default;
	padding: spacing(1);
	font-family: "sofia-pro";
	color: $denim;

	@include if-size("tablet") {
		font-size: 20px;
		padding: spacing(1) spacing(2);
	}

	&:active,
	&:focus,
	&:hover {
		outline: none;
		border-color: $denim;
	}

	&:disabled {
		cursor: not-allowed;
		border: 1px solid #7f939d;
		color: $denim;
		background-color: $cloud;
		opacity: 0.5;
	}
}

.input-warning {
	border-style: solid;
	border-color: $terracotta;
	border-width: 2px;

	&:focus {
		border-color: $terracotta;
	}
}

.input-label {
	margin-left: spacing(1);
}

.input-email-suggestion {
	padding-left: 2px;

	span {
		cursor: pointer;
	}
}

.sq-ft-input {
	position: relative;

	&::after {
		position: absolute;
		content: "SQ FT";
		right: 16px;
		font-size: 14px;
		bottom: 12px;
		color: $denim;

		@include if-size("tablet") {
			bottom: 14px;
			font-size: 18px;
		}
	}
}

.percentage-input {
	position: relative;

	&::after {
		position: absolute;
		content: "%";
		right: 16px;
		font-size: 14px;
		bottom: 12px;
		color: $denim;

		@include if-size("tablet") {
			bottom: 14px;
			font-size: 18px;
		}
	}
}

.select-field-input-wrapper,
.text-field-input-wrapper {
	width: 100%;
	position: relative;

	input,
	select {
		width: 100%;
	}

	&:has(input.with-label),
	&:has(select.with-label) {
		margin-top: spacing(1);
	}

	.text-field-show-password-container {
		cursor: pointer;
		position: absolute;
		right: spacing(2);
		top: 50%;
		transform: translateY(-50%);
	}

	&:has(input[data-password-valid="true"]) {
		&::after {
			content: url("/checkmark_small.svg");
			position: absolute;
			right: 16px;
			top: 50%;
			transform: translateY(-50%);
		}

		.text-field-show-password-container {
			right: spacing(5);
		}
	}

	.text-field-input-inner-right-label {
		position: absolute;
		top: 14px;
		right: 16px;

		p {
			line-height: 13px;
			color: $denim_5;

			@include if-size("tablet") {
				line-height: 17px;
			}
		}
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.label-container {
	display: flex;
	gap: 5px;
	justify-content: start;
	align-items: center;
	svg {
		cursor: pointer;
	}
	.tooltip2-body,
	.triangle-top {
		z-index: 12;
		background-color: $slate;
	}
	.tooltip2-body-text {
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.01em;
		text-align: left;
	}
	.tooltip2-body-label {
		font-size: 12px;
		font-weight: 700;
		line-height: 13.8px;
		letter-spacing: 0.08em;
		text-align: left;
		margin-bottom: 8px;
	}
}
