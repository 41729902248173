#modal-portal {
	width: 100%;
	height: 100%;
	display: none;
}

#offer-modal-container {
	z-index: 9999999;
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba($denim, 0.5);
}

#offer-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	height: auto;
	width: 80%;
	max-width: 560px;
	background-color: #fff;
	display: grid;
	justify-content: center;
	border-radius: spacing(1);
	box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);

	#offer-modal-body {
		padding: spacing(2);

		@include if-size("tablet") {
			padding: spacing(4);
		}
	}

	.offer-modal-title {
		margin-bottom: 2px;

		@include if-size("tablet") {
			margin-bottom: spacing(1);
		}
	}

	.offer-modal-subtitle {
		margin-bottom: spacing(2);
	}

	.offer-modal-terms {
		color: $denim;
		opacity: 0.5;
		margin-bottom: spacing(2);
	}

	#offer-modal-close {
		height: 18px;
		width: 18px;
		z-index: 1000;
		background-color: #fff;
		border-radius: 50%;
		padding: 4px;
		cursor: pointer;
		position: absolute;
		right: spacing(1);
		top: spacing(1);
		box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);

		@include if-size("tablet") {
			height: 24px;
			width: 24px;
		}

		&.deadline-modal-close {
			border: 2px solid $denim;
			box-shadow: none;
		}
	}
}

.deadline-modal-cta {
	margin: spacing(2) auto 0;
}
