.trends-breadcrumbs {
	display: flex;
	gap: spacing(1);

	.trends-breadcrumb {
		display: flex;
		gap: spacing(1);
	}
}

.trends-map-card-and-map-stats {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: spacing(2);

	.trends-map-card {
		flex: 1;
		flex-basis: 752px;
	}

	.trends-map-stats {
		flex: 1;
		flex-basis: 368px;

		display: flex;
		flex-direction: column;
		gap: spacing(2);
	}
}

.trends-map-stat-card-cta {
	.trends-map-stat-card-cta-banner {
		background-color: $sky;
		padding: 6px 0px;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;

		.quick-link-link {
			letter-spacing: -0.015em;
		}
	}

	.trends-map-stat-card {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}
}

.trends-map-stat-card {
	border-radius: 16px;
	padding: spacing(2);
	background-color: $white;
	box-shadow: 0px 4px 16px 0px #7f939d2e;

	.trends-map-stat-card-title {
		font-size: 18px;
		font-weight: 800;
		line-height: 26px;
		letter-spacing: -0.02em;

		@include if-size("desktop") {
			font-size: 20px;
			font-weight: 800;
			line-height: 28px;
			letter-spacing: -0.01em;
		}
	}

	.trends-map-stat-card-value {
		color: $royal;
		font-size: 24px;
		font-weight: 800;
		line-height: 30px;
		letter-spacing: -0.008em;

		@include if-size("desktop") {
			font-size: 32px;
			font-weight: 800;
			line-height: 40px;
			letter-spacing: -0.03em;
		}
	}
}

.trends-map-card {
	padding: spacing(2);
	border-radius: 16px;
	background-color: $white;
	box-shadow: 0px 4px 24px 0px #7f939d2e;
	max-width: 100%;

	.trends-map-container {
		position: relative;

		.loader {
			background-color: $denim-lightest;
			border-radius: 8px;
			max-width: 100%;
			aspect-ratio: 8 / 6;
			display: flex;

			img {
				margin: auto;
			}
		}

		.trends-map-geo-info {
			pointer-events: none;
			position: absolute;
			background-color: $white;
			border-radius: 8px;
			padding: spacing(2);
			border: 1px solid #f7f9fc;
			box-shadow: 0px 4px 24px 0px #7f939d2e;

			bottom: 8px;
			left: 8px;
			min-width: 180px;
			@include if-size("desktop") {
				bottom: 16px;
				left: 16px;
				min-width: 290px;
			}

			.trends-map-geo-info-title {
				color: $azure;
				font-size: 16px;
				font-weight: 622;
				line-height: 24px;
				letter-spacing: -0.02em;

				@include if-size("desktop") {
					font-size: 18px;
					font-weight: 622;
					line-height: 26px;
					letter-spacing: -0.02em;
				}
			}
		}

		.map-buttons {
			position: absolute;
			top: 8px;
			left: 8px;
			display: flex;
			flex-direction: column;
			gap: spacing(2);

			.zoom-buttons {
				display: flex;
				flex-direction: column;

				.zoom-button {
					height: 32px;
					width: 32px;
					box-sizing: border-box;
					border: solid 1px $denim_5;
					box-shadow: 1px 3px 7px 2px #0532490d;
					border-radius: 4px;
					background-color: $white;

					display: grid;

					svg {
						margin: auto;
						width: 16px;
						height: 16px;
					}

					&:hover {
						cursor: pointer;
					}

					&.zoom-in-button {
						border-bottom: 1px solid #bbccd4;
						border-radius: 4px 4px 0 0;
					}

					&.zoom-out-button {
						border-top: none;
						border-radius: 0 0 4px 4px;
					}
				}
			}

			.reset-map-button {
				height: 32px;
				width: 32px;
				box-sizing: border-box;
				border: solid 1px $denim_5;
				box-shadow: 1px 3px 7px 2px #0532490d;
				border-radius: 4px;
				background-color: $white;
				padding: 6px 6px 7px 7px;

				svg {
					width: 17px;
					height: 17px;
				}

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.trends-map {
		background-color: $denim-lightest;
		border-radius: 8px;

		.rsm-geography {
			&:focus {
				outline: none;
			}
		}
	}

	.trends-map-card-title {
		color: $denim;
		font-size: 24px;
		font-weight: 800;
		line-height: 30px;
		letter-spacing: -0.02em;
	}
}

.trends-ownwell-source {
	color: $denim-medium;
	font-weight: 433;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.015em;
}

.trends-table-card {
	padding: spacing(3);
	border-radius: 16px;
	background-color: $white;
	box-shadow: 0px 4px 24px 0px #7f939d2e;
	width: 100%;

	.trends-table-and-search {
		.search-input-container {
			input.search {
				padding: 8px 48px;
				border: 1px solid $denim_5;
			}
		}
	}

	.trends-table-container {
		overflow: auto;
		max-height: 288px;
		border-bottom: 1px solid #ecece7;

		&::-webkit-scrollbar-track {
			background-color: $sand;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $denim-extra-light;
		}
	}

	.trends-table {
		border-spacing: 0;
		border-collapse: collapse;
		width: 100%;

		thead {
			background-color: $denim-lightest;
			position: sticky;
			top: 0;
			th {
				min-width: min-content;
				text-wrap: nowrap;
				text-align: left;
				padding: 12px;
				font-size: 14px;
				font-weight: 622;
				line-height: 16.8px;

				@include if-size("desktop") {
					padding: 12px 16px;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}

		tbody {
			td {
				min-width: min-content;
				text-wrap: nowrap;
				text-align: left;
				padding: 12px;
				font-size: 14px;
				font-weight: 433;
				line-height: 16.8px;

				@include if-size("desktop") {
					padding: 12px 16px;
					font-size: 16px;
					line-height: 24px;
				}

				a {
					text-decoration: underline;
				}
			}
		}

		thead {
			th {
				border-bottom: solid 1px #ecece7;
				border-right: solid 1px #ecece7;

				&:last-child {
					border-right: none;
				}
			}
		}

		tbody {
			tr {
				td {
					border-bottom: solid 1px #ecece7;
					border-right: solid 1px #ecece7;

					&:last-child {
						border-right: none;
					}
				}

				&:last-child {
					td {
						border-bottom: none;
					}
				}
			}
		}
	}

	.trends-table-card-title {
		color: $denim;
		font-size: 20px;
		font-weight: 800;
		line-height: 28px;
		letter-spacing: -0.01em;

		@include if-size("desktop") {
			font-size: 20px;
			font-weight: 800;
			line-height: 28px;
			letter-spacing: -0.01em;
		}
	}
}

.bg-sky {
	background-color: $sky;
}

.bg-sky-light {
	background-color: $sky-light;
}

.bg-white {
	background-color: $white;
}

.bg-denim-lightest {
	background-color: $denim-lightest;
}

.trends-hero-title {
	color: $denim;
	font-size: 40px;
	font-weight: 800;
	line-height: 48px;
	letter-spacing: -0.01em;

	@include if-size("desktop") {
		font-size: 56px;
		font-weight: 800;
		line-height: 64px;
		letter-spacing: -0.02em;
	}
}

.trends-title {
	color: $denim;
	font-size: 24px;
	font-weight: 800;
	line-height: 30px;
	letter-spacing: -0.02em;

	@include if-size("desktop") {
		font-size: 48px;
		font-weight: 800;
		line-height: 58px;
		letter-spacing: -0.03em;
	}
}

.trends-section-title {
	color: $denim;
	font-size: 32px;
	font-weight: 727;
	line-height: 40px;
	letter-spacing: -0.01em;

	@include if-size("desktop") {
		font-size: 48px;
		font-weight: 727;
		line-height: 58px;
		letter-spacing: -0.03em;
	}
}

.trends-body {
	color: $denim-medium;
	font-size: 14px;
	font-weight: 433;
	line-height: 18px;
	letter-spacing: -0.015em;

	@include if-size("desktop") {
		font-size: 16px;
		font-weight: 433;
		line-height: 22px;
		letter-spacing: -0.015em;
	}
}

#trends-nav-bar {
	position: sticky;
	z-index: 2999;
	top: 0px;
	transition: top 0.3s;
	border-bottom: 1px solid $slate;

	ul {
		max-width: 100%;
		height: 58px;
		grid-column: 1 / -1;
		list-style: none;
		display: flex;
		gap: spacing(3);

		@include if-size("desktop") {
			gap: spacing(4);
			justify-content: center;
		}
		overflow-x: auto;
		scrollbar-width: 0;
		-ms-overflow-style: none;

		background: $white;

		&::-webkit-scrollbar {
			display: none;
		}

		li {
			white-space: nowrap;
			padding: spacing(2) 0;
			cursor: pointer;
		}

		li.active {
			border-bottom: 2px solid $denim;
		}
	}
}

.trends-signup-box {
	background-color: $sky;
	border-radius: 16px;
	padding: spacing(3);

	display: flex;
	flex-direction: column;
	gap: spacing(3);

	@include if-size("desktop") {
		align-items: center;
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	.trends-signup-box-average-savings {
		background-color: $sky-light;
		border-radius: 8px;
		padding: spacing(2);

		box-shadow: 0px 4px 16px 0px #7f939d2e;

		.trends-signup-box-average-savings-amount {
			font-size: 48px;
			font-weight: 800;
			line-height: 56px;
			letter-spacing: -0.01em;

			@include if-size("desktop") {
				font-size: 64px;
				font-weight: 800;
				line-height: 74px;
				letter-spacing: -0.03em;
			}
		}

		.trends-signup-box-average-savings-description {
			font-size: 20px;
			font-weight: 622;
			line-height: 28px;

			@include if-size("desktop") {
				font-size: 20px;
				font-weight: 622;
				line-height: 28px;
			}
		}
	}

	.trends-signup-box-title {
		font-size: 20px;
		font-weight: 800;
		line-height: 24px;
		letter-spacing: -0.01em;

		@include if-size("desktop") {
			font-size: 24px;
			font-weight: 800;
			line-height: 30px;
			letter-spacing: -0.02em;
		}
	}

	.trends-signup-box-ctas {
		display: flex;
		flex-direction: column;
		gap: spacing(2);
		align-items: center;

		@include if-size("desktop") {
			flex-direction: row;
			align-items: center;
		}
	}
}

.signup-steps {
	.signup-steps-title {
		font-size: 40px;
		font-weight: 800;
		line-height: 48px;
		letter-spacing: -0.01em;

		@include if-size("desktop") {
			font-size: 56px;
			font-weight: 800;
			line-height: 64px;
			letter-spacing: -0.02em;
		}
	}

	.signup-steps-steps {
		display: flex;
		flex-direction: column;
		gap: spacing(4);

		@include if-size("desktop") {
			gap: spacing(2);
			flex-direction: row;
			justify-content: center;
		}

		.signup-step {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			width: auto;
			@include if-size("desktop") {
				width: 368px;
			}

			h3 {
				color: $denim;
				font-family: "sofia-pro";
				font-size: 20px;
				font-weight: 727;
				line-height: 28px;
				letter-spacing: -0.01em;
				text-align: center;

				@include if-size("desktop") {
					font-family: "sofia-pro";
					font-size: 24px;
					font-weight: 727;
					line-height: 32px;
					letter-spacing: -0.005em;
					text-align: center;
				}

				margin-bottom: spacing(1);
			}

			p {
				color: $denim-medium;
				font-family: "sofia-pro";
				font-size: 18px;
				font-weight: 433;
				line-height: 26px;
				letter-spacing: -0.02em;
				text-align: center;

				@include if-size("desktop") {
					font-size: 20px;
					font-weight: 433;
					line-height: 28px;
					letter-spacing: -0.01em;
					text-align: center;
				}
			}

			.img-circle {
				background-color: $white;
				box-shadow: 0px 4px 24px 0px #7f939d2e;
				border: 1px solid #ffffff;
				border-radius: 100px;

				width: 96px;
				height: 96px;

				@include if-size("desktop") {
					width: 128px;
					height: 128px;
				}

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				img {
					width: 48px;
					height: 48px;

					@include if-size("desktop") {
						width: 64px;
						height: 64px;
					}
				}

				margin-bottom: spacing(3);
			}
		}
	}
}

.trends-lower-tax-content-block {
	display: flex;
	gap: spacing(2);
	justify-content: center;
	flex-wrap: wrap;

	@include if-size("desktop") {
		align-items: start;
		flex-wrap: nowrap;
	}
}

.trends-tax-bill-img {
	min-width: 100%;

	@include if-size("desktop") {
		min-width: 368px;
	}
}

.trends-available-exemptions-card {
	border: 1px solid $slate;
	box-shadow: 0px 4px 24px 0px #7f939d2e;
	border-radius: 16px;
	overflow: hidden;

	min-width: 100%;

	@include if-size("desktop") {
		min-width: 272px;
	}

	> p {
		padding: spacing(2);
		background-color: $sky-light;
	}

	ul {
		padding: 0;
		list-style-type: none;

		li {
			padding: spacing(2);
			display: flex;
			justify-content: space-between;

			&:not(:last-child) {
				border-bottom: 1px solid $denim-lightest;
			}
		}
	}
}

#county-dates-and-links {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	flex-wrap: wrap;

	@include if-size("monitor") {
		flex-direction: row;
		gap: spacing(3);
	}

	.county-resources-card {
		height: fit-content;
		flex: 1;
		border-radius: 16px;
		position: relative;
		border: 1px solid $slate;
		box-shadow:
			-2px 13px 28px 0px #ababab1a,
			-7px 51px 51px 0px #ababab17,
			-16px 115px 70px 0px #ababab0d,
			-28px 204px 82px 0px #ababab03,
			-44px 319px 90px 0px #ababab00;

		.county-resources-table {
			width: 100%;
			margin-top: 0;
			height: fit-content;

			thead {
				background-color: $sky-light;
			}

			tbody {
				background-color: $white;

				tr,
				td {
					border-color: $denim_lightest;
				}
			}
		}
	}
}

.tax-bill-calculator {
	.tax-bill-calculator-dollar-input-container {
		margin-bottom: spacing(2);

		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: spacing(2);

		flex-wrap: wrap;
		@include if-size("desktop") {
			flex-wrap: nowrap;
		}

		label {
			font-family: "sofia-pro";
			font-size: 20px;
			font-weight: 727;
			line-height: 28px;
			letter-spacing: -0.01em;
			text-align: left;

			color: $denim_5;
			text-transform: none;

			width: 100%;
			@include if-size("desktop") {
				width: auto;
			}
		}
	}

	.tax-bill-calculator-slider {
		margin-bottom: spacing(1);

		.rc-slider {
			height: 36px;
			padding: spacing(2) 0;
		}

		.rc-slider-rail {
			height: 8px;
			background-color: $slate;
		}

		.rc-slider-track {
			height: 8px;
			background-color: $azure;
		}

		.rc-slider-handle {
			background-color: $white;
			border: 3px solid $azure;
			opacity: 1;
			height: 36px;
			width: 36px;
			top: 7px;

			&.rc-slider-handle-dragging {
				box-shadow: none;
				border: 3px solid $azure;
			}
		}
	}

	.tax-bill-info-cards {
		display: flex;
		gap: spacing(2);
		flex-direction: column;

		@include if-size("desktop") {
			flex-direction: row;
		}
		.tax-bill-info-card {
			flex: 1;
			border: 1px solid $slate;
			box-shadow: 0px 4px 16px 0px #7f939d2e;
			background: $denim-lightest;
			border-radius: 16px;
			padding: spacing(2);

			.ownwell-logo {
				margin-top: 4px;
			}

			.strikethrough {
				text-decoration: line-through;
				text-decoration-color: $rust-dark;
			}

			h3 {
				letter-spacing: -0.03em;
			}
		}
	}
}

.trends-save-with-ownwell {
	@include if-size("desktop") {
		max-width: 368px;
	}

	.trends-save-with-ownwell-savings-amount {
		font-size: 48px;
		font-weight: 800;
		line-height: 56px;
		letter-spacing: -0.01em;

		@include if-size("desktop") {
			font-size: 64px;
			font-weight: 800;
			line-height: 74px;
			letter-spacing: -0.03em;
		}
	}

	.trends-save-with-ownwell-savings {
		font-size: 16px;
		font-weight: 622;
		line-height: 22px;
		letter-spacing: -0.01em;

		@include if-size("desktop") {
			font-size: 18px;
			font-weight: 622;
			line-height: 26px;
			letter-spacing: -0.02em;
		}
	}

	.trends-save-with-ownwell-top-banner {
		border-radius: 16px 16px 0 0;

		background-color: $kelly-dark;
		padding: 6px 0px;

		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;

		p {
			color: $white;

			font-size: 18px;
			font-weight: 800;
			line-height: 26px;
			letter-spacing: -0.02em;
			@include if-size("desktop") {
				font-size: 20px;
				font-weight: 727;
				line-height: 28px;
				letter-spacing: -0.01em;
			}
		}

		.ownwell-logo {
			width: 24px;
			height: 24px;
		}
	}

	.trends-save-with-ownwell-content {
		background-color: $kelly-light;
		padding: spacing(3);
		border-radius: 0 0 16px 16px;

		/* override all default desktop styles */
		#landing-hero-address-input {
			padding: 0;

			&:has(#landing-hero-address-search.focused) {
				padding-bottom: 71px;

				@include if-size("desktop") {
					padding-bottom: 78px;
				}
			}

			#landing-hero-address-search-with-results {
				#landing-hero-address-search {
					flex-direction: column;
					gap: 8px;
					border-radius: 8px;
					padding: 12px;

					.search-separator {
						@include if-size("desktop") {
							display: block;
							width: 100%;
						}
					}

					&.focused {
						&:not(.results) {
							.search-separator {
								display: none;
							}
						}
					}

					.search-input-container {
						input.search {
							font-size: 16px;
							padding: 6px spacing(4) 8px;
							line-height: unset;

							@include if-size("desktop") {
								padding: 6px spacing(5) 8px;
							}
						}
					}

					button.button-v2.primary {
						width: 100%;
					}

					&.results {
						padding-bottom: 0;
						border-bottom: none;
						border-radius: 8px 8px 0 0;
					}
				}

				.search-results-separator-section {
					.search-results-separator-line {
						display: none;
					}
				}

				#landing-title-autocomplete-results-wrapper {
					border-radius: 0 0 8px 8px;
					max-width: 100%;

					.landing-title-autocomplete-item {
						margin: 8px 8px;
					}
				}
			}
		}
	}
}

.trends-tax-bill-calculator-save-with-ownwell-row {
	display: flex;
	flex-direction: column;
	gap: spacing(8);

	@include if-size("desktop") {
		flex-direction: row;
		justify-content: space-between;
		gap: 112px;
	}

	.trends-tax-bill-calculator {
		flex: 1;
	}
}

.trends-location-finder {
	.trends-location-finder-title {
		color: $denim;
		font-size: 20px;
		font-weight: 800;
		line-height: 24px;
		letter-spacing: -0.01em;

		@include if-size("desktop") {
			font-size: 24px;
			font-weight: 800;
			line-height: 30px;
			letter-spacing: -0.02em;
		}
	}

	.trends-location-finder-locations {
		display: flex;
		flex-wrap: wrap;

		gap: 12px;
		@include if-size("desktop") {
			gap: 16px;
		}

		.trends-location-finder-location {
			background-color: $sky-light;
			border-radius: 6px;
			padding: 6px;

			color: $azure;
			font-size: 10px;
			font-weight: 800;
			line-height: 11.5px;
			letter-spacing: -0.02em;
			box-shadow: 2px 3px 4px 0px #0532490d;

			border: 2px solid transparent;

			&:hover {
				border: 2px solid $azure;
				cursor: pointer;
			}

			@include if-size("desktop") {
				font-size: 14px;
				font-weight: 800;
				line-height: 16.1px;
				letter-spacing: -0.02em;
			}

			width: calc((100% - 24px) / 3);
			@include if-size("desktop") {
				width: calc((100% - 96px) / 5);
			}

			@include if-size("monitor") {
				width: calc((100% - 96px) / 7);
			}
		}
	}
}
