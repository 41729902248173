.loader {
	width: 100%;
}

.loading-img {
	margin: auto;
	width: 150px;

	img {
		width: 100%;
		height: auto;
	}
}

.loading-component {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
