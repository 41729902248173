#refinance-calculator-page {
	background-color: white;
	@include if-size("desktop") {
		background-color: $denim_lightest;
	}
	p {
		color: $denim;
	}
	.blur {
		background-color: white;
		opacity: 0.2;
		@include if-size("desktop") {
			opacity: 0.4;
		}
	}
	input,
	select {
		padding: 4px 12px;
		font-size: 16px;
		height: 30px;
	}
	label {
		font-size: 12px;
		font-weight: 700;
		line-height: 13.8px;
		letter-spacing: 1px;
		text-align: left;
		margin-bottom: 4px;
	}
	.percentage-input::after {
		bottom: 6px;
	}
	.refinance-calculator-header {
		.h5 {
			margin-bottom: 4px;
		}
		.back-btn {
			display: flex;
			justify-content: start;
			align-items: center;
			width: fit-content;
			margin-bottom: 10px;
			svg {
				height: 24px;
				width: 24px;
			}
			&:hover {
				color: $azure;
				svg {
					fill: $azure;
					path {
						fill: $azure;
					}
				}
			}
		}
	}
	.refinance-calculator-body {
		display: flex;
		margin-top: 16px;
		gap: 16px;
		flex-direction: column-reverse;
		@include if-size("desktop") {
			margin-top: 24px;
			gap: 24px;
			flex-direction: row;
		}
		.refinance-calculator-form {
			width: 100%;
			max-width: 400px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			.form-box {
				padding: 16px;
				background-color: $white;
				border: 1px solid $slate;
				border-radius: 16px;
			}
			.current-loan-info,
			.new-loan-info {
				.form-group {
					margin-top: 16px;
					display: flex;
					gap: 16px;
					flex-direction: column;
					label {
						color: $denim-medium;
					}
					.text-field-input-wrapper,
					.select-field-input-wrapper,
					select.with-label {
						margin: 0;
					}
					.slider-container {
						padding: 0 12px;
						background: linear-gradient(to right, #1c7ce5 50%, #d4d4d4 50%);
						height: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
						.rc-slider {
							.rc-slider-handle {
								top: 0;
								opacity: 1;
							}
						}
					}
				}
			}
			.new-loan-amount-input-container,
			.new-interest-input-container {
				position: relative;
				.onwell-tag {
					position: absolute;
					bottom: 0;
					z-index: 10;
					padding: 8px;
					right: 0;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					p {
						padding: 2px 6px;
						background-color: $cloud;
						border-radius: 4px;
					}
				}
				input {
					padding-right: 150px;
				}
			}
		}
		.refinance-calculator-results {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 16px;
			.bordered-box {
				padding: 16px;
				background-color: $white;
				border: 1px solid $slate;
				border-radius: 16px;
			}
			.result-outline {
				display: flex;
				flex-direction: column;
				height: fit-content;
				gap: 24px;
				.results-header {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
					.final-result {
						font-weight: 800;
						.red {
							color: $rust-dark;
						}
						.green {
							color: $kelly-dark;
						}
					}
				}
				.result-graph {
					width: 100%;
					height: 200px;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					.blur-div {
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						display: flex;
						justify-content: center;
						align-items: start;
						text-align: center;
						z-index: 20;
						border-top: 1px solid $sand-light;
						padding-top: 20px;
						@include if-size("desktop") {
							justify-content: center;
						}
					}
				}
				.results-body {
					display: flex;
					gap: 16px;
					flex-direction: column;
					flex-wrap: wrap;
					@include if-size("desktop") {
						flex-direction: row;
						gap: 24px;
					}
					.result-box {
						flex-grow: 1;
						flex-shrink: 0;
						background-color: $denim_lightest;
						border-radius: 8px;
						border: 1px solid $slate;
						padding: 24px 32px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						gap: 12px;
						.result-row {
							width: 100%;
							display: flex;
							justify-content: space-between;
							gap: 20px;
							align-items: center;
							p.semibold {
							}
							p.h5 {
							}
							.result-label {
								display: flex;
								gap: 10px;
								justify-content: start;
								align-items: center;
								.color-dot {
									width: 20px;
									height: 20px;
									border-radius: 50%;
									&.cost {
										background: #d8846d;
									}
									&.savings {
										background: #249241;
									}
									&.interest {
										background: #a42300;
									}
								}
							}
						}
					}
				}
			}
			.result-summary {
				height: fit-content;
				display: flex;
				gap: 8px;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				@include if-size("desktop") {
					flex-direction: row;
				}
				.house-img-container {
					width: 120px;
					height: 120px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.result-summary-body {
					display: flex;
					flex-direction: column;
					gap: 8px;
					justify-content: start;
					.green {
						color: $kelly-dark;
					}
					.red {
						color: $rust-dark;
					}
				}
			}
		}
	}
	.refinance-calculator-footer {
		.line-break {
			width: 100%;
			height: 1px;
			background-color: $sand-light;
			margin: 20px 0;
		}
		ul {
			margin-left: 24px;
		}
	}
}
