#referral-section-share-section {
	padding: spacing(6) 0 spacing(4);
	align-items: center;
}

#referral-section-share-header {
	grid-column: 1/-1;
	display: flex;
	align-items: center;

	@include if-size("desktop") {
		grid-column: 3/8;
	}

	#referral-section-share-img {
		width: 64px;

		@include if-size("tablet") {
			width: 120px;
		}
	}

	#referral-section-share-title {
		width: 100%;
		padding-left: spacing(2);
	}
}

#referral-section-list {
	ul {
		list-style-position: inside;
		padding-left: spacing(2);
	}
}

#referral-section-fine-print {
	margin: spacing(4) 0;

	p {
		font-size: 10px;
		line-height: 14px;

		@include if-size("tablet") {
			font-size: 12px;
			line-height: 16px;
		}
	}
}

#referral-section-share-text {
	p {
		grid-column: 1/-1;
		@include if-size("desktop") {
			grid-column: 3/11;
		}
	}
}
