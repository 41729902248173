#expenses-comparison-table {
	height: 100%;

	.expenses-comparison-card {
		padding: spacing(2);
		border: 1px solid $slate;
		border-radius: 8px;

		&:not(:last-of-type) {
			margin-bottom: spacing(1);
		}

		.appeal-info-question-card-tooltip {
			svg {
				height: 14px;
				width: 14px;
				margin-left: 4px;
				transform: translateY(-3px);

				@include if-size("desktop") {
					height: 16px;
					width: 16px;
					transform: translateY(-2px);
				}

				path {
					fill: $denim_5;
				}
			}
		}

		#expenses-comparison-card-mortgage-paid-off-badge {
			background: $kelly-light;
			border: 1px solid $kelly;
			border-radius: 6px;
			padding: spacing(0.5) spacing(1);
			width: fit-content;
			margin-bottom: spacing(2);

			> p {
				color: $kelly-dark;
				font-weight: 600;
			}
		}

		.expenses-comparison-card-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			&-no-expand {
				@extend .expenses-comparison-card-header;
				cursor: default;
			}

			.expenses-comparison-card-header-title {
				display: flex;
				align-items: center;
			}

			.expenses-comparison-card-header-value {
				display: flex;
				align-items: center;
				gap: spacing(1);
			}

			.expenses-comparison-card-caret {
				height: 12px;
				width: 12px;
				margin-left: spacing(1);
			}
		}

		#expenses-comparison-card-mortgage-interest-rate-container {
			background: $denim_lightest;
			padding: spacing(1);
			margin-bottom: spacing(2);
			border-radius: 8px;
		}

		#expenses-comparison-card-mortgage-total-payment-container {
			border-top: 1px solid $sand;
			padding-top: spacing(2);
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		// #expenses-comparison-card-mortgage-breakdown {
		// 	background: $cloud;
		// 	padding: spacing(1) spacing(2);
		// 	margin-top: spacing(2);
		// 	border-radius: 8px;
		// 	display: flex;
		// 	flex-direction: column;
		// 	gap: spacing(1);
		//
		// 	.expenses-comparison-card-mortgage-breakdown-line-item {
		// 		display: flex;
		// 		justify-content: space-between;
		// 		align-items: center;
		// 	}
		//
		// 	.expenses-comparison-card-mortgage-breakdown-line-item-value {
		// 		display: flex;
		// 		justify-content: space-between;
		// 		align-items: center;
		// 		gap: spacing(1);
		// 	}
		// }
	}
}

.expenses-comparison-card-header-value-badge {
	padding: 3px spacing(1);
	border-radius: 6px;

	&-monitoring {
		@extend .expenses-comparison-card-header-value-badge;
		background: $sky;
		border: 1px solid $azure;
		p {
			color: $royal;
		}
	}

	&-low {
		@extend .expenses-comparison-card-header-value-badge;
		background: $kelly-light;
		border: 1px solid $kelly;

		p {
			color: $kelly-dark;
		}
	}

	&-normal {
		@extend .expenses-comparison-card-header-value-badge;
		background: $sunflower-lightest;
		border: 1px solid $sunflower;
	}

	&-high {
		@extend .expenses-comparison-card-header-value-badge;
		background: rgba(206, 101, 73, 0.16);
		border: 1px solid $rust;

		p {
			color: $denim;
		}
	}
}

.expenses-comparison-slider {
	width: 100%;
	max-width: 588px;
	position: relative;
	height: 28px;
	margin: spacing(1) auto;

	.expenses-comparison-slider-container {
		display: flex;
		gap: 4px;
		position: relative;

		.expenses-comparison-slider-segment {
			height: 8px;
			border-radius: 8px;

			&:nth-child(1) {
				background: $kelly;
				flex: 1;
			}

			&:nth-child(2) {
				background: $sunflower;
				flex: 2;
			}

			&:nth-child(3) {
				background: $rust;
				flex: 1;
			}
		}

		.expenses-comparison-slider-thumb {
			position: absolute;
			height: 20px;
			width: 20px;
			border-radius: 10px;
			background: $sky;
			border: 4px solid $azure;
			top: 50%;
			transform: translateY(-50%);
		}

		.expenses-comparison-slider-range-label {
			position: absolute;
			top: 10px;
			transform: translateX(-50%);

			&-1 {
				@extend .expenses-comparison-slider-range-label;
				left: 25%;
			}

			&-2 {
				@extend .expenses-comparison-slider-range-label;
				left: 75%;
			}
		}
	}
}

.expenses-tooltip-label {
	margin-bottom: -4px;

	.appeal-info-question-card-tooltip {
		svg {
			height: 14px;
			width: 14px;
			margin-left: 4px;
			transform: translateY(-3px);

			@include if-size("desktop") {
				height: 16px;
				width: 16px;
				transform: translateY(-2px);
			}

			path {
				fill: $denim;
			}
		}
	}

	&-disabled {
		@extend .expenses-tooltip-label;

		.label {
			color: $denim_5;
		}

		.appeal-info-question-card-tooltip {
			svg {
				path {
					fill: $denim_5;
				}
			}
		}
	}
}
