a {
	outline: none;
	text-decoration: none;
	color: $azure;
}

a.active {
	@extend a;

	color: $navy;
	font-weight: 600;
}

a.underline-hover:hover {
	text-decoration: underline;
}

a.underline {
	text-decoration: underline;
}
