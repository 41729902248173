#expenses-page {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: spacing(3);

	#expenses-card {
		flex: 1 1;
		background: $white;
		box-shadow: 0px 8px 32px 0px rgba(127, 147, 157, 0.12);

		.quick-link-small {
			display: none;
		}
	}

	@include if-size("desktop") {
		flex-direction: row;
		align-items: flex-start;
	}

	#ways-to-save {
		width: 100%;
	}
}

#expenses-page-chart-card {
	flex: 1;
	border-radius: 16px;
	padding: spacing(2);
	height: fit-content;
	width: 100%;
	border: 1px solid #d4dee3;
	box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	@include if-size("desktop") {
		padding: spacing(3);
		min-width: 380px;
		max-width: 420px;
	}

	#expenses-page-chart-card-subheading {
		margin-top: 2px;
		margin-bottom: spacing(4);
	}

	.button-v2 {
		margin-top: spacing(3);
		width: 100%;
	}

	// #expenses-page-chart-card-chart-container {
	// 	height: 100%;
	// 	min-height: 320px;
	// }
}

#expenses-pie-chart-legend {
	height: fit-content;
	display: flex;
	justify-content: center;
	column-gap: spacing(2);

	#expenses-pie-chart-legend-col-1 {
		display: flex;
		flex-direction: column;
		row-gap: spacing(1);

		div {
			display: flex;
			align-items: center;
		}
	}
}

.expenses-pie-chart-legend-bullet {
	max-height: 16px;
	max-width: 16px;
	min-height: 16px;
	min-width: 16px;
	border-radius: 16px;
	margin-right: 4px;
}

#expenses-page-comparison-card {
	flex: 1;
	border-radius: 16px;
	padding: spacing(2);
	border: 1px solid #d4dee3;
	box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	@include if-size("desktop") {
		flex: 2;
		padding: spacing(3);
		min-width: 550px;
	}

	#expenses-page-comparison-card-header {
		display: flex;
		align-items: center;

		#expenses-page-comparison-card-header-title {
			width: fit-content;

			@include if-size("desktop") {
				width: 50%;
			}
		}

		#expenses-page-comparison-card-header-desktop-cta {
			display: none;

			@include if-size("desktop") {
				flex: 1;
				display: flex;
				justify-content: flex-end;
			}
		}

		#expenses-page-comparison-card-header-mobile-cta {
			flex: 1;
			display: flex;
			justify-content: flex-end;

			@include if-size("desktop") {
				display: none;
			}
		}
	}
}

.expenses-comparison-table-placeholder-card {
	width: 100%;
	padding: spacing(2);
	height: 56px;
	margin-bottom: spacing(2);
	border: 1px solid $slate;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20%;

	&:first-of-type {
		margin-top: spacing(2);
	}

	> div {
		&:nth-child(1) {
			flex: 1;
			height: 22px;
			border-radius: 8px;
		}

		&:nth-child(2) {
			width: 75px;
			height: 22px;
			border-radius: 8px;
		}
	}
}

#expenses-overview-card-content {
	background: $white;
	display: flex;
	flex-direction: column;
	margin-top: spacing(
		2
	); //TODO: wrap skeleton placeholder and this component in the same container cuz this is duplicated
	gap: spacing(1);
	padding: spacing(2);
	flex-wrap: wrap;
	border-radius: 8px;

	@include if-size("desktop") {
		flex-direction: row;
	}

	#expenses-overview-card-chart-container {
		flex: 2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: spacing(2);
	}

	#expenses-overview-card-comparison-container {
		flex: 3;
	}
}
