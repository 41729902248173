.preferences-page {
	@include flex("flex-start");
	height: 100%;
	width: 100%;
	color: $ra-navy;

	@include respond-to("medium") {
		width: 100%;
	}

	.preferences-container {
		@include flex("flex-start", "flex-start", "column");
		position: relative;
		width: 100%;
		max-width: 500px;
		margin: 0 auto;

		form {
			width: 100%;
		}

		@include respond-to("medium") {
			width: 100%;
		}

		.save-btn {
			width: 150px;
			margin-top: 40px;
		}

		.email-invalid-message {
			margin-top: 12px;
			color: red;
			text-align: center;
		}
	}

	.pref-label {
		text-decoration: underline;
		text-decoration-color: $ra-navy;
		margin: 0 0 16px;
	}

	.referral-input-container {
		@include flex("flex-start", "center");
		position: relative;

		.referral-input {
			@include base-input;
			border-radius: 4px 0 0 4px;
			border-right: none;
			text-overflow: ellipsis;
			font-size: 13px;
			margin-left: 5%;
			width: calc(90% - 120px);
			-webkit-box-shadow: 0 0 0 30px rgba(225, 241, 239, 1) inset !important;
			box-shadow: 0 0 0 30px rgba(225, 241, 239, 1) inset !important;
			-webkit-text-fill-color: rgba(52, 72, 94, 1);
			background-clip: content-box;
		}

		.send-invite-input {
			@include base-input;
			width: calc(90% - 120px);
			border-radius: 4px 0 0 4px;
			margin-left: 5%;
			box-shadow: none;
			font-size: 14px;
			color: $ra-navy;

			&:focus {
				outline: none;
			}
		}

		.copy-btn {
			@include flex("center", "center");
			position: absolute;
			right: 5%;
			top: 8px;
			height: calc(100% - 8px);
			border-radius: 0 4px 4px 0;
			width: 120px;
			background: $ra-teal;
			color: #fff;
			cursor: pointer;
			&:hover {
				background: #33877f;
			}

			&-disabled {
				@extend .copy-btn;
				cursor: not-allowed;
				background: #aaa;
				&:hover {
					background: #aaa;
				}
			}
		}
	}

	.send-invite-btn {
		@extend .copy-btn;
		background: $ra-navy !important;
		width: 120px !important;
		padding: 0 !important;
		border-radius: 0 4px 4px 0 !important;
		&:hover {
			background: #59799c !important;
		}
	}

	.referral-count-box {
		margin-top: 18px;
		width: 100%;
		background: #e1ebf5;
		padding: 12px;
		border-radius: 4px;
		color: $ra-navy;

		p > span {
			font-weight: 600;
		}
	}

	.pref-container {
		@include flex("flex-start", "flex-start", "column");
		margin-bottom: 24px;

		input[type="radio"] {
			display: none;
		}
		input[type="radio"] + *::before {
			content: "";
			display: inline-block;
			vertical-align: bottom;
			width: 1rem;
			height: 1rem;
			margin-right: 0.3rem;
			border-radius: 50%;
			border-style: solid;
			border-width: 0.1rem;
			border-color: gray $ra-teal;
		}

		input[type="radio"]:checked + * {
			color: teal;
		}
		input[type="radio"]:checked + *::before {
			background: radial-gradient(
				$ra-teal 0%,
				$ra-teal 40%,
				transparent 50%,
				transparent
			);
			border-color: $ra-teal;
		}

		input[type="radio"]:checked + * {
			color: teal;
		}
		input[type="radio"]:checked + *::before {
			background: radial-gradient(
				$ra-teal 0%,
				$ra-teal 40%,
				transparent 50%,
				transparent
			);
			border-color: $ra-teal;
		}

		/* basic layout */
		fieldset {
			margin: 20px;
			max-width: 400px;
		}
		label > input[type="radio"] + * {
			display: inline-block;
			padding: 0.5rem 1rem;
		}

		> div {
			margin: 0 0 10px 0;
			> label {
				font-size: 18px;
			}
		}
	}
}

.share-container {
	@include flex("center", "center");
	width: 100%;
	margin-top: 20px;

	.share-btn {
		margin: 0 10px;

		svg {
			width: 30px;
			height: 30px;
		}
	}
}

.login-btn-seperator {
	width: 80%;
	margin: 28px auto 0;
	text-align: center;
	position: relative;

	&:before {
		position: absolute;
		top: 50%;
		left: 0;
		height: 1px;
		width: 100%;
		content: "";
		background: #aaa;
		z-index: -5;
	}

	p {
		z-index: 5;
		color: #777;
		background: #fff;
		font-size: 14px;
		width: 40px;
		margin: 0 auto;
	}
}

.referral-tracking-container {
	margin: 24px auto;
	width: 90%;
}

.referral-title {
	text-align: center;
	@include text("30px", 600);
	margin-bottom: 18px;
}
.referral-input-title {
	@include text("18px", 600);
	text-align: center;
	width: 100%;
	margin: 22px 0 12px;
}

.referral-img {
	display: block;
	margin: 0 auto 12px;
	height: 175px;
	width: auto;
}
