#onboarding-survey-builder {
	.survey-builder-template-multiple-flows-fullscreen-nav {
		background-color: $denim-lightest;
		@include if-size("desktop") {
			background-color: white;
		}
	}

	#surveys-nav {
		width: 100%;
		overflow: auto;
		background-color: $denim-lightest;
		padding: spacing(2) 0;
		margin: 0 spacing(2);

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		&::-webkit-scrollbar-thumb {
			width: 0;
			height: 0;
		}

		@include if-size("desktop") {
			margin: unset;
			padding: unset;
			background-color: unset;
			@include styled-scrollbar();
		}

		&-address {
			display: none;

			@include if-size("desktop") {
				display: flex;
				align-items: center;
				gap: spacing(1);

				.denim-house-silhoutte {
					width: 20px;
					height: 20px;
				}
			}
		}

		.surveys-nav-groups {
			min-width: 100%;
			display: flex;
			flex-direction: row;
			gap: 2px;

			@include if-size("desktop") {
				flex-direction: column;
				gap: spacing(1);
			}
		}

		.surveys-nav-group {
			white-space: nowrap;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 0;
			gap: 2px;

			.surveys-nav-group-progress-dot {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: solid 1px $denim-extra-light;
				margin: 2px;
			}

			.get-started-user {
				width: 16px;
				height: 16px;
			}
			.ways-to-save-tag {
				width: 16px;
				height: 16px;
			}
			.evidence-package-folder {
				width: 16px;
				height: 16px;
			}
			.final-details-paper {
				width: 16px;
				height: 16px;
			}

			.check-in-azure-circle {
				display: none;
				width: 16px;
				height: 16px;
			}

			&.active,
			&.completed {
				p.sm {
					color: $azure;
				}

				.surveys-nav-group-progress-dot {
					border-color: $azure;
					background-color: $azure;
				}

				.get-started-user,
				.ways-to-save-tag,
				.evidence-package-folder,
				.final-details-paper {
					path {
						fill: $azure;
					}
				}
			}

			&.completed {
				.check-in-azure-circle {
					display: block;
					margin-left: auto;
				}
			}

			@include if-size("desktop") {
				background-color: $denim-lightest;
				padding: 6px spacing(1);
				gap: 4px;

				p.sm {
					font-weight: 700;
				}

				.surveys-nav-group-progress-dot {
					display: none;
				}

				.get-started-user {
					width: 21px;
					height: 21px;
				}
				.ways-to-save-tag {
					width: 24px;
					height: 24px;
				}
				.evidence-package-folder {
					width: 24px;
					height: 24px;
				}
				.final-details-paper {
					width: 18px;
					height: 18px;
				}

				.check-in-azure-circle {
					display: none;
					width: 20px;
					height: 20px;
				}
			}
		}

		.surveys-nav-group-spacer {
			min-width: 30px;
			border-top: 1px solid $denim-extra-light;
			margin: auto 0;

			@include if-size("desktop") {
				width: unset;
				border-top: unset;
				margin: unset;
				height: 24px;
				margin-left: 10px;
				border-left: 1px solid $denim-extra-light;
			}
		}
	}

	#property-equity-details-content {
		padding: 0;
	}

	#property-equity-details-footer {
		border-top: 0;
		padding: 0;

		margin-top: spacing(3);
	}

	.property-survey-evidence-modal-container {
		z-index: 1000000;
	}

	.concierge-payment-wrapper {
		.payment-card-wrapper,
		.plan-details-card {
			width: 100%;

			@include if-size("desktop") {
				width: unset;
			}
		}
	}

	.concierge-confirmation-content {
		gap: 0;
	}

	.congrats-handshake {
		width: 128px;
		height: auto;
	}

	#referral-modal {
		border-radius: 16px;
		margin: 0 auto;
		margin-top: spacing(3);
		position: relative;
		transform: none;
		top: unset;
		left: unset;
		flex-direction: column;
		max-width: 100%;
		min-width: unset;

		@include if-size("desktop-xl") {
			flex-direction: row-reverse;
		}

		.referral-modal-image-container {
			@include if-size("mobile-lg") {
				display: none;
			}

			@include if-size("desktop-xl") {
				display: block;
			}

			#referral-modal-image-dogs {
				transform: unset;
				position: relative;
				top: unset;
				left: unset;
				height: auto;
			}
		}

		#referral-modal-body {
			h1.display1 {
				white-space: wrap;
			}
		}
	}
}
