.step-counter {
	width: 100%;
	.step-counter-step-container {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		margin: 0 auto;

		.step {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 4px;

			&:first-of-type {
				.dot-row {
					.dot-line:first-of-type {
						background-color: transparent;
					}
				}
			}

			&:last-of-type {
				.dot-row {
					.dot-line:last-of-type {
						background-color: transparent;
					}
				}
			}

			.dot-row {
				width: 100%;
				flex: 1;
				display: flex;
				align-items: center;

				.dot-line {
					flex: 1;
					height: 2px;
					background-color: $denim-extra-light;
					z-index: 0;
				}

				.dot-stack {
					flex: 0 1 16px;
					width: 16px;
					height: 16px;
					position: relative;

					.dot {
						position: absolute;
						top: 0px;
						left: 0px;

						height: 16px;
						width: 16px;
						border-radius: 50%;
						border: 2px solid $denim-extra-light;
						background-color: $white;
						z-index: 2;

						&-active {
							background-color: $azure;
						}
					}

					.dot-current {
						position: absolute;
						top: -4px;
						left: -4px;

						height: 24px;
						width: 24px;

						border-radius: 50%;

						background-color: rgba($azure, 0.25);
					}
				}

				&-active {
					.dot-line {
						background-color: $azure;
					}

					.dot-stack {
						.dot {
							border: 2px solid $azure;
						}
					}
				}

				&-current {
					.dot-line:last-of-type {
						background-color: $denim-extra-light;
					}
				}
			}

			.step-label {
				display: none;

				@include if-size("desktop") {
					display: block;
				}
			}
		}
	}

	.mobile-step-label {
		display: block;

		@include if-size("desktop") {
			display: none;
		}
	}
}

.step-counter-100-width {
	width: 100%;
}

.step-counter-complete {
	@extend .step-counter;
}
