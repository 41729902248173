.search-input-container {
	position: relative;

	input.search {
		padding: 6px spacing(4) 8px;

		@include if-size("tablet") {
			padding: 14px spacing(6) 16px spacing(6);
			line-height: 30px;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active,
		&:-moz-autofill,
		&:-moz-autofill:hover,
		&:-moz-autofill:focus,
		&:-moz-autofill:active,
		&:autofill,
		&:autofill:hover,
		&:autofill:focus,
		&:autofill:active {
			box-shadow: 0 0 0 1000px white inset !important;
			background-color: transparent !important;
			-webkit-box-shadow: 0 0 0 1000px white inset !important;
			-moz-box-shadow: 0 0 0 1000px white inset !important;
			background-color: white !important;
			-webkit-background-clip: text !important;
			background-clip: text !important;
			transition: background-color 5000s ease-in-out 0s !important;
		}
	}

	input.search + label {
		margin-top: spacing(1);
	}

	.search-icon-left {
		position: absolute;
		top: calc(50%);
		left: 8px;
		transform: translateY(-50%);
		width: auto;
		height: 20px;
		fill: $terracotta;

		@include if-size("tablet") {
			left: 14px;
			height: 30px;
		}
	}

	.search-icon-right {
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		width: auto;
		height: 20px;
		cursor: pointer;
		fill: $azure;

		@include if-size("tablet") {
			right: 16px;
			height: 30px;
		}
	}
}
