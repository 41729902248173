#address-property-type-toggle {
	margin-top: 16px;
}
#multi-page-toggle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	margin: 0 auto;
	flex-direction: column;
	gap: 8px;
	z-index: 9;
	position: relative;
	.property-type-label {
		font-size: 12px;
		font-weight: 622;
		line-height: 14.4px;
		letter-spacing: 1px;
		color: $denim_5;
	}
	.toggle-btns {
		display: flex;
		padding: 3px 4px 3px 4px;
		border-radius: 24px;
		background-color: white;
		align-items: center;
		justify-content: center;
		gap: 2px;
		.toggle-btn {
			cursor: pointer;
			padding: 4px 12px;
			background-color: white;
			color: $denim_5;
			border-radius: 32px;
			&:hover {
				background-color: $denim_lightest;
			}
			&.active {
				background-color: $sky;
				color: $azure;
				&:hover {
					background-color: $sky;
				}
			}
		}
	}
	&.dark-mode {
		margin-bottom: 24px;
		.property-type-label {
			color: white;
		}
		.toggle-btns {
			background-color: $denim;
			.toggle-btn {
				background-color: $denim;
				color: $denim_5;
				&:hover {
					background-color: $denim-dark;
				}
				&.active {
					background-color: white;
					color: $denim-black;
					&:hover {
						background-color: white;
					}
				}
			}
		}
	}
	.page-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 9999;
	}
}
