#onboarding-intro {
	position: relative;
	overflow: hidden;
	height: 100%;

	#top-left-cloud {
		position: absolute;
		top: -45%;
		left: 0;

		@include if-size("desktop") {
			top: -40%;
		}
	}

	#right-cloud {
		max-height: 31px;
		max-width: 200px;
		position: absolute;
		left: 50%;
		top: 10%;
	}

	#left-cloud {
		max-height: 25px;
		max-width: 129px;
		position: absolute;
		left: 5%;
		top: 12%;

		@include if-size("tablet") {
			top: 18%;
		}

		@include if-size("desktop") {
			left: 30%;
			top: 22%;
		}
	}

	#bottom-right-cloud {
		position: absolute;
		bottom: 0;
		right: 0;
		max-height: 212px;
		max-width: 880px;
	}

	#house {
		position: absolute;
		max-height: 253px;
		max-width: min(779px, 100%);
		top: 50%;
		transform: translateY(-100%);
		text-align: center;

		h3 {
			font-family: "sofia-pro";
			font-style: normal;
			font-weight: 800;

			font-size: 32px;
			line-height: 58px;
			letter-spacing: -0.96px;
			padding: 0 spacing(2);

			@include if-size("desktop") {
				padding: 0;
				font-size: 42px;
				line-height: 58px;
				letter-spacing: -1.26px;
			}
		}

		p {
			font-family: "sofia-pro";
			font-style: normal;
			font-weight: 433;
			padding: 0 spacing(2);

			@include if-size("desktop") {
				padding: 0;
				font-size: 17px;
				line-height: 26px;
				letter-spacing: -0.36px;
			}
		}
	}

	@include if-size("desktop") {
		#top-left-cloud {
			position: absolute;
			top: -30%;
			left: 0;
		}

		#right-cloud {
			position: absolute;
			left: 70%;
			top: 30%;
		}

		#house {
			top: 50%;
		}
	}
}
