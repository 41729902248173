#more-ways-to-save-modal {
	display: flex;
	flex-direction: column;
	gap: spacing(2);

	p:first-of-type {
		margin-bottom: spacing(1);
	}

	form {
		display: flex;
		flex-direction: column;
		gap: spacing(2);

		.input-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			row-gap: spacing(2);
			column-gap: spacing(1);
			justify-content: space-between;

			background-color: $denim_lightest;

			border-radius: 8px;
			padding: 12px;

			@include if-size("desktop") {
				border-radius: 16px;
				padding: 16px;
			}

			p {
				margin: 0;
				@include if-size("desktop") {
					flex: 4;
				}
			}

			.radio-group {
				display: flex;
				flex-direction: row;
				gap: spacing(2);

				width: 100%;
				@include if-size("desktop") {
					justify-content: flex-end;
					width: auto;
					flex: 1;
				}

				.radio p {
					margin: 0;
				}
			}

			textarea {
				flex: 0 0 100%;
			}
		}
	}

	#more-ways-to-save-success-animation-container {
		margin: 0 auto;
		width: fit-content;
	}
}
