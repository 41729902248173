.tooltip-css {
	position: relative;
	width: fit-content;
	z-index: 1001;
}

.tooltip-css::before,
.tooltip-css::after {
	opacity: 0;
	position: absolute;
	z-index: 1001;
	line-height: 18px;
	font-size: 14px;
	font-family: $ff-secondary;
}

.tooltip-css-left::before,
.tooltip-css-left::after {
	left: -8px;
}
.tooltip-css:hover::before,
.tooltip-css:focus::before,
.tooltip-css:hover::after,
.tooltip-css:focus::after {
	opacity: 1;
	transform: scale(1) translateX(0);
	z-index: 1001;
}

/* Pointer tip */
.tooltip-css::before {
	border-style: solid;
	content: "";
	transition:
		all 0.3s cubic-bezier(0.84, -0.18, 0.31, 1.26),
		opacity 0.3s 0.1s;
}
.tooltip-css-left::before {
	border-width: 12px;
	border-color: transparent transparent transparent $azure;
	top: 6px;
	margin-left: -0.5em;
	transform: scale(0.6) translateX(-90%);
}

.tooltip-css:hover::before,
.tooltip-css:focus::before {
	transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
}

/* Speech Bubble */
.tooltip-css::after {
	background: $azure;
	border-radius: 8px;
	color: $white;
	content: attr(data-tip);
	padding: 12px 16px 16px;
	transition: all 0.3s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
	width: 17.5em;
	z-index: 1001;
}
.tooltip-css-left::after {
	left: -290px;
	top: -50%;
	transform: scale(0.6) translateX(50%);
	pointer-events: none;
}

.tooltip-css:hover::after,
.tooltip-css:focus::after {
	transition: all 0.4s cubic-bezier(0.84, -0.18, 0.31, 1.26);
}

.tooltip-css::after,
.tooltip-css::before {
	@media (max-width: 580px) {
		display: none;
	}
}

.tooltip2 {
	position: relative;

	&.disabled {
		* {
			cursor: not-allowed !important;
		}
	}

	&-trigger {
		display: grid;
		*:hover {
			cursor: pointer;
		}

		position: relative;

		.triangle-top {
			position: absolute;
			top: 100%;
			left: 50%;
			width: 16px;
			height: 16px;
			border-radius: 3px 0px 0px 0px;
			transform: translate(-50%, 4px) rotate(45deg);
			background-color: $azure;

			pointer-events: none;
			opacity: 0;
			&.reveal {
				opacity: 1;
				transition: all 0.25s ease-in-out;
				pointer-events: all;
			}
		}
	}

	&-direction-left {
		.tooltip2-transparent-cursor-bridge {
			right: -20px;
			left: unset;

			.triangle-top {
				right: 18px;
				left: unset;
			}
		}
	}

	&-direction-right {
		.tooltip2-transparent-cursor-bridge {
			left: -20px;
			right: unset;
		}
	}

	&-transparent-cursor-bridge {
		padding-top: 12px;
		position: absolute;
		top: 100%;
		left: -20px;

		pointer-events: none;
		&.reveal {
			pointer-events: all;
		}
	}

	&-body {
		position: relative;
		pointer-events: none;
		text-align: left;
		background-color: $azure;
		border-radius: 8px;
		padding: spacing(2);
		box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
		opacity: 0;
		min-width: 150px;

		@include if-size("desktop") {
			width: 260px;
		}

		z-index: 2;

		&.reveal {
			opacity: 1;
			transition: all 0.2s ease-in-out;
			pointer-events: all;
		}

		.tooltip2-body-label {
			color: $white;
			text-transform: uppercase;
			letter-spacing: 0.08em;
			margin-bottom: 2px;

			font-size: 10px;
			line-height: 11.5px;
		}

		.tooltip2-body-text {
			color: $white;
			white-space: normal;
			text-transform: none;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: normal;
		}
	}
}

.tooltip3 {
	position: relative;
	grid-column: 1 / -1;
	width: fit-content;

	.tooltip3-body {
		position: absolute;
		top: calc(100% + 12px);
		left: 0;
		z-index: 1000;
		width: 275px;
		height: auto;
		background-color: $azure;
		border-radius: 8px;
		padding: spacing(2);
		box-shadow: 2px 2px 7px 2px rgba(5, 50, 73, 0.4);
		pointer-events: none;

		label {
			margin-bottom: spacing(1);
		}

		label,
		p {
			color: #fff;
		}
	}

	.tooltip3-text {
		display: flex;
		align-items: center;
		margin-top: spacing(1);
		cursor: help;
		width: fit-content;

		p {
			@extend .semibold;
			color: $azure;
			width: fit-content;
			margin-bottom: 2px;
		}

		.tooltip3-icon {
			position: relative;
			svg {
				width: 16px;
				margin-left: 1ch;
			}

			.triangle-top {
				position: absolute;
				left: 9px;
				top: 20px;
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 8px 16px 8px;
				border-color: transparent transparent $azure transparent;
			}
		}
	}

	&-small {
		@extend .tooltip3;

		.tooltip3-text {
			.tooltip3-icon {
				svg {
					min-width: 16px;
					max-width: 16px;
				}
			}
		}
	}
}

/* For Tooltip positioned top w/ arrow facing down - saved because this could be used in the future */
// .tooltip-css-above {
// 	position: relative;
// 	display: inline-block;
// }

// .tooltip-css-above::before,
// .tooltip-css-above::after {
// 	left: 50%;
// 	opacity: 0;
// 	position: absolute;
// 	z-index: 101;
// 	line-height: 18px;
// 	font-size: 14px;
// 	font-family: $ff-secondary;
// }

// .tooltip-css-above:hover::before,
// .tooltip-css-above:focus::before,
// .tooltip-css-above:hover::after,
// .tooltip-css-above:focus::after {
// 	opacity: 1;
// 	transform: scale(1) translateY(0);
// 	z-index: 101;
// }

// /* Pointer tip */
// .tooltip-css-above::before {
// 	border-style: solid;
// 	border-width: 1em 0.75em 0 0.75em;
// 	border-color: $azure transparent transparent transparent;
// 	top: -20px;
// 	content: "";
// 	margin-left: -0.5em;
// 	transition: all 0.3s cubic-bezier(0.84, -0.18, 0.31, 1.26), opacity 0.3s 0.1s;
// 	transform: scale(0.6) translateY(-90%);
// }

// /* Speech Bubble */
// .tooltip-css-above::after {
// 	background: $azure;
// 	border-radius: 8px;
// 	bottom: 160%;
// 	color: $white;
// 	content: attr(data-tip);
// 	margin-left: -8.75em;
// 	padding: spacing(2);
// 	transition: all 0.3s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
// 	transform: scale(0.6) translateY(50%);
// 	width: 17.5em;
// }

// .tooltip-css-above:hover::before,
// .tooltip-css-above:focus::before {
// 	transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
// }

// .tooltip-css-above:hover::after,
// .tooltip-css-above:focus::after {
// 	transition: all 0.4s cubic-bezier(0.84, -0.18, 0.31, 1.26);
// }

// .tooltip-css-above::after {
// 	@media (max-width: 760px) {
// 		line-height: 22px;
// 		margin-left: -5em;
// 		width: 10em;
// 	}
// }
