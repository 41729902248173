#amortization-schedule-card {
	order: 5;
	grid-column: 1 / -1;
	position: relative;
	background-color: $white;
	box-shadow:
		-2px 13px 28px 0px #ababab1a,
		-7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d,
		-28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	border: solid 1px $slate;
	border-radius: 16px;
	padding: spacing(2);
	height: min-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	@include if-size("desktop") {
		grid-column: span 2;
		padding: spacing(3);
	}
	.card-subtext {
		color: $denim_5;
		font-weight: 400;
	}
	.card-top-section {
		display: flex;
		gap: spacing(3);
		flex-direction: column;
		@include if-size("tablet") {
			flex-direction: row;
		}
		.heading-section {
			flex: 1;
			.card-subtext {
				color: $denim_5;
				font-weight: 400;
			}
		}
		.box {
			background-color: $denim_lightest;
			padding: 10px;
			border: solid 1px $slate;
			border-radius: spacing(1);
			display: flex;
			flex-direction: column;
			flex: 1;
			.box-label {
				color: $denim_5;
				font-size: spacing(2);
			}
			.box-value {
				color: $denim;
				font-size: spacing(3);
				font-weight: bold;
			}
		}
	}
	.amortization-schedule-graph {
		position: relative;
		width: 100%;
		#amortization-chart-wrapper {
			width: 100%;
			height: 400px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			&.blur {
				filter: blur(20px);
			}
			.custom-tooltip {
				background-color: $white;
				box-shadow:
					-2px 13px 28px 0px #ababab1a,
					-7px 51px 51px 0px #ababab17,
					-16px 115px 70px 0px #ababab0d,
					-28px 204px 82px 0px #ababab03,
					-44px 319px 90px 0px #ababab00;
				border: solid 1px $slate;
				border-radius: 16px;
				padding: spacing(2);
				display: flex;
				flex-direction: column;
				gap: spacing(1);
				.tooltip-label {
					color: $denim_5;
					font-size: 12px;
				}
				.tooltip-value {
					color: $denim;
					font-size: 14px;
					font-weight: bold;
				}
			}
		}
		.cta-wrapper {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 450px;
			max-width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 20px;
			text-align: center;
			padding: 20px;
			background-color: white;
			border-radius: 16px;
			border: solid 1px $slate;
			box-shadow:
				-2px 13px 28px 0px #ababab1a,
				-7px 51px 51px 0px #ababab17,
				-16px 115px 70px 0px #ababab0d,
				-28px 204px 82px 0px #ababab03,
				-44px 319px 90px 0px #ababab00;
		}
	}
}
