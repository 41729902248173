#not-served-img {
	margin: 45px 0;
}

#not-served-title {
	text-align: center;
	font-size: 32px;
	margin-bottom: 24px;
	color: $navy;
}

#not-served-description {
	text-align: center;
	font-size: 18px;
	line-height: 22px;
	max-width: 450px;
	margin-bottom: 70px;
	color: $navy;
}
