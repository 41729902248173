.roi {
	margin-left: 8px;
	padding: 6px 8px;
	border-radius: 8px;
	border: 1.5px solid $denim;
	background-color: $denim_lightest;

	font-size: 14px;
	line-height: 16px;
	letter-spacing: -0.02em;

	display: flex;
	align-items: center;
	gap: 4px;
	svg {
		width: 9px;
	}

	&-increase {
		color: $kelly-dark;
		border-color: $kelly;
		background-color: $kelly-light;

		svg path {
			fill: $kelly-dark;
		}
	}

	&-decrease {
		color: $rust_dark;
		border-color: $rust;
		background-color: $rust-lightest;

		svg path {
			fill: $rust_dark;
		}
	}
}
