.toggle-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	.toggle-options {
		width: fit-content;
		display: flex;
		background: $denim_lightest;
		padding: 3px 4px;
		border-radius: 24px;
		justify-content: center;
		align-items: center;
		text-align: center;
		.toggle-option {
			border-radius: 16px;
			color: $denim_5;
			padding: 4px 12px;
			@include if-size("desktop") {
				padding: 4px 28px;
			}
			cursor: pointer;
			&.selected {
				border: 1px solid $azure;
				color: $azure;
				background-color: $sky;
			}
		}
	}
}
