.taxes-total-saved {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 4px;

	flex-wrap: wrap;
	padding: spacing(3);

	border: 2px solid $kelly;
	border-radius: 16px;
	background-color: $kelly-light;
	position: relative;
	box-shadow: -2px 13px 28px 0px #ababab1a, -7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d, -28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	&:hover {
		cursor: pointer;
	}

	&-tooltip {
		&-text {
			margin: auto;
			line-height: 1.4em;
			text-align: center;
			color: $denim;
		}

		&-icon {
			width: 12px;
			height: 12px;

			path {
				fill: $denim;
			}
		}

		&-body {
			pointer-events: none;
			position: absolute;
			left: 0%;
			width: 100%;
			background-color: $kelly-dark;
			border-radius: 8px;
			padding: spacing(1);
			box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
			opacity: 0;
			z-index: 1000;

			&.reveal {
				opacity: 1;
				transition: all 0.2s ease-in-out;
				top: calc(100% + 16px);
			}

			p.sm {
				font-size: 12px;
				color: $white;
			}
		}
	}

	&-value {
		font-weight: bold;
		font-size: 32px;
		line-height: 36px;
		color: $kelly-dark;

		@include if-size("monitor") {
			font-size: 40px;
			line-height: 46px;
		}
	}
}
