.taxes-tooltip {
	cursor: pointer;
	position: relative;

	&-trigger {
		display: grid;
	}

	&-body {
		text-align: left;
		pointer-events: none;
		position: absolute;
		top: 100%;
		left: -20px;
		transform: translate(0, 12px);
		background-color: $azure;
		border-radius: 8px;
		padding: spacing(2);
		box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
		opacity: 0;
		min-width: 150px;

		@include if-size("desktop") {
			width: 260px;
		}

		z-index: 2;

		.triangle-top {
			position: absolute;
			top: -7px;
			left: 20px;
			width: 16px;
			height: 16px;
			border-radius: 3px 0px 0px 0px;
			transform: rotate(45deg);
			background-color: $azure;
		}

		&.reveal {
			opacity: 1;
			transition: all 0.2s ease-in-out;
		}

		p.label {
			color: $white;
			text-transform: uppercase;
			letter-spacing: 0.08em;
			margin-bottom: 2px;

			font-size: 10px;
			line-height: 11.5px;
		}

		p.sm {
			color: $white;

			font-size: 14px;
			line-height: 18px;
		}
	}
}
