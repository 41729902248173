.takeover-reminder {
	background-color: $sunflower-lightest;
	border-radius: spacing(1);
	padding: spacing(2);
	display: flex;
	gap: spacing(2);
	flex-wrap: wrap;
	align-items: flex-start;
	margin-bottom: spacing(2);

	@include if-size("desktop") {
		flex-wrap: nowrap;
	}

	&-badge {
		background-color: $sunflower-light;
		width: fit-content;
		padding: 6px 8px;
		border-radius: spacing(1);

		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;

		svg {
			width: 14px;
			height: 14px;
		}
	}

	&-message {
		color: $denim;
	}
}
