#simple-table-view-container {
	max-width: 100%;
}

#simple-table-view {
	overflow-x: auto;
	@include styled-scrollbar;
	overflow: auto;
	table {
		border-collapse: collapse;
		overflow: auto;
		th {
			background-color: $denim-lightest;
			> div {
				padding: 12px 8px;
			}
		}
		td {
			height: 48px;
			@include if-size("tablet") {
				height: 56px;
			}
			background-color: $white;
			position: relative;

			> div {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				//overflow: hidden;
				padding: 0 8px;

				display: flex;
				align-items: center;
			}
		}

		td,
		th {
			z-index: 1;

			> div {
				border-left: solid 1px $sand;
				border-bottom: solid 1px $sand;
			}

			div p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
			}

			&:first-child > div {
				border-left: none;
			}

			&:last-child > div {
				border-right: none;
			}
			.header-cell {
				display: flex;
				justify-content: start;
				align-items: center;
				gap: 10px;
			}
		}
	}
}
