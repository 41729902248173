select {
	padding: spacing(1);
	border-radius: 8px;
	color: $denim;
	border: 1px solid $input-border-default;
	font-family: "sofia-pro";
	font-size: 16px;
	font-weight: 400;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: $white;
	background-image: url("data:image/svg+xml;utf8,<svg fill='%23b1b1bb' width='8' height='4' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
	background-repeat: no-repeat;
	background-position: calc(100% - 0.5rem) center;

	@include if-size("tablet") {
		font-size: 20px; //new
		padding: spacing(1) spacing(2);
		background-position: calc(100% - 0.8rem) center;
		background-image: url("data:image/svg+xml;utf8,<svg fill='%23b1b1bb' width='13' height='6' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
	}
	//IE11 - hide native dropdown icon
	&:-ms-expand {
		display: none;
	}

	&:invalid {
		color: $input-border-default;
		background-image: url("data:image/svg+xml;utf8,<svg fill='%23b1b1bb' width='8' height='4' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
		background-repeat: no-repeat;
		background-position: calc(100% - 0.5rem) center;

		@include if-size("tablet") {
			background-position: calc(100% - 0.8rem) center;
			background-image: url("data:image/svg+xml;utf8,<svg fill='%23b1b1bb' width='13' height='6' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
		}
	}

	option {
		color: $denim;
		font-size: 1em;
	}

	&:active,
	&:focus,
	&:hover {
		outline: none;
		border-color: $denim;
		background-image: url("data:image/svg+xml;utf8,<svg fill='%23053249' width='8' height='4' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
		@include if-size("tablet") {
			background-image: url("data:image/svg+xml;utf8,<svg fill='%23053249' width='13' height='6' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
		}
	}
}

select.with-label {
	margin-top: 8px;
}

select.empty {
	background-image: url("data:image/svg+xml;utf8,<svg fill='%23b1b1bb' width='8' height='4' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
	color: $input-border-default;

	@include if-size("tablet") {
		background-image: url("data:image/svg+xml;utf8,<svg fill='%23b1b1bb' width='13' height='6' viewBox='0 0 13 6' xmlns='http://www.w3.org/2000/svg'><path d='M1.25004 0.0427828C1.34849 0.0426508 1.446 0.0620155 1.53693 0.0997597C1.62785 0.137504 1.71041 0.19288 1.77983 0.262694L5.61479 4.09715C5.73082 4.21321 5.86858 4.30528 6.0202 4.36809C6.17182 4.4309 6.33432 4.46323 6.49844 4.46323C6.66255 4.46323 6.82506 4.4309 6.97668 4.36809C7.12829 4.30528 7.26605 4.21321 7.38208 4.09715L11.2111 0.267692C11.2802 0.196088 11.3629 0.138975 11.4544 0.0996837C11.5459 0.0603928 11.6442 0.0397117 11.7438 0.0388467C11.8433 0.0379817 11.9421 0.0569501 12.0342 0.0946456C12.1263 0.132341 12.21 0.188008 12.2804 0.2584C12.3508 0.328791 12.4065 0.412496 12.4442 0.504631C12.4819 0.596766 12.5008 0.695486 12.5 0.79503C12.4991 0.894575 12.4784 0.99295 12.4391 1.08442C12.3998 1.17588 12.3427 1.25861 12.2711 1.32776L8.44216 5.15722C7.92632 5.6721 7.22727 5.96127 6.49844 5.96127C5.76961 5.96127 5.07056 5.6721 4.55472 5.15722L0.719752 1.32277C0.614836 1.21792 0.54338 1.0843 0.514424 0.938833C0.485469 0.793361 0.500316 0.642569 0.557087 0.505538C0.613858 0.368506 0.710001 0.251394 0.833351 0.169021C0.956701 0.086648 1.10171 0.0427156 1.25004 0.0427828Z'/></svg>");
	}
}

select:disabled {
	cursor: not-allowed;
}
