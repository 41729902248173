.property-grid {
	display: grid;
	grid-template-columns: 100%;
	grid-auto-flow: row dense;
	gap: spacing(2);

	margin: 0 auto;
	width: 100%;
	max-width: 100%;

	@include if-size("desktop-xl") {
		grid-template-columns: 560px 368px;
		max-width: fit-content;
	}

	@include if-size("monitor") {
		grid-template-columns: minmax(560px, 786px) 368px;
	}

	@include if-size("monitor-lg") {
		grid-template-columns: 786px minmax(368px, 480px);
	}

	@include if-size("monitor-xl") {
		grid-template-columns: 786px 480px;
	}
}
