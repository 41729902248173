@mixin styled-scrollbar {
	&::-webkit-scrollbar {
		width: 14px;
		height: 14px;
	}

	&::-webkit-scrollbar-track {
		background-color: $cloud;
	}

	&::-webkit-scrollbar-thumb {
		width: 14px;
		height: 14px;
		background-color: $denim-extra-light;
		border-radius: 100px;

		border: 3px solid transparent;
		background-clip: padding-box; // <== make the border work
	}

	&::-webkit-scrollbar-thumb:hover {
		border-width: 0px;
		cursor: pointer;
	}
}

* {
	@include styled-scrollbar();
}
